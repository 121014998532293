
import { React, useEffect } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from "react-router-dom";
import Header from '../../Components/Header';
import SideBar from '../../Components/SideBar';
import { LuPencil } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";

import { patchForm, AddSection, fetchForms, deleteForm, setResponseDescription, setFormForEdit, setVisualDescription, setAction, setVisualFileUpload, setChecklistAdminResponse, setChecklistDescription, RemoveSection, setUserResponsePrompt, setIncludePar, setParValue, SaveItemToField, addNewForm, selectForms, getFormLoadStatus, addFieldToSection, removeFieldFromSection, clearForm } from './formSlice';
import styles from "./viewForm.module.css";

function viewForm(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const forms = useSelector(selectForms);
    const loadingforms = useSelector(getFormLoadStatus);


    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }
    useEffect(() => {

        if (loadingforms != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchForms({ org_id: user.org_id, createLocation: user?.viewingLocation }))
        }
    }, [loadingforms, dispatch])

    console.log("Pulled forms : ", forms, forms.Forms.docs, loadingforms, user)

    return (
        <div>

            <>

                <div className={styles.pageWrapper}>

                    <div className={styles.inputSection} >

                        <div>
                            <h4 style={{ marginTop: '1%' }}>Forms</h4>

                            <div style={{ textAlign: 'right', marginBottom: '3%' }}>
                                <h4 style={{ color: 'grey', textAlign: 'right', marginTop: '-1.1%' }}>Filters</h4>
                            </div>
                        </div>

                        {loadingforms == "Done" && forms?.Forms?.docs?.map((form, index2) => (
                            <div style={{ marginBottom: '1%', width: '100%' }}>
                                <span> {form.name} </span>

                                <div style={{ float: 'Right', justifyContent: 'space-between', display: 'flex', fontSize: '20px', width: '130px', }}>

                                   { /* <button onClick={() => { resetForm(form) }}> Reset form (To reuse) </button>*/}

                                    <LuPencil onClick={() => { openPage('Create_Form', form) }} />

                                    <FaRegTrashAlt onClick={() => { dispatchDeleteForm(form) }} />
                                </div>
                                <div className={styles.blackLineDiv2}>
                                    &nbsp;
                        </div>
                            </div>

                        ))}

                    </div>






                </div>

            </>
        </div>
    )


    async function resetForm(form) {
        // Create a deep copy of the form to avoid mutating the original
        const resetFormCopy = JSON.parse(JSON.stringify(form));

        // Ensure form and sections exist
        if (resetFormCopy.form && resetFormCopy.form.sections) {
            // Reset each section
            resetFormCopy.form.sections = resetFormCopy.form.sections.map(section => {
                // Keep only fields and type, reset everything else
                const resetSection = {
                    fields: section.fields.map(field => ({
                        ...field,
                        userResponse: field.userResponse.map(response => ({
                            question: response.question,
                            answer: "" // Clear the answer
                        })),
                        // Reset other field-level properties if they exist
                        //adminIncludeInOrder: "",
                      //  adminReviewCheckboxValue: "",
                       // adminReviewCheckboxToggle: "off"
                    })),
                    type: section.type,
                    // Reset section-level properties
                    submissions: [],
                    submitted: false,
                    cart: {},
                    checkedOut: {},
                    orderPlaced: {},
                    summary: null,
                    destinationType: "",
                    destinationFormat: "",
                    // Preserve any other essential properties that shouldn't be reset
                    assignedUser: section.assignedUser || [],
                    assignedJobPositions: section.assignedJobPositions || [],
                    frequency: section.frequency || {},
                    schedule: section.schedule || []
                };

                return resetSection;
            });



            await dispatch(patchForm({
                _id: form._id,
                name: form.name,
                form: resetFormCopy.form
            }));




        }
/*
        // Dispatch the patch action to update the form
        return {
            ...resetFormCopy,
            _id: form._id // Ensure _id is preserved
        };*/
    }


    function dispatchDeleteForm(form) {
        dispatch(deleteForm(form))
        dispatch(fetchForms())
    }


    function previewForm() {

    }

    function openPage(page, form) {
        const location = {
            pathname: `/${page}/${form.name}`,
            state: { formToDisplay: form }
        }
        console.log("Need to open this form :", form)
        dispatch(setFormForEdit(form)).then(() => {
            if (loadingforms == 'Done') {

            }

        })


        history.push(location);
        window.location.href = history.location.pathname

    }


}

export default viewForm;