import { React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { fetchForms, getFormLoadStatus, selectForms } from '../formBuilder/formSlice';
import { fetchItems, getItemLoadStatus, getitemsError, getLoadedItem, selectItems } from '../forms/items/itemSlice';
import { fetchJobPositions, getJobPositionLoadStatus, getjobPositionsError, getLoadedJobPosition, selectJobPositions } from '../forms/JobPositions/jobPositionSlice';
import { fetchUsers, getLoadedUser, getUserLoadStatus, getusersError, selectUsers, updateUser } from '../forms/users/UsersSlice';
import styles from "./task.module.css";
import {
    retrieveFileForFileUpload, uploadFile, retrieveFile, setParValue, setMonthValueForYearRecurrenceBottom, setDayValueForYearRecurrence, setMonthDayToRepeat, setYearOption, setMonthOption, setOrdinalValueMonth, setOrdinalValueYear, setDayValueOfMonthRecurrence, setMonthValueForYearRecurrence, setDisplayOfReport, setIntervalAmount, setIntervalValue, setRepeatIntervalType, setDueDateOfReport, setSelectedDeleteRows, getRowsForDeletion, deleteSelectedRows, setEndDate, setStartDate, setAssignedJobPositions, setFrequency, addUserResponse, removeUserResponse, addNewTask, settingUserToReportTo, clearTask, settingJobToReportTo, fetchTasks, getTaskLoadStatus, patchTask, RemoveSection, SaveItemToField, selectTasks, setAssignedUser, setDestinationFormat, setDestinationType, setFormField, setJobPosition, setKeepUpdatedTask, setRequestStatusReportOnComplete, SetSection, setTaskForEdit, setTaskName, updateNote, updateQuantity
} from './taskSlice';
import { style } from 'dom-helpers';
import Calendar from '../../Components/calendar/Calendar'






import { FaUpload } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";

const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]

const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' },]

const destinationType = [{ label: 'User Email' }, { label: '-' }]

const destinationFormat = [{ label: 'Format' }, { label: '-' }]

const timePeriodSelect = [{ label: 'Day' }, { label: 'Week' }, { label: 'Month' }, { label: 'Year' }]

const ordinalSelect = [{ label: 'First', value: 1 }, { label: 'Second', value: 2 }, { label: 'Third', value: 3 }, { label: 'Fourth', value: 4 }, { label: 'Last', value: -1 }]

const MonthSelect = [{ label: 'January' }, { label: 'February' }, { label: 'March' }, { label: 'April' }, { label: 'May' },
{ label: 'June' }, { label: 'July' }, { label: 'August' }, { label: 'September' }, { label: 'October' }
    , { label: 'November' }, { label: 'December' }]



const callBackFromChild = (value) => {
    console.log("Child gave back value : ", value)
}


const frequencyOptions = [{ label: 'Infinite' }, { label: 'Reoccuring' }, { label: 'One Time' }]

const dueDateOptions = [{ label: 'Same Day', value: 0 }, { label: '3 Days', value: 3 }, { label: '5 Days', value: 5 }, { label: '7 Days', value: 7 }]


let frequency = []

function createTask({ navigation, route }) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const tasks = useSelector(selectTasks);
    const loadingTasks = useSelector(getTaskLoadStatus);

    const forms = useSelector(selectForms);
    const loadingForms = useSelector(getFormLoadStatus);

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);

    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);

    const selectFrequencyRef = useRef();
    const selectDestFormatRef = useRef();
    const selectDestTypeRef = useRef();


    const [selectedJobOptions, setSelectedJob] = useState([]);
    const [selectedUserOptions, setSelectedUser] = useState([]);

    const selectedDeletionRows = useSelector(getRowsForDeletion)

    const [displayTypeCal1, setDisplayTypeCal1] = useState('Input');
    const [displayTypeCal2, setDisplayTypeCal2] = useState('Input');


    var dataGoingToCalendar = {
        style: { input: { display: 'flex', width: '101%', zIndex: '0', marginLeft: '0%', textAlign: 'center', marginLeft: '-14%', borderRadius: '5px', border: '1px solid lightgrey', height: '38px' }, calendar: { zIndex: '100000', left: '32%', display: 'block', position: 'absolute ', top: '60%' } },
        dayToLimitTo: 'Monday',
        callBack: handleCallback,
        placeholder: 'Start Date',
        lastClickLocation: '',
        displayType: displayTypeCal1,
        setDisplayType: handleSetDisplay1

    }



    var dataGoingToCalendar2 = {
        style: { input: { display: 'flex', width: '88%', zIndex: '0', marginLeft: '4%', textAlign: 'center', borderRadius: '5px', border: '1px solid lightgrey', height: '38px' }, calendar: { zIndex: '100000', left: '60%', display: 'block', position: 'absolute ', top: '60%' } },
        dayToLimitTo: 'Monday',
        callBack: handleCallback2,
        placeholder: 'End Date',
        lastClickLocation: '',
        displayType: displayTypeCal2,
        setDisplayType: handleSetDisplay2,
    }



    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }

    console.log("checking :", history)

    useEffect(() => {
        if (history?.location?.state?.taskToDisplay) {

            dispatch(setTaskForEdit(history.location.state.taskToDisplay))
        }
    }, [])

    useEffect(() => {

        if (loading != 'Done') {
            dispatch(fetchForms({ org_id: user.org_id, createLocation: user?.viewingLocation }))
        }
    }, [loadingForms, dispatch])


    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            console.log("fetching items ")
            dispatch(fetchItems({ org_id: user.org_id, viewingLocation: user?.viewingLocation }))

        }
    }, [loading, dispatch])

    useEffect(() => {
        if (loadingUsers != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [loadingUsers, dispatch])


    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [])

    useEffect(() => {
        if (jobPositionLoading != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }))
            //  jobPositions.push({'jobTitle':'-'})
        }
    }, [jobPositionLoading, dispatch])



    console.log("Items after loading :", items)
    return (<>





        <div id="popupBackground1" className={styles.popupBackground}>
        </div>

        <div id="popupMessage1" className={styles.popupMessage} >
            <button className={styles.closeButton} onClick={() => { cancelSectionAdd() }}>X</button>

            <div style={{ marginTop: '2%', marginBottom: '3%' }}>
                <span >Type of Task</span>

            </div>


            <div style={{ marginBottom: '2%' }}>
                <button onClick={() => { addSectionToTasks('Maintenance Request'); hidePopup1() }} className={styles.defaultInp}>Maintenance Request</button>
            </div>

            <div style={{ marginBottom: '2%' }}>
                <button onClick={() => { addSectionToTasks('Purchase Request'); hidePopup1() }} className={styles.defaultInp}>Purchase Request</button>
            </div>

            <div style={{ marginBottom: '2%' }}>
                <button onClick={() => { addSectionToTasks('Notification'); hidePopup1() }} className={styles.defaultInp}>Notification</button>
            </div>


        </div>



        <div id="popupBackground2" className={styles.popupBackground2}>
        </div>

        <div id="popupMessage2" className={styles.popupMessage2} >
            <button className={styles.closeButton} onClick={() => { cancelSectionAdd() }}>X</button>

            <div className={styles.bottomSection}>
                <div style={{ display: 'inline' }}>


                    <div style={{ display: 'flex', marginTop: '2%', justifyContent: 'space-evenly' }}>

                        <div style={{ textAlign: 'left', marginLeft: '3%' }}>
                            <h4 style={{ paddingLeft: '3%' }}> Assign</h4>

                            <div style={{ marginTop: '3%' }}>

                                {jobPositionLoading == 'Done' && <Select id="jobSelect" options={jobPositions} className={styles.userSelect}
                                    onChange={(e) => { dispatch(settingJobToReportTo({ e, fieldIndex: tasks.taskBeingMade.fields.length })) }}
                                    value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedJobPositions}
                                    isDisabled={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedUser?.length > 0}
                                    placeholder="Job Positions"
                                    isMulti
                                    isOptionDisabled={() => selectedJobOptions.length >= 1}

                                    style={{ marginTop: '4%', marginLeft: '1%' }}
                                    getOptionLabel={(option) => option.jobTitle}
                                    getOptionValue={(option) => option.jobTitle}
                                > </Select>
                                }

                            </div>

                        </div>

                        <div>
                            <span style={{ paddingLeft: '3%' }}> </span>

                            <div style={{ marginTop: '3%' }}>


                                {loadingUsers == 'Done' && <Select id="userSelect" className={styles.userSelect}
                                    options={users}
                                    onChange={(e) => { dispatch(setAssignedUser({ e, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                                    value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedUser}
                                    isDisabled={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedJobPositions?.length > 0}
                                    style={{ marginTop: '4%', marginLeft: '1%', marginLeft: '4%' }}

                                    placeholder="Users"
                                    isMulti
                                    isOptionDisabled={() => selectedUserOptions.length >= 1}
                                    getOptionLabel={(option) => option.displayName}
                                    getOptionValue={(option) => option.displayName}
                                > </Select>
                                }
                            </div>


                        </div>

                        <div>

                            <div className={styles.userSelect}>

                            </div>


                        </div>

                        <div>

                            <div className={styles.userSelect}>

                            </div>

                        </div>



                    </div>

                    <div style={{ display: 'block', marginTop: '8%', marginLeft: '3%' }}>
                        <h4 style={{ textAlign: 'left', paddingLeft: '3%' }}>Frequency</h4>

                        {
                            forms.requestedReports.map((item, i) => (
                                <div style={{ marginBottom: '5%', marginLeft: '1%' }}>

                                    <div className={styles.reportLine} >
                                        <div style={{ width: '198px', minWidth: '198px', marginRight: tasks.taskBeingMade.frequency.label == 'Infinite' ? '11%' : '0%' }}>
                                            <Select placeholder="" id="frequencySelect" style={{ width: '150px', minWidth: '150px' }} options={frequencyOptions} className={styles.select2}
                                                value={tasks.taskBeingMade.frequency.value ? tasks.taskBeingMade.frequency.value : {}}

                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                onChange={(evt) => { dispatch(setFrequency({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>
                                        </div>

                                        <div style={{ marginLeft: tasks.taskBeingMade.frequency.label == 'Reoccuring' ? '-12.5%' : tasks.taskBeingMade.frequency.label == 'One Time' ? '2%' : tasks.taskBeingMade.frequency.label == 'Infinite' ? '0%' : '-12.5%' }}>

                                            <Calendar dataFromParent={dataGoingToCalendar} onChange2={handleCallback} sectionIndex={tasks.taskBeingMade.fields.length - 1} reportIndex={i} />
                                        </div>

                                        {tasks.taskBeingMade.frequency.label == 'Infinite' &&
                                            <div>

                                                <div style={{ marginLeft: '0%' }}>


                                                    <Calendar dataFromParent={dataGoingToCalendar2} onChange2={handleCallback2} sectionIndex={tasks.taskBeingMade.fields.length - 1} reportIndex={i} />
                                                </div>

                                                <div style={{ minWidth: '22%' }}>

                                                </div>

                                            </div>


                                        }

                                        {tasks.taskBeingMade.frequency.label == 'One Time' &&
                                            <div style={{ marginLeft: '-15%', minWidth: '175px' }}>
                                                <div style={{ marginLeft: '0%' }}>


                                                </div>

                                                <div style={{}}>
                                                    {tasks.taskBeingMade.frequency.label == 'One Time' &&
                                                        <Select placeholder="" style={{ width: '198px', marginLeft: '-15%', minWidth: '175px', }} id="frequencySelect" options={dueDateOptions} className={styles.select2}
                                                            value={tasks.taskBeingMade.due ? tasks.taskBeingMade.due.label : {}}
                                                            placeholder="Due date"
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setDueDateOfReport({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>
                                                    }

                                                </div>

                                            </div>


                                        }

                                        <div style={{ width: '175px' }}>

                                        </div>




                                    </div>


                                    {tasks.taskBeingMade.frequency.label == 'Reoccuring' && <div style={{ marginTop: '5%', display: 'flex', width: '99%', marginLeft: '1%', height: '42px' }}>
                                        <h4 style={{ minWidth: '120px', height: '36px', alignContent: 'center' }}>Repeat every</h4>

                                        <div style={{ display: 'flex' }} >

                                            <input className={styles.roundedGreyRec} style={{ width: '193px', height: '37px', padding: '1%', marginLeft: '6%', marginRight: '3%', textAlign: 'center' }} onChange={(evt) => { dispatch(setIntervalValue({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}></input>
                                            <Select placeholder="" id="frequencySelect" style={{ width: '193px', marginTop: '1%', height: '93px', marginLeft: '1.2%' }} options={timePeriodSelect} className={styles.select2}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                onChange={(evt) => { dispatch(setRepeatIntervalType({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>
                                        </div>


                                        <h4 style={{ minWidth: '54px', height: '36px', alignContent: 'center' }}>Repeat</h4>

                                        <div style={{ display: 'flex' }} >

                                            <input className={styles.roundedGreyRec} style={{ width: '75px', height: '37px', padding: '1%', marginLeft: '4%', marginRight: '3%', textAlign: 'center' }} onChange={(evt) => { dispatch(setIntervalAmount({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}></input>

                                        </div>

                                        <h4 style={{ minWidth: '40px', height: '36px', alignContent: 'center', marginLeft: '1%' }}>times</h4>



                                    </div>}

                                    {tasks.taskBeingMade.intervalType == 'Week' && tasks.taskBeingMade.frequency.label == 'Reoccuring' && <div className={styles.weekSection}>



                                        <div>

                                            <h5 style={{ color: 'grey' }}> Display On</h5>
                                            <Select isMulti placeholder="Days" id="daySelect" options={daysOfWeek} className={styles.select2}
                                                value={tasks.taskBeingMade.display.length > 0 ? tasks.taskBeingMade.display : []}

                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                onChange={(evt) => { dispatch(setDisplayOfReport({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                        </div>
                                    </div>}

                                    {tasks.taskBeingMade.intervalType == 'Month' && tasks.taskBeingMade.frequency.label == 'Reoccuring' &&
                                        <div className={styles.monthSection}>
                                            <div style={{ width: '400px', marginBottom: '2%' }}>
                                                <input onClick={() => { dispatch(setMonthOption({ value: 'cardinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                <label style={{ marginRight: '4%', marginLeft: '1%' }} for="onDay">On day</label>
                                                <input className={styles.roundedGreyRec} style={{ textAlign: 'center' }} onChange={(evt) => { dispatch(setDayValueOfMonthRecurrence({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}></input>
                                            </div>

                                            <div style={{ textAlign: 'left', display: 'flex' }}>
                                                <input onClick={(evt) => { dispatch(setMonthOption({ value: 'ordinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '2%' }} for="onThe">On the</label>

                                                <div className={styles.select2} style={{ paddingRight: '1%' }}>
                                                    <Select placeholder="First" id="daySelect" style={{ width: '118px', }} options={ordinalSelect} className={styles.select2}
                                                        value={tasks.taskBeingMade?.ordinalMonthValue ? tasks.taskBeingMade?.ordinalMonthValue : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(evt) => { dispatch(setOrdinalValueMonth({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>
                                                </div>



                                                <Select placeholder="January" id="daySelect" style={{ width: '118px' }} options={daysOfWeek} className={styles.select2}
                                                    value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>


                                            </div>

                                        </div>}

                                    {tasks.taskBeingMade.intervalType == 'Year' && tasks.taskBeingMade.frequency.label == 'Reoccuring' &&
                                        <div style={{ width: '500px', display: 'inline-block', marginLeft: '-42%', marginBottom: '4%', marginTop: '2%' }}>
                                            <div style={{ display: 'flex' }}>
                                                <input onClick={(evt) => { dispatch(setYearOption({ value: 'cardinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1.4%' }} for="onDay">On</label>



                                                <Select placeholder="January" id="daySelect" options={MonthSelect} style={{ marginRight: '3%' }} className={styles.select2}
                                                    value={tasks.taskBeingMade.monthValueForYear ? tasks.taskBeingMade?.monthValueForYear : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setMonthValueForYearRecurrence({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                <input className={styles.roundedGreyRec} style={{ marginLeft: '1%', textAlign: 'center' }} placeholder="Enter Date" onChange={(evt) => { dispatch(setDayValueForYearRecurrence({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} ></input>

                                            </div>

                                            <div style={{ display: 'flex', marginTop: '4%' }}>


                                                <input onClick={(evt) => { dispatch(setYearOption({ value: 'ordinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1%' }} for="onThe">On the</label>

                                                <div className={styles.select2} style={{ paddingRight: '1%' }}>

                                                    <Select placeholder="First" id="daySelect1" options={ordinalSelect} style={{ marginRight: '1%', paddingRight: '.5%' }} className={styles.select2}
                                                        value={tasks.taskBeingMade.ordinalValueForYear ? tasks.taskBeingMade?.ordinalValueForYear : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setOrdinalValueYear({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                </div>
                                                <Select placeholder="Monday" id="daySelect2" options={daysOfWeek} style={{ marginRight: '1%' }} className={styles.select2}
                                                    value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                <label style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%' }} for="onThe">of</label>

                                                <Select placeholder="January" id="daySelect3" options={MonthSelect} className={styles.select2}
                                                    value={tasks.taskBeingMade.monthValue2ForYear ? tasks.taskBeingMade?.monthValue2ForYear : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setMonthValueForYearRecurrenceBottom({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                            </div>
                                        </div>}



                                </div>


                            ))}

                    </div>
                </div>

                <div style={{ display: 'block', marginTop: '1%', marginBottom: '2%', marginLeft: '6%' }}>
                    <h4 style={{ textAlign: 'left' }}>Destination</h4>

                    <div style={{ display: 'flex', marginTop: '1%', justifyContent: 'space-between', width: '41.6%' }}>

                        {<Select id="destinationType" options={destinationType} className={styles.userSelect}
                            value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.destinationType}
                            style={{ width: '193px' }}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.label}
                            onChange={(evt) => { dispatch(setDestinationType({ evt, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                        > </Select>


                        }

                        {<Select id="destinationFormat" placeholder="Choose an option..." options={destinationFormat} className={styles.userSelect}
                            value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.destinationFormat}
                            style={{ marginLeft: '0%' }}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.label}
                            onChange={(evt) => { dispatch(setDestinationFormat({ evt, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                        > </Select>
                        }

                    </div>


                </div>

            </div>



            <div style={{ marginTop: '5%' }} >
                <button onClick={() => { hidePopup2(); showPopup1(); dispatch(RemoveSection()); }} className={styles.popupBtn3}>Back</button>

                <button onClick={() => { hidePopup1(); hidePopup2(); }} className={styles.popupBtn3}>Finish</button>
            </div>


        </div>



        <div className={styles.pageWrapper}>

            <span className={styles.taskName}> Task Name </span>

            <div className={styles.flexBox} style={{ width: '647px', display: 'flex', marginTop: '1%', justifyContent: 'space-between' }}>


                <input id="taskName" className={styles.defaultInp} placeholder='Add Name' onChange={(item) => settingTaskName(item)} value={tasks.taskBeingMade.name} style={{ marginRight: '1%' }} placeholder={tasks.taskBeingMade.name ? tasks.taskBeingMade.name : 'Add Name'} value={tasks.taskBeingMade.name} />

                <button style={{ marginLeft: '2%' }} className={styles.defaultInp} onClick={() => { showPopup1() }} disabled={tasks.taskBeingMade.name ? false : true}> Type of task</button>


            </div>


            <div className={styles.blackLineDiv2}>
                &nbsp;
            </div>

            <div className={styles.editArea}>

                {tasks.taskBeingMade.type == 'Maintenance Request' && tasks.taskBeingMade.fields.map((field, index2) => (

                    <div style={{ textAlign: 'left', display: 'flex', height: '25%' }}>
                        <div className={styles.pu3Box} style={{ width: '98%', paddingLeft: '0%', zIndex: 10000 - index2 }}>

                            <div>

                                <div style={{ marginLeft: '2%' }}>
                                    <span>Item</span>


                                    {index2 == 0 &&
                                        <div style={{ display: 'contents' }}>

                                            <span style={{ marginLeft: "11.6%" }}>Quantity </span>
                                            <span style={{ marginLeft: "8%" }}>Note </span>

                                        </div>
                                    }

                                    {index2 != 0 &&
                                        <div style={{ display: 'contents' }}>

                                        </div>

                                    }

                                </div>
                            </div>


                            <div style={{ display: 'flex', height: 'auto', width: 'auto', marginTop: '.4%', marginBottom: '1.5%', overflow: 'visible', position: 'relative', zIndex: '10000' }}>

                                <input id="checkbox1" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, tasks?.taskBeingMade?.fields.length - 1, index2)} />

                                <div style={{ width: '168px', minWidth: '168px', height: '30px', marginLeft: '1%', marginRight: '2%', overflow: 'visible', position: 'relative', zIndex: '10000' }}>


                                    <div style={{ height: '30px', overflow: 'visible', position: 'relative', zIndex: '10000' }}>
                                        {loading == 'Done' && <Select id={"itemSelect" + index2} value={field.item} options={items} className={styles.userSelect2} onChange={(item) => itemSelectedInField(item, index2)}
                                            style={{ width: '100%' }}
                                            getOptionLabel={(option) => option.itemName}
                                            getOptionValue={(option) => option.itemName}
                                        > </Select>
                                        }
                                    </div>

                                </div>


                                <input className={styles.defaultInp} style={{ width: '140px', height: '40px', marginLeft: '.5%', marginRight: '2.5%', marginTop: '-.2%' }} value={tasks.taskBeingMade.parValue} onChange={(evt) => dispatch(setParValue({ value: evt.target.value, fieldIndex: index2, }))} placeholder="Quantity" />

                                <input className={styles.defaultInp} style={{ width: '140px', height: '40px', marginLeft: '.5%', marginRight: '2.5%', marginTop: '-.2%' }} value={tasks.taskBeingMade.note} onChange={(evt) => dispatch(updateNote({ value: evt.target.value, fieldIndex: index2, }))} placeholder="Note" />



                                {field?.userResponse?.map((obj, index4) => (

                                    <div className={styles.DEfieldSection} style={{ marginRight: '2.3%', width: '180px' }} >

                                        <input className={styles.defaultInp} style={{ width: '140px', height: '38px', padding: '.5%' }} value={obj.question} onChange={(evt) => settingUserResponsePrompt(evt, tasks?.taskBeingMade?.sections.length - 1, index2, index4)}></input>
                                    </div>

                                ))}




                            </div>


                        </div>

                    </div>
















































                ))}

                {tasks.taskBeingMade.type == 'Purchase Request' && tasks.taskBeingMade.fields.map((field, index2) => (




                    <div style={{ textAlign: 'left', display: 'flex', height: '25%' }}>
                        <div className={styles.pu3Box} style={{ width: '98%', paddingLeft: '0%' }}>

                            <div>

                                <div style={{ marginLeft: "2%" }}>
                                    <span>Item</span>


                                    {index2 == 0 &&
                                        <div style={{ display: 'contents' }}>
                                            <span style={{ marginLeft: "11.6%" }}>Note </span>

                                            <span style={{ marginLeft: "9.5%" }}>Quantity </span>

                                        </div>



                                    }


                                    {index2 != 0 &&
                                        <div style={{ display: 'contents' }}>


                                        </div>

                                    }


                                    {field?.userResponse?.length > 0 && index2 == 0 &&
                                        <div style={{ display: 'contents' }}>
                                            <label style={{ marginLeft: (7.3) + '%' }}>User Response</label>

                                        </div>



                                    }






                                </div>
                            </div>

                            <div style={{}}>

                                <div style={{ display: 'flex', height: 'auto', width: 'auto', marginTop: '.4%', marginBottom: '1.5%' }}>

                                    <input id="checkbox1" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, tasks?.taskBeingMade?.fields.length - 1, index2)} />

                                    <div style={{ width: '168px', minWidth: '168px', height: '30px', marginLeft: '1%', marginRight: '2%' }}>


                                        <div style={{ height: '30px' }}>

                                            {loading == 'Done' && <Select id={"itemSelect" + index2} value={field.item} options={items} className={styles.userSelect2} onChange={(item) => itemSelectedInField(item, index2)}
                                                style={{ width: '100%' }}
                                                getOptionLabel={(option) => option.itemName}
                                                getOptionValue={(option) => option.itemName}
                                            > </Select>
                                            }
                                        </div>

                                    </div>


                                    <input className={styles.defaultInp} style={{ width: '140px', height: '40px', marginLeft: '.5%', marginRight: '2.5%', marginTop: '-.2%' }} value={tasks.taskBeingMade.note} onChange={(evt) => dispatch(updateNote({ value: evt.target.value, fieldIndex: index2, }))} placeholder="Note" />

                                    <input className={styles.defaultInp} style={{ width: '140px', height: '40px', marginLeft: '.5%', marginRight: '2.5%', marginTop: '-.2%' }} value={tasks.taskBeingMade.parValue} onChange={(evt) => dispatch(setParValue({ value: evt.target.value, fieldIndex: index2, }))} placeholder="Quantity" />


                                    {field?.userResponse?.map((obj, index4) => (

                                        <div className={styles.DEfieldSection} style={{ marginRight: '2.3%', width: '180px' }} >

                                            <input className={styles.defaultInp} style={{ width: '140px', height: '38px', padding: '.5%' }} value={obj.question} onChange={(evt) => settingUserResponsePrompt(evt.target.value, tasks?.taskBeingMade?.fields.length - 1, index2, index4)}></input>
                                        </div>

                                    ))}


                                    <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={(evt) => removedCol({ fieldIndex: index2, columnIndex: field?.userResponse?.length - 1 })} />


                                    <FaPlusCircle style={{ marginLeft: '2%' }} onClick={(evt) => addDataEntryCol({ fieldIndex: index2 })} />


                                </div>

                            </div>

                        </div>

                    </div>





                ))}

                {tasks.taskBeingMade.type == 'Notification' && tasks.taskBeingMade.fields.map((oneRow2, index2) => (
                    <div style={{ display: 'flex', marginTop: '2%' }}>


                        <input id="checkbox1" type="checkbox" style={{ marginRight: '1%' }} onChange={(evt) => toggleRowForDeletion(evt, tasks?.taskBeingMade?.fields.length - 1, index2)} />

                        <div className={styles.inputSection} style={{ display: 'grid', marginTop: '-2%', width: index2 != tasks?.taskBeingMade?.fields.length - 1 ? '21.2%' : '24%' }}>
                            <span style={{ marginRight: '2%' }}>Notification</span>
                            <input id={"Note" + index2} className={styles.defaultInp} placeholder='Type here...' value={oneRow2.note} onChange={(evt) => dispatch(updateNote({ value: evt.target.value, fieldIndex: index2 }))} />
                        </div>

                        <div className={styles.inputSection} style={{ width: '18%' }} >
                            <div style={{ display: 'block', width: '375px', marginLeft: '.5%' }}>
                                <label className={styles.fileInputLabel}>
                                    <input
                                        style={{ display: 'none' }}
                                        className={styles.defaultInp}
                                        type='file'
                                        onChange={(evt) => settingImageUpload(evt, index2)}
                                    />
                                    <span className={styles.defaultInp} style={{ width: '66%', textAlign: 'center', alignContent: 'center', display: 'block' }}>{oneRow2?.picture?.name ? oneRow2.picture.name : 'Image Upload'}</span>
                                    <FaUpload style={{ display: 'block', marginTop: '-8%', marginBottom: '6%', marginLeft: '57%' }} />

                                </label>

                            </div>



                        </div>

                        <div className={styles.inputSection} style={{ width: '44%' }} >

                            <div style={{ display: 'block', width: '375px', marginLeft: '.5%' }}>
                                <label className={styles.fileInputLabel}>
                                    <input
                                        style={{ display: 'none' }}
                                        className={styles.defaultInp}
                                        type='file'
                                        onChange={(evt) => settingFileUpload(evt, index2)}
                                    />
                                    <span className={styles.defaultInp} style={{ width: '66%', textAlign: 'center', alignContent: 'center', display: 'block' }}>{oneRow2?.file?.name ? oneRow2.file.name : 'File Upload'}</span>
                                    <FaUpload style={{ display: 'block', marginTop: '-8%', marginBottom: '6%', marginLeft: '57%' }} />

                                </label>

                            </div>



                        </div>



                        { index2 != tasks.taskBeingMade.fields.length - 1 && <div>
                        </div>}

                        {index2 == tasks.taskBeingMade.fields.length - 1 && <div style={{ display: 'flex', marginLeft: '20%' }} >




                        </div>}
                    </div>

                ))}

                {tasks.taskBeingMade.type == 'Select Form' && tasks.taskBeingMade.fields.map((oneRow2, index2) => (
                    <div style={{ display: 'flex' }}>

                        <div className={styles.formSelectSection} style={{ width: '300px', marginRight: '3%', marginTop: '2.1%' }}>

                            <Select
                                onChange={(option) => updateFormSelectField(option, index2)}
                                value={oneRow2.formRef}
                                className='formSelect'
                                options={forms.Forms.docs}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}> </Select>
                        </div>

                        <div style={{ display: 'grid' }} >
                            <span>Notification </span>
                            <input id={"Note" + index2} className={styles.defaultInp} value={oneRow2.note} placeholder='Type here...' onChange={(evt) => dispatch(updateNote({ value: evt.target.value, fieldIndex: index2 }))} />
                        </div>

                        <div style={{ display: 'flex', marginTop: '2.5%', justifyContent: 'space-between', marginLeft: '1%', width: '50px' }} >
                            <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={() => { removeSectionFromTasks('Select Form', index2) }} />


                            <FaPlusCircle style={{ marginLeft: '2%' }} onClick={() => { addSectionToTasks('Select Form') }} />
                        </div>

                    </div>

                ))}
                {tasks.taskBeingMade.fields.length > 0 &&
                    <div style={{ marginTop: '.5%' }}>
                        <button className={styles.addRowBtn} onClick={() => { addSectionToTasks(tasks.taskBeingMade.type) }} style={{ marginRight: '2.7%', marginLeft: '2%' }} > + Add Row</button>
                        <button className={styles.popupBtn3} onClick={() => { dispatch(deleteSelectedRows({ task: tasks.taskBeingMade, toRemove: selectedDeletionRows })) }} style={{ marginRight: '5%', width: '111px' }} >Delete rows</button>

                    </div>

                }

            </div>

            <div className={styles.blackLineDiv2}>
                &nbsp;
            </div>




            {tasks.taskBeingMade.fields.length > 0 &&

                <div style={{ paddingTop: '0%', zIndex: '0', overflow: 'hidden', overflowY: 'overlay' }} className={styles.bottomSection}>
                    <div style={{ display: 'block' }}>


                        <div style={{ display: 'flex', marginTop: '2%', justifyContent: 'space-between' }}>

                            <div style={{ textAlign: 'left', }}>
                                <h4 style={{ paddingLeft: '0%' }}> Assign</h4>

                                <div style={{ marginTop: '3%' }}>


                                    {jobPositionLoading == 'Done' && <Select id="jobSelect" options={jobPositions} className={styles.userSelect}
                                        onChange={(e) => { dispatch(setAssignedJobPositions({ e, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                                        value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedJobPositions}
                                        isDisabled={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedUser?.length > 0}
                                        placeholder="Job Positions"
                                        isMulti
                                        isOptionDisabled={() => selectedJobOptions.length >= 1}

                                        style={{ marginTop: '4%', marginLeft: '1%' }}
                                        getOptionLabel={(option) => option.jobTitle}
                                        getOptionValue={(option) => option.jobTitle}
                                    > </Select>
                                    }

                                </div>

                            </div>

                            <div>
                                <span style={{ paddingLeft: '3%' }}> </span>

                                <div style={{ marginTop: '3%' }}>


                                    {loadingUsers == 'Done' && <Select id="userSelect" className={styles.userSelect}
                                        options={users}
                                        onChange={(e) => { dispatch(setAssignedUser({ e, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                                        value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedUser}
                                        isDisabled={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.assignedJobPositions?.length > 0}
                                        style={{ marginTop: '4%', marginLeft: '0%', marginLeft: '0%' }}

                                        placeholder="Users"
                                        isMulti
                                        isOptionDisabled={() => selectedUserOptions.length >= 1}
                                        getOptionLabel={(option) => option.displayName}
                                        getOptionValue={(option) => option.displayName}
                                    > </Select>
                                    }
                                </div>


                            </div>

                            <div>

                                <div className={styles.userSelect}>

                                </div>


                            </div>

                            <div>

                                <div className={styles.userSelect}>

                                </div>

                            </div>



                        </div>

                        <div style={{ display: 'block', marginTop: '2%', }}>
                            <h4 style={{ textAlign: 'left', }}>Frequency</h4>

                            {
                                forms.requestedReports.map((item, i) => (
                                    <div style={{ marginBottom: '2%', marginLeft: '-2%' }}>

                                        <div style={{ justifyContent: '', width: '70%' }} className={styles.reportLine}>
                                            <div style={{ width: '198px', minWidth: '198px', marginRight: tasks?.taskBeingMade?.frequency?.value?.label == 'Infinite' ? '11%' : '0%' }}>
                                                <Select placeholder="" id="frequencySelect" style={{ width: '150px', minWidth: '150px' }} options={frequencyOptions} className={styles.select2}
                                                    value={tasks?.taskBeingMade?.frequency?.value?.label ? tasks?.taskBeingMade?.frequency?.value : {}}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setFrequency({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>
                                            </div>

                                            {tasks?.taskBeingMade?.frequency?.value?.label == 'One Time' &&

                                                <div style={{ marginLeft: '10.8%' }}>

                                                    <Calendar dataFromParent={dataGoingToCalendar} onChange2={handleCallback} sectionIndex={tasks.taskBeingMade.fields.length - 1} reportIndex={i} />
                                                </div>
                                            }
                                            {tasks?.taskBeingMade?.frequency?.value?.label == 'Infinite' &&

                                                <div>

                                                    <Calendar dataFromParent={dataGoingToCalendar} onChange2={handleCallback} sectionIndex={tasks.taskBeingMade.fields.length - 1} reportIndex={i} />
                                                </div>
                                            }
                                            {tasks?.taskBeingMade?.frequency?.value?.label == 'Infinite' &&

                                                <div style={{ marginLeft: '4%' }}>
                                                    <Calendar dataFromParent={dataGoingToCalendar2} onChange2={handleCallback2} sectionIndex={tasks.taskBeingMade.fields.length - 1} reportIndex={i} />
                                                </div>
                                            }


                                            {tasks?.taskBeingMade?.frequency?.value?.label == 'Reoccuring' && <div style={{ display: 'flex', width: '99%', marginLeft: '12%', height: '42px' }}>
                                                <h4 style={{ minWidth: '120px', height: '36px', alignContent: 'center' }}>Repeat every</h4>

                                                <div style={{ display: 'flex' }} >

                                                    <input className={styles.roundedGreyRec} style={{ width: '75px', height: '37px', padding: '1%', marginLeft: '6.5%', marginRight: '3%', textAlign: 'left' }} onChange={(evt) => { dispatch(setIntervalValue({ value: evt.target.value, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}></input>


                                                    <div style={{ width: '137px' }}>
                                                        <Select placeholder="" id="frequencySelect" style={{ width: '100%', marginTop: '1%', height: '93px', marginLeft: '1.2%' }} options={timePeriodSelect} className={styles.select2}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setRepeatIntervalType({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>
                                                    </div>




                                                </div>


                                                <div style={{ marginLeft: '20%', display: 'flex' }}>


                                                    <h4 style={{ minWidth: '60px', height: '36px', alignContent: 'center' }}>Repeat</h4>

                                                    <div style={{ display: 'flex' }} >

                                                        <input className={styles.roundedGreyRec} style={{ width: '75px', height: '37px', padding: '1%', marginLeft: '4%', marginRight: '3%', textAlign: 'center' }} onChange={(evt) => { dispatch(setIntervalAmount({ value: evt.target.value, sectionIndex: forms?.formBeingMade?.fields?.length - 1, reportIndex: i })) }}></input>

                                                    </div>

                                                    <h4 style={{ minWidth: '40px', height: '36px', alignContent: 'center', marginLeft: '4%' }}>times</h4>

                                                </div>


                                                {tasks.taskBeingMade.intervalType == 'Week' && tasks?.taskBeingMade?.frequency?.value?.label == 'Reoccuring' && <div className={styles.weekSection} style={{ display: 'flex', marginLeft: '10%', width: '500px', zIndex: '100000' }}>

                                                    <h4 style={{ color: 'grey', alignContent: 'center', minWidth: '90px', height: '38px' }}> Display On</h4>
                                                    <div style={{ width: '240px' }}>

                                                        <Select isMulti placeholder="Days" id="daySelect" options={daysOfWeek} className={styles.select2}
                                                            style={{ width: '100%', }}
                                                            value={tasks.taskBeingMade?.display?.length > 0 ? tasks?.taskBeingMade?.display : []}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setDisplayOfReport({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>
                                                    </div>

                                                </div>}



                                                {tasks.taskBeingMade.intervalType == 'Month' && tasks?.taskBeingMade?.frequency?.value?.label == 'Reoccuring' &&
                                                    <div className={styles.monthSection} >
                                                        <div style={{ width: '400px', marginBottom: '2%' }}>
                                                            <input onClick={(evt) => { dispatch(setMonthOption({ value: 'cardinal', sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                            <label style={{ marginRight: '4%', marginLeft: '1%' }} for="onDay">On day</label>
                                                            <input className={styles.roundedGreyRec} style={{ textAlign: 'center' }} onChange={(evt) => { dispatch(setDayValueOfMonthRecurrence({ value: evt.target.value, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}></input>
                                                        </div>

                                                        <div style={{ textAlign: 'left', display: 'flex' }}>
                                                            <input onClick={(evt) => { dispatch(setMonthOption({ value: 'ordinal', sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                            <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '2%' }} for="onThe">On the</label>

                                                            <div className={styles.select2} style={{ paddingRight: '1%' }}>
                                                                <Select placeholder="First" id="daySelect" style={{ width: '118px', }} options={ordinalSelect} className={styles.select2}
                                                                    value={tasks.taskBeingMade?.ordinalMonthValue ? tasks.taskBeingMade?.ordinalMonthValue : []}

                                                                    getOptionLabel={(option) => option.label}
                                                                    getOptionValue={(option) => option.value}
                                                                    onChange={(evt) => { dispatch(setOrdinalValueMonth({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>
                                                            </div>



                                                            <Select placeholder="January" id="daySelect" style={{ width: '118px' }} options={daysOfWeek} className={styles.select2}
                                                                value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.label}
                                                                onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>


                                                        </div>

                                                    </div>}


                                            </div>}


                                            {tasks.taskBeingMade.intervalType == 'Year' && tasks?.taskBeingMade?.frequency?.value?.label == 'Reoccuring' &&
                                                <div style={{ width: '500px', display: 'inline-block', marginBottom: '4%', marginTop: '-2%', marginLeft: '17.4%', minWidth: '531px' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <input onClick={(evt) => { dispatch(setYearOption({ value: 'cardinal', sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                        <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1.4%' }} for="onDay">On</label>



                                                        <Select placeholder="January" id="daySelect" options={MonthSelect} style={{ marginRight: '3%' }} className={styles.select2}
                                                            value={tasks.taskBeingMade.monthValueForYear ? tasks.taskBeingMade?.monthValueForYear : []}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setMonthValueForYearRecurrence({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>

                                                        <input className={styles.roundedGreyRec} style={{ marginLeft: '1%', textAlign: 'center' }} placeholder="Enter Date" onChange={(evt) => { dispatch(setDayValueForYearRecurrence({ value: evt.target.value, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }} ></input>

                                                    </div>

                                                    <div style={{ display: 'flex', marginTop: '4%' }}>


                                                        <input onClick={(evt) => { dispatch(setYearOption({ value: 'ordinal', sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                        <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1%' }} for="onThe">On the</label>

                                                        <div className={styles.select2} style={{ paddingRight: '1%' }}>

                                                            <Select placeholder="First" id="daySelect1" options={ordinalSelect} style={{ marginRight: '1%', paddingRight: '.5%' }} className={styles.select2}
                                                                value={tasks.taskBeingMade.ordinalValueForYear ? tasks.taskBeingMade?.ordinalValueForYear : []}

                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.label}
                                                                onChange={(evt) => { dispatch(setOrdinalValueYear({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>

                                                        </div>
                                                        <Select placeholder="Monday" id="daySelect2" options={daysOfWeek} style={{ marginRight: '1%' }} className={styles.select2}
                                                            value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>

                                                        <label style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%' }} for="onThe">of</label>

                                                        <Select placeholder="January" id="daySelect3" options={MonthSelect} className={styles.select2}
                                                            value={tasks.taskBeingMade.monthValue2ForYear ? tasks.taskBeingMade?.monthValue2ForYear : []}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setMonthValueForYearRecurrenceBottom({ value: evt, sectionIndex: tasks?.taskBeingMade?.fields - 1, reportIndex: i })) }}> </Select>

                                                    </div>
                                                </div>}


                                            {tasks?.taskBeingMade?.frequency?.value?.label == 'One Time' &&


                                                <div style={{ minWidth: '22%', marginLeft: '-0%' }}>
                                                    <Select placeholder="" style={{ width: '198px', marginLeft: '-3%' }} id="frequencySelect" options={dueDateOptions} className={styles.select2}
                                                        value={tasks.taskBeingMade.due ? tasks.taskBeingMade.due : {}}
                                                        placeholder="Due date"
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setDueDateOfReport({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                </div>

                                            }


                                        </div>







                                        {tasks.taskBeingMade.intervalType == 'Week' && tasks.taskBeingMade.frequency.label == 'Reoccuring' && <div className={styles.weekSection}>



                                            <div>

                                                <h5 style={{ color: 'grey' }}> Display On</h5>
                                                <Select isMulti placeholder="Days" id="daySelect" options={daysOfWeek} className={styles.select2}
                                                    value={tasks.taskBeingMade.display.length > 0 ? tasks.taskBeingMade.display : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setDisplayOfReport({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                            </div>
                                        </div>}

                                        {tasks.taskBeingMade.intervalType == 'Month' && tasks.taskBeingMade.frequency.label == 'Reoccuring' &&
                                            <div className={styles.monthSection}>
                                                <div style={{ width: '400px', marginBottom: '2%' }}>
                                                    <input onClick={(evt) => { dispatch(setMonthOption({ value: 'cardinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                    <label style={{ marginRight: '4%', marginLeft: '1%' }} for="onDay">On day</label>
                                                    <input onChange={(evt) => { dispatch(setDayValueOfMonthRecurrence({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}></input>
                                                </div>

                                                <div style={{ textAlign: 'left', display: 'flex' }}>
                                                    <input onClick={(evt) => { dispatch(setMonthOption({ value: 'ordinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                    <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '2%' }} for="onThe">On the</label>

                                                    <Select placeholder="First" id="daySelect" style={{ width: '118px' }} options={ordinalSelect} className={styles.select2}
                                                        value={tasks.taskBeingMade?.ordinalMonthValue ? tasks.taskBeingMade?.ordinalMonthValue : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.value}
                                                        onChange={(evt) => { dispatch(setOrdinalValueMonth({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                    <Select placeholder="January" id="daySelect" style={{ width: '118px' }} options={daysOfWeek} className={styles.select2}
                                                        value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>


                                                </div>

                                            </div>}

                                        {tasks.taskBeingMade.intervalType == 'Year' && tasks.taskBeingMade.frequency.label == 'Reoccuring' &&
                                            <div style={{ width: '500px', display: 'inline-block', marginLeft: '-42%', marginBottom: '4%', marginTop: '2%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <input onClick={(evt) => { dispatch(setYearOption({ value: 'cardinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onDay" name="drone" value="onDay" />
                                                    <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1.4%' }} for="onDay">On</label>

                                                    <Select placeholder="January" id="daySelect" options={MonthSelect} style={{ marginRight: '3%' }} className={styles.select2}
                                                        value={tasks.taskBeingMade.monthValueForYear ? tasks.taskBeingMade?.monthValueForYear : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthValueForYearRecurrence({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                    <input style={{ marginLeft: '1%', height: '37px' }} placeholder="Enter Date" onChange={(evt) => { dispatch(setDayValueForYearRecurrence({ value: evt.target.value, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} ></input>

                                                </div>

                                                <div style={{ display: 'flex', marginTop: '4%' }}>


                                                    <input onClick={(evt) => { dispatch(setYearOption({ value: 'ordinal', sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }} type="radio" id="onThe" name="drone" value="onThe" />
                                                    <label style={{ minWidth: '50px', marginLeft: '1%', marginRight: '5%', marginTop: '1%' }} for="onThe">On the</label>

                                                    <Select placeholder="First" id="daySelect1" options={ordinalSelect} style={{ marginRight: '1%' }} className={styles.select2}
                                                        value={tasks.taskBeingMade.ordinalValueForYear ? tasks.taskBeingMade?.ordinalValueForYear : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setOrdinalValueYear({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                    <Select placeholder="Monday" id="daySelect2" options={daysOfWeek} style={{ marginRight: '1%' }} className={styles.select2}
                                                        value={tasks.taskBeingMade.monthDayToRepeat ? tasks.taskBeingMade?.monthDayToRepeat : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                    <label style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%' }} for="onThe">of</label>

                                                    <Select placeholder="January" id="daySelect3" options={MonthSelect} className={styles.select2}
                                                        value={tasks.taskBeingMade.monthValue2ForYear ? tasks.taskBeingMade?.monthValue2ForYear : []}

                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthValueForYearRecurrenceBottom({ value: evt, sectionIndex: tasks.taskBeingMade.fields.length - 1, reportIndex: i })) }}> </Select>

                                                </div>
                                            </div>}



                                    </div>


                                ))}


                            <h4 style={{ textAlign: 'left' }}>Destination</h4>

                            <div style={{ display: 'flex', marginTop: '0%', justifyContent: 'space-between', width: '41.6%' }}>

                                {<Select id="destinationType" options={destinationType} className={styles.userSelect}
                                    value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.destinationType}
                                    style={{ width: '193px' }}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    onChange={(evt) => { dispatch(setDestinationType({ evt, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                                > </Select>


                                }

                                {<Select id="destinationFormat" placeholder="Choose an option..." options={destinationFormat} className={styles.userSelect}
                                    value={tasks.taskBeingMade.fields[tasks.taskBeingMade.fields.length - 1]?.destinationFormat}
                                    style={{ marginLeft: '0%' }}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    onChange={(evt) => { dispatch(setDestinationFormat({ evt, sectionIndex: tasks.taskBeingMade.fields.length })) }}
                                > </Select>
                                }

                            </div>





                        </div>






                    </div>



                    <div className={styles.formBtnDiv} style={{ left: '5%', top: '-11%', marginLeft: '72%', marginTop: '-.7%' }}>
                        <button onClick={() => { onCancelClicked() }} className={styles.formBtn}>Cancel</button>
                        <button onClick={() => { onSaveClicked() }} className={styles.formBtn2}>Save</button>
                    </div>
                </div>

            }



        </div >
    </>
    )

    function handleSetDisplay2(k) {
        console.log("Handle display 2 : ", k)

        setDisplayTypeCal2(k)
    }


    function onDeleteClicked() {

        console.log("on delete clicked : ", selectedDeletionRows)
        dispatch(deleteSelectedRows(taskBeingMade, selectedDeletionRows))
    }

    function toggleRowForDeletion(evt, index1, index2) {
        //rows can change after being toggled for deletion, so we have to save a list of indexes to delete
        console.log("EVT :", evt.target.checked)
        let listOfCheckedRowsToDelete = structuredClone(selectedDeletionRows)
        let toRemove = { section: index1, field: index2 }

        let result;
        if (evt.target.checked) {
            console.log('Checked to delete :  ', listOfCheckedRowsToDelete, toRemove)

            listOfCheckedRowsToDelete.push(toRemove)
            dispatch(setSelectedDeleteRows(listOfCheckedRowsToDelete))

        }
        else {

            console.log("Looking for : ", toRemove, " in , ", listOfCheckedRowsToDelete)

            result = listOfCheckedRowsToDelete.filter(function (element) {
                console.log("cheeeeeeeeeck : ", element.section != toRemove.section, element.field != toRemove.field, toRemove, element, element.field, toRemove.field)

                if (element.field != toRemove.field) {
                    return element
                }
                else {
                    if (element.section != toRemove.section) {
                        return element
                    }
                    else {
                        //match found, dont return it (that way it gets filtered out)
                    }
                }



            });
            console.log("Final result ROWS :", result)

            dispatch(setSelectedDeleteRows(result))

        }
    }


    async function settingFileUpload(evt, fieldIndex) {
        console.log("settingImageUpload : ", evt, fieldIndex)

        //uploadingFile
        //files need to be on our server to be used/displayed
        await dispatch(uploadFile({ name: evt.target.value, files: evt.target.files[0] }))
        await dispatch(retrieveFileForFileUpload({ value: evt.target.files[0].name, fieldIndex })) //Loads file id into state

        //  dispatch(setVisualFileUpload({files: evt.target.files, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    async function settingImageUpload(evt, fieldIndex) {
        console.log("settingImageUpload : ", evt, fieldIndex)

        //uploadingFile
        //files need to be on our server to be used/displayed
        await dispatch(uploadFile({ name: evt.target.value, files: evt.target.files[0] }))
        await dispatch(retrieveFile({ value: evt.target.files[0].name, fieldIndex })) //Loads file id into state

        //  dispatch(setVisualFileUpload({files: evt.target.files, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    function handleSetDisplay1(k) {
        console.log("Handle display 1: ", k)

        setDisplayTypeCal1(k)
    }

    function handleCallback(data, sectionIndex, reportIndex) {
        //  console.log("Reached", data, data2, data3)

        dispatch(setStartDate({ data, sectionIndex, reportIndex }))
    }

    function handleCallback2(data, sectionIndex, reportIndex) {

        console.log("Setting end date :", "yep")

        dispatch(setEndDate({ data, sectionIndex, reportIndex }))
    }


    function cancelSectionAdd() {
        //    dispatch(RemoveSection())

        hidePopup1()
        hidePopup2()
    }


    function hidePopup1() {
        document.getElementById("popupMessage1").style.display = "none"
        document.getElementById("popupBackground1").style.visibility = "hidden"
    }

    function showPopup1() {
        document.getElementById("popupBackground1").style.visibility = "visible"
        document.getElementById("popupMessage1").style.display = 'block'
    }

    function hidePopup2() {
        document.getElementById("popupMessage2").style.display = "none"
        document.getElementById("popupBackground2").style.display = "none"
    }

    function showPopup2() {
        document.getElementById("popupBackground2").style.display = "block"
        document.getElementById("popupMessage2").style.display = 'block'
    }




    function removedCol(fieldIndex, columnIndex) {

        dispatch(removeUserResponse(fieldIndex, columnIndex))
    }


    function addDataEntryCol(fieldIndex) {
        dispatch(addUserResponse(fieldIndex))
    }

    function settingRequestStatusReportOnComplete(evt) {
        dispatch(setRequestStatusReportOnComplete(evt.target.checked))
    }

    function settingTaskName(evt) {
        dispatch(setTaskName(evt.target.value))
    }

    function settingKeepUpdatedTask(evt) {
        dispatch(setKeepUpdatedTask(evt.target.checked))
    }

    function settingDestinationFormat(evt) {
        dispatch(setDestinationFormat(evt))
    }

    function settingDestinationType(evt) {
        dispatch(setDestinationType(evt))
    }

    function updateFormSelectField(evt, index) {
        dispatch(setFormField({ form: evt, index: index }))
    }

    function settingJobPosition(evt) {
        console.log('Sending along event ', evt)
        dispatch(setJobPosition(evt))
    }

    function settingAssignedUser(evt) {
        dispatch(setAssignedUser(evt))
    }


    function removeSectionFromTasks(evt, index) {
        dispatch(RemoveSection({ evt: evt, index: index }))
    }

    function openPage(page) {
        navigate(page)
    }

    function toggleDay(day) {
        if (!frequency.includes(day)) {          //checking weather array contain the id
            frequency.push(day);               //adding to array because value doesnt exists
        } else {
            frequency.splice(frequency.indexOf(day), 1);  //deleting
        }

        dispatch(updateUser(loadedUser, { availability: frequency }))
        dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }))
    }


    function itemSelectedInField(item, index) {
        console.log("Item and index changed:", item, index)
        dispatch(SaveItemToField({ item: item, index: index }))
    }

    function addSectionToTasks(evt) {
        //set section type
        dispatch(SetSection(evt))
    }

    function onCancelClicked() {
        clearFields()
        dispatch(clearTask())

    }

    function updateNoteField(value, index1) {

        console.log('updateNoteField ', value, index1)
        dispatch(updateNote({ index: index1, note: value }))
    }

    function updateQuantityField(index) {


        dispatch(updateQuantity({ index: index, value: value }))
    }

    function clearFields() {
        //  document.getElementById("checkbox1").checked = false
        //  document.getElementById("checkbox2").checked = false
        //   document.getElementById("taskName").value = ''

        clearSelects()
    }

    function clearSelects() {
        let blankEntry = { label: '', value: '' }

        dispatch(settingUserToReportTo(blankEntry))

        dispatch(settingJobToReportTo(blankEntry))

        dispatch(setDestinationFormat(blankEntry))
        dispatch(setDestinationType(blankEntry))
        dispatch(setJobPosition(blankEntry))
        dispatch(setAssignedUser(blankEntry))
        dispatch(setFrequency(blankEntry))
        dispatch(clearTask())
    }

    function onSaveClicked() {
        let name = document.getElementById("taskName").value

        //send data from bottom selects to be saved
        const cloneOfTask = structuredClone(tasks.taskBeingMade);

        console.log("Task patching : ", cloneOfTask)
        cloneOfTask.location = user.viewingLocation

        if (history?.location?.state?.taskToDisplay) {
            cloneOfTask._id = history.location.state.taskToDisplay._id
            dispatch(patchTask(cloneOfTask))

        }
        else {
            dispatch(addNewTask({ name: name, task: cloneOfTask, org_id: user.org_id }))
        }

        clearFields()
        clearSelects()
        dispatch(clearTask())
    }

}

export default createTask;