import './Header.css';
import React from 'react';
import { FaSearch } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Select from 'react-select';
import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from 'react-redux';
import { getTokenStatus, fetchToken } from '../store/login.slice'
import { login, logout } from '../store/actions/auth'
import Cookies from 'universal-cookie';

import LoginForm from './loginComp'


// Replace the existing user retrieval code with this safer version
const getUserFromStorage = () => {
    try {
        const userStr = localStorage.getItem('user');
        if (!userStr) return '';

        const parsedData = JSON.parse(userStr);

        // Check if login failed
        if (parsedData === 'Login failed') {
            return parsedData;
        }

        // Return first user if it's an array, otherwise return the parsed data
        return Array.isArray(parsedData) ? parsedData[0] : parsedData;
    } catch (error) {
        console.error('Error parsing user data:', error);
        localStorage.removeItem('user'); // Clean up invalid data
        return '';
    }
};


function Header(props) {
    //fetchToken

    const [toggle, setToggle] = useState(false)

    // const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const user = getUserFromStorage();


    //const { loginWithRedirect } = useAuth0();
    const cookies = new Cookies();


    const dispatch = useDispatch();

    //const items = useSelector(selectItems);
    const loadingToken = useSelector(getTokenStatus);

    let isAuthenticated = false;

    //  const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) == 'Login failed' ? JSON.parse(localStorage.getItem('user')) : JSON.parse(localStorage.getItem('user'))[0] : ''

    if (user && user?._id) {
        isAuthenticated = true


    }


    function login() {
        console.log("Attempt login : ", document.getElementById("username").value, document.getElementById("password").value, process.env)

        //  const dispatch = useDispatch();

        dispatch(login(document.getElementById("username").value, password), document.getElementById("password").value)
        window.location.reload();

        return 1
    }

    function logoutUser() {


        console.log("Attempt logout ")

        //  const dispatch = useDispatch();

        dispatch(logout())
        location.reload()

        return 1
    }


    if (!isAuthenticated) {
        //  dispatch(logout())
        //  location.reload()
    }
    //  console.log("Checking info header:", process.env, user)


    if (user == "Login Failed") {
        //logout and clear user 
        dispatch(logout())
        location.reload()
    }
    else {

    }
    //const { logout } = useAuth0();

    /*

        <div className='pageOption fade floatIn' onClick={() => { openPage('Profile') }}>
                                        Profile
            </div>

                                            <div className='pageOption fade floatIn' onClick={() => { openPage('Profile') }}>
                                    Profile
                            </div>

                            
    */
    return (
        <>
            {(!user || user === "Login failed") &&

                <div className='areaWhenSidebarIsHidden'>
                    <div className="mainHeader2 ">
                        <div className='toolbarSection'>
                            {isAuthenticated && user && <div className='profileButton'>
                                < span id="nameSpan" className='usernameSpan'>{user.displayName}</span>
                                <div>
                                    <div className='blackLineDiv fade floatIn' style={{ marginTop: '14%' }}>  &nbsp;</div>




                                    <div className='pageOption fade floatIn' style={{ marginTop: '3%' }} onClick={() => logoutUser()}>
                                        Logout
                            </div>

                                </div>

                            </div>

                            }

                            {!isAuthenticated && <div className='profileButtonNoAnim' onClick={() => setToggle(!toggle)}>
                                <span className='usernameSpan2'>Login</span>
                            </div>
                            }

                        </div>
                    </div>

                </div>
            }



            {user && user != "Login failed" &&
                <div className="mainHeader " style={{ backgroundColor: '#f5f5f5', borderRadius: '0 0 0 0' }}>

                    <div className='toolbarSection'>

                        {isAuthenticated && <div className='profileButton'>

                            < span id="nameSpan" className='usernameSpan'>{user.displayName}</span>

                            <div>

                                <div className='blackLineDiv fade floatIn' style={{ marginTop: '27%' }}>  &nbsp;</div>




                                <div className='pageOption fade floatIn' style={{ marginTop: '3%' }} onClick={() => logoutUser()}>
                                    Logout
                            </div>




                            </div>

                        </div>

                        }

                        {!isAuthenticated && <div className='profileButtonNoAnim'>


                            <span onClick={() => setToggle(!toggle)} className='usernameSpan2'>Login</span>


                        </div>
                        }





                    </div>

                </div>
            }

            {toggle &&

                <LoginForm toggle={toggle}></LoginForm>


            }

        </>
    );



    function toggleCheck() {
        console.log("toggle :", toggle)
    }


    function openPage(page) {


        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}/` + page; //TODO

        //navigate(page)

    }

    function Box(props) {
        return (
            <div
                style={{ visibility: props.status ? "visible" : "hidden" }}
                className="box"
            ></div>
        );
    }

}


export default Header;

