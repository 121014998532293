import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// context.js
import { createContext } from 'react';
import store from './store';
import Cookies from 'universal-cookie'


const SET_USER = 'SET_USER'

export const loginContext = createContext('Default Value');

export const setUserLogin = createAsyncThunk(
  'account/setUserLogin',

  async (item) => {
    console.log("payload : ", item)
    return {
      type: SET_USER,
      payload: item
    }
  }
);

//Careful with security of what we show here
const initialState = {
  loading: false
};


export const loginSlice = createSlice({
  name: 'account',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    /*  setUserLogin: (state, action)=>{
        console.log("Setting account :" , action)
             state.account = action.payload
         }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(setUserLogin.pending, (state, action) => {
        console.log("setting login pending", state.account)
        //  state.account.loading = true
      })

      .addCase(setUserLogin.rejected, (state, action) => {
        console.log("rejected user login : ", action)
      })

      .addCase(setUserLogin.fulfilled, (state, action) => {
        console.log("Setting user login : ", action, state)
        state.loggedIn = true
        state.account.userAccount = action.payload.payload.data
        // state.account.loading = false

      })
  },
});





export const fetchToken = createAsyncThunk(
  'login/fetchToken',
  async () => {
    try {
      const cookies = new Cookies();

      console.log("fetching token for flow")
      console.log("Current cookies :", cookies.get('Auth0-API-TOKEN')); //encrypted by backend

      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/Client-Credential-Flow`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'accessToken' : cookies.get('accessTokenAuth0')})

      })



      const realData = await test.json();


    //  cookies.set('Auth0-API-TOKEN', realData['Auth0-API-TOKEN'], { path: '/' });


      console.log("returned cookie not being set? :", realData)
      return realData
    }
    catch (err) {
      console.log("Error message :", err.message)
      return err.message
    }

  }
);





export const getTokenStatus = (state) => {
  // fetchAccount(state, dispatch) //error here i bet 
  return state.loading;
}


export default loginSlice.reducer;


/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.login =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/