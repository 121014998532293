import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styles from "./location.module.css";
import {
    addNewLocation,
    getLocationBeingMade,
    setLocationWebsiteBeingMade,
    setLocationAddressBeingMade,
    setLocationEmailBeingMade,
    setLocationNameBeingMade,
    setLocationPhoneBeingMade,
    deleteLocation,
    fetchLocations,
    getLoadedLocation,
    getLocationLoadStatus,
    patchLocation,
    selectLocations,
    SetLocation,
    setLocationBeingMade
} from './locationSlice';

const InputField = React.memo(({ label, id, value, onChange, type = "text", tabIndex }) => {
    const inputRef = useRef(null);

    const handleChange = (event) => {
        const { selectionStart, selectionEnd } = event.target;
        onChange(event);
        setTimeout(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    };

    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <input
                type={type}
                id={id}
                ref={inputRef}
                value={value || ''}
                onChange={handleChange}
                className={styles.defaultInp}
                tabIndex={tabIndex}
            />
        </label>
    );
});

function LocationSetup(props) {
    const dispatch = useDispatch();
    const [toggleShowCreate, setToggle1] = useState(false);
    const [toggleShowEdit, setToggle2] = useState(false);

    const locations = useSelector(selectLocations);
    const loading = useSelector(getLocationLoadStatus);
    const loadedLocation = useSelector(getLoadedLocation);
    const locationBeingCreated = useSelector(getLocationBeingMade);

    const selectLocationInputRef = useRef();

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }


    useEffect(() => {
        if (loading != 'Done') {
            dispatch(fetchLocations(user.org_id));
        }
    }, [loading, dispatch, user.org_id]);

    const getPageWrapperClassName = useCallback(() => {
        const baseClass = styles.pageWrapper;
        const scrollClass = (toggleShowCreate === 'create' || toggleShowEdit === 'edit') ? styles.pageWrapperScroll : '';
        return `${baseClass} ${scrollClass}`.trim();
    }, [toggleShowCreate, toggleShowEdit]);

    const clearSelects = useCallback(() => { 
        let blankEntry = { label: '', value: '' };
        dispatch(SetLocation(blankEntry));
    }, [dispatch]);

    const clearLocationForm = useCallback(() => {
        dispatch(setLocationBeingMade({
            name: '',
            address: '',
            phone: '',
            email: '',
            website: ''
        }));
    }, [dispatch]);


    const settingToggle1 = useCallback((value) => {
        if (toggleShowCreate === 'create') {
            setToggle1('');
        } else {
            if (toggleShowEdit === 'edit') {
                clearSelects();
            }
            clearLocationForm();
            setToggle1(value);
        }
        setToggle2('');
    }, [toggleShowCreate, toggleShowEdit, clearLocationForm]);




    const settingToggle2 = useCallback((value) => {
        if (toggleShowEdit == 'edit') {
            setToggle2('');
        } else {
            setToggle2(value);
        }
        setToggle1('');
    }, [toggleShowEdit]);

    const selectChange = useCallback((evt) => {
        clearSelects();
        if (toggleShowEdit != 'edit') {
            settingToggle2('edit');
        }
        dispatch(setLocationBeingMade(evt));
        dispatch(SetLocation(evt));
    }, [dispatch, toggleShowEdit, settingToggle2]);

    const onDeleteClicked = useCallback((item) => {
        dispatch(deleteLocation(item));
        clearSelects();
    }, [dispatch]);

    // Also update onCancelClicked to use this
    const onCancelClicked = useCallback(() => {
        clearSelects();
        clearLocationForm();
        setToggle2(false);
        setToggle1(false);
    }, [clearSelects, clearLocationForm]);


    const onSaveClicked = useCallback(() => {
        let newLocation = structuredClone(locationBeingCreated);
        newLocation.org_id = user.org_id;

        if (toggleShowEdit == 'edit') {
            newLocation._id = loadedLocation._id;
            dispatch(patchLocation(newLocation));
        } else {
            dispatch(addNewLocation(newLocation));
        }
    }, [dispatch, locationBeingCreated, user.org_id, toggleShowEdit, loadedLocation]);

    return (










        
        <div className={getPageWrapperClassName()}>
            <div className={styles.userForm}>
                <div className={styles.userFormContent} style={{ width: '99%' }}>
                    <h3 id="titleText" style={{ textAlign: 'left' }} className={styles.existingUser}>Locations</h3>

                    <div className={styles.selectandbuttonwrapper}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="locationSelect"
                                ref={selectLocationInputRef}
                                value={loadedLocation}
                                className={styles.locationSelect}
                                onChange={selectChange}
                                options={locations}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                tabIndex={0}
                            />
                        </div>

                        <div className={styles.createLocationWrapper}>
                            <button onClick={() => settingToggle1("create")} className={styles.formBtn3}>
                                Create Location
                            </button>
                        </div>
                    </div>
                </div>
            </div>















            {(toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <div className={styles.locationInfoSection}>
                    <h4 className={styles.contactInfo} style={{ textAlign: 'left' }}>Location Info</h4>

                    <div className={styles.flexBox}>
                        <div className={styles.leftCol}>
                            <InputField
                                label="Name"
                                id="name"
                                value={locationBeingCreated?.name}
                                tabIndex={1}
                                onChange={(evt) => dispatch(setLocationNameBeingMade(evt.target.value))}
                            />
                            <InputField
                                label="Address"
                                id="address"
                                value={locationBeingCreated?.address}
                                tabIndex={3}
                                onChange={(evt) => dispatch(setLocationAddressBeingMade(evt.target.value))}
                            />
                            <InputField
                                label="Phone Number"
                                id="phone"
                                tabIndex={5}
                                value={locationBeingCreated?.phone}
                                onChange={(evt) => dispatch(setLocationPhoneBeingMade(evt.target.value))}
                            />
                        </div>

                        <div className={styles.rightCol}>
                            <InputField
                                label="Email"
                                id="email"
                                tabIndex={2}
                                value={locationBeingCreated?.email}
                                onChange={(evt) => dispatch(setLocationEmailBeingMade(evt.target.value))}
                            />
                            <InputField
                                label="Website"
                                id="website"
                                tabIndex={4}
                                value={locationBeingCreated?.website}
                                onChange={(evt) => dispatch(setLocationWebsiteBeingMade(evt.target.value))}
                            />
                        </div>
                    </div>
                </div>
            )}





            <div className={styles.blackLineDiv}></div>





            {(toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <div className={styles.bottomSection} style={{ width: '82%' }}>
                    <div className={styles.formBtnDiv}>
                        <button onClick={onCancelClicked} className={styles.formBtn}>Cancel</button>
                        <button onClick={() => onDeleteClicked(loadedLocation)} className={styles.formBtn}>Delete</button>
                        <button
                            onClick={onSaveClicked}
                            className={styles.formBtn2}
                        >
                            {toggleShowEdit == 'edit' ? 'Save' : 'Create'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LocationSetup;