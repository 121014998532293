
import { React, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from "react-router-dom";
import Select from 'react-select';
import Header from '../../Components/Header';
import SideBar from '../../Components/SideBar';
import styles from "./purchaseRequest.module.css";
import { fetchTasks, setTaskForEdit, patchTask, loadTasks, getTaskLoadStatus, selectTasks, SetSection, RemoveSection, setKeepUpdatedTask, setRequestStatusReportOnComplete, setDestinationFormat, addNewTask, SaveItemToField, updateQuantity, setFrequency, setDestinationType, updateNote, setJobPosition, setAssignedUser, taskSlice, deleteTask } from '../taskBuilder/taskSlice';
import { fetchItems, getItemLoadStatus, getLoadedItem, getitemsError, selectItems, itemSearch, getSearchResult, getSearchDone } from '../forms/items/itemSlice';
import { companySearch } from '../forms/vendors/vendorSlice'
import { SetJobPosition, addNewJobPosition, deleteJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../forms/JobPositions/jobPositionSlice';
import { SetUser, addNewUser, selectUsers, getUserLoadStatus, getusersError, getLoadedUser, fetchUsers, deleteUser, updateUser } from '../forms/users/UsersSlice';
import { saveItemSelected, saveQuantityToItem, setPurchaseRequest, addCartToPendingPurchaseRequest, selectpurchaseRequest, setPageView, setSummary, getSummary } from './purchaseRequest.slice'
import { updateQuantityPr, setSummaryFromForm, setFormLoaded, updatePendingPurchase, setOrderIndexToBeReconciled, setSelectedOrder, getSearchResultPendingPurchase, pendingPurchaseSearch, updateNoteOnPendingPurchase, updatePrice, toggleGoodStatus, toggleBadStatus, toggleNotRecievedStatus, createPendingPurchase, getPendingPurchaseLoadStatus, fetchPendingPurchases, selectPendingPurchase } from './pendingPurchase.slice'
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";

const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]
const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' }]

const destinationType = [{ label: 'User Email' },]

const destinationFormat = [{ label: 'Format' },]
let frequency = []


function purchaseRequest(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const tasks = useSelector(selectTasks);
    const loadingTasks = useSelector(getTaskLoadStatus);

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);
    const searchResult = useSelector(getSearchResult);
    const searchDone = useSelector(getSearchDone);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);


    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);
    const searchResultPendingPurchases = useSelector(getSearchResultPendingPurchase);

    const purchaseRequestState = useSelector(selectpurchaseRequest);
    const pendingPurchaseState = useSelector(selectPendingPurchase);
    const ppLoadStatus = useSelector(getPendingPurchaseLoadStatus);
    const summary = useSelector(getSummary);
    const [queryParams, setQueryParams] = useState({});


    // const { user, isAuthenticated, isLoading } = useAuth0();

    // console.log("Pending purchase state :", pendingPurchaseState)
    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }
    //const loadedTask = useSelector(getLoadedItem);



    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const paramsObject = {};
        for (const [key, value] of params.entries()) {
            paramsObject[key] = value;
        }
        setQueryParams(paramsObject);

        if (paramsObject.fromForm === 'true') {
            const storedTask = localStorage.getItem('pendingPurchaseTask');
            const summaryItem = localStorage.getItem('summaryItem');

            if (storedTask) {
                try {
                    const parsedTask = JSON.parse(storedTask);


                    dispatch(setFormLoaded(parsedTask));
                    openSubPage('PendingPurchase', purchaseRequestState);

                    localStorage.removeItem('pendingPurchaseTask');
                } catch (error) {
                    console.error('Error parsing stored task:', error);
                }
            }

            if (summaryItem) {
                const parsedsummaryItem = JSON.parse(summaryItem);
                dispatch(setSummaryFromForm(parsedsummaryItem ?? parsedTask.section.summary ?? {}));
                console.log("Checking memoery :", storedTask, summaryItem, parsedsummaryItem ?? parsedTask.section.summary ?? {})

            }
        }
    }, [dispatch]);


    useEffect(() => {
        if (pendingPurchaseState.summaryFromForm?.length > 0) {
            //  openSubPage('summary', purchaseRequestState);
        } else if (queryParams['view'] && purchaseRequestState.currentView !== queryParams['view']) {
            dispatch(setPageView(queryParams['view']));
        }
    }, [queryParams, purchaseRequestState.currentView]);


    // console.log("params check :   ", queryParams)

    useEffect(() => {

        if (ppLoadStatus != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchPendingPurchases({ org_id: user.org_id, location_id: user.viewingLocation }))
            //   dispatch(companySearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [ppLoadStatus, dispatch])

    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ type: 'Purchase Request', org_id: user.org_id, location_id: user.viewingLocation }))
            dispatch(pendingPurchaseSearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }))

        }
    }, [loadingTasks, dispatch])


    useEffect(() => {

        if (searchDone != true) {
            //  console.log("fetching vendors")
            //  dispatch(itemSearch(''))
        }
    }, [searchDone, dispatch])

    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            dispatch(fetchItems({ org_id: user.org_id, location: user?.user_metadata?.viewingLocation }))
            dispatch(itemSearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }))

        }
    }, [loading, dispatch])

    console.log("pendingPurchaseState.searchResult", Array.from(pendingPurchaseState.searchResult), pendingPurchaseState.searchResult)

    return (
        <>
            {purchaseRequestState.currentView == 'request' &&
                <div className={styles.pageWrapper}>


                    {tasks?.tasks.length > 0 &&
                        <div>
                            <div className={styles.subPageHeaders}>
                                <span onClick={() => { openSubPage('request', purchaseRequestState) }}>Request</span> <span style={{ marginLeft: '5%' }} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState) }}  >Pending Purchases</span><button className={styles.formBtn} onClick={() => { openSubPage('summary', purchaseRequestState) }} style={{ marginLeft: '63%', borderRadius: '10px 10px 10px 10px' }}>Check out</button>
                            </div>

                            <div className={styles.scrollWrapper}>

                                <div className={styles.inputSection} >

                                    <div className="checkboxes">
                                        <label> </label>  </div>

                                    {tasks?.tasks?.map((task2) => (
                                        < div className={styles.purchaseRequest} style={{ backgroundColor: purchaseRequestState?.selectedPR?._id == task2._id ? 'rgb(255 235 215)' : '#f5f5f5', border: purchaseRequestState?.selectedPR?._id == task2._id ? '2px solid rgb(255 174 87)' : '1px solid black' }} >

                                            <input type="checkbox" checked={purchaseRequestState.selectedPR && purchaseRequestState?.selectedPR?._id == task2._id} onChange={(evt) => setSelectedPurchaseRequest(evt, task2)} />

                                            <div style={{ marginLeft: '3%', textAlign: 'center', marginTop: '1%' }}>

                                                {task2?.task?.assignedUser.length > 0 &&
                                                    Array.from(task2?.task?.assignedUser).map((user) => {
                                                        return (

                                                            <div className={styles.nameColumn}>

                                                                <span>{user.displayName || 'No name'}</span>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>

                                            <div className={styles.prItemArea}>
                                                <div className={styles.itemField}>

                                                    <div className={styles.nameColumn}>
                                                        Item
                                            </div>

                                                    <div className={styles.quantityColumn}>
                                                        Note
                                            </div>

                                                    <div className={styles.noteColumn}>
                                                        Quantity
                                            </div>
                                                    <div className={styles.SummaryCol}>
                                                        Order Summary
                                            </div>
                                                </div>

                                                {task2?.task.fields?.map((field) => {
                                                    return (

                                                        <div className={styles.itemField}>

                                                            <div className={styles.nameColumn}>
                                                                {field.item?.itemName || 'No item name'}

                                                            </div>
                                                            <div className={styles.noteColumn} >
                                                                {field.note || <span > -</span>}
                                                            </div>

                                                            <div className={styles.quantityColumn}>
                                                                <input type="number" style={{ width: '34px', textAlign: 'center', opacity: '1', backgroundColor: 'transparent', border: 'none' }} value={field.parValue}></input>
                                                            </div>


                                                            <div className={styles.quantityColumn}>
                                                            </div>
                                                        </div>

                                                    )
                                                })}

                                            </div>

                                            <div className={styles.prOrderSummary}>

                                                {task2.checkedOut && task2.checkedOut.map((item, index) => {
                                                    return (
                                                        < div className={styles.helperDiv1}>
                                                            <div >   <FaRegTrashAlt style={{ marginLeft: '-5%', marginRight: '2%', }} onClick={(evt) => deleteItemFromCheckedOut(item, index, task2)} />{item.itemName} {item?.vendor?.company && <span>from {item?.vendor?.company} </span>} </div>  Qty: {item.pendingOrderQuantity}

                                                        </div>
                                                    )
                                                })}

                                            </div>

                                        </div>
                                    ))}


                                </div>
                            </div>
                            <div className={styles.blackLineDiv2}>
                                &nbsp;
                                    </div>

                            <div >
                                <input placeholder="Search items" className={styles.SearchboxSection} onChange={(evt) => searchItems(evt)}></input>
                            </div>

                            <div className={styles.searchResArea} style={{ height: '87%' }}>

                                {searchDone && searchResult.length != items.length && Object.keys(searchResult).map((companyName, index) => {

                                    return (

                                        <div className={styles.helperDiv2}>

                                            <div style={{ backgroundColor: '#0c1620', borderRadius: '0 0 7px 7px', color: 'white', padding: '.5%' }}>
                                                {companyName}
                                            </div>
                                            <table>
                                                <tr style={{ padding: '.5%' }}>
                                                    <th></th>

                                                    <th style={{ width: '125px' }}>Item</th>
                                                    <th style={{ width: '125px' }}>Item Number</th>
                                                    <th style={{ width: '125px' }}>Size</th>
                                                    <th style={{ width: '125px' }}>Cost</th>
                                                    <th style={{ width: '125px' }}>Order Qty</th>
                                                </tr>


                                                {searchResult[companyName].map((item, itemIndex) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: '50px' }}><input type="checkbox" id={'itemCheckbox' + index} onChange={(evt) => tempSaveItemSelected(evt, item, index, companyName)} /></td>
                                                            <td style={{ width: '125px' }}>{item.itemName}</td>
                                                            <td style={{ width: '125px' }}>{item.itemNumber}</td>
                                                            <td style={{ width: '125px' }}>{item.size}</td>
                                                            <td style={{ width: '125px' }}>{item.cost}</td>

                                                            <td style={{ width: '125px' }} ><input id={'quantField' + index} type='number' value={item.pendingOrderQuantity} onChange={(evt) => saveQuantityOnPendingOrder(evt, item, companyName)} ></input></td>
                                                        </tr>

                                                    )

                                                })}

                                            </table>
                                            <div style={{ padding: '.5%' }}>

                                                <button className='addToCartBtn' onClick={() => { addPendingCartToPendingOrder(companyName, purchaseRequestState) }} className={styles.formBtn}>Add to Cart</button>

                                            </div>

                                        </div>)
                                })}

                            </div>

                        </div>
                    }
                    {tasks?.tasks.length <= 0 &&
                        <div className={styles.pageWrapper}>

                            <button style={{ marginTop: '31vh', marginBottom: 'auto', marginLeft: '25vw', marginRight: 'auto', borderRadius: '6px', padding: '2vw' }} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState) }}>There are no active requests (Go to pending orders)</button>

                        </div>
                    }


                </div>
            }

            {
                purchaseRequestState.currentView == 'summary' &&
                <div className={styles.pageWrapper}>

                    <div className={styles.inputSection2} >

                        <div className="checkboxes">
                            <label> </label>  </div>


                        <div className={styles.subPageHeaders}>
                            <span className={styles.headerButton} onClick={() => openSubPage('request', purchaseRequestState)}>Request</span>

                            <span className={styles.headerButton} onClick={() => { goToDashboard() }}>Go To Dashboard</span>


                            {(pendingPurchaseState.summaryFromForm?.length > 0 || pendingPurchaseState?.summary?.length > 0 || pendingPurchaseState?.summary?.length > 0) && <span className={styles.headerButton} onClick={() => openSubPage('summary', purchaseRequestState)}>
                                Check Out
                            </span>}



                            <span className={styles.headerButton} onClick={() => openSubPage('PendingPurchase', purchaseRequestState)}>
                                Pending Purchases
                            </span>

                        </div>

                    </div>


                    <div className={styles.summaryArea}>

                        {purchaseRequestState.summary && Object.keys(purchaseRequestState.summary).map((companyName, index) => {

                            return (
                                <div className={styles.summaryItem}>

                                    <div className={styles.cardHeader}>
                                        <span style={{ marginBottom: '2%' }}>
                                            {companyName} | Contact
                                            </span>
                                    </div>

                                    <div className={styles.topCard}>
                                        <div style={{ paddingLeft: '1%', paddingTop: '1%' }}>

                                            <span>Contact Information</span>

                                        </div>

                                        <div className={styles.midCard}>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}> Phone: </span>

                                                    <span className={styles.rightItem1}>{purchaseRequestState.summary[companyName][0].vendor.phoneNumber}</span>

                                                </div>

                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1} >
                                                        Min Order:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.minOrder}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}>
                                                        Email:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.contactEmail}
                                                    </span>
                                                </div>


                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.rightItem1}>
                                                        Order Deadline:
                                                    </span>
                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.orderDeadline}
                                                    </span>
                                                </div>


                                            </div>

                                            <div className={styles.cardRow}>

                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.leftItem1}>
                                                        Contact Person:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.contactPerson}
                                                    </span>


                                                </div>


                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.leftItem1}>
                                                        Instructions:
                                                    </span>
                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.instructions}
                                                    </span>
                                                </div>


                                            </div>



                                            <div className={styles.cardRow}>


                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}>
                                                        Website:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.website}
                                                    </span>


                                                </div>

                                            </div>

                                            <div className={styles.cardRow}>

                                                <div className={styles.cardLineItem}>
                                                    <span>
                                                        Username:
                                                    </span>


                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.username}
                                                    </span>

                                                </div>



                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1}>
                                                        Payment Terms:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.paymentTerms}
                                                    </span>
                                                </div>

                                            </div>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1}>
                                                        Password:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.password}
                                                    </span>


                                                </div>


                                            </div>


                                        </div>

                                    </div>

                                    <div className={styles.blackLineDiv2}>
                                        &nbsp;
                                    </div>


                                    <div className={styles.bottomCard}>
                                        <div className={styles.leftHalfBC}>
                                            <div>
                                                <span className={styles.orderSumHeader}>Order Summary</span> <span style={{ marginLeft: '5%' }}>Purchase No: {purchaseRequestState.selectedPR._id} </span>   <span style={{ marginLeft: '15%' }}>Note</span> <span  >{purchaseRequestState.summary[companyName][0].vendor.Note}</span>
                                            </div>

                                            {purchaseRequestState.summary[companyName].map((item, itemIndex) => {
                                                return (
                                                    <div>
                                                        <span style={{ width: '100px' }}>{item.itemName}  </span> <span> Qty:{item.pendingOrderQuantity}</span>

                                                    </div>
                                                )

                                            })}

                                            <span>Arrive on (?)</span>

                                        </div>

                                        <div className={styles.rightHalfTC}>
                                            <div>
                                                <input type="checkbox" /> <span>Website</span>
                                            </div>

                                            <div>
                                                <input type="checkbox" /> <span>Phone</span>
                                            </div>

                                            <div>
                                                <input type="checkbox" /> <span>Email</span>
                                            </div>


                                        </div>

                                        <div style={{ paddingTop: '2%', paddingRight: '2%' }}>
                                            <button className={styles.donebtn} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState, purchaseRequestState.summary[companyName]) }}>Order</button>

                                        </div>

                                    </div>

                                </div>
                            )
                        })}





                    </div>




                </div>
            }

            {purchaseRequestState.currentView == 'PendingPurchase' && (
                <div className={styles.pageWrapper}>
                    <div className={styles.subPageHeaders}>

                        <span className={styles.headerButton} onClick={() => openSubPage('request', purchaseRequestState)}>Requests</span>

                        <span className={styles.headerButton} onClick={() => { goToDashboard() }}>Go To Dashboard</span>


                        {(pendingPurchaseState.summaryFromForm?.length > 0 || pendingPurchaseState?.summary?.length > 0) && <span className={styles.headerButton} onClick={() => openSubPage('summary', purchaseRequestState)}>
                            Checked Out
                            </span>}



                        <span className={styles.headerButton} onClick={() => openSubPage('PendingPurchase', purchaseRequestState)}>
                            Pending Purchases
                            </span>
                    </div>

                    <div>
                        <input
                            placeholder="Search Companies"
                            className={styles.SearchboxSection}
                            onChange={(evt) => searchCompanies(evt)}
                        ></input>
                    </div>

                    <div className={styles.searchResArea} style={{ height: '87vh' }}>
                        {pendingPurchaseState.searchResult &&
                            Object.keys(pendingPurchaseState?.searchResult).map((vendorName, index1) => (
                                <div key={index1}>
                                    <div className={styles.helperDiv2}>
                                        <div style={{ backgroundColor: '#0c1620', borderRadius: '0 0 7px 7px', color: 'white', padding: '0.5%' }}>
                                            {vendorName}
                                        </div>


                                        {console.log("Checking vendor names : ", vendorName)}


                                        <div style={{ padding: '0.5%' }}>
                                            {searchResultPendingPurchases[vendorName]?.map((order, orderIndex) => (
                                                <div
                                                    key={order._id}
                                                    className={styles.notificationBody}
                                                    style={{
                                                        transition: 'all 0.3s ease-in-out',
                                                        height: pendingPurchaseState?.selectedOrder?._id === order?._id ? 'auto' : '5vh',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => setOrder(order)}
                                                >


                                                    <div className={styles.midbuffer}>
                                                        <div style={{ display: 'flex' }}>
                                                            <span style={{ paddingTop: '1%', width: '30vw' }}>{order._id}</span>

                                                            <div style={{ paddingTop: '1%', width: '30vw' }}>
                                                                <span>
                                                                    {order.purchaseOrder.length} {order.purchaseOrder.length > 1 ? 'items' : 'item'}
                                                                </span>
                                                            </div>

                                                            <div style={{ paddingTop: '1%', width: '30vw' }}>
                                                                <span>Ordered: {new Date(order.dateCreated).toLocaleDateString()}</span>
                                                            </div>
                                                        </div>

                                                        <span
                                                            style={{
                                                                display: pendingPurchaseState?.selectedOrder?._id === order?._id ? 'block' : 'none',
                                                                marginTop: '1vh',
                                                            }}
                                                        >
                                                            <div className={styles.blackLineDiv2} style={{ marginLeft: '-2%', width: '100%' }}>
                                                                &nbsp;
                                                            </div>

                                                            <tr style={{ padding: '0.5%' }}>
                                                                <th style={{ width: '12.5vw' }}></th>
                                                                <th style={{ width: '12.5vw' }}>Item</th>
                                                                <th style={{ width: '12.5vw' }}>Item No.</th>
                                                                <th style={{ width: '12.5vw' }}>Size</th>
                                                                <th style={{ width: '12.5vw' }}>Cost</th>
                                                                <th style={{ width: '12.5vw' }}>Order Qty</th>
                                                            </tr>

                                                            {order?.purchaseOrder?.map((item, poIndex) => (
                                                                <div style={{ marginTop: '1%' }} onClick={(e) => e.stopPropagation()}>
                                                                    <tr>
                                                                        <td style={{ width: '12.5vw' }}>
                                                                            <button onClick={(evt) => dispatch(setOrderIndexToBeReconciled(poIndex))}>
                                                                                Reconcile
                                                                            </button>
                                                                        </td>
                                                                        <td style={{ width: '12.5vw' }}>{item.itemName}</td>
                                                                        <td style={{ width: '12.5vw' }}>{item.itemNumber}</td>
                                                                        <td style={{ width: '12.5vw' }}>{item.size}</td>
                                                                        <td style={{ width: '12.5vw' }}>{item.cost}</td>
                                                                        <td style={{ width: '12.5vw' }}>{item.pendingOrderQuantity}

                                                                        </td>
                                                                    </tr>

                                                                    {pendingPurchaseState.selectedOrderForReconcileIndex == poIndex && (
                                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: 'rgb(231 231 231)',
                                                                                    borderRadius: '4px',
                                                                                    marginTop: '1%',
                                                                                    marginBottom: '4%',
                                                                                }}
                                                                            >
                                                                                <tr>
                                                                                    <th style={{ width: '25vw' }}>Qty.</th>
                                                                                    <th style={{ width: '12.5vw' }}>Price</th>
                                                                                    <th style={{ width: '12.5vw' }}>Good</th>
                                                                                    <th style={{ width: '12.5vw' }}>Bad</th>
                                                                                    <th style={{ width: '22.5vw' }}>Not Received</th>
                                                                                    <th style={{ width: '12.5vw' }}>Note</th>
                                                                                </tr>

                                                                                <tr style={{ padding: '0.5%', position: 'relative' }}>
                                                                                    <td style={{ width: '25vw' }}>
                                                                                        <input
                                                                                            onChange={(evt) =>
                                                                                                dispatch(updateQuantityPr({ value: evt.target.value, orderIndex, itemIndex: poIndex, vendorName }))
                                                                                            }
                                                                                            type='number' value={item.pendingOrderQuantity}></input>
                                                                                    </td>
                                                                                    <td style={{ width: '12.5vw' }}>
                                                                                        <input
                                                                                            style={{ width: '5vw' }}
                                                                                            value={item.cost}
                                                                                            type='number'
                                                                                            onChange={(evt) =>
                                                                                                dispatch(updatePrice({ value: evt.target.value, orderIndex, itemIndex: poIndex, vendorName }))
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '2.5vw' }}>
                                                                                        <input
                                                                                            checked={!!item.goodStatus}
                                                                                            type="checkbox"
                                                                                            onChange={(evt) =>
                                                                                                dispatch(toggleGoodStatus({
                                                                                                    value: evt.target.checked,
                                                                                                    orderIndex,
                                                                                                    itemIndex: poIndex,
                                                                                                    vendorName
                                                                                                }))
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '12.5vw' }}>
                                                                                        <input
                                                                                            checked={!!item.badStatus}
                                                                                            type="checkbox"
                                                                                            onChange={(evt) =>
                                                                                                dispatch(toggleBadStatus({
                                                                                                    value: evt.target.checked,
                                                                                                    orderIndex,
                                                                                                    itemIndex: poIndex,
                                                                                                    vendorName
                                                                                                }))
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td style={{ width: '22.5vw' }}>
                                                                                        <input
                                                                                            checked={!!item.notReceived}  // Changed from notRecieved to notReceived
                                                                                            type="checkbox"
                                                                                            onChange={(evt) =>
                                                                                                dispatch(toggleNotRecievedStatus({
                                                                                                    value: evt.target.checked,
                                                                                                    orderIndex,
                                                                                                    itemIndex: poIndex,
                                                                                                    vendorName
                                                                                                }))
                                                                                            }
                                                                                        />
                                                                                    </td>

                                                                                    <td style={{ width: '25vw' }}>
                                                                                        <textarea></textarea>
                                                                                    </td>

                                                                                </tr>

                                                                            </div>

                                                                            <div style={{ width: '14vw', float: 'left', display: 'flex', marginTop: '2vh', justifyContent: 'space-between' }}>
                                                                                <button style={{ width: '100%', height: '5vh', borderRadius: '10px', marginTop: 'auto', marginBottom: '3vh' }} onClick={() => { onSaveReconcileClicked({ orderIndex, itemIndex: poIndex, vendorName, index1 }) }}  >Save changes</button>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            ))}
                                                        </span>

                                                    </div>

                                                    <div className={styles.iconBuffer}>
                                                        <div className={styles.iconArea}>
                                                            {pendingPurchaseState?.selectedOrder?._id === order?._id ? (
                                                                <FiMinusCircle />
                                                            ) : (
                                                                    <FiPlusCircle />
                                                                )}
                                                        </div>
                                                    </div>


                                                    {pendingPurchaseState?.selectedOrder?._id === order?._id && (
                                                        <div style={{ marginLeft: '-12vw' }}>
                                                            <button
                                                                className={styles.finishedButton}
                                                                onClick={(evt) => markAsFinishedFunc({ value: pendingPurchaseState?.selectedOrder })}
                                                            >
                                                                Mark Finished
                                                        </button>


                                                        </div>

                                                    )}


                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}

        </>
    )

    async function markAsFinishedFunc(evt) {
        try {
            console.log("Marking as finished:", evt.value);

            // Create a clean copy of the order
            let temp = structuredClone(evt.value);
            // Ensure we have the required fields
            const updateData = {
                _id: temp._id,
                markedAsFinished: true,
                purchaseOrder: temp.purchaseOrder || [],
                org_id: temp.org_id,
                location: temp.location
            };

            console.log("Cant tell whats happening : ", updateData)
            await dispatch(updatePendingPurchase(updateData));

            refreshData();

        } catch (error) {
            console.error("Error marking order as finished:", error);
            // Could add error handling here like showing a notification
        }
    }



    function onSaveReconcileClicked(data) {
        let { orderIndex, itemIndex, vendorName, index1 } = data;
        console.log("Inputs:", pendingPurchaseState.searchResult[vendorName], orderIndex, itemIndex, vendorName, index1);

        const orderToUpdate = {
            _id: pendingPurchaseState.searchResult[vendorName][orderIndex]._id,
            purchaseOrder: pendingPurchaseState.searchResult[vendorName][orderIndex].purchaseOrder,
            org_id: pendingPurchaseState.searchResult[vendorName][orderIndex].org_id,
            location: pendingPurchaseState.searchResult[vendorName][orderIndex].location
        };

        dispatch(updatePendingPurchase(orderToUpdate));

        refreshData()

    }

    function refreshData() {
        dispatch(fetchPendingPurchases({ org_id: user.org_id, location_id: user.viewingLocation }));
        dispatch(fetchTasks({ type: 'Purchase Request', org_id: user.org_id, location_id: user.viewingLocation }));
        dispatch(pendingPurchaseSearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }));
        dispatch(itemSearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }));
    }

    async function deleteItemFromCheckedOut(item, index, task) {
        console.log("deleteItemFromCheckedOut params : ", item, index, task)
        const editedState = structuredClone(task);

        const indexRemove = editedState.checkedOut.indexOf(item);

        for (var i = editedState.checkedOut.length - 1; i >= 0; --i) {
            if (editedState.checkedOut[i]._id == item._id) {
                editedState.checkedOut.splice(i, 1);
                console.log("Index :", i)

            }
        }

        console.log("checked out after deleting :", editedState.checkedOut)

        await dispatch(patchTask(editedState))
        await dispatch(loadTasks({ type: 'Purchase Request' }))

        // await dispatch(setPurchaseRequest({evt: 'on', task: editedState.selectedPR }))

        await dispatch(fetchPendingPurchases(user.org_id))

        await dispatch(setPurchaseRequest({ evt: true, task: editedState.selectedPR }))

        dispatch(fetchTasks({ type: 'Purchase Request', org_id: user.org_id, location_id: user.viewingLocation }))



        //    await dispatch(setPurchaseRequest({evt: 'on', task: task }))
    }

    function setSelectedPurchaseRequest(evt, task) {
        console.log("Evt and task : ", evt.target.value, task)
        dispatch(setPurchaseRequest({ evt: evt.target.value, task }))
    }


    function saveQuantityOnPendingOrder(evt, item, companyName) {
        console.log("Temp save reached saveQuantityOnPendingOrder;", evt.target.value)
        dispatch(saveQuantityToItem({ evt: evt.target.value, item, companyName: companyName }))

    }


    function goToDashboard() {
        const page = 'User_Dashboard';
        window.location.replace(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}${page}`);
    };

    function openSubPage(view, state, create = "no") {
        let shouldChangeView = state.currentView !== view;

        console.log("Open subpage clicked with view:", view);
        try {
            if (create !== "no") {

                //createOrderAndShowOrders(view, state, create);

                // Mark items in original form as ordered
                create.forEach(element => {
                    const index2 = originalFormToMarkOff.section.fields.findIndex(item => item._id === element._id);
                    if (index2 !== -1) {
                        originalFormToMarkOff.section.fields[index2].hasBeenOrdered = true;
                    }
                });
            } else if (pendingPurchaseState.summaryFromForm?.length > 0) {
                console.log("Processing summaryFromForm:", pendingPurchaseState.summaryFromForm);

                const groupedCheckout = pendingPurchaseState.summaryFromForm.reduce((groups, item) => {
                    const vendorCompany = item.vendor?.company || 'Unknown Vendor';
                    if (!groups[vendorCompany]) {
                        groups[vendorCompany] = [];
                    }
                    groups[vendorCompany].push(item);
                    return groups;
                }, {});

                console.log("Created groupedCheckout:", groupedCheckout);

                const taskStructure = {
                    ...state,
                    checkedOut: pendingPurchaseState.summaryFromForm,
                    summary: groupedCheckout
                };


                // Remove the vendor block that's being checked out from summary
                const vendorToRemove = create[0]?.vendor?.company;
                const updatedSummary = { ...state.summary };
                delete updatedSummary[vendorToRemove];


                dispatch(setSummaryFromForm(groupedCheckout));
                dispatch(setPurchaseRequest({ evt: true, task: taskStructure }));
                dispatch(setFormLoaded(false));

                // Create pending purchase for the vendor block
                if (create != 'no') {
                    dispatch(createPendingPurchase({
                        create,
                        "org_id": user.org_id,
                        location: user.viewingLocation
                    }));
                }


            } else if (view === 'summary' || state.summary) {
                const originalTaskToMarkOff = structuredClone(state.selectedPR);
                const checkOutCopy = structuredClone(originalTaskToMarkOff.checkedOut);
                const createPurchases = [];

                // Remove the vendor block that's being checked out from summary
                const vendorToRemove = create[0]?.vendor?.company;
                const updatedSummary = { ...state.summary };
                delete updatedSummary[vendorToRemove];


                const updatedGroupedCheckout = {};
                for (const vendorCompany in state.summary) {
                    const items = state.summary[vendorCompany];
                    const createItems = items.filter(item => item.create);
                    const remainingItems = items.filter(item => !item.create);

                    if (createItems.length > 0) {
                        createPurchases.push({ vendor: vendorCompany, items: createItems });
                    }

                    if (remainingItems.length > 0) {
                        updatedGroupedCheckout[vendorCompany] = remainingItems;
                    }

                    createItems.forEach(item => {
                        const index = checkOutCopy.findIndex(i => i._id === item._id);
                        if (index !== -1) {
                            checkOutCopy.splice(index, 1);
                        }
                    });
                }

                dispatch(setSummaryFromForm(updatedGroupedCheckout));

                originalTaskToMarkOff.checkedOut = checkOutCopy;
                originalTaskToMarkOff.summary = updatedGroupedCheckout;

                dispatch(patchTask(originalTaskToMarkOff));
                if (create != 'no') {

                    // Create pending purchase for the vendor block
                    dispatch(createPendingPurchase({
                        create,
                        "org_id": user.org_id,
                        location: user.viewingLocation
                    }));
                }
            }

            if (shouldChangeView) {
                console.log("Setting view again:", view);
                dispatch(setPageView(view));
            }
        } catch (error) {
            console.error("Error in openSubPage:", error);
            if (shouldChangeView) {
                dispatch(setPageView(view));
            }
        }
    }



    function tempSaveItemSelected(evt, item, index, companyName) {
        console.log("Temp save reached ;", evt.target.checked, companyName,)

        dispatch(saveItemSelected({ check: evt.target.checked, item, companyName: companyName }))

        if (document.getElementById('quantField' + index).value) {//if value already exists, save quantity to item that was added
            dispatch(saveQuantityToItem({ evt: document.getElementById('quantField' + index).value, item }))

        }
    }
    function refreshData() {
        const user = JSON.parse(localStorage.getItem('user'))[0];

        dispatch(fetchPendingPurchases({
            org_id: user.org_id,
            location_id: user.viewingLocation
        }));

        dispatch(fetchTasks({
            type: 'Purchase Request',
            org_id: user.org_id,
            location_id: user.viewingLocation
        }));

        dispatch(pendingPurchaseSearch({
            data: '',
            org_id: user.org_id,
            location_id: user.viewingLocation
        }));

        dispatch(itemSearch({
            data: '',
            org_id: user.org_id,
            location_id: user.viewingLocation
        }));

        // Reset any selected states if needed
        dispatch(setSelectedOrder({}));
        dispatch(setOrderIndexToBeReconciled(null));
    }
    function reloadPage() {
        dispatch(fetchTasks({ type: 'Purchase Request', org_id: user.org_id, location_id: user.viewingLocation }))
    }

    async function addPendingCartToPendingOrder(companyName, state) {
        let dataToAdd = state.pendingOrder[companyName] //later this line needs to be a loop adding data to a list or whatever... we need to add every item in that case

        //when a given company's section is clicked add that section to the check out

        const editedState = structuredClone(state);
        if (!editedState.selectedPR.checkedOut || editedState.selectedPR.checkedOut.length == 0) {
            editedState.selectedPR.checkedOut = []
        }
        //check if a task is selected
        if (Object.keys(editedState.selectedPR).length > 0) {

            for (var element in dataToAdd) {
                //if elem in checked out, replace it

                let wasUpdated = false;

                for (var i = editedState.selectedPR.checkedOut.length - 1; i >= 0; --i) {
                    console.log("comparing : ", editedState.selectedPR.checkedOut[i]._id, element)
                    if (editedState.selectedPR.checkedOut[i]._id == dataToAdd[element]._id) {
                        console.log("updating element :", dataToAdd[element])
                        editedState.selectedPR.checkedOut[i] = dataToAdd[element]
                        wasUpdated = true
                    }

                }
                if (!wasUpdated) {
                    console.log("Adding element :", dataToAdd[element])
                    editedState.selectedPR.checkedOut.push(dataToAdd[element])
                }
            }

            console.log("patching task with :", editedState.selectedPR)
            //addCartToPendingPurchaseRequest
            await dispatch(patchTask(editedState.selectedPR))

            await dispatch(loadTasks({ type: 'Purchase Request' }))

            // await dispatch(setPurchaseRequest({evt: 'on', task: editedState.selectedPR }))

            await dispatch(fetchPendingPurchases(user.org_id))

            await dispatch(setPurchaseRequest({ evt: true, task: editedState.selectedPR }))

            dispatch(fetchTasks({ type: 'Purchase Request', org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }

    function setOrder(order) {
        console.log("set order reached :", order)

        if (!pendingPurchaseState?.selectedOrder?._id) {
            dispatch(setSelectedOrder(order))

        }
        else {
            if (order._id == pendingPurchaseState?.selectedOrder?._id) {
                dispatch(setSelectedOrder({}))
            }
            else {
                dispatch(setSelectedOrder(order))
            }
        }
    }

    function searchItems(evt) {
        console.log("id check search : ", user.org_id)
        console.log("current searchResult before search:", searchResult)
        console.log("Searching items :", evt.target)
        dispatch(itemSearch({ data: evt.target.value, org_id: user.org_id, location_id: user.viewingLocation }))

    }

    function searchCompanies(evt) {

        console.log("id check search : ", user.org_id)
        console.log("current searchResult before search:", searchResultPendingPurchases)
        console.log("Searching items :", evt.target)
        dispatch(pendingPurchaseSearch({ data: evt.target.value, org_id: user.org_id, location_id: user.viewingLocation }))


    }


    function openPage(page, task) {
        const location = {
            pathname: `/${page}/${task.name}`,
            state: { taskToDisplay: task }
        }
        console.log("Need to open this task :", task)
        //  dispatch(setTaskForEdit(task))

        history.push(location);


    }

    function settingRequestStatusReportOnComplete(evt) {
        dispatch(setRequestStatusReportOnComplete(evt.target.checked))
    }


    function settingKeepUpdatedTask(evt) {
        console.log("evt :", evt)
        dispatch(setKeepUpdatedTask(evt.target.checked))
    }

    function settingDestinationFormat(evt) {
        dispatch(setDestinationFormat(evt))
    }

    function settingDestinationType(evt) {
        dispatch(setDestinationType(evt))
    }


    function settingJobPosition(evt) {
        dispatch(setJobPosition(evt))
    }

    function settingAssignedUser(evt) {
        dispatch(setAssignedUser(evt))
    }

    function settingFrequency(evt) {
        dispatch(setFrequency(evt))
    }

    function removeSectionFromTasks(evt, index) {
        dispatch(RemoveSection({ evt: evt, index: index }))
    }



    function toggleDay(day) {
        if (!frequency.includes(day)) {          //checking weather array contain the id
            frequency.push(day);               //adding to array because value doesnt exists
        } else {
            frequency.splice(frequency.indexOf(day), 1);  //deleting
        }
        console.log(frequency, frequency.includes('M'));


        ///  loadedUser.availability = frequency
        console.log("check : ", loadedUser, frequency)
        dispatch(updateUser(loadedUser, { availability: frequency }))
        dispatch(fetchUsers(user.org_id))
    }



    function reportingGroupSelectChange(evt) {
        //  console.log("Checking evt : ", evt)
        //dispatch(setRG(evt))
    }

    function vendorSelectChange(evt) {
        //  console.log("vender set :", evt)
        //dispatch(setVendor(evt))
    }

    function itemSelectedInField(item, index) {
        console.log("Item and index :", item, index)


        dispatch(SaveItemToField({ item: item, index: index }))
    }

    function addSectionToTasks(evt) {

        //set section type
        console.log(" evt ; ", evt)
        dispatch(SetSection(evt))
    }



    function onDeleteClicked(item) {
        /*
        dispatch(deleteItem(item)).then(res => {
            clearDataFromFields()
        })*/
    }


    function onCancelClicked() {/*
    //should clear selected states too todo


    clearSelects()*/
    }

    function clearSelects() {
        /*
        let blankEntry = { label: '', value: '' }
     
        dispatch(setVendor(blankEntry))
        dispatch(setRG(blankEntry))
        dispatch(SetItem(blankEntry))*/
    }


    function updateNoteField(index) {

        console.log("Element :", document.getElementById("Note" + index), "Note" + index)

        var value = document.getElementById("Note" + index).value;
        console.log("Update note :", { index: index, note: value })

        dispatch(updateNote({ index: index, note: value }))

        console.log("Value check : ", value)
    }


    function updateQuantityField(index) {
        console.log("Update qty :", index)

        var value = document.getElementById("Qty" + index).value;

        dispatch(updateQuantity({ index: index, value: value }))

        console.log("Value check : ", value)

    }


    function onSaveClicked() {

        console.log("Data that needs saving :", tasks, tasks.taskBeingMade)

        let name = document.getElementById("taskName").value
        dispatch(addNewTask({ name: name, task: tasks.taskBeingMade, org_id: user.org_id }))


    }




    function clearDataFromFields() {
        /*
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''*/
    }




    async function addPendingCartToPendingOrder(companyName, state) {
        let dataToAdd = state.pendingOrder[companyName] //later this line needs to be a loop adding data to a list or whatever... we need to add every item in that case

        //when a given company's section is clicked add that section to the check out

        const editedState = structuredClone(state);
        if (!editedState.selectedPR.checkedOut || editedState.selectedPR.checkedOut.length == 0) {
            editedState.selectedPR.checkedOut = []
        }
        //check if a task is selected
        if (Object.keys(editedState.selectedPR).length > 0) {

            for (var element in dataToAdd) {
                //if elem in checked out, replace it

                let wasUpdated = false;

                for (var i = editedState.selectedPR.checkedOut.length - 1; i >= 0; --i) {
                    console.log("comparing : ", editedState.selectedPR.checkedOut[i]._id, element)
                    if (editedState.selectedPR.checkedOut[i]._id == dataToAdd[element]._id) {
                        console.log("updating element :", dataToAdd[element])
                        editedState.selectedPR.checkedOut[i] = dataToAdd[element]
                        wasUpdated = true
                    }

                }
                if (!wasUpdated) {
                    console.log("Adding element :", dataToAdd[element])

                    editedState.selectedPR.checkedOut.push(dataToAdd[element])

                }

            }

            console.log("patching task with :", editedState.selectedPR)
            //addCartToPendingPurchaseRequest
            await dispatch(patchTask(editedState.selectedPR))

            //  dispatch(setPageView(view))

        }
    }
}
export default purchaseRequest;