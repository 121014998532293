import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetch from "node-fetch";



const FETCH_JOB_POSITIONS_REQUESTED = 'FETCH_JOB_POSITIONS_REQUESTED'
const FETCH_JOB_POSITIONS_SUCCEDED = 'FETCH_JOB_POSITIONS_SUCCEDED'
const FETCH_JOB_POSITIONS_FAILED = 'FETCH_JOB_POSITIONS_FAILED'
const SET_LOADED_JOB_POSITION = 'SET_LOADED_JOB_POSITION'
const SET_PERMISSIONS = 'SET_PERMISSIONS'
const SET_PERM_TO_REMOVE = 'SET_PERM_TO_REMOVE'
const SET_PERM_TO_ADD = 'SET_PERM_TO_ADD'
const ADD_PERMISSION = 'ADD_PERMISSION'
const REMOVE_PERMISSION = 'REMOVE_PERMISSION'
const SET_JOB = "SET_JOB_BEING_MADE"
const CLEAR_JOB = "CLEAR_JOB"
const SET_JOBTITLE = "SET_JOBTITLE"
const SET_DESCRIPTION = "SET_DESCRIPTION"


export const setPermissionList = createAsyncThunk(
  'JobPositions/setPermissionList',
  async (jobPosition) => {

    return {
      type: SET_PERMISSIONS,
      payload: jobPosition
    }

  }
);

export const SetJobPosition = createAsyncThunk(
  'JobPositions/setJobPosition',
  async (jobPosition) => {

    return {
      type: SET_LOADED_JOB_POSITION,
      payload: jobPosition
    }

  }
);



export const SetAddPerms = createAsyncThunk(
  'JobPositions/SetAddPerms',
  async (permToAdd) => {

    return {
      type: SET_PERM_TO_ADD,
      payload: permToAdd
    }

  }
);

export const SetRemovePerms = createAsyncThunk(
  'JobPositions/SetRemovePerms',
  async (permToRemove) => {

    return {
      type: SET_PERM_TO_REMOVE,
      payload: permToRemove
    }

  }
);


export const setJobBeingMade = createAsyncThunk(
  'JobPositions/setJobBeingMade',
  async (jobInfo) => {

    return {
      type: SET_JOB,
      payload: jobInfo
    }

  }
);

export const clearJobBeingMade = createAsyncThunk(
  'JobPositions/clearJobBeingMade',
  async (jobInfo) => {

    return {
      type: CLEAR_JOB,
      payload: jobInfo
    }

  }
);

export const setJobTitle = createAsyncThunk(
  'JobPositions/setJobTitle',
  async (jobInfo) => {

    return {
      type: SET_JOBTITLE,
      payload: jobInfo
    }

  }
);

export const setDescription = createAsyncThunk(
  'JobPositions/setDescription',
  async (jobInfo) => {

    return {
      type: SET_DESCRIPTION,
      payload: jobInfo
    }

  }
);




const initialState = {
  loadingJobPosition: false,
  jobPositions: [],
  jobPositionsError: null,
  loadedJobPosition: '',
  permissionList: [],
  permissionsToAdd: [],
  permissionsToRemove: [],
  jobBeingCreated: {}
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchJobPositions = createAsyncThunk(
  'JobPositions/fetchJobPositions',
  async (data) => {
    try {
      console.log("fetching job with org id :", data, data.viewingLocation)

      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/jobPositions?org_id=${data.org_id}&viewingLocation=${data.viewingLocation}`)

      const realData = await test.json();

      return realData
    }
    catch (err) {
      return err.message
    }

  }
);

export const fetchPermissions = createAsyncThunk(
  'JobPositions/fetchPermissions',
  async (id) => {
    try {

      console.log("fetch permissions reached", id)

      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/permissions?id=${id}`)

      const realData = await test.json();

      return realData
    }
    catch (err) {
      return err.message
    }

  }
);

export const AddPermission = createAsyncThunk('JobPositions/AddPermission', async (initialData) => {

  return {
    type: ADD_PERMISSION,
    payload: initialData
  }

}
);



export const RemovePermission = createAsyncThunk('JobPositions/RemovePermission', async (initialData) => {


  return {
    type: REMOVE_PERMISSION,
    payload: initialData
  }

}
);



export const addNewJobPosition = createAsyncThunk('JobPositions/addNewJobPosition', async (initialData) => {

  try {

    console.log("Check create ; ", initialData)

    console.log("Post reached for 'roles' ")

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/jobPositions`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();


    realData.permissions = initialData.permissions
    
/*

    console.log("Update permissions ; ", initialData, "  real data:", realData)

    let test2 = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/permissions`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(realData),

    })

    const secondData = await test2.json();



    console.log("returned id :", secondData)

    return {
      secondData
    }*/

  }
  catch (err) {
    return err.message
  }
}
);




export const updatePermissionsOnJob = createAsyncThunk('JobPositions/updatePermissionsOnJob', async (initialData) => {

  try {

    console.log("Update permissions ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/permissions`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    console.log("Result of patch permissions :", realData)

    return realData
  }
  catch (err) {
    return err.message
  }
}
);


export const patchJob = createAsyncThunk('JobPositions/patchJob', async (data) => {

  try {

    console.log("patch data :", data)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/jobPositions/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

});

export const deleteJobPosition = createAsyncThunk('JobPositions/deleteJobPosition', async (toDelete) => {


  try {

    console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/jobPositions/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

}
);



export const jobPositionSlice = createSlice({
  name: 'jobPositions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_JOB_POSITIONS_REQUESTED: (state, action) => {
      return state
    },

    FETCH_JOB_POSITIONS_SUCCEDED: (state, action) => {
      return { loading: false, jobPositions: action.payload, error: '' }
    },

    FETCH_JOB_POSITIONS_FAILED: (state, action) => {
      //  console.log("fetch jobPositions failed reached")
      return { loading: false, jobPositions: [], error: action.payload }
    },
    /*
        SET_LOADED_JOB_POSITION: (state, action) => {
          state.loadedJobPosition = action.payload
          console.log("Set loadedJobPosition :" , state.loadedJobPosition)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(setJobBeingMade.fulfilled, (state, action) => {
        console.log('Setting job being made : ', state.action)
        state.jobBeingCreated.description = action.payload.payload.description
        state.jobBeingCreated.jobTitle = action.payload.payload.jobTitle
      })

      .addCase(clearJobBeingMade.fulfilled, (state, action) => {
        console.log("Clearing job")
        
        state.jobBeingCreated.description = ""
        state.jobBeingCreated.jobTitle = ""
      })


      .addCase(setJobTitle.fulfilled, (state, action) =>{
      
        console.log("Setting job title : ", action)
        state.jobBeingCreated.jobTitle = action.payload.payload
      })

      .addCase(setDescription.fulfilled, (state, action) =>{
        console.log("Setting job description : ", action)

       
        state.jobBeingCreated.description = action.payload.payload
      })

      //Fetching jobPositions
      .addCase(fetchJobPositions.pending, (state, action) => {
        state.loadingJobPosition = true;
        state.jobPositionsError = ''

      })

      .addCase(fetchJobPositions.fulfilled, (state, action) => {
        state.loadingJobPosition = 'Done';

        console.log("Action : ", action)
        state.jobPositions = action.payload?.docs
        state.jobPositionsError = ''
      })


      .addCase(fetchPermissions.fulfilled, (
        state, action) => {
        state.permissionList = action.payload

        console.log("Action fetchPermissions: ", action)

      })


      .addCase(SetAddPerms.fulfilled, (state, action) => {
        console.log("Action SetAddPerms: ", action)

        state.permissionsToAdd = action.payload

      })

      .addCase(SetRemovePerms.fulfilled, (state, action) => {
        console.log("Action SetRemovePerms: ", action)

        state.permissionsToRemove = action.payload

      })

      .addCase(SetRemovePerms.rejected, (state, action) => {
        console.log("error SetRemovePerms: ", action)

        var x;
        var doesItExist = false;
        var t = 0
        for (x in list) {
          if (permissionsToRemove.hasOwnProperty(x) && list[x] === action.payload.payload) {
            //
            doesItExist = true
          }
          t++
        }

        if (t == permissionsToRemove.length && !doesItExist) {
          state.permissionsToRemove.append(action.payload.payload)
        }

      })


      .addCase(fetchJobPositions.rejected, (state, action) => {
        state.loadingJobPosition = false;
        state.jobPositions = []
        console.log("Error message ;", action.error)
        state.jobPositionsError = action.error.message
      })




      .addCase(setPermissionList.fulfilled, (state, action) => {
        console.log("Setting permissions:", action)
        state.permissionList = action.payload.payload
        state.jobPositionsError = ''

      })

      .addCase(setPermissionList.rejected, (state, action) => {
        console.log("Setting permissions error :", action)
        state.permissionList = action.payload.payload
        state.jobPositionsError = ''

      })


      //Selecting jobPositions
      .addCase(SetJobPosition.fulfilled, (state, action) => {
        console.log("Setting job :", action)
        state.loadedJobPosition = action.payload.payload
        state.permissionList = action.payload?.payload?.permissions
        state.loadingJobPosition = 'Done';
        state.jobPositionsError = ''

      })
      //Deleting JobPositions
      .addCase(SetJobPosition.rejected, (state, action) => {
        state.jobPositionsError = action.error.message
        console.log("Error message ;", action.error)

      })



      //Creating JobPositions
      .addCase(addNewJobPosition.fulfilled, (state, action) => {
        state.loadingJobPosition = false;
        //  state.jobPositions.push(action.payload) TODO ideally jobPositions are updated here, but couldnt get it working so going to call GET again
        state.jobPositionsError = ''
      })


      //Deleting JobPositions
      .addCase(deleteJobPosition.rejected, (state, action) => {
        state.loadingJobPosition = false;
        state.jobPositionsError = action.error.message
        console.log("Error message ;", action.error)

      })
      .addCase(deleteJobPosition.pending, (state, action) => {
        state.loadingJobPosition = true;
        state.jobPositionsError = ''
      })
      .addCase(deleteJobPosition.fulfilled, (state, action) => {
        state.loadingJobPosition = false; //by setting false here, the component will call fetch to update data
        state.jobPositionsError = ''
      })
  },
});


export const loadJobPositions = () => {
  return dispatch(fetchJobPositions())
}




// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectJobPositions = (state) =>  //alternative syntax
  state.jobPositions.jobPositions
  ;
export const getJobPositionLoadStatus = (state) => state.jobPositions.loadingJobPosition;
export const getjobPositionsError = (state) => state.jobPositions.jobPositionsError;
export const getLoadedJobPosition = (state) => state.jobPositions.loadedJobPosition;
export const getPermissionList = (state) => state.jobPositions.permissionList;
export const getPermsToAdd = (state) => state.jobPositions.permToAdd;
export const getPermsToRemove = (state) => state.jobPositions.permToRemove;
export const getJobBeingMade = (state) => state.jobPositions.jobBeingCreated

export default jobPositionSlice.reducer;
export const jobPositionActions = jobPositionSlice.actions;

