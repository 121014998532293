import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styles from "./Users.module.css";
import { setLocationsSelected, SetUser, addNewUser, clearUserBeingCreated, deleteUser, fetchUsers, getAvailability, getLoadedUser, getUserBeingMade, getUserLoadStatus, getusersError, patchUser, selectUsers, setAvailability, setUserBeingCreated, setuserBeingCreatedAddress, setuserBeingCreatedEmail, setuserBeingCreatedFullName, setuserBeingCreatedPassword, setuserBeingCreatedPhoneNumber, setuserBeingCreatedUsername } from './UsersSlice';
import { SetJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../JobPositions/jobPositionSlice';
import { fetchLocations, selectLocations, getLocationLoadStatus, getLoadedLocation } from '../Locations/locationSlice';
import { Eye, EyeOff } from 'lucide-react';

const InputField = React.memo(({ label, id, value, onChange, type = "text", tabIndex }) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef(null);

    const togglePasswordVisibility = useCallback(() => {
        setShowPassword(prev => !prev);
    }, []);

    const handleChange = (event) => {
        const { selectionStart, selectionEnd } = event.target;
        onChange(event);
        setTimeout(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    };

    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <div className={styles.inputWrapper}>
                <input
                    type={type === "password" && showPassword ? "text" : type}
                    id={id}
                    ref={inputRef}
                    value={value || ''}
                    onChange={handleChange}
                    className={`${styles.defaultInp} ${type === "password" ? styles.passwordInput : ''}`}
                    tabIndex={tabIndex}
                />
                {type === "password" && (
                    <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={styles.passwordToggle}
                        tabIndex={-1}
                    >
                        {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
                    </button>
                )}
            </div>
        </label>
    );
});

function UserSetup() {
    const dispatch = useDispatch();
    const [toggleShowCreate, setToggleShowCreate] = useState(false);
    const [toggleShowEdit, setToggleShowEdit] = useState(false);
    const [blursEnabled, setBlurEnabledStatus] = useState(false);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }
    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);
    const availability = useSelector(getAvailability);
    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);
    const userBeingCreated = useSelector(getUserBeingMade);
    const locations = useSelector(selectLocations);
    const loading = useSelector(getLocationLoadStatus);
    const loadedLocation = useSelector(getLoadedLocation);

    const selectUserInputRef = useRef();
    const jobSelect = useRef();

    useEffect(() => {
        if (loadingUsers !== 'Done') {
            dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }));
        }
    }, [loadingUsers, userBeingCreated, dispatch, user.org_id, user.viewingLocation]);

    useEffect(() => {
        if (jobPositionLoading !== 'Done') {
            dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }));
        }
    }, [jobPositionLoading, dispatch, user.org_id, user.viewingLocation]);

    useEffect(() => {
        if (loading !== 'Done') {
            dispatch(fetchLocations(user.org_id));
        }
    }, [loading, dispatch, user.org_id]);

    const getPageWrapperClassName = useCallback(() => {
        const baseClass = styles.pageWrapper;
        const scrollClass = (toggleShowCreate === 'create' || toggleShowEdit === 'edit') ? styles.pageWrapperScroll : '';
        return `${baseClass} ${scrollClass}`.trim();
    }, [toggleShowCreate, toggleShowEdit]);

    const hidePopup = useCallback(() => {
        document.getElementById("popupMessage").style.visibility = "hidden";
        document.getElementById("popupBackground").style.visibility = "hidden";
    }, []);

    const settingToggle1 = useCallback((value) => {
        if (toggleShowCreate === 'create' || toggleShowEdit === 'create') {
            setToggleShowCreate('');
            document.getElementById("titleText").textContent = "Creating User";
        } else {
            if (toggleShowEdit === 'edit') {
                clearDataFromFields();
                clearSelects();
                dispatch(clearUserBeingCreated());
                document.getElementById("titleText").textContent = "Existing User";
            }
            setToggleShowCreate(value);
        }
        setToggleShowEdit('');

        if (value === 'create') {
            document.getElementById("titleText").textContent = "Creating User";
        }

        setTimeout(() => {
            if (!blursEnabled) {
                document.getElementById("fullname").onblur = function () {
                    let nameParts = document.getElementById('fullname').value.split(" ");
                    let newName = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(" ");
                    document.getElementById("fullname").value = newName;
                };

                document.getElementById("username").onblur = function () {
                    let newName = document.getElementById('username').value.toLowerCase();
                    document.getElementById("username").value = newName;
                };

                setBlurEnabledStatus(true);
            }
        }, 300);
    }, [toggleShowCreate, toggleShowEdit, blursEnabled, dispatch]);

    const settingToggle2 = useCallback((value) => {
        if (toggleShowEdit === 'edit') {
            setToggleShowEdit('');
        } else {
            setToggleShowEdit(value);
        }
        setToggleShowCreate('');

        setTimeout(() => {
            if (!blursEnabled) {
                document.getElementById("fullname").onblur = function () {
                    let nameParts = document.getElementById('fullname').value.split(" ");
                    let newName = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(" ");
                    dispatch(setuserBeingCreatedFullName(newName));
                };

                document.getElementById("username").onblur = function () {
                    let newName = document.getElementById('username').value.toLowerCase();
                    dispatch(setuserBeingCreatedUsername(newName));
                };

                setBlurEnabledStatus(true);
            }
        }, 300);
    }, [toggleShowEdit, blursEnabled, dispatch]);

    const toggleDay = useCallback((day) => {
        const currentAvailability = Array.isArray(availability) ? availability : [];

        const newAvailability = currentAvailability.includes(day)
            ? currentAvailability.filter(d => d !== day)
            : [...currentAvailability, day];

        dispatch(setAvailability(newAvailability));
    }, [dispatch, availability]);

    const locationSelectChange = useCallback((evt) => {
        dispatch(setLocationsSelected(evt));
    }, [dispatch]);

    const selectChange = useCallback((evt) => {
        clearSelects();
        if (toggleShowEdit !== 'edit') {
            settingToggle2('edit');
        }
        dispatch(SetUser(evt));
        dispatch(setAvailability(evt?.availability || []));
        dispatch(setUserBeingCreated(evt));
        dispatch(SetJobPosition(evt.jobPosition));
        document.getElementById("titleText").textContent = "Existing User";
    }, [dispatch, toggleShowEdit, settingToggle2]);

    const jobSelectChange = useCallback((evt) => {
        dispatch(SetJobPosition(evt));
    }, [dispatch]);

    const onDeleteClicked = useCallback((user) => {
        dispatch(deleteUser(user)).then(() => {
            clearDataFromFields();
            dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }));


            onCancelClicked()

        });
    }, [dispatch, user.org_id, user.viewingLocation]);

    const onCancelClicked = useCallback(() => {
        dispatch(setAvailability([]));
        clearSelects();
        dispatch(clearUserBeingCreated());
        setToggleShowEdit(false);
        setToggleShowCreate(false);
    }, [dispatch]);

    const clearSelects = useCallback(() => {
        let blankEntry = { label: '', value: '' };
        dispatch(SetUser(blankEntry));
        dispatch(SetJobPosition(blankEntry));
    }, [dispatch]);

    const clearDataFromFields = useCallback(() => {
        clearSelects();
    }, [clearSelects]);

    const onSaveClicked = useCallback(() => {
        let newUser = {
            ...userBeingCreated,
            availability,
            jobPosition: loadedJobPosition,
            org_id: user.org_id,
            createLocation: user.viewingLocation,
            viewingLocation: user.viewingLocation
        };

        let nameParts = document.getElementById('fullname').value.split(" ");
        let newName = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(" ");

        if (toggleShowEdit !== 'edit') {
            if (users?.some(e => e.username === document.getElementById('username').value.toLowerCase())) {
                document.getElementById("popupBackground").style.visibility = "visible";
                document.getElementById("popupMessage").style.visibility = 'visible';
            } else {
                dispatch(addNewUser(newUser));
            }
        } else {
            newUser._id = loadedUser._id;
            dispatch(patchUser({ newUser, loadedJobPosition, org_id: user.org_id }));
            dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }));
        }

        settingToggle2('edit');
        onCancelClicked();
    }, [userBeingCreated, availability, loadedJobPosition, user, toggleShowEdit, users, loadedUser, dispatch, settingToggle2, onCancelClicked]);

    return (
        <div className={getPageWrapperClassName()}>
            <div id="popupBackground" onClick={hidePopup} style={{ visibility: 'hidden' }} className={styles.popupBackground}></div>
            <div id="popupMessage" className={styles.popupMessage} style={{ visibility: 'hidden' }} onClick={hidePopup}>
                <span>A user with this username already exists, please use a different one.</span>
            </div>

            <div className={styles.userForm}>
                <div style={{ width: '100%' }}>
                    <h3 id="titleText" style={{ textAlign: 'left' }} className={styles.existingUser}>Existing User</h3>

                    <div style={{ marginTop: '1%', display: 'flex', width: '100%', }}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="userSelect"
                                ref={selectUserInputRef}
                                value={loadedUser}
                                className={styles.userSelect}
                                onChange={selectChange}
                                options={users}
                                getOptionLabel={(option) => option.username}
                                getOptionValue={(option) => option.username}
                                style={{ tabIndex: '0' }}
                            />
                        </div>

                        <div className={styles.createUserWrapper} >
                            <button onClick={() => settingToggle1("create")} className={styles.formBtn3}>
                                Create User
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {(toggleShowCreate === 'create' || toggleShowEdit === 'edit') && (
                <div className={styles.userInfoSection}>
                    <h4 className={styles.contactInfo} style={{ textAlign: 'left' }}>Contact Info</h4>

                    <div className={styles.flexBox}>
                        <div className={styles.leftCol}>
                            <InputField
                                label="Full Name"
                                id="fullname"
                                value={userBeingCreated?.displayName}
                                onChange={(evt) => dispatch(setuserBeingCreatedFullName(evt.target.value))}
                                tabIndex={1}
                            />
                            <InputField
                                label="Address"
                                id="address"
                                value={userBeingCreated?.address}
                                onChange={(evt) => dispatch(setuserBeingCreatedAddress(evt.target.value))}
                                tabIndex={3}
                            />
                            <InputField
                                label="Username"
                                id="username"
                                value={userBeingCreated?.username}
                                onChange={(evt) => dispatch(setuserBeingCreatedUsername(evt.target.value))}
                                tabIndex={5}
                            />
                        </div>
                        <div className={styles.rightCol}>
                            <InputField
                                label="Phone Number"
                                id="phoneNum"
                                value={userBeingCreated?.phoneNumber}
                                onChange={(evt) => dispatch(setuserBeingCreatedPhoneNumber(evt.target.value))}
                                tabIndex={2}
                            />
                            <InputField
                                label="Email"
                                id="email"
                                value={userBeingCreated?.email}
                                onChange={(evt) => dispatch(setuserBeingCreatedEmail(evt.target.value))}
                                tabIndex={4}
                            />
                            <InputField
                                label="Password"
                                id="password"
                                type="password"
                                value={userBeingCreated?.password}
                                onChange={(evt) => dispatch(setuserBeingCreatedPassword(evt.target.value))}
                                tabIndex={6}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.blackLineDiv}></div>

            {(toggleShowCreate === 'create' || toggleShowEdit === 'edit') && (
                <div className={styles.bottomSection}>
                    <div className={styles.jobPositionSection}>
                        <h4 className={styles.jobPositionText} style={{ textAlign: 'left' }}>Job Position</h4>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="jobSelect"
                                ref={jobSelect}
                                value={loadedJobPosition}
                                options={jobPositions}
                                isMulti
                                className={styles.jobSelect}
                                onChange={jobSelectChange}
                                getOptionLabel={(option) => option.jobTitle}
                                getOptionValue={(option) => option.jobTitle}
                                tabIndex={7}
                            />
                        </div>
                    </div>

                    <div className={styles.locationSection}>
                        <h4 className={styles.locationText} style={{ textAlign: 'left' }}>Location</h4>


                        <div className={styles.selectWrapper}>
                            <Select
                                id="locationSelect"
                                value={userBeingCreated.locations}
                                className={styles.locationSelect}
                                onChange={locationSelectChange}
                                options={locations}
                                getOptionLabel={(option) => option.name}
                                isMulti
                                getOptionValue={(option) => option.name}
                                tabIndex={8}
                            />
                        </div>
                    </div>

                    <div className={styles.availabilityBox}>
                        <h4 className={styles.availabilityText} style={{ textAlign: 'left' }}>Availability</h4>
                        <div className={styles.rowOfBoxes}>
                            {['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'].map((day) => (
                                <div
                                    key={day}
                                    className={styles.availabilityDay}
                                    onClick={() => toggleDay(day)}
                                    style={{
                                        backgroundColor: Array.isArray(availability) && availability.includes(day) ? 'orange' : 'white',
                                        color: Array.isArray(availability) && availability.includes(day) ? 'white' : 'black'
                                    }}
                                >
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.formBtnDiv}>
                        <button tabIndex={8} onClick={() => onDeleteClicked(loadedUser)} className={styles.formBtn} style={{ border: '1px solid black' }}>Delete</button>
                        <button tabIndex={9} onClick={onCancelClicked} className={styles.formBtn} style={{ backgroundColor: 'black', color: 'white' }}>Cancel</button>
                        <button
                            tabIndex={10}
                            onClick={onSaveClicked}
                            className={styles.formBtn2}
                        >
                            {toggleShowEdit === 'edit' ? 'Save' : 'Create'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserSetup;