import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"



const UPDATE_PRICE = "UPDATE_PRICE"
const UPDATE_GOOD_STATUS = "UPDATE_GOOD_STATUS"
const UPDATE_RECIEVED_STATUS = "UPDATE_RECIEVED_STATUS"
const UPDATE_BAD_STATUS = "UPDATE_BAD_STATUS"
const UPDATE_NOTE = "UPDATE_NOTE"

const SET_ORDER_INDEX_RECONCILE = "SET_ORDER_INDEX_RECONCILE"
const SET_ORDER = "SET_ORDER"
const MARK_AS_FINISHED = "MARK_AS_FINISHED"
const UPDATE_QUANTITY = 'UPDATE_QUANTITY'

const SET_SUMMARY_FROM_FORM = "SET_SUMMARY_FROM_FORM"

export const toggleNotRecievedStatus = createAsyncThunk(
    'pendingPurchase/toggleNotRecievedStatus',
    async (item) => {
        return {
            type: UPDATE_RECIEVED_STATUS,
            payload: item
        }
    }
);
export const updatePrice = createAsyncThunk(
    'pendingPurchase/updatePrice',
    async (item) => {

        return {
            type: UPDATE_PRICE,
            payload: item
        }

    }
);
export const setSummaryFromForm = createAsyncThunk(
    'pendingPurchase/setSummaryFromForm',
    async (formData) => {
        return {
            type: SET_SUMMARY_FROM_FORM,
            payload: formData
        }
    }
);
export const toggleBadStatus = createAsyncThunk(
    'pendingPurchase/toggleBadStatus',
    async (item) => {

        return {
            type: UPDATE_BAD_STATUS,
            payload: item
        }

    }
);
export const toggleGoodStatus = createAsyncThunk(
    'pendingPurchase/toggleGoodStatus',
    async (item) => {

        return {
            type: UPDATE_GOOD_STATUS,
            payload: item
        }

    }
);
// Add a new action creator
export const setFormLoaded = createAsyncThunk(
    'pendingPurchase/setFormLoaded',
    async (formLoaded) => {
        return {
            type: 'SET_FORM_LOADED',
            payload: formLoaded
        }
    }
);
export const updateNoteOnPendingPurchase = createAsyncThunk(
    'pendingPurchase/updateNoteOnPendingPurchase',
    async (item) => {

        return {
            type: UPDATE_NOTE,
            payload: item
        }

    }
);




export const fetchPendingPurchases = createAsyncThunk(
    'pendingPurchase/fetchPendingPurchase',
    async (data) => {
        try {

            console.log("org id pending purchase: ", data)
            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase?org_id=${data.org_id}&location_id=${data.location_id}`)

            const realData = await test.json();

            return realData
        }
        catch (err) {
            return err.message
        }

    }
);

/*
export const markAsFinished = createAsyncThunk(
    'pendingPurchase/markAsFinished',
    async (data) => {
        try {

            console.log("updatePendingPurchase: ", data)
            let test = await patch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase?org_id=${data.org_id}&location_id=${data.location_id}`)

            const realData = await test.json();

            return realData
        }
        catch (err) {
            return err.message
        }

    }
);*/
export const updateQuantityPr = createAsyncThunk(
    'pendingPurchase/updateQuantity',
    async (item) => {
        return {
            type: "UPDATE_QUANTITY",
            payload: item
        }
    }
);
export const updatePendingPurchase = createAsyncThunk(
    'pendingPurchase/updatePendingPurchase',
    async (data, { rejectWithValue }) => {
        try {
            console.log("Incoming data :", data)
            // Handle different update scenarios
            const payload = {
                _id: data._id,
                markedAsFinished: data.markedAsFinished,
                purchaseOrder: data.purchaseOrder?.map(item => ({
                    itemName: item.itemName,
                    itemNumber: item.itemNumber,
                    cost: item.cost,
                    par: item.par,
                    vendor: item.vendor,
                    orderTriggerIneq: item.orderTriggerIneq,
                    reportingGroup: item.reportingGroup,
                    viewingLocation: item.viewingLocation,
                    org_id: item.org_id,
                    itemAmount: item.itemAmount,
                    size: item.size,
                    orderTriggerValue: item.orderTriggerValue,
                    description: item.description,
                    pendingOrderQuantity: item.pendingOrderQuantity,
                    dateOfCost: item.dateOfCost,
                    goodStatus: item.goodStatus,
                    badStatus: item.badStatus,
                    notReceived: item.notReceived,
                    note: item.note
                })),
                org_id: data.org_id,
                location: data.location
            };

            console.log("updatePendingPurchase payload: ", payload);

            
            const response = await fetch(
                `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase/${data._id}`,
                {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update pending purchase');
            }

            const updatedPurchase = await response.json();
            return updatedPurchase;
        } catch (err) {
            console.error('Error updating pending purchase:', err);
            return rejectWithValue(err.message);
        }
    }
);




export const pendingPurchaseSearch = createAsyncThunk(
    'pendingPurchase/pendingPurchaseSearch',
    async (param) => {
        try {
            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase?org_id=${param.org_id}&search=${param.data}&location=${param.location_id}`)

            const realData = await test.json();

            return realData

        }
        catch (err) {
            console.log("Error message :", err.message)
            return err.message
        }

    }
);
export const setSelectedOrder = createAsyncThunk(
    'pendingPurchase/setSelectedOrder',
    async (item) => {
        return {
            type: SET_ORDER,
            payload: item
        }
    }
);
export const setOrderIndexToBeReconciled = createAsyncThunk(
    'pendingPurchase/setOrderIndexToBeReconciled',
    async (item) => {
        return {
            type: SET_ORDER_INDEX_RECONCILE,
            payload: item
        }
    }
);



export const createPendingPurchase = createAsyncThunk(
    'pendingPurchase/createPendingPurchase',
    async (data) => {

        try {


            console.log("data incoming :  ", data)
            const editedItem = structuredClone(data.create);

            for (let i = 0; i < editedItem.length; i++) {
                delete editedItem[i]._id
            }

            console.log("Posting!!!!! !:", editedItem)


            /*
                        const groups = action.payload.docs.reduce((groups, item) => {
                            if (groups[item.vendor.company]) {
                                let group = groups[item.vendor.company]
                                group.push(item);
                            }
                            else {
                                let group = [item]h
                                groups[item.vendor.company] = group;
                            }
            
                            return groups;
                        }, {});*/




            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ purchaseOrder: editedItem, org_id: data.org_id, location: data.location }),

            })

            const realData = await test.json();

            return realData
        }
        catch (err) {
            console.log("whats my error : ", err.message)
            return err.message
        }
    }
);


const initialState = {
    ppLoadStatus: '',
    searchDone: false,
    searchResult: [],
    orders: [],
    selectedOrder: {},
    formLoaded: false,  // Add this property
    summaryFromForm: [] // If not already present

};


export const pendingPurchaseSlice = createSlice({
    name: 'pendingPurchase',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            .addCase(fetchPendingPurchases.pending, (state, action) => {
                state.ppLoadStatus = '';
            })

            .addCase(fetchPendingPurchases.fulfilled, (state, action) => {
                state.ppLoadStatus = 'Done';

                state.orders = action.payload.docs
            })

            .addCase(createPendingPurchase.pending, (state, action) => {
                state.ppLoadStatus = '';
            })


            .addCase(updateQuantityPr.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;

                const orders = state.searchResult[vendorName];
                if (orders[orderIndex]) {
                    if (itemIndex !== undefined) {
                        orders[orderIndex].purchaseOrder[itemIndex].pendingOrderQuantity = value;
                    } else {
                        orders[orderIndex].purchaseOrder.forEach(item => {
                            item.pendingOrderQuantity = value;
                        });
                    }
                }
            })
            .addCase(pendingPurchaseSearch.pending, (state, action) => {

                console.log("Pending :", action)
                state.searchDone = false;
                state.searchResult = []
            })


            .addCase(pendingPurchaseSearch.fulfilled, (state, action) => {
                state.searchDone = true;
                console.log("vendor search result :", action, (typeof action.payload.docs), action.payload.docs)
                //here group by vendor for displaying
                //groups is a dictionary type thing
                const groups = action.payload.docs.reduce((groups, pendingPurchase) => {
                    if (groups[pendingPurchase.purchaseOrder[0].vendor?.company]) {
                        let group = groups[pendingPurchase.purchaseOrder[0].vendor?.company]
                        group.push(pendingPurchase);
                    }
                    else {
                        let group = [pendingPurchase]
                        groups[pendingPurchase.purchaseOrder[0].vendor?.company] = Array.from(group);
                    }

                    return groups;
                }, {});

                console.log("groups :", groups)
                // state.itemsError = action.error.message
                state.searchResult = groups
            })


            .addCase(setFormLoaded.fulfilled, (state, action) => {
                state.formLoaded = action.payload.payload;
            })



            .addCase(setSummaryFromForm.fulfilled, (state, action) => {
                try {
                    const formData = action.payload?.payload;
                    
                    // Handle object with vendor keys
                    if (formData && typeof formData === 'object' && !Array.isArray(formData)) {
                        // Convert object of vendor arrays to single array
                        const cartItems = Object.values(formData).flat().map(item => ({
                            ...item,
                            vendor: item.vendor || null,
                            pendingOrderQuantity: item.pendingOrderQuantity || 0, 
                            cost: item.cost || 0,
                            itemName: item.itemName || '',
                            itemNumber: item.itemNumber || ''
                        })).filter(Boolean);
             
                        state.summaryFromForm = cartItems;
                        console.log('Successfully set summaryFromForm:', cartItems);
                        
                    } else if (Array.isArray(formData)) {
                        // Handle array format
                        state.summaryFromForm = formData.map(item => ({
                            ...item,
                            vendor: item.vendor || null,
                            pendingOrderQuantity: item.pendingOrderQuantity || 0,
                            cost: item.cost || 0,
                            itemName: item.itemName || '',
                            itemNumber: item.itemNumber || ''
                        })).filter(Boolean);
                        
                    } else if (formData?.section?.cart) {
                        state.summaryFromForm = formData.section.cart;
                    } else {
                        console.warn('Invalid cart data received:', formData);
                        state.summaryFromForm = [];
                    }
                } catch (error) {
                    console.error('Error processing form summary:', error);
                    state.summaryFromForm = [];
                }
             })



            .addCase(updateNoteOnPendingPurchase.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;

                const orders = state.searchResult[vendorName];
                if (orders[orderIndex]) {
                    if (itemIndex !== undefined) {
                        orders[orderIndex].purchaseOrder[itemIndex].note = value;
                    } else {
                        orders[orderIndex].purchaseOrder.note = value;
                    }
                }
            })

            .addCase(toggleGoodStatus.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;

                const orders = state.searchResult[vendorName];
                if (orders?.[orderIndex]?.purchaseOrder?.[itemIndex]) {
                    orders[orderIndex].purchaseOrder[itemIndex].goodStatus = value;

                    // If good is checked, uncheck bad and notReceived
                    if (value === true) {
                        orders[orderIndex].purchaseOrder[itemIndex].badStatus = false;
                        orders[orderIndex].purchaseOrder[itemIndex].notReceived = false;
                    }
                }
            })

            .addCase(toggleBadStatus.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;

                const orders = state.searchResult[vendorName];
                if (orders?.[orderIndex]?.purchaseOrder?.[itemIndex]) {
                    orders[orderIndex].purchaseOrder[itemIndex].badStatus = value;

                    // If bad is checked, uncheck good and notReceived
                    if (value === true) {
                        orders[orderIndex].purchaseOrder[itemIndex].goodStatus = false;
                        orders[orderIndex].purchaseOrder[itemIndex].notReceived = false;
                    }
                }
            })

            .addCase(toggleNotRecievedStatus.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;
                console.log("Toggling not received:", orderIndex, itemIndex, vendorName, value); // Add logging
                
                const orders = state.searchResult[vendorName];
                if (orders?.[orderIndex]?.purchaseOrder?.[itemIndex]) {
                    orders[orderIndex].purchaseOrder[itemIndex].notReceived = value;
                    
                    // If notReceived is checked, uncheck others
                    if (value === true) {
                        orders[orderIndex].purchaseOrder[itemIndex].goodStatus = false;
                        orders[orderIndex].purchaseOrder[itemIndex].badStatus = false;
                    }
                }
            })
            .addCase(updatePendingPurchase.rejected, (state, action) => {
                // Handle the error case, e.g., set an error message in the state

                state.error = action.payload;
            })



            .addCase(setSelectedOrder.fulfilled, (state, action) => {
                state.selectedOrder = action.payload.payload
                state.selectedOrderForReconcileIndex = null
            })

            .addCase(updatePrice.fulfilled, (state, action) => {
                const { orderIndex, itemIndex, vendorName, value } = action.payload.payload;
                console.log("sent to price:", action);

                if (itemIndex === undefined) {
                    console.error("itemIndex is required to update the cost");
                    return;
                }

                const orders = state.searchResult[vendorName];
                if (orders[orderIndex]) {
                    orders[orderIndex].purchaseOrder[itemIndex].cost = value;
                }
            })

            .addCase(setOrderIndexToBeReconciled.fulfilled, (state, action) => {
                if (state.selectedOrderForReconcileIndex === action.payload.payload) {
                    state.selectedOrderForReconcileIndex = null;
                } else {
                    state.selectedOrderForReconcileIndex = action.payload.payload;
                }
            })


    },
});





/*
export const loadPendingPurchases = () => {
    return dispatch(fetchpurchaseRequest())
}
*/

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPendingPurchase = (state) => state.pendingPurchase;
export const getPendingPurchaseLoadStatus = (state) => state.pendingPurchase.ppLoadStatus;
export const getSearchResultPendingPurchase = (state) => state.pendingPurchase.searchResult
export const getFormLoaded = (state) => state.pendingPurchase.formLoaded;

export default pendingPurchaseSlice.reducer;

export const pendingPurchaseActions = pendingPurchaseSlice.actions;


