
import { React, useEffect, useRef } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from "react-router-dom";
import Select from 'react-select';
import Header from '../../Components/Header';
import SideBar from '../../Components/SideBar';
import styles from "./maintenanceRequest.module.css";
import { fetchTasks, setTaskForEdit, patchTask, loadTasks, getTaskLoadStatus, selectTasks, SetSection, RemoveSection, setKeepUpdatedTask, setRequestStatusReportOnComplete, setDestinationFormat, addNewTask, SaveItemToField, updateQuantity, setFrequency, setDestinationType, updateNote, setJobPosition, setAssignedUser, taskSlice, deleteTask } from '../taskBuilder/taskSlice';
import { fetchItems, getItemLoadStatus, getLoadedItem, getitemsError, selectItems, itemSearch, getSearchResult, getSearchDone } from '../forms/items/itemSlice';
import { SetJobPosition, addNewJobPosition, deleteJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../forms/JobPositions/jobPositionSlice';
import { SetUser, addNewUser, selectUsers, getUserLoadStatus, getusersError, getLoadedUser, fetchUsers, deleteUser, updateUser } from '../forms/users/UsersSlice';
import { saveItemSelected, saveQuantityToItem, setPurchaseRequest, addCartToPendingPurchaseRequest, selectpurchaseRequest, setPageView, setSummary, getSummary } from './purchaseRequest.slice'
import { updateNoteOnPendingPurchase, updatePrice, toggleGoodStatus, toggleBadStatus, toggleNotRecievedStatus, createPendingPurchase, getPendingPurchaseLoadStatus, fetchPendingPurchases, selectPendingPurchase } from './maintenanceRequest.js.slice'
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegTrashAlt } from "react-icons/fa";

const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]
const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' }]

const destinationType = [{ label: 'User Email' },]

const destinationFormat = [{ label: 'Format' },]
let frequency = []


function MaintenanceRequest(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const tasks = useSelector(selectTasks);
    const loadingTasks = useSelector(getTaskLoadStatus);

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);
    const searchResult = useSelector(getSearchResult);
    const searchDone = useSelector(getSearchDone);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);


    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);

    const purchaseRequestState = useSelector(selectpurchaseRequest);
    const pendingPurchaseState = useSelector(selectPendingPurchase);
    const ppLoadStatus = useSelector(getPendingPurchaseLoadStatus);
    const summary = useSelector(getSummary);


    // const { user, isAuthenticated, isLoading } = useAuth0();

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }
    //const loadedTask = useSelector(getLoadedItem);

    /*

    const vendors = useSelector(selectVendors);
    const vendorLoading = useSelector(getVendorLoadStatus);
    const vendorsError = useSelector(getVendorsError);
    const loadedVendor = useSelector(getLoadedVendor);
*/



    useEffect(() => {

        if (ppLoadStatus != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchPendingPurchases({ org_id: user.org_id, location_id: user.viewingLocation }))

        }
    }, [ppLoadStatus, dispatch])

    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ type: 'Maintenance Request', org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [loadingTasks, dispatch])


    useEffect(() => {

        if (searchDone != true) {
            //  console.log("fetching vendors")
            //  dispatch(itemSearch(''))
        }
    }, [searchDone, dispatch])



    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            dispatch(fetchItems({ org_id: user.org_id, location: user?.user_metadata?.viewingLocation }))
            dispatch(itemSearch({ data: '', org_id: user.org_id, location_id: user.viewingLocation }))

        }
    }, [loading, dispatch])

    return (
        <>
            {purchaseRequestState.currentView == 'request' &&
                <div className={styles.pageWrapper}>

                    <div className={styles.subPageHeaders}>
                        <span onClick={() => { openSubPage('request', purchaseRequestState) }}>Request</span> <span style={{ marginLeft: '5%' }} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState) }}  >Pending Maintenance Requests</span><button onClick={() => { openSubPage('summary', purchaseRequestState) }} style={{ marginLeft: '50%' }}>Schedule Request</button>
                    </div>

                    <div className={styles.scrollWrapper}>

                        <div className={styles.inputSection} >

                            <div className="checkboxes">
                                <label> </label>  </div>

                            {tasks?.tasks?.map((task2) => (
                                < div className={styles.purchaseRequest} style={{ backgroundColor: purchaseRequestState?.selectedPR?._id == task2._id ? 'rgb(255 235 215)' : '#f5f5f5', border: purchaseRequestState?.selectedPR?._id == task2._id ? '2px solid rgb(255 174 87)' : '1px solid black' }} >

                                    <input type="checkbox" checked={purchaseRequestState.selectedPR && purchaseRequestState?.selectedPR?._id == task2._id} onChange={(evt) => setSelectedPurchaseRequest(evt, task2)} />

                                    <div style={{ marginLeft: '3%', textAlign: 'center', marginTop: '1%' }}>

                                        {task2?.task?.assignedUser.length > 0 &&
                                            Array.from(task2?.task?.assignedUser).map((user) => {
                                                return (

                                                    <div className={styles.nameColumn}>

                                                        <span>{user.displayName || 'No name'}</span>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>

                                    <div className={styles.prItemArea}>
                                        <div className={styles.itemField}>

                                            <div className={styles.nameColumn}>
                                                Item
                                            </div>

                                            <div className={styles.quantityColumn}>
                                                Note
                                            </div>

                                            <div className={styles.noteColumn}>
                                                Quantity
                                            </div>
                                            <div className={styles.SummaryCol}>
                                                Order Summary
                                            </div>
                                        </div>

                                        {task2?.task.fields?.map((field) => {
                                            return (

                                                <div className={styles.itemField}>

                                                    <div className={styles.nameColumn}>
                                                        {field.item?.itemName || 'No item name'}

                                                    </div>
                                                    <div className={styles.noteColumn} style={{ width: '115px', }}>
                                                        {field.note || <span style={{ marginLeft: '12%' }}> -</span>}
                                                    </div>

                                                    <div className={styles.quantityColumn}>
                                                        <input type="number" style={{ width: '34px', textAlign: 'center', opacity: '1', backgroundColor: 'transparent', border: 'none' }} value={field.parValue}></input>
                                                    </div>


                                                </div>

                                            )
                                        })}

                                    </div>

                                    <div className={styles.prOrderSummary}>

                                        {task2.checkedOut && task2.checkedOut.map((item, index) => {
                                            return (
                                                < div style={{ width: '350px', minWidth: '350px', display: 'flex' }} >
                                                    <div style={{ width: '200px' }}>   <FaRegTrashAlt style={{ marginLeft: '-5%', marginRight: '2%', }} onClick={(evt) => deleteItemFromCheckedOut(item, index, task2)} />{item.itemName} {item?.vendor?.company && <span>from {item?.vendor?.company} </span>} </div>  Qty: {item.pendingOrderQuantity}

                                                </div>
                                            )
                                        })}

                                    </div>

                                </div>
                            ))}


                        </div>
                    </div>

                    <div >
                        <input placeholder="Search items" className={styles.SearchboxSection} onChange={(evt) => searchItems(evt)}></input>
                    </div>


                    <div className={styles.searchResArea}>

                        {searchDone && searchResult.length != items.length && Object.keys(searchResult).map((companyName, index) => {

                            return (

                                <div style={{ border: '1px solid black', marginTop: '2%', width: '90%', backgroundColor: 'white', borderRadius: '4px' }}>

                                    <div style={{ backgroundColor: '#0c1620', borderRadius: '0 0 7px 7px', color: 'white', padding: '.5%' }}>
                                        {companyName}
                                    </div>

                                    <div style={{ padding: '.5%' }}>

                                        <table>
                                            <tr style={{ padding: '.5%' }}>
                                                <th></th>

                                                <th style={{ width: '125px' }}>Item</th>
                                                <th style={{ width: '125px' }}>Item Number</th>
                                                <th style={{ width: '125px' }}>Size</th>
                                                <th style={{ width: '125px' }}>Cost</th>
                                                <th style={{ width: '125px' }}>Order Qty</th>
                                            </tr>


                                            {searchResult[companyName].map((item, itemIndex) => {
                                                return (
                                                    <tr>
                                                        <td style={{ width: '50px' }}><input type="checkbox" id={'itemCheckbox' + index} onChange={(evt) => tempSaveItemSelected(evt, item, index, companyName)} /></td>
                                                        <td style={{ width: '125px' }}>{item.itemName}</td>
                                                        <td style={{ width: '125px' }}>{item.itemNumber}</td>
                                                        <td style={{ width: '125px' }}>{item.size}</td>
                                                        <td style={{ width: '125px' }}>{item.cost}</td>

                                                        <td style={{ width: '125px' }} ><input id={'quantField' + index} onChange={(evt) => saveQuantityOnPendingOrder(evt, item, companyName)} ></input></td>
                                                    </tr>

                                                )

                                            })}

                                        </table>

                                        <button className='addToCartBtn' onClick={() => { addPendingCartToPendingOrder(companyName, purchaseRequestState) }} style={{ marginLeft: '90%' }}>Add to Cart</button>


                                    </div>

                                </div>)
                        })}

                    </div>


                </div>
            }

            {
                purchaseRequestState.currentView == 'summary' &&
                <div className={styles.pageWrapper}>

                    <div className={styles.inputSection2} >

                        <div className="checkboxes">
                            <label> </label>  </div>


                        <div className={styles.subPageHeaders}>
                            <span onClick={() => { openSubPage('request', purchaseRequestState) }}>Request</span> <span style={{ marginLeft: '5%' }} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState) }}>Pending Purchases</span><button onClick={() => { openSubPage('request', purchaseRequestState) }} style={{ marginLeft: '50%' }}>Edit Order</button>

                        </div>

                    </div>


                    <div className={styles.summaryArea}>

                        {purchaseRequestState.summary && Object.keys(purchaseRequestState.summary).map((companyName, index) => {

                            return (
                                <div className={styles.summaryItem}>




                                    <div className={styles.cardHeader}>
                                        <span style={{ marginBottom: '2%' }}>
                                            {companyName} | Contact
                                            </span>
                                    </div>

                                    <div className={styles.topCard}>
                                        <div style={{ paddingLeft: '1%', paddingTop: '1%' }}>

                                            <span>Contact Information</span>

                                        </div>

                                        <div className={styles.midCard}>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}> Phone: </span>


                                                    <span className={styles.rightItem1}>{purchaseRequestState.summary[companyName][0].vendor.phoneNumber} 123</span>

                                                </div>

                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1} >
                                                        Min Order:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.minOrder}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}>
                                                        Email:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.contactEmail}
                                                    </span>
                                                </div>


                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.rightItem1}>
                                                        Order Deadline:
                                                    </span>
                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.orderDeadline}
                                                    </span>
                                                </div>


                                            </div>

                                            <div className={styles.cardRow}>

                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.leftItem1}>
                                                        Contact Person:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.contactPerson}
                                                    </span>


                                                </div>


                                                <div className={styles.cardLineItem}>

                                                    <span className={styles.leftItem1}>
                                                        Instructions:
                                                    </span>
                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.instructions}
                                                    </span>
                                                </div>


                                            </div>



                                            <div className={styles.cardRow}>


                                                <div className={styles.cardLineItem}>
                                                    <span className={styles.leftItem1}>
                                                        Website:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.website}
                                                    </span>


                                                </div>




                                            </div>

                                            <div className={styles.cardRow}>

                                                <div className={styles.cardLineItem}>
                                                    <span>
                                                        Username:
                                                    </span>


                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.username}
                                                    </span>

                                                </div>



                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1}>
                                                        Payment Terms:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.paymentTerms}
                                                    </span>
                                                </div>

                                            </div>

                                            <div className={styles.cardRow}>
                                                <div className={styles.cardLineItem}>


                                                    <span className={styles.leftItem1}>
                                                        Password:
                                                    </span>

                                                    <span className={styles.rightItem1}>
                                                        {purchaseRequestState.summary[companyName][0].vendor.password}
                                                    </span>


                                                </div>


                                            </div>


















                                        </div>

                                    </div>

                                    <div className={styles.blackLineDiv2}>
                                        &nbsp;
                                    </div>


                                    <div className={styles.bottomCard}>
                                        <div className={styles.leftHalfBC}>
                                            <div>

                                                <span>Order Summary</span> <span style={{ marginLeft: '5%' }}>Purchase No: {purchaseRequestState.selectedPR._id} </span>   <span style={{ marginLeft: '15%' }}>Note</span> <span  >{purchaseRequestState.summary[companyName][0].vendor.Note}</span>
                                            </div>

                                            {purchaseRequestState.summary[companyName].map((item, itemIndex) => {
                                                return (
                                                    <div>
                                                        <span style={{ width: '100px' }}>{item.itemName}  </span> <span> Qty:{item.pendingOrderQuantity}</span>

                                                    </div>
                                                )

                                            })}

                                            <span>Arrive on (?)</span>

                                        </div>

                                        <div className={styles.rightHalfTC}>
                                            <div>
                                                <input type="checkbox" /> <span>Website</span>
                                            </div>

                                            <div>
                                                <input type="checkbox" /> <span>Phone</span>
                                            </div>

                                            <div>
                                                <input type="checkbox" /> <span>Email</span>
                                            </div>


                                        </div>

                                        <div style={{ paddingTop: '2%', paddingRight: '2%' }}>
                                            <button className={styles.donebtn} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState, purchaseRequestState.summary[companyName]) }}>Done</button>

                                        </div>

                                    </div>

                                </div>
                            )
                        })}





                    </div>




                </div>
            }

            {
                purchaseRequestState.currentView == 'PendingPurchase' &&
                <div className={styles.pageWrapper}>
                    <div className={styles.subPageHeaders}>
                        <span onClick={() => { openSubPage('request', purchaseRequestState) }}>Request</span> <span style={{ marginLeft: '5%' }} onClick={() => { openSubPage('PendingPurchase', purchaseRequestState) }}  >Pending Purchases</span>
                    </div>

                    <div className={styles.realTableWrapper}>


                        <table className={styles.tableWrapper}>

                            {ppLoadStatus == 'Done' && pendingPurchaseState.orders.map((order, orderIndex) => {
                                return (

                                    <div className={styles.purchaseOrder}>
                                        <div className={styles.orderHeader} >
                                            <span> {order?.purchaseOrder[0]?.vendor?.company}  </span>  <span style={{ marginLeft: '3%' }}> Order: {order?._id}  </span>    <span style={{ marginLeft: '3%' }}> Vendor: {order?.purchaseOrder?.vendor?.company}  </span>    <span style={{ float: 'right' }}>Last Updated: 12/4/2023</span>

                                        </div>

                                        <tr style={{ padding: '.5%', left: '3%', position: 'relative' }}>

                                            <th style={{ width: '6%' }}>Item</th>
                                            <th style={{ width: '6%' }}>Qty.</th>
                                            <th style={{ width: '6%' }}>Price</th>
                                            <th style={{ width: '6%' }}>Good</th>
                                            <th style={{ width: '6%' }}>Bad</th>
                                            <th style={{ width: '6%' }}>Not Recieved</th>

                                            <th style={{ width: '6%' }}>Note</th>

                                        </tr>


                                        {order?.purchaseOrder?.length > 0 && order?.purchaseOrder?.map((item, itemIndex) => {
                                            return (

                                                <tr style={{ padding: '.5%', left: '3%', position: 'relative', }}>
                                                    <td style={{ width: '6%' }}>{item.itemName} </td>
                                                    <td style={{ width: '6%', marginLeft: '1%' }}>{item.pendingOrderQuantity}</td>
                                                    <td style={{ width: '6%' }}> <input style={{ width: '50px' }} value={item.cost} onChange={(evt) => { dispatch(updatePrice({ value: evt.target.value, orderIndex: orderIndex, itemIndex: itemIndex })) }} /></td>
                                                    <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleGoodStatus({ value: evt.target.checked, orderIndex: orderIndex, itemIndex: itemIndex })) }} /></td>
                                                    <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleBadStatus({ value: evt.target.checked, orderIndex: orderIndex, itemIndex: itemIndex })) }} /> </td>
                                                    <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleNotRecievedStatus({ value: evt.target.checked, orderIndex: orderIndex, itemIndex: itemIndex })) }} /> </td>

                                                    <td style={{ width: '6%' }}><textarea></textarea></td>

                                                </tr>
                                            )

                                        })}

                                        {!order?.purchaseOrder.length && (order?.purchaseOrder) &&

                                            < tr style={{ padding: '.5%', left: '3%', position: 'relative' }}>
                                                <td style={{ width: '6%' }}>{order?.purchaseOrder.itemName}</td>
                                                <td style={{ width: '6%', marginLeft: '1%' }}>{order?.purchaseOrder.pendingOrderQuantity}</td>
                                                <td style={{ width: '6%' }}> <input style={{ width: '50px' }} value={order?.purchaseOrder.cost} onChange={(evt) => { dispatch(updatePrice({ value: evt.target.value, orderIndex: orderIndex, itemIndex: itemIndex })) }} /></td>
                                                <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleGoodStatus({ value: evt.target.checked, orderIndex: orderIndex, itemIndex: itemIndex })) }} /></td>
                                                <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleBadStatus({ value: evt.target.checked, orderIndex, itemIndex: itemIndex })) }} /> </td>
                                                <td style={{ width: '6%' }}> <input type="checkbox" onChange={(evt) => { dispatch(toggleNotRecievedStatus({ value: evt.target.checked, orderIndex, itemIndex: itemIndex })) }} /> </td>

                                                <td style={{ width: '6%' }}   ><textarea onChange={(evt) => { dispatch(updateNoteOnPendingPurchase({ value: evt.target.value, orderIndex, itemIndex: itemIndex })) }}></textarea></td>

                                            </tr>
                                        }

                                        <div style={{ display: 'flex' }}>
                                            <button style={{ marginLeft: '85%' }}>Update</button>
                                            <button style={{ marginLeft: '3%' }}>Complete</button>

                                        </div>

                                    </div>
                                )
                            })}

                        </table>
                    </div >

                </div >
            }

        </>
    )

    async function deleteItemFromCheckedOut(item, index, task) {
        console.log("deleteItemFromCheckedOut params : ", item, index, task)
        const editedState = structuredClone(task);

        const indexRemove = editedState.checkedOut.indexOf(item);

        for (var i = editedState.checkedOut.length - 1; i >= 0; --i) {
            if (editedState.checkedOut[i]._id == item._id) {
                editedState.checkedOut.splice(i, 1);
                console.log("Index :", i)

            }
        }

        console.log("checked out after deleting :", editedState.checkedOut)

        await dispatch(patchTask(editedState))
        await dispatch(loadTasks({ type: 'Purchase Request' }))

        // await dispatch(setPurchaseRequest({evt: 'on', task: editedState.selectedPR }))

        await dispatch(fetchPendingPurchases(user.org_id))

        await dispatch(setPurchaseRequest({ evt: true, task: editedState.selectedPR }))

        dispatch(fetchTasks({ type: 'Maintenance Request', org_id: user.org_id, location_id: user.viewingLocation }))



        //    await dispatch(setPurchaseRequest({evt: 'on', task: task }))
    }

    function setSelectedPurchaseRequest(evt, task) {
        console.log("Evt and task : ", evt.target.value, task)
        dispatch(setPurchaseRequest({ evt: evt.target.value, task }))
    }


    function saveQuantityOnPendingOrder(evt, item, companyName) {
        console.log("Temp save reached saveQuantityOnPendingOrder;", evt.target.value)
        dispatch(saveQuantityToItem({ evt: evt.target.value, item, companyName: companyName }))

    }

    function openSubPage(view, state, create = 'no') {
        console.log("Open sub page", view, state, create)

        if (create != 'no') {
            //in this case, the variable create is an item/ list of items being checked out
            //from a single vendor.
            console.log("Creating new pending purchase :", create)
            dispatch(createPendingPurchase({ create, "org_id": user.org_id, location: user.viewingLocation }))
        }


        if (view == 'summary') {//when check out is clicked

            const groupedCheckout = purchaseRequestState.selectedPR.checkedOut.reduce((groups, item) => {

                console.log("Item check : ", item)
                if (groups[item.vendor.company]) {
                    let group = groups[item.vendor.company]//compile data into groups by vendor name
                    group.push(item);
                }
                else {
                    let group = [item]
                    groups[item.vendor.company] = group;
                }

                return groups;
            }, {});
            console.log("grouped checkout : ", groupedCheckout)
            dispatch(setSummary(groupedCheckout))

        }


        dispatch(setPageView(view))

    }

    function tempSaveItemSelected(evt, item, index, companyName) {
        console.log("Temp save reached ;", evt.target.checked, companyName,)

        dispatch(saveItemSelected({ check: evt.target.checked, item, companyName: companyName }))

        if (document.getElementById('quantField' + index).value) {//if value already exists, save quantity to item that was added
            dispatch(saveQuantityToItem({ evt: document.getElementById('quantField' + index).value, item }))

        }

    }

    function reloadPage() {
        dispatch(fetchTasks({ type: 'Maintenance Request', org_id: user.org_id, location_id: user.viewingLocation }))
    }

    async function addPendingCartToPendingOrder(companyName, state) {
        let dataToAdd = state.pendingOrder[companyName] //later this line needs to be a loop adding data to a list or whatever... we need to add every item in that case

        //when a given company's section is clicked add that section to the check out

        const editedState = structuredClone(state);
        if (!editedState.selectedPR.checkedOut || editedState.selectedPR.checkedOut.length == 0) {
            editedState.selectedPR.checkedOut = []
        }
        //check if a task is selected
        if (Object.keys(editedState.selectedPR).length > 0) {

            for (var element in dataToAdd) {
                //if elem in checked out, replace it

                let wasUpdated = false;

                for (var i = editedState.selectedPR.checkedOut.length - 1; i >= 0; --i) {
                    console.log("comparing : ", editedState.selectedPR.checkedOut[i]._id, element)
                    if (editedState.selectedPR.checkedOut[i]._id == dataToAdd[element]._id) {
                        console.log("updating element :", dataToAdd[element])
                        editedState.selectedPR.checkedOut[i] = dataToAdd[element]
                        wasUpdated = true
                    }

                }
                if (!wasUpdated) {
                    console.log("Adding element :", dataToAdd[element])

                    editedState.selectedPR.checkedOut.push(dataToAdd[element])

                }

            }

            console.log("patching task with :", editedState.selectedPR)
            //addCartToPendingPurchaseRequest
            await dispatch(patchTask(editedState.selectedPR))

            await dispatch(loadTasks({ type: 'Maintenance Request' }))

            // await dispatch(setPurchaseRequest({evt: 'on', task: editedState.selectedPR }))

            await dispatch(fetchPendingPurchases(user.org_id))

            await dispatch(setPurchaseRequest({ evt: true, task: editedState.selectedPR }))

            dispatch(fetchTasks({ type: 'Maintenance Request', org_id: user.org_id, location_id: user.viewingLocation }))


        }


    }





    function searchItems(evt) {
        console.log("id check search : ", user.org_id)
        console.log("current searchResult before search:", searchResult)
        console.log("Searching items :", evt.target)
        dispatch(itemSearch({ data: evt.target.value, org_id: user.org_id, location_id: user.viewingLocation }))

    }


    function openPage(page, task) {
        const location = {
            pathname: `/${page}/${task.name}`,
            state: { taskToDisplay: task }
        }
        console.log("Need to open this task :", task)
        //  dispatch(setTaskForEdit(task))

        history.push(location);

        /*
        props.navigation.navigate(page, {
                        data: {task: task },
        });*/

        /*
                navigation.navigate('http://localhost:3000/' + page, {
                        params: {task: task },
                });
        */

        // navigation.navigate({task})


        /*
      setTimeout(() => {
                        window.location.href = 'http://localhost:3000/' + page; //TODO

      }, 1000);*/


        //navigate(page)
    }

    function settingRequestStatusReportOnComplete(evt) {
        dispatch(setRequestStatusReportOnComplete(evt.target.checked))
    }


    function settingKeepUpdatedTask(evt) {
        console.log("evt :", evt)
        dispatch(setKeepUpdatedTask(evt.target.checked))
    }

    function settingDestinationFormat(evt) {
        dispatch(setDestinationFormat(evt))
    }

    function settingDestinationType(evt) {
        dispatch(setDestinationType(evt))
    }


    function settingJobPosition(evt) {
        dispatch(setJobPosition(evt))
    }

    function settingAssignedUser(evt) {
        dispatch(setAssignedUser(evt))
    }

    function settingFrequency(evt) {
        dispatch(setFrequency(evt))
    }

    function removeSectionFromTasks(evt, index) {
        dispatch(RemoveSection({ evt: evt, index: index }))
    }



    function toggleDay(day) {
        if (!frequency.includes(day)) {          //checking weather array contain the id
            frequency.push(day);               //adding to array because value doesnt exists
        } else {
            frequency.splice(frequency.indexOf(day), 1);  //deleting
        }
        console.log(frequency, frequency.includes('M'));


        ///  loadedUser.availability = frequency
        console.log("check : ", loadedUser, frequency)
        dispatch(updateUser(loadedUser, { availability: frequency }))
        dispatch(fetchUsers(user.org_id))
    }



    function reportingGroupSelectChange(evt) {
        //  console.log("Checking evt : ", evt)
        //dispatch(setRG(evt))
    }

    function vendorSelectChange(evt) {
        //  console.log("vender set :", evt)
        //dispatch(setVendor(evt))
    }

    function itemSelectedInField(item, index) {
        console.log("Item and index :", item, index)


        dispatch(SaveItemToField({ item: item, index: index }))
    }

    function addSectionToTasks(evt) {

        //set section type
        console.log(" evt ; ", evt)
        dispatch(SetSection(evt))
    }



    function onDeleteClicked(item) {
        /*
        dispatch(deleteItem(item)).then(res => {
            clearDataFromFields()
        })*/
    }


    function onCancelClicked() {/*
        //should clear selected states too todo
        document.getElementById('displayName').value = ''
        document.getElementById('Cost').value = ''
        document.getElementById('Date').value = ''
        document.getElementById('Par').value = ''
        document.getElementById('Size').value = ''
        document.getElementById("itemNumber").value = ''

        clearSelects()*/
    }

    function clearSelects() {
        /*
        let blankEntry = { label: '', value: '' }
     
        dispatch(setVendor(blankEntry))
        dispatch(setRG(blankEntry))
        dispatch(SetItem(blankEntry))*/
    }


    function updateNoteField(index) {

        console.log("Element :", document.getElementById("Note" + index), "Note" + index)

        var value = document.getElementById("Note" + index).value;
        console.log("Update note :", { index: index, note: value })

        dispatch(updateNote({ index: index, note: value }))

        console.log("Value check : ", value)
    }


    function updateQuantityField(index) {
        console.log("Update qty :", index)

        var value = document.getElementById("Qty" + index).value;

        dispatch(updateQuantity({ index: index, value: value }))

        console.log("Value check : ", value)

    }


    function onSaveClicked() {

        console.log("Data that needs saving :", tasks, tasks.taskBeingMade)

        let name = document.getElementById("taskName").value
        dispatch(addNewTask({ name: name, task: tasks.taskBeingMade, org_id: user.org_id }))


    }




    function clearDataFromFields() {
        /*
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''*/
    }




}

export default MaintenanceRequest;