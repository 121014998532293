import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import './Sidebar.css';
import * as actions from '../store/actions/MenuNavigation.js'
import { SetJobPosition, RemovePermission, AddPermission, getPermsToAdd, getPermsToRemove, fetchPermissions, updatePermissionsOnJob, patchJob, addNewJobPosition, deleteJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../Pages/forms/JobPositions/jobPositionSlice.js';
import Select from 'react-select';
import { patchUser } from '../Pages/forms/users/UsersSlice';

import { logout, login } from '../store/actions/auth'
import { fetchLocations, selectLocations, getLocationLoadStatus, getLoadedLocation } from '../Pages/forms/Locations/locationSlice'
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";

const getUserFromStorage = () => {
    try {
      const userStr = localStorage.getItem('user');
      if (!userStr) return '';
      
      const parsedData = JSON.parse(userStr);
      
      if (parsedData === 'Login failed') {
        return parsedData;
      }
      
      return Array.isArray(parsedData) ? parsedData[0] : parsedData;
    } catch (error) {
      console.error('Error parsing user data:', error);
      localStorage.removeItem('user');
      return '';
    }
};

function SideBar(props) {
    const location = useLocation();
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
    const [isExpanded, setIsExpanded] = useState(() => {
        const path = location.pathname;
        return path === '/' || path === '';
    });

    const selectedPage = useSelector((state) => state.selectedPage);
    const dispatch = useDispatch();
    const history = useHistory();

    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);

    const locations = useSelector(selectLocations);
    const loading = useSelector(getLocationLoadStatus);
    const loadedLocation = useSelector(getLoadedLocation);

    const [permissionList, setPermissionList] = useState([]);

    console.log("Permissions loaded :" , permissionList)
    const user = getUserFromStorage();

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobileOrTablet(window.innerWidth <= 1024);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    useEffect(() => {
        const path = location.pathname;
        if (path !== '/' && path !== '' && path !== undefined) {
            setIsExpanded(false);
        }
    }, [location]);

    if (user) {
        useEffect(() => {
            console.log("trying to set permissions ", user?.jobPosition);
            var tempPerms = [];

            if (user?.jobPosition) {
                if (Array.isArray(user.jobPosition)) {
                    user.jobPosition.forEach(element => {
                        if (element.permissions) {
                            tempPerms.push(...element.permissions);
                        }
                    });
                } else if (typeof user.jobPosition === 'object') {
                    if (user.jobPosition.permissions) {
                        tempPerms.push(...user.jobPosition.permissions);
                    }
                }
            }

            tempPerms = [...new Set(tempPerms)];
            setPermissionList(tempPerms);
        }, [jobPositionLoading, dispatch]);
    }

    if (user) {
        useEffect(() => {
            if (loading !== 'Done') {
                dispatch(fetchLocations(user.org_id));
            }
        }, [loading, dispatch]);
    }

    if (user === "Login failed") {
        dispatch(logout());
        location.reload();
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderMainMenuItems = () => (
        <>
            {/* Home Dashboard */}
            <div className='listItem raise' id="home" onClick={() => openPage('')}>
                Home
            </div>

            {/* User Dashboard */}
            <div className='listItem raise' onClick={() => openPage('User_Dashboard')}>
                User Dashboard
            </div>
        </>
    );

    const renderUserTasksMenu = () => (
        <>
            {/* User Tasks Section */}
            <div className='listItem raise' onClick={() => stateChange('selectedPage', 'User\'s Tasks')}>
                User's Tasks
            </div>
            {selectedPage === 'User\'s Tasks' && (
                <ul>
                    {/*permissionList?.includes('Maintenance Requests') && (
                        <li className="raiseNoOutline" onClick={() => openPage('Maintenance_Request')}>
                            Maintenance Request
                        </li>
                    )*/}
                    {permissionList?.includes('Purchase Requests') && (
                        <li onClick={() => openPage('Purchase_Request')}>
                            Purchase Request
                        </li>
                    )}
                    {/*permissionList?.includes('Tasks') && (
                        <li onClick={() => openPage('Notification')}>
                            Notification
                        </li>
                    )*/}
                </ul>
            )}
        </>
    );

    const renderSetupMenu = () => (
        <>
            {/* Setup Section */}
            <div className='listItem raise' name='Setup' onClick={() => stateChange('selectedPage', 'Setup')}>
                Setup
            </div>
            <div className='setupSubPages'>
                {selectedPage === 'Setup' && (
                    <ul>
                        {permissionList?.some(e => e === 'Users') && (
                            <li onClick={() => openPage('Setup_Users')} className="setupListItem raiseNoOutline">
                                Users
                            </li>
                        )}
                        <li onClick={() => openPage('Setup_Jobs')} className="setupListItem raiseNoOutline">
                            Job Positions
                        </li>
                        <li onClick={() => openPage('Setup_Items')} className="setupListItem raiseNoOutline">
                            Items
                        </li>
                        <li onClick={() => openPage('Setup_Vendors')} className="setupListItem raiseNoOutline">
                            Vendors
                        </li>
                        <li onClick={() => openPage('Setup_ReportingGroups')} className="setupListItem raiseNoOutline">
                            Reporting Groups
                        </li>
                        <li onClick={() => openPage('Setup_Locations')} className="setupListItem raiseNoOutline">
                            Locations
                        </li>
                    </ul>
                )}
            </div>
        </>
    );

    const renderCoreFeatures = () => (
        <>
            {/* Training */}
            <div className='listItem raise' onClick={() => openPage('Training')}>
                Training
            </div>

            {/* CRM */}
            <div className='listItem raise' onClick={() => openPage('CRM')}>
                CRM
            </div>

            {/* Media Marketplace */}
            <div className='listItem raise' onClick={() => openPage('Media Marketplace')}>
                Media Marketplace
            </div>
        </>
    );

    const renderFormMenu = () => (
        <>
            {/* Forms Section */}
            <div className='listItem raise' name='Form' onClick={() => stateChange('selectedPage', 'Form')}>
                Form
            </div>
            {selectedPage === 'Form' && (
                <ul>
                    <li className="raiseNoOutline" style={{ marginTop: '2%' }} onClick={() => openPage('Create_Form')}>
                        Create Form
                    </li>
                    <li className="raiseNoOutline" style={{ marginTop: '2%' }} onClick={() => openPage('View_Form')}>
                        View Form
                    </li>
                </ul>
            )}
        </>
    );

    const renderTasksMenu = () => (
        <>
            {/* Tasks Section */}
            <div className='listItem raise' name='Tasks' onClick={() => stateChange('selectedPage', 'Tasks')}>
                Tasks
            </div>
            {selectedPage === 'Tasks' && (
                <ul>
                    <li className="raiseNoOutline" style={{ marginTop: '2%' }} onClick={() => openPage('Create_Task')}>
                        Create Tasks
                    </li>
                    <li className="raiseNoOutline" style={{ marginTop: '2%' }} onClick={() => openPage('View_Task')}>
                        View Tasks
                    </li>
                </ul>
            )}
        </>
    );

    const renderAnalyticsAndPlugins = () => (
        <>
            {/* Analytics */}
            <div className='listItem raise' onClick={() => stateChange('selectedPage', 'Analytics')}>
                Analytics
            </div>

            {/* Plugin/Subscription */}
            <div className='listItem raise' onClick={() => stateChange('selectedPage', 'Plugin/Subscription')}>
                Plugin/Subscription
            </div>
        </>
    );

    return (
        <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
            {user && 
                <div className={isExpanded ? "main" : "collapsed-sidebar"}>
                    {/* Logo */}
                    {isExpanded && <img className='logo' src={require("../Assets/EuSocialLogo.png")} />}

                    {/* Mobile Toggle Button */}
                    {isMobileOrTablet && (
                        <div className="toggleBtn" onClick={toggleExpand}>
                            <i className={`fas fa-chevron-${isExpanded ? 'left' : 'right'}`}></i>
                        </div>
                    )}

                    {isExpanded ? (
                        <div className="linkArea">
                            {user && user !== "Login failed" && (
                                <div style={{ cursor: "default" }} className="linkArea">
                                    {/* Location Selector */}
                                    <div className="selectWrapper">
                                        {loading && (
                                            <Select
                                                id="locationSelect"
                                                value={locations.filter(x => x._id === user.viewingLocation)}
                                                placeholder="Select Location"
                                                onChange={(e) => onSetLocation(e)}
                                                options={locations}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.name}
                                            />
                                        )}
                                    </div>

                                    {/* Main Menu Items */}
                                    {renderMainMenuItems()}

                                    {/* User Tasks Menu */}
                                    {renderUserTasksMenu()}

                                    {/* Setup Menu */}
                                    {renderSetupMenu()}

                                    {/* Core Features */}
                                    {/*renderCoreFeatures()*/}

                                    {/* Form Menu */}
                                    {renderFormMenu()}

                                    {/* Tasks Menu */}
                                    {/*renderTasksMenu()*/}

                                    {/* Analytics and Plugins */}
                                    {/*renderAnalyticsAndPlugins()*/}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="collapsed-icons">
                            <i className="fas fa-home"></i>
                            <i className="fas fa-tasks"></i>
                            <i className="fas fa-cog"></i>
                            <i className="fas fa-graduation-cap"></i>
                            <i className="fas fa-users"></i>
                            <i className="fas fa-store"></i>
                            <i className="fas fa-file-alt"></i>
                            <i className="fas fa-clipboard-list"></i>
                            <i className="fas fa-chart-bar"></i>
                            <i className="fas fa-plug"></i>
                        </div>
                    )}

                    <button
                        onClick={toggleExpand}
                        className={`expandButton expandbutton2 ${isExpanded ? 'expanded' : ''}`}
                    >
                        {isExpanded ? <FaArrowLeft /> : <FaArrowRight />}
                    </button>
                </div>
            }
        </div>
    );

    function openPage(page) {
        window.location.replace(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}` + page)
    }

    function onSetLocation(evt) {
        var e = document.getElementById("locationSelect");
        var value = e.value;

        let newData = user
        newData.viewingLocation = evt._id

        dispatch(patchUser({ newUser: newData, newLocation: evt, loadedJobPosition: user.jobPosition, org_id: user.org_id }))
        dispatch(logout())

        dispatch(login(newData.username, newData.password))
            .then((res) => {
                window.location.reload();
                location.reload()
                console.log("logged back in ")
            })
    }

    function stateChange(name, value) {
        if (selectedPage == value) {
            value = ''
        }
        dispatch(actions.set_selected_menu_tab(value))
    }
}

export default SideBar;