import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchVendors } from './vendorsApi';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import fetch from "node-fetch"

//types
const FETCH_VENDORS_REQUESTED = 'FETCH_VENDORS_REQUESTED'
const FETCH_VENDORS_SUCCEDED = 'FETCH_VENDORS_SUCCEDED'
const FETCH_VENDORS_FAILED = 'FETCH_VENDORS_FAILED'
const SET_LOADED_VENDOR = 'SET_LOADED_VENDOR'
const ADD_CATAGORY = 'ADD_CATAGORY'
const REMOVE_CATAGORY = 'REMOVE_CATAGORY'
const SET_CATAGORY = 'SET_CATAGORY'
const SET_COMPANY = "SET_COMPANY"
const SET_ADDRESS = "SET_ADDRESS"
const SET_STATE = 'SET_STATE'
const SET_CITY = "SET_CITY"

const SET_ZIP = "SET_ZIP"
const SET_PHONE = "SET_PHONE"
const SET_EMAIL = "SET_EMAIL"
const SET_CONTACT_PERSON = "SET_CONTACT_PERSON'"
const SET_CONTACT_PHONE = "SET_CONTACT_PHONE"
const SET_CONTACT_EMAIL = "SET_CONTACT_EMAIL"
const SET_CONTACT_WEBSITE = "SET_CONTACT_WEBSITE"
const SET_MIN_ORDER = "SET_MIN_ORDER"
const SET_PAYMENT_TERMS = "SET_PAYMENT_TERMS"
const SET_INSTRUCTIONS = "SET_INSTRUCTIONS"
const SET_USERNAME = "SET_USERNAME"
const SET_PASSWORD = "SET_PASSWORD"
const CLEAR_VENDOR = "CLEAR_VENDOR"

const SET_CREATED = "SET_CREATED"
const SET_COUNTRY = "SET_COUNTRY"


const fetchVendorsRequested = () => {
  return {
    type: FETCH_VENDORS_REQUESTED
  }
}

const fetchVendorsSuccess = (vendors) => {
  return {
    type: FETCH_VENDORS_SUCCEDED
  }
}

const fetchVendorsFailed = (error) => {
  return {
    type: FETCH_VENDORS_FAILED,
    payload: error
  }
}
// Add this action creator with the other action creators
export const setVendorBeingCreatedCountry = createAsyncThunk(
  'Vendors/setVendorBeingCreatedCountry',
  async (vendor) => {
    return {
      type: SET_COUNTRY,
      payload: vendor
    }
  }
);
export const setVendorBeingCreatedCompany = createAsyncThunk(
  'Vendors/setVendorBeingCreatedCompany',
  async (vendor) => {

    return {
      type: SET_COMPANY,
      payload: vendor
    }

  }
);


export const setVendorBeingCreatedAddress = createAsyncThunk(
  'Vendors/setVendorBeingCreatedAddress',
  async (vendor) => {

    return {
      type: SET_ADDRESS,
      payload: vendor
    }

  }
);
export const setVendorBeingCreatedState = createAsyncThunk(
  'Vendors/setVendorBeingCreatedState',
  async (vendor) => {

    return {
      type: SET_STATE,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedCity = createAsyncThunk(
  'Vendors/setVendorBeingCreatedCity',
  async (vendor) => {

    return {
      type: SET_CITY,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedZip = createAsyncThunk(
  'Vendors/setVendorBeingCreatedZip',
  async (vendor) => {

    return {
      type: SET_ZIP,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedPhoneNumber = createAsyncThunk(
  'Vendors/setVendorBeingCreatedPhoneNumber',
  async (vendor) => {

    return {
      type: SET_PHONE,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedEmail = createAsyncThunk(
  'Vendors/setVendorBeingCreatedEmail',
  async (vendor) => {

    return {
      type: SET_EMAIL,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedContactPerson = createAsyncThunk(
  'Vendors/setVendorBeingCreatedContactPerson',
  async (vendor) => {

    return {
      type: SET_CONTACT_PERSON,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedContactPhone = createAsyncThunk(
  'Vendors/setVendorBeingCreatedContactPhone',
  async (vendor) => {

    return {
      type: SET_CONTACT_PHONE,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedContactEmail = createAsyncThunk(
  'Vendors/setVendorBeingCreatedContactEmail',
  async (vendor) => {

    return {
      type: SET_CONTACT_EMAIL,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedContactWebsite = createAsyncThunk(
  'Vendors/setVendorBeingCreatedContactWebsite',
  async (vendor) => {

    return {
      type: SET_CONTACT_WEBSITE,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedMinOrder = createAsyncThunk(
  'Vendors/setVendorBeingCreatedMinOrder',
  async (vendor) => {

    return {
      type: SET_MIN_ORDER,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedInstructions = createAsyncThunk(
  'Vendors/setVendorBeingCreatedInstructions',
  async (vendor) => {

    return {
      type: SET_INSTRUCTIONS,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedPaymentTerms = createAsyncThunk(
  'Vendors/setVendorBeingCreatedPaymentTerms',
  async (vendor) => {

    return {
      type: SET_PAYMENT_TERMS,
      payload: vendor
    }

  }
);

export const setVendorBeingCreatedUsername = createAsyncThunk(
  'Vendors/setVendorBeingCreatedUsername',
  async (vendor) => {

    return {
      type: SET_USERNAME,
      payload: vendor
    }

  }
);
export const setVendorBeingCreatedPassword = createAsyncThunk(
  'Vendors/setVendorBeingCreatedPassword',
  async (vendor) => {

    return {
      type: SET_PASSWORD,
      payload: vendor
    }

  }
);

export const clearVendorBeingMade = createAsyncThunk(
  'Vendors/clearVendorBeingMade',
  async (vendor) => {

    return {
      type: CLEAR_VENDOR,
      payload: vendor
    }

  }
);

export const setVendor = createAsyncThunk(
  'Vendors/setVendor',
  async (vendor) => {

    return {
      type: SET_LOADED_VENDOR,
      payload: vendor
    }

  }
);


export const setVendorBeingCreated = createAsyncThunk(
  'Vendors/setVendorBeingCreated',
  async (vendor) => {

    return {
      type: SET_CREATED,
      payload: vendor
    }

  }
);

const initialState = {
  vendorLoading: false,
  vendors: [],
  vendorsError: null,
  loadedVendor: '',
  rgCatagories: [],
  vendorBeingCreated: {},
  searchDone: false,
  searchResult: [],
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks


export const RemoveCatagory = createAsyncThunk(
  'Items/removeCatagory',
  async (item) => {

    return {
      type: REMOVE_CATAGORY,
      payload: item
    }

  }
);

export const AddCatagory = createAsyncThunk(
  'Items/AddCatagory',
  async (item) => {

    return {
      type: ADD_CATAGORY,
      payload: item
    }

  }
);

export const SetCatagory = createAsyncThunk(
  'Items/SetCatagory',
  async (item) => {

    console.log("Item : ", item)

    return {
      type: SET_CATAGORY,
      payload: item
    }

  }
);

export const fetchVendors = createAsyncThunk(
  'Vendors/fetchVendors',
  async (data) => {
    try {

      console.log("data fetchVendors:", data)


      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor?org_id=${data.org_id}&location=${data.location}`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({'accessToken' : cookies.get('accessTokenAuth0')})
      })

      const realData = await test.json();
      console.log("after :", realData)

      return realData
    }
    catch (err) {
      return err.message
    }

  }
);

export const addNewVendor = createAsyncThunk('Vendors/addNewVendor', async (initialData) => {

  try {

    console.log("Check create ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor?org_id=${initialData.org_id}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    return err.message
  }

}
);





export const patchVendor = createAsyncThunk('Vendors/patchVendor', async (data) => {

  try {

    console.log("patch ; ", data, data._id)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

});
export const deleteVendor = createAsyncThunk('Vendors/deleteVendor', async (toDelete) => {


  try {

    console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

}
);







export const companySearch = createAsyncThunk(
  'Vendors/companySearch',
  async (param) => {
    try {
      console.log("Fetching w/ search params :", param, param.org_id)
      //  const encodedValue = encodeURIComponent(someVariable);
      //fetch(`http://example.com/foo?bar=${encodedValue}`);


      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor?org_id=${param.org_id}&search=${param.data}&location=${param.location_id}`)


      // fetch('http://example.com?' + new URLSearchParams(param))


      const realData = await test.json();



      console.log("Result from fetching vendors : " ,  realData)
      /*
      console.log("formatting data :", realData.docs)
      const formattedResponse = realData.docs.reduce((formattedResponse, item) => {
        const group = (formattedResponse[item.vendor.company] || []);
        group.push(item);
        formattedResponse[item.vendor.company ? item.vendor.company : 'N/A'] = group;
        return formattedResponse;
      }, {});

      console.log(formattedResponse);

      console.log("Returned data :", formattedResponse)*/
      return realData

    }
    catch (err) {
      console.log("Error message :", err.message)
      return err.message
    }

  }
);






















export const vendorSlice = createSlice({
  name: 'vendors',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_VENDORS_REQUESTED: (state, action) => {
      return state
    },

    FETCH_VENDORS_SUCCEDED: (state, action) => {
      return { vendorLoading: false, vendors: action.payload, error: '' }
    },

    FETCH_VENDORS_FAILED: (state, action) => {
      //   console.log("fetch vendors failed reached")
      return { vendorLoading: false, vendors: [], error: action.payload }
    },

  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      //Fetching vendors
      .addCase(fetchVendors.pending, (state) => {
        state.vendorLoading = true;
        state.vendorsError = ''
      })

      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.vendorLoading = 'Done';
        state.vendors = action.payload.docs
        state.vendorsError = ''
        //  console.log("data vendors:", state.vendors, action)
      })
      .addCase(setVendorBeingCreatedCountry.fulfilled, (state, action) => {
        state.vendorBeingCreated.country = action.payload.payload
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.vendorLoading = false;
        state.vendors = []
        console.log("Error message ;", action.error)
        state.vendorsError = action.error.message
      })

      .addCase(setVendorBeingCreatedCompany.fulfilled, (state, action) => {
        state.vendorBeingCreated.company = action.payload.payload
      })

      .addCase(setVendorBeingCreatedAddress.fulfilled, (state, action) => {
        state.vendorBeingCreated.address = action.payload.payload
      })

      .addCase(setVendorBeingCreatedState.fulfilled, (state, action) => {
        state.vendorBeingCreated.state = action.payload.payload
      })

      .addCase(setVendorBeingCreatedCity.fulfilled, (state, action) => {
        state.vendorBeingCreated.city = action.payload.payload
      })
      .addCase(setVendorBeingCreatedZip.fulfilled, (state, action) => {
        state.vendorBeingCreated.zip = action.payload.payload
      })
      .addCase(setVendorBeingCreatedPhoneNumber.fulfilled, (state, action) => {
        state.vendorBeingCreated.phoneNumber = action.payload.payload
      })

      .addCase(setVendorBeingCreatedEmail.fulfilled, (state, action) => {
        state.vendorBeingCreated.email = action.payload.payload
      })
      .addCase(setVendorBeingCreatedContactEmail.fulfilled, (state, action) => {
        state.vendorBeingCreated.contactEmail = action.payload.payload
      })

      .addCase(setVendorBeingCreatedContactWebsite.fulfilled, (state, action) => {
        state.vendorBeingCreated.website = action.payload.payload
      })
      .addCase(setVendorBeingCreatedInstructions.fulfilled, (state, action) => {
        state.vendorBeingCreated.instructions = action.payload.payload
      })
      .addCase(setVendorBeingCreatedUsername.fulfilled, (state, action) => {
        state.vendorBeingCreated.username = action.payload.payload
      })
      .addCase(setVendorBeingCreatedPassword.fulfilled, (state, action) => {
        state.vendorBeingCreated.password = action.payload.payload
      })

      .addCase(setVendorBeingCreatedPaymentTerms.fulfilled, (state, action) => {
        console.log("Setting payment terms : ", action)
        state.vendorBeingCreated.paymentTerms = action.payload.payload
      })
      .addCase(setVendorBeingCreatedMinOrder.fulfilled, (state, action) => {
        state.vendorBeingCreated.minOrder = action.payload.payload
      })
      .addCase(setVendorBeingCreatedContactPhone.fulfilled, (state, action) => {
        state.vendorBeingCreated.contactPhoneNumber = action.payload.payload
      })

      .addCase(setVendorBeingCreatedContactPerson.fulfilled, (state, action) => {
        state.vendorBeingCreated.contactPerson = action.payload.payload
      })

      .addCase(setVendorBeingCreated.fulfilled, (state, action) => {
        console.log("CHECKING :", action)
        state.vendorBeingCreated = action.payload.payload
      })


      .addCase(companySearch.pending, (state, action) => {

        console.log("Pending :" , action)
        state.searchDone = false;
        state.searchResult = []
      })


      .addCase(companySearch.fulfilled, (state, action) => {
        state.searchDone = true;
        console.log("vendor search result :", action, (typeof action.payload.docs), action.payload.docs)
        //here group by vendor for displaying
        //groups is a dictionary type thing
        const groups = action.payload.docs.reduce((groups, vendor) => {
          if (groups[vendor.company]) {
            let group = groups[vendor.company]
            group.push(vendor);
          }
          else {
            let group = [vendor]
            groups[vendor.company] = group;
          }

          return groups;
        }, {});

        console.log("groups :", groups)
        // state.itemsError = action.error.message
        state.searchResult = groups
      })


      .addCase(clearVendorBeingMade.fulfilled, (state, action) => {
        state.vendorBeingCreated.company = ""
        state.vendorBeingCreated.address = ""
        state.vendorBeingCreated.state = ""
        state.vendorBeingCreated.zip = ""
        state.vendorBeingCreated.phoneNumber = ""
        state.vendorBeingCreated.email = ""
        state.vendorBeingCreated.contactEmail = ""
        state.vendorBeingCreated.website = ""
        state.vendorBeingCreated.instructions = ""
        state.vendorBeingCreated.username = ""
        state.vendorBeingCreated.password = ""
        state.vendorBeingCreated.paymentTerms = ""
        state.vendorBeingCreated.minOrder = ""
        state.vendorBeingCreated.contactPhoneNumber = ""
        state.vendorBeingCreated.contactPerson = ""
        state.vendorBeingCreated.city = ""
      })


      //Selecting vendors
      .addCase(setVendor.fulfilled, (state, action) => {
        console.log("payload check: ", action)
        state.loadedVendor = action.payload.payload
        state.vendorLoading = 'Done';
        state.vendorsError = ''
      })
      //Selecting vendors
      .addCase(setVendor.rejected, (state, action) => {
        console.log("payload check: ", action)
        state.loadedVendor = action.payload.payload
        state.vendorLoading = 'Done';
        state.vendorsError = ''
      })

      //Creating Vendors
      .addCase(addNewVendor.fulfilled, (state, action) => {
        state.vendorLoading = false;
        //  state.vendors.push(action.payload) TODO ideally vendors are updated here, but couldnt get it working so going to call GET again
        state.vendorsError = ''
      })

      //Creating Vendors
      .addCase(SetCatagory.fulfilled, (state, action) => {
        console.log("Setting rgCatagories :", action)
        state.rgCatagories = action.payload.payload;
      })


      .addCase(RemoveCatagory.fulfilled, (state, action) => {
        console.log("remove catagories: ", action)

        let indToRemove = state.rgCatagories.findIndex(i => i.value === action.payload.payload.value);
        state.rgCatagories.splice(indToRemove, 1);
      })


      .addCase(AddCatagory.fulfilled, (state, action) => {
        console.log("add catagories: ", action)
        state.rgCatagories.push(action.payload.payload)
      })

      //Deleting Vendors
      .addCase(deleteVendor.rejected, (state, action) => {
        state.vendorLoading = false;
        state.vendorsError = action.error.message
      })
      .addCase(deleteVendor.pending, (state, action) => {
        state.vendorLoading = true;
        state.vendorsError = ''
      })
      .addCase(deleteVendor.fulfilled, (state, action) => {
        state.vendorLoading = false; //by setting false here, the component will call fetch to update data
        state.vendorsError = ''
      })
  },
});


export const loadVendors = () => {
  return dispatch(fetchVendors())
}

/*W
export const setLoadedVendors = (vendor) => {
  return dispatch(SET_SELECTED_ITEM(vendor))
}
*/


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export const selectVendors = (state) => state.vendor.vendors;
export const getVendorLoadStatus = (state) => state.vendor.vendorLoading;
export const getVendorsError = (state) => state.vendor.vendorsError;
export const getLoadedVendor = (state) => state.vendor.loadedVendor;
export const getCatagories = (state) => state.vendor.rgCatagories;
export const getVendorBeingMade = (state) => state.vendor.vendorBeingCreated;


export default vendorSlice.reducer;
export const vendorActions = vendorSlice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.vendors =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/