import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import styles from "./Vendor.module.css";
import { fetchReportingGroups, getLoadedRG, getreportingGroupsError, getRGLoadStatus, selectReportingGroups } from '../ReportingGroups/reportingGroupSlice';
import { AddCatagory, addNewVendor, clearVendorBeingMade, deleteVendor, fetchVendors, getCatagories, getLoadedVendor, getVendorBeingMade, getVendorLoadStatus, getVendorsError, patchVendor, RemoveCatagory, selectVendors, SetCatagory, setVendor, setVendorBeingCreated, setVendorBeingCreatedAddress, setVendorBeingCreatedCity, setVendorBeingCreatedCompany, setVendorBeingCreatedContactEmail, setVendorBeingCreatedContactPerson, setVendorBeingCreatedContactPhone, setVendorBeingCreatedContactWebsite, setVendorBeingCreatedEmail, setVendorBeingCreatedInstructions, setVendorBeingCreatedMinOrder, setVendorBeingCreatedPaymentTerms, setVendorBeingCreatedPhoneNumber, setVendorBeingCreatedState, setVendorBeingCreatedZip, setVendorBeingCreatedCountry, setVendorBeingCreatedUsername, setVendorBeingCreatedPassword } from '../vendors/vendorSlice';

const InputField = React.memo(({ label, id, value, onChange, type = "text" }) => {
    const inputRef = useRef(null);

    const handleChange = (event) => {
        const { selectionStart, selectionEnd } = event.target;
        onChange(event);
        setTimeout(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    };

    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <input
                type={type}
                id={id}
                ref={inputRef}
                value={value || ''}
                onChange={handleChange}
                className={styles.defaultInp}
            />
        </label>
    );
});

function VendorSetup(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [toggleShowCreate, setToggle1] = useState(false);
    const [toggleShowEdit, setToggle2] = useState(false);
    const [toggle, setToggle] = useState(true);

    const vendorBeingCreated = useSelector(getVendorBeingMade);
    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }
    const vendors = useSelector(selectVendors);
    const vendorLoading = useSelector(getVendorLoadStatus);
    const vendorsError = useSelector(getVendorsError);
    const loadedVendor = useSelector(getLoadedVendor);
    const rgCatagories = useSelector(getCatagories);

    const selectVendorInputRef = useRef();

    const reportingGroups = useSelector(selectReportingGroups);
    const rgLoading = useSelector(getRGLoadStatus);
    const rgErrors = useSelector(getreportingGroupsError);
    const loadedRG = useSelector(getLoadedRG);

    let orderDeadline = [];

    useEffect(() => {
        if (vendorLoading != 'Done') {
            dispatch(fetchVendors({ org_id: user.org_id, location: user?.viewingLocation }));
        }
    }, [vendorLoading, vendorBeingCreated, dispatch]);

    useEffect(() => {
        if (rgLoading != 'Done') {
            dispatch(fetchReportingGroups({ org_id: user.org_id, location: user?.viewingLocation }));
        }
    }, [rgLoading, dispatch]);

    function hidePopup() {
        document.getElementById("popupMessage").style.visibility = "hidden";
        document.getElementById("popupBackground").style.visibility = "hidden";
    }

    function settingToggle1(value) {
        if (toggleShowCreate == 'create') {
            setToggle1('');
            document.getElementById("titleText").textContent = "Creating Vendors";
        } else {
            if (toggleShowEdit == 'edit') {
                clearDataFromFields();
                clearSelects();
                dispatch(clearVendorBeingMade());
            }
            document.getElementById("titleText").textContent = "Editing Vendors";
            setToggle1(value);
        }
        setToggle2('');

        if (value == 'create') {
            document.getElementById("titleText").textContent = "Creating Vendors";
        }
    }

    function pageClickEvent(e) {
        // This function is kept as-is, even though it's currently empty
    }

    function settingToggle2(value) {
        if (toggleShowEdit == 'edit') {
            setToggle2('');
        } else {
            setToggle2(value);
        }
        setToggle1('');
    }

    function reportingGroupSelectChange(catagory, option) {
        if (option == 'add') {
            dispatch(AddCatagory(catagory));
        } else {
            dispatch(RemoveCatagory(catagory));
        }
    }

    function toggleDay(day) {
        if (!orderDeadline.includes(day)) {
            orderDeadline = [...orderDeadline, day];
        } else {
            orderDeadline = orderDeadline.filter(d => d !== day);
        }
        dispatch(fetchVendors({ org_id: user.org_id, location: user?.viewingLocation }));
    }

    function vendorSelectChange(evt) {
        if (toggleShowEdit != 'edit') {
            settingToggle2('edit');
        }
        clearSelects();
        dispatch(setVendor(evt));
        dispatch(setVendorBeingCreated(evt));
        dispatch(SetCatagory(evt.reportingGroups ? evt.reportingGroups : []));
        orderDeadline = evt.orderDeadline ? evt.orderDeadline : [];
        document.getElementById("titleText").textContent = "Existing Vendors";
        dispatch(fetchVendors({ org_id: user.org_id, location: user?.viewingLocation }));
    }

    function renderNames() {
        let names = rgCatagories.map(a => a.value);
        return names.join(', ');
    }

    function onDeleteClicked(item) {
        dispatch(deleteVendor(item)).then(() => {
            clearDataFromFields();
        });
    }

    function onCancelClicked() {
        orderDeadline = [];
        clearDataFromFields();
        clearSelects();
        dispatch(clearVendorBeingMade());
        setToggle2(false);
        setToggle1(false);
    }

    function clearSelects() {
        let blankEntry = {};
        dispatch(SetCatagory([]));
        dispatch(clearVendorBeingMade());
        dispatch(setVendor(blankEntry));
    }

    function onSaveClicked() {
        let newVendor = {...vendorBeingCreated};
        delete newVendor._id;
        newVendor.reportingGroups = rgCatagories;
        newVendor.orderDeadline = orderDeadline;
        newVendor.org_id = user.org_id;
        newVendor.viewingLocation = user.viewingLocation;

        if (toggleShowEdit == 'edit') {
            newVendor._id = loadedVendor._id;
            dispatch(patchVendor(newVendor));
        } else {
            if (vendors?.some(e => e.company === document.getElementById('company').value.toLowerCase())) {
                document.getElementById("popupBackground").style.visibility = "visible";
                document.getElementById("popupMessage").style.visibility = 'visible';
            } else {
                dispatch(addNewVendor(newVendor));
            }
        }

        dispatch(fetchVendors({ org_id: user.org_id, location: user?.viewingLocation }));
        dispatch(clearVendorBeingMade());
        orderDeadline = [];
    }

    function clearDataFromFields() {
        let blankEntry = {};
        orderDeadline = [];
        dispatch(SetCatagory([]));
        dispatch(clearVendorBeingMade());
        dispatch(setVendor(blankEntry));
    }

    return (
        <div className={styles.pageWrapper} onClick={(e) => { pageClickEvent(e) }}>
            <div id="popupBackground" onClick={hidePopup} style={{visibility:'hidden'}} className={styles.popupBackground}></div>
            <div id="popupMessage" className={styles.popupMessage} style={{visibility:'hidden'}} onClick={hidePopup}>
                <span>A vendor with this company name already exists, please use a different one.</span>
            </div>

            <div className={styles.userForm}>
                <div className={styles.userFormContent}>
                    <h3 id="titleText" className={styles.existingUser} style={{marginTop:'3%', textAlign:'left' }}>Vendors</h3>
                    <div className={styles.selectandbuttonwrapper}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="vendorSelect"
                                ref={selectVendorInputRef}
                                value={loadedVendor}
                                className={styles.vendorSelect}
                                onChange={vendorSelectChange}
                                options={vendors}
                                getOptionLabel={(option) => option.company}
                                getOptionValue={(option) => option.company}
                            />
                        </div>
                        <div className={styles.createVendorWrapper}>
                            <button onClick={() => settingToggle1("create")} className={styles.formBtn3}>
                                Create Vendor
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {(toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <>
                    <div className={styles.vendorInfoSection}>
                        <h4 className={styles.contactInfo}>Company Info</h4>
                        <div className={styles.flexBox}>
                            <div className={styles.leftCol}>
                                <InputField
                                    label="Company Name"
                                    id="company"
                                    value={vendorBeingCreated.company}
                                    onChange={(evt) => dispatch(setVendorBeingCreatedCompany(evt.target.value))}
                                />
                                <InputField
                                    label="Address"
                                    id="address"
                                    value={vendorBeingCreated?.address}
                                    onChange={(evt) => dispatch(setVendorBeingCreatedAddress(evt.target.value))}
                                />
                                <div className={styles.threeInputRow}>
                                    <InputField
                                        label="Country"
                                        id="country"
                                        value={vendorBeingCreated.country}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedCountry(evt.target.value))}
                                    />
                                    <InputField
                                        label="State"
                                        id="state"
                                        value={vendorBeingCreated.state}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedState(evt.target.value))}
                                    />
                                    <InputField
                                        label="City"
                                        id="city"
                                        value={vendorBeingCreated.city}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedCity(evt.target.value))}
                                    />
                                </div>
                                <div className={styles.twoInputRow}>
                                    <InputField
                                        label="Phone Number"
                                        id="phoneNumber"
                                        value={vendorBeingCreated.phoneNumber}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedPhoneNumber(evt.target.value))}
                                    />
                                    <InputField
                                        label="Email Address"
                                        id="email"
                                        value={vendorBeingCreated.email}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedEmail(evt.target.value))}
                                    />
                                </div>
                            </div>
                        </div>

                        <h4 className={styles.contactInfo}>Contact Person</h4>
                        <div className={styles.flexBox}>
                            <div className={styles.leftCol}>
                                <div className={styles.threeInputRow}>
                                    <InputField
                                        label="Full Name"
                                        id="contactPerson"
                                        value={vendorBeingCreated.contactPerson}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedContactPerson(evt.target.value))}
                                    />
                                    <InputField
                                        label="Phone Number"
                                        id="contactPhoneNumber"
                                        value={vendorBeingCreated.contactPhoneNumber}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedContactPhone(evt.target.value))}
                                    />
                                    <InputField
                                        label="Contact Email"
                                        id="contactEmail"
                                        value={vendorBeingCreated?.contactEmail}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedContactEmail(evt.target.value))}
                                    />
                                </div>
                                <div className={styles.threeInputRow}>
                                    <InputField
                                        label="Website"
                                        id="website"
                                        value={vendorBeingCreated.website}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedContactWebsite(evt.target.value))}
                                    />
                                    <InputField
                                        label="Username"
                                        id="username"
                                        value={vendorBeingCreated.username}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedUsername(evt.target.value))}
                                    />
                                    <InputField
                                        label="Password"
                                        id="password"
                                        type="password"
                                        value={vendorBeingCreated.password}
                                        onChange={(evt) => dispatch(setVendorBeingCreatedPassword(evt.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.blackLineDiv}></div>

                    <div className={styles.bottomSection}>
                        <div className={styles.leftBottomSection}>
                            <InputField
                                label="Min Order"
                                id="minOrder"
                                value={vendorBeingCreated.minOrder}
                                onChange={(evt) => dispatch(setVendorBeingCreatedMinOrder(evt.target.value))}
                            />
                            {/* ... */}
                            <div className={styles.paymentTerms}>
                                <h4 className={styles.inpLabel}>Payment Terms</h4>
                                <div>
                                    <button 
                                        onClick={() => dispatch(setVendorBeingCreatedPaymentTerms('COD'))} 
                                        className={`${styles.formBtn1} ${vendorBeingCreated.paymentTerms === 'COD' ? styles.selected : ''}`}
                                    >
                                        COD
                                    </button>
                                    <button 
                                        onClick={() => dispatch(setVendorBeingCreatedPaymentTerms('EFT'))} 
                                        className={`${styles.formBtn1} ${vendorBeingCreated.paymentTerms === 'EFT' ? styles.selected : ''}`}
                                    >
                                        EFT
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightBottomSection}>
                            <div className={styles.field}>
                                <h4 className={styles.inpLabel}>Instructions</h4>
                                <textarea
                                    id="instructions"
                                    value={vendorBeingCreated.instructions}
                                    onChange={(evt) => dispatch(setVendorBeingCreatedInstructions(evt.target.value))}
                                    className={styles.instructionsInput}
                                />
                            </div>
                        </div>

                        <div className={styles.formBtnDiv}>
                            <button onClick={() => onDeleteClicked(loadedVendor)} className={styles.formBtn}>Delete</button>
                            <button onClick={onCancelClicked} style={{backgroundColor:'black', color:'white'}} className={styles.formBtn}>Cancel</button>
                            <button
                                onClick={onSaveClicked}
                                className={styles.formBtn2}
                            >
                                {toggleShowEdit == 'edit' ? 'Save' : 'Create'}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default VendorSetup;