import axios from "axios";

const API_URL = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/login/`;
//TODO

const register = (username, password, org_id) => {
  return axios.post(API_URL + "signup", {
    displayName: username,
    phoneNumber: '',
    address: '',
    username: username,
    email: username,
    password: password,
    availability: [],
    jobPosition: '',
    org_id: org_id,

  });
};

const login = (username, password) => {

  console.log("Url check login : ", API_URL)
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {

      console.log("recieved response :", response.data, response)

      if (!response.data){
        localStorage.setItem("user", JSON.stringify("ERROR login failed"));

      }
      else{
        localStorage.setItem("user", JSON.stringify(response.data));

      }


      return response.data;
    });
};

const logout = () => {
  console.log("logging out user ")
  localStorage.removeItem("user");
  //window.location.reload();

};

export default {
  register,
  login,
  logout,
};