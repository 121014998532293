import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchReportingGroups } from './reportingGroupsApi';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import fetch from "node-fetch"


const FETCH_REPORTING_GROUPS_REQUESTED = 'FETCH_REPORTING_GROUPS_REQUESTED'
const FETCH_REPORTING_GROUPS_SUCCEDED = 'FETCH_REPORTING_GROUPS_SUCCEDED'
const FETCH_REPORTING_GROUPS_FAILED = 'FETCH_REPORTING_GROUPS_FAILED'
const SET_LOADED_REPORTING_GROUP = 'SET_LOADED_REPORTING_GROUP'
const SET_Page_View = 'SET_Page_View'
const SET_CATAGORY = 'SET_CATAGORY'
const CLEAR_CATAGORY = "CLEAR_CATAGORY"
const SET_TITLE = "SET_TITLE"



const fetchReportingGroupsRequested = () => {
  return {
    type: FETCH_REPORTING_GROUPS_REQUESTED
  }
}

const fetchReportingGroupsSuccess = (reportingGroups) => {
  return {
    type: FETCH_REPORTING_GROUPS_SUCCEDED
  }
}

const fetchReportingGroupsFailed = (error) => {
  return {
    type: FETCH_REPORTING_GROUPS_FAILED,
    payload: error
  }
}


export const setPageView = createAsyncThunk(
  'ReportingGroups/pageView',
  async (reportingGroup) => {

    console.log("Check setPageView ; ", reportingGroup)

    return {
      type: SET_Page_View,
      payload: reportingGroup
    }

  }
);


export const setCatagoryBeingMade = createAsyncThunk(
  'ReportingGroups/setCatagoryBeingMade',
  async (reportingGroup) => {

    return {
      type: SET_CATAGORY,
      payload: reportingGroup
    }

  }
);


export const setTitle = createAsyncThunk(
  'ReportingGroups/setTitle',
  async (reportingGroup) => {

    return {
      type: SET_CATAGORY,
      payload: reportingGroup
    }

  }
);


export const clearCatagoryBeingMade = createAsyncThunk(
  'ReportingGroups/clearCatagoryBeingMade',
  async (reportingGroup) => {

    return {
      type: CLEAR_CATAGORY,
      payload: reportingGroup
    }

  }
);

const initialState = {
  loadingRG: false,
  reportingGroups: [],
  reportingGroupsError: null,
  loadedRGList: [],
  rgCurrentView: 'bucket',
  loadingCatagories: false,
  catagoryBeingMade: {}
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchReportingGroups = createAsyncThunk(
  'ReportingGroups/fetchReportingGroups',
  async (data) => {
    try {

      console.log("fetch reporting groups : ", data, process.env.REACT_APP_EUSOCIAL_PORT)
      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroups?org_id=${data.org_id}&location=${data.location}`)

      const realData = await test.json();

      console.log("data Rg :", realData)
      return realData

    }
    catch (err) {
      console.log("fetch rg failed :", err.message)
      return err.message
    }

  }
);

export const fetchCatagories = createAsyncThunk(
  'ReportingGroups/fetchCatagories',
  async (data) => {
    try {

      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroupCatagories?org_id=${data.org_id}&location=${data.location}`)
      const realData = await test.json();

      console.log("Catagory data :", realData)
      return realData

    }
    catch (err) {
      console.log("fetch rg failed :", err.message)
      return err.message
    }

  }
);


export const addNewRG = createAsyncThunk('ReportingGroups/addNewRG', async (initialData) => {

  try {

    console.log("Posting initial data :", initialData)
    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroups`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    return err.message
  }

}
);

export const addSubcatagoryToRG = createAsyncThunk('ReportingGroups/addSubcatagoryToRG', async (initialData) => {

  console.log("Create catagory ; ", initialData)
  try {

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroupCatagories`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    //so here i add, need to search/get ID of newly added catagory and add it to list on 
    //reporting Group... probably should do it from this function, or combine in backend and return
    //here09


    console.log("returned from post :", realData)

    return realData
  }
  catch (err) {
    return err.message
  }

}
);

export const patchRG = createAsyncThunk('ReportingGroups/patchRG', async (data) => {

  try {

    console.log("patch ; ", data, data._id, JSON.stringify(data))

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroups/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

});

export const deleteRG = createAsyncThunk('ReportingGroups/deleteRG', async (toDelete) => {
  try {

    console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reportingGroups/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

}
);

export const setRG = createAsyncThunk(
  'ReportingGroups/setRG',
  async (item) => {

    return {
      type: SET_LOADED_REPORTING_GROUP,
      payload: item
    }

  }
);

export const reportingGroupSlice = createSlice({
  name: 'reportingGroups',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_REPORTING_GROUPS_REQUESTED: (state, action) => {
      return state
    },

    FETCH_REPORTING_GROUPS_SUCCEDED: (state, action) => {
      return { loadingRG: false, reportingGroups: action.payload, error: '' }
    },

    FETCH_REPORTING_GROUPS_FAILED: (state, action) => {
      //  console.log("fetch reportingGroups failed reached")
      return { loadingRG: false, reportingGroups: [], error: action.payload }
    },
    /*
        SET_LOADED_REPORTING_GROUP: (state, action) => {
          state.loadedRG = action.payload
          console.log("Set loadedRG :" , state.loadedRG)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      //Fetching reportingGroups
      .addCase(fetchReportingGroups.pending, (state) => {
        state.loadingRG = true;
        state.reportingGroupsError = ''

      })

      .addCase(fetchCatagories.rejected, (state, action) => {
        console.log("error? : ", action)
        state.reportingGroupsError = ''
        //    console.log("data :", state.reportingGroups, action)
      })

      .addCase(fetchCatagories.fulfilled, (state, action) => {
        state.loadingCatagories = 'Done';

        console.log("Returned fetchCatagories:", action.payload)
        state.catagories = action.payload.docs
        state.reportingGroupsError = ''
        //    console.log("data :", state.reportingGroups, action)
      })

      .addCase(fetchReportingGroups.fulfilled, (state, action) => {
        state.loadingRG = 'Done';


        state.reportingGroups = action.payload.docs
        state.reportingGroupsError = ''
        //    console.log("data :", state.reportingGroups, action)
      })
      .addCase(fetchReportingGroups.rejected, (state, action) => {
        state.loadingRG = false;
        state.reportingGroups = []
        console.log("Error message ;", action.error)
        state.reportingGroupsError = action.error.message
      })

      .addCase(clearCatagoryBeingMade.fulfilled, (state, action) => {
        state.catagoryBeingMade.displayName = '';

      })

      .addCase(setTitle.fulfilled, (state, action) => {
        console.log("Setting title :", action)
        state.catagoryBeingMade.displayName = action.payload.payload;
      })


      //Fetching reportingGroups
      .addCase(setPageView.pending, (state) => {
        state.loadingRG = true;
        state.reportingGroupsError = ''
        console.log("Setting view pending:", state)

      })
      .addCase(setPageView.fulfilled, (state, action) => {
        console.log("Setting view :", action)
        state.loadingRG = 'Done';
        state.rgCurrentView = action.payload.payload


        state.reportingGroupsError = -''
        //    console.log("data :", state.reportingGroups, action)
      })
      .addCase(setPageView.rejected, (state, action) => {
        console.log("Setting view rejected:", action)

        state.loadingRG = false;
        state.rgCurrentView = action.payload
        state.reportingGroupsError = action.error.message
      })



      //Selecting reportingGroups
      .addCase(setRG.rejected, (state, action) => {

        console.log("setting rg :", action)
        console.log("Error message ;", action.payload)

        //TODO here make a s

        state.loadedRGList = action.payload.payload

        state.loadingRG = 'Done';
        state.reportingGroupsError = ''
      })
      
      .addCase(setCatagoryBeingMade.fulfilled, (state, action) => {

        state.catagoryBeingMade.displayName = action.payload.payload.displayName
        state.loadingRG = 'Done';
      })
      //Selecting reportingGroups
      .addCase(setRG.fulfilled, (state, action) => {

        console.log("setting rg :", action)

        //TODO here make a s
        state.catagoryBeingMade.displayName = action.payload.payload.displayName
        state.loadedRGList = action.payload.payload
        state.loadingRG = 'Done';
        state.reportingGroupsError = ''
      })
      .addCase(patchRG.fulfilled, (state, action) => {
        state.loadingRG = false; //by setting false here, the component will call fetch to update data
        state.itemsError = ''
      })

      //Creating ReportingGroups
      .addCase(addNewRG.fulfilled, (state, action) => {
        console.log("checking :", action)
        state.loadingRG = false;
        //  state.reportingGroups.push(action.payload) TODO ideally reportingGroups are updated here, but couldnt get it working so going to call GET again
        state.reportingGroupsError = ''
      })

      //Creating subcatagory
      .addCase(addSubcatagoryToRG.fulfilled, (state, action) => {
        state.loadingRG = false;
        state.rgCurrentView = 'catagory'

        //  state.reportingGroups.push(action.payload) TODO ideally reportingGroups are updated here, but couldnt get it working so going to call GET again
        state.reportingGroupsError = ''
      })

      .addCase(addSubcatagoryToRG.rejected, (state, action) => {
        state.loadingRG = false;
        state.loadingCatagories = false;
        state.reportingGroupsError = action.error.message
        console.log("error :", action.error)
      })

      //Deleting ReportingGroups
      .addCase(deleteRG.rejected, (state, action) => {
        state.loadingRG = false;
        state.reportingGroupsError = action.error.message
      })
      .addCase(deleteRG.pending, (state, action) => {
        state.loadingRG = true;
        state.reportingGroupsError = ''
      })
      .addCase(deleteRG.fulfilled, (state, action) => {
        state.loadingRG = false; //by setting false here, the component will call fetch to update data
        state.reportingGroupsError = ''
      })
  },
});


export const loadReportingGroups = () => {
  return dispatch(fetchReportingGroups())
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectReportingGroups = (state) => state.reportingGroup.reportingGroups;
export const getRGLoadStatus = (state) => state.reportingGroup.loadingRG;
export const getCatagoryLoadStatus = (state) => state.reportingGroup.loadingCatagories;


export const getreportingGroupsError = (state) => state.reportingGroup.reportingGroupsError;
export const getLoadedRG = (state) => state.reportingGroup.loadedRGList;
export const getCurrentView = (state) => state.reportingGroup.rgCurrentView;
export const getCatagoryBeingMade = (state) => state.reportingGroup.catagoryBeingMade;

//export const get = (state) => state.reportingGroup.rgCurrentView;


export default reportingGroupSlice.reducer;
export const reportingGroupActions = reportingGroupSlice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.reportingGroups =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/