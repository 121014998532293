import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { login, register } from "../store/actions/auth";



const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};
// Replace the existing user retrieval code with this safer version
const getUserFromStorage = () => {
    try {
      const userStr = localStorage.getItem('user');
      if (!userStr) return '';
  
      const parsedData = JSON.parse(userStr);
  
  
      console.log("User in retrieval")
  
      // Check if login failed
      if (parsedData === 'Login failed') {
        return parsedData;
      }
  
      // Return first user if it's an array, otherwise return the parsed data
      return Array.isArray(parsedData) ? parsedData[0] : parsedData;
    } catch (error) {
      console.error('Error parsing user data:', error);
      localStorage.removeItem('user'); // Clean up invalid data
      return '';
    }
  };
const LoginForm = (props) => {
    let history = useHistory();
    console.log("Prop check :  ", props)

    const [showRegister, setShowRegister] = useState(false)

    const form = useRef();
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    // const { message } = useSelector(state => state.message);

    const dispatch = useDispatch();


   // const user = JSON.parse(localStorage.getItem('user'));
    const user = getUserFromStorage();


    console.log("user before logging in : ", user)


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (!showRegister) {
            //If logging in
            setLoading(true);

            dispatch(login(document.getElementById("username").value, document.getElementById("password").value))
                .then((res) => {
                    window.location.reload();
                })
                .catch(() => {
                    setLoading(false);
                });
        }
        else {
            //If registering in
            dispatch(register(document.getElementById("username").value, document.getElementById("password").value, document.getElementById("orgid").value))
                .then((res) => {
                    window.location.reload();
                })
                .catch(() => {
                    setLoading(false);
                });
        }


    };

    return (
        <div class="container">
            <div class="screen" >
                <div class="screen__content">

                    {!showRegister && <div>
                        <div class="login__field">
                            <i class="login__icon fas fa-user"></i>
                            <input type="text" id="username" class="login__input" placeholder="User name / Email" />
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            <input type="password" id="password" class="login__input" placeholder="Password" />
                        </div>
                    </div>}

                    {showRegister && (
                        <div>
                            <div class="login__field">
                                <label for="username" onClick={() => document.getElementById('username').focus()}>
                                    <i class="login__icon fas fa-user"></i>
                                    <span>User name / Email</span>
                                    <input type="text" id="username" class="login__input" placeholder="User name / Email" />
                                </label>
                            </div>
                            <div class="login__field">
                                <label for="password" onClick={() => document.getElementById('password').focus()}>
                                    <i class="login__icon fas fa-lock"></i>
                                    <span>Password</span>
                                    <input type="password" id="password" class="login__input" placeholder="Password" />
                                </label>
                            </div>
                            <div class="login__field">
                                <label for="orgid" onClick={() => document.getElementById('orgid').focus()}>
                                    <i class="login__icon fas fa-lock"></i>
                                    <span>Organization Id</span>
                                    <input type="text" id="orgid" class="login__input" placeholder="Organization Id" />
                                </label>
                            </div>
                        </div>
                    )}


                    {showRegister &&

                        <span style={{ marginRight: '6%', fontSize: '14px' }} onClick={() => setShowRegister(false)}>Back to Login</span >

                    }

                    {!showRegister && <span class="needToReg" style={{ marginRight: '6%', fontSize: '14px' }} onClick={() => setShowRegister(true)}>Need to register?</span>
                    }


                    <button class="button login__submit" onClick={(e) => handleLogin(e)}>


                        {showRegister && <span style={{ color: 'black' }}>Register</span>}
                        {!showRegister && <span style={{ color: 'black' }} >Login</span>}


                        <i class="button__icon fas fa-chevron-right"></i>
                    </button>



                </div>
                <div class="screen__background">
                    <span class="screen__background__shape screen__background__shape4"></span>
                    <span class="screen__background__shape screen__background__shape3"></span>
                    <span class="screen__background__shape screen__background__shape2"></span>
                    <span class="screen__background__shape screen__background__shape1"></span>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;