import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const SET_CURRENT_MONTH = 'SET_CURRENT_MONTH'
const SET_CURRENT_YEAR = 'SET_CURRENT_YEAR'
const SET_HELPER1 = 'SET_HELPER1'
const SET_HELPER2 = 'SET_HELPER2'
const SET_DAYS_IN_MONTH = "SET_DAYS_IN_MONTH"
const SET_DAYS_IN_LAST_MONTH = "SET_DAYS_IN_LAST_MONTH"
const SET_DAYS_IN_LAST_MONTH_TO_SHOW = "SET_DAYS_IN_LAST_MONTH_TO_SHOW"
const SET_LOAD = "SET_LOAD"
const SET_VIEW_DATE = "SET_VIEW_DATE'"
const SET_ACTIVE_CALENDAR_LIST = "SET_ACTIVE_CALENDAR_LIST"



let numRowsInCal = 6
let numDaysInWeek = 7
let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const setCurrentMonthVal = createAsyncThunk(
    'calendar/setCurrentMonthVal',
    async (calendar) => {
        return {
            type: SET_CURRENT_MONTH,
            payload: calendar
        }
    }
);

export const setCurrentYear = createAsyncThunk(
    'calendar/setCurrentYear',
    async (calendar) => {
        return {
            type: SET_CURRENT_YEAR,
            payload: calendar
        }
    }
);
export const setLoadStatus = createAsyncThunk(
    'calendar/setLoadStatus',
    async (calendar) => {
        return {
            type: SET_LOAD,
            payload: calendar
        }
    }
);

export const setCurrentHelperList1 = createAsyncThunk(
    'calendar/setCurrentHelperList1',
    async (calendar) => {
        return {
            type: SET_HELPER1,
            payload: calendar
        }
    }
);

export const setCurrentHelperList2 = createAsyncThunk(
    'calendar/setCurrentHelperList2',
    async (calendar) => {
        return {
            type: SET_HELPER2,
            payload: calendar
        }
    }
);

export const setNumdaysInThisMonth = createAsyncThunk(
    'calendar/setNumdaysInThisMonth',
    async (calendar) => {
        return {
            type: SET_DAYS_IN_MONTH,
            payload: calendar
        }
    }
);

export const setNumdaysInPreviousMonthToShow = createAsyncThunk(
    'Calendar/setNumdaysInPreviousMonthToShow',
    async (calendar) => {
        return {
            type: SET_DAYS_IN_LAST_MONTH_TO_SHOW,
            payload: calendar
        }
    }
);

export const setNumdaysPreviousMonth = createAsyncThunk(
    'Calendar/setNumdaysPreviousMonth',
    async (calendar) => {
        return {
            type: SET_DAYS_IN_LAST_MONTH,
            payload: calendar
        }
    }
);

export const setViewDate
    = createAsyncThunk(
        'Calendar/setViewDate',
        async (calendar) => {
            return {
                type: SET_VIEW_DATE,
                payload: calendar
            }
        }
    );




var monthCodeMap = {
    0: 0,
    1: 3,
    2: 3,
    3: 6,
    4: 1,
    5: 4,
    6: 6,
    7: 2,
    8: 5,
    9: 0,
    10: 3,
    11: 5
}



const initialState = {
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    today: new Date().getDate(),
    startDateReference: new Date().getDate(),
    selectYear: new Date().getFullYear(),
    selectMonth: new Date().getMonth(),
    NumdaysInThisMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate(),
    NumdaysInPreviousMonthToShow: ((((new Date().getFullYear() % 100 / 4) + (new Date().getFullYear() % 100)) % 7) + monthCodeMap[new Date().getMonth()] + 6 + new Date(new Date().setDate(1)).getDate() - (new Date().getFullYear() % 4 == 0 ? 0 : 1)) % 7,
    NumdaysInPreviousMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate(),
    htmlHelperList: Array(numRowsInCal).fill(0).map((x, i) => i),
    htmlHelperList2: Array(numDaysInWeek).fill(0).map((x, i) => i),
    firstDayThisMonth: '',
    calendarError: '',
    loading: true,
    viewDate: '',
    htmlListLoading: 'Done',
};


export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        SET_CURRENT_MONTH: (state, action) => {
            console.log("SETTING CURRENT MONTH REACHED : ", action)
            return state
        },
    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            .addCase(setCurrentMonthVal.pending, (state, action) => {
                // state.loading = true;
                state.loading = true
            })

            .addCase(setCurrentMonthVal.rejected, (state, action) => {
                console.log("error: ", action,)
                state.currentMonth = action.payload.payload
                state.loading = false

            })

            .addCase(setCurrentMonthVal.fulfilled, (state, action) => {
                console.log("Setting current month value : ", action)
                state.currentMonth = action.payload.payload
                //  state.loading = 'Done';
            })

            .addCase(setCurrentYear.fulfilled, (state, action) => {

                state.currentYear = action.payload.payload
            })

            .addCase(setLoadStatus.fulfilled, (state, action) => {
                console.log("Setting loading : ", action)

                state.loading = action.payload.payload
            })






            .addCase(setViewDate.fulfilled, (state, action) => {
                console.log("Setting viewDate : ", action)

                state.viewDate = action.payload.payload
            })

            .addCase(setCurrentHelperList1.fulfilled, (state, action) => {
                state.htmlHelperList = Array(action.payload.payload).fill(0).map((x, i) => i)
                state.htmlListLoading = 'Done';
            })
            .addCase(setCurrentHelperList2.fulfilled, (state, action) => {
                state.htmlHelperList2 = Array(action.payload.payload).fill(0).map((x, i) => i)
                state.htmlListLoading = 'Done';

            })


            .addCase(setCurrentHelperList1.pending, (state, action) => {
                state.htmlListLoading = true;
            })
            .addCase(setCurrentHelperList2.pending, (state, action) => {
                state.htmlListLoading = true;
            })


            .addCase(setNumdaysInThisMonth.fulfilled, (state, action) => {
                console.log("setNumdaysInThisMonth : ", action, "result : ", new Date(action.payload.payload.year, action.payload.payload.month, 0).getDate())
                state.NumdaysInThisMonth = new Date(action.payload.payload.year, action.payload.payload.month, 0).getDate()
            })

            .addCase(setNumdaysInPreviousMonthToShow.fulfilled, (state, action) => {
                console.log("setNumdaysInPreviousMonthToShow : ", action.payload.payload, " Result : ", (((new Date().getFullYear() % 100 / 4 + (new Date().getFullYear() % 100)) % 7) + monthCodeMap[action.payload.payload] + 6 + new Date(new Date(new Date().setMonth(action.payload.payload)).setDate(1)).getDate() - (new Date().getFullYear() % 4 == 0 ? 0 : 1)) % 7)

                state.NumdaysInPreviousMonthToShow = (((new Date().getFullYear() % 100 / 4 + (new Date().getFullYear() % 100)) % 7) + monthCodeMap[action.payload.payload] + 6 + new Date(new Date(new Date().setMonth(action.payload.payload)).setDate(1)).getDate() - (new Date().getFullYear() % 4 == 0 ? 0 : 1)) % 7
            })

            .addCase(setNumdaysPreviousMonth.fulfilled, (state, action) => {
                console.log("setNumdaysPreviousMonth : ", action.payload.payload, "result : ", new Date(action.payload.payload.year, action.payload.payload.month - 1, 0).getDate())

                state.NumdaysInPreviousMonth = new Date(action.payload.payload.year, action.payload.payload.month, 0).getDate()
            })




    },
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCalendar = (state) => state.calendar;
export const getCalendarError = (state) => state.calendar.calendarError;

export const getCurrentMonth = (state) => state.calendar.currentMonth;
export const getCurrentYear = (state) => state.calendar.currentYear;
export const getHelperList1 = (state) => state.calendar.htmlHelperList;
export const getHelperList2 = (state) => state.calendar.htmlHelperList2;
export const getViewDate = (state) => state.calendar.viewDate;

export const getNumdaysInPreviousMonthToShow = (state) => state.calendar.NumdaysInPreviousMonthToShow;
export const getNumdaysInPreviousMonth = (state) => state.calendar.NumdaysInPreviousMonth;
export const getNumdaysInThisMonth = (state) => state.calendar.NumdaysInThisMonth;
export const getLoadStatus = (state) => state.calendar.loading;
export const getHtmlListLoading = (state) => state.calendar.htmlListLoading;

export default calendarSlice.reducer;
export const calendarActions = calendarSlice.actions;
