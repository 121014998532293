import { React, useEffect, useRef, createRef } from 'react';

import { useAuth0 } from "@auth0/auth0-react";


function Callbackpage(props) {

    const { user, isAuthenticated, isLoading } = useAuth0();

    console.log("Logged in  :" , user, isAuthenticated)

    /*
    if(!isAuthenticated){
        window.location.href= "http://localhost:3000/login"
    }*/

    //setup api management token based on this user
    //and save as cookie?

    return (
        <>

            <div>

                callback page
            </div>

        </>
    );


}
//http://20.246.95.35:3000/callback?code=5PyCoEzfPwJNvUJJXVJRdDAuUGOLBs1UcT2VDmKaN2ctg&state=ZHdCaGNnQ1lXS3VhSFBSQ2pWU2xuRnl4bnlTbmpzOU1wbzVpWmNDY25xdQ%3D%3D




export default Callbackpage;
