import { combineReducers } from '@reduxjs/toolkit';
import formReducer from '../../Pages/formBuilder/formSlice';
import itemReducer from '../../Pages/forms/items/itemSlice';
import jobPositionReducer from '../../Pages/forms/JobPositions/jobPositionSlice';
import reportingGroupReducer from '../../Pages/forms/ReportingGroups/reportingGroupSlice';
import userReducer from '../../Pages/forms/users/UsersSlice';
import vendorReducer from '../../Pages/forms/vendors/vendorSlice';
import taskReducer from '../../Pages/taskBuilder/taskSlice';
import counterReducer from "./counter";
import loggedReducer from "./isLoggedIn";
import userDashboardReducer from '../../Pages/userDashboard/userDashboardSlice'
import userDashboardAdminReducer from '../../Pages/userDashboardAdmin/userDashboardAdminSlice'
import purchaseRequestReducer from '../../Pages/purchaseRequest/purchaseRequest.slice'
import pendingPurchaseReducer from '../../Pages/purchaseRequest/pendingPurchase.slice'
//import loginReducer from '../login.slice'
import { selectedPage } from "./MenuNavigation";
import locationReducer from "../../Pages/forms/Locations/locationSlice"
import auth from "./auth";
import notificationReducer from "../../Pages/forms/notifications/notification.slice"

import calendarReducer from '../../Components/calendar/CalendarSlice'
//list of state properties
//accessed like: state.item in components
const allReducers = combineReducers({
    counter: counterReducer,
    // isLogged: loggedReducer,
    selectedPage,
    item: itemReducer,
    vendor: vendorReducer,
    reportingGroup: reportingGroupReducer,
    jobPositions: jobPositionReducer,
    users: userReducer,
    tasks: taskReducer,
    forms: formReducer,
    userDashboard: userDashboardReducer,
    userDashboardAdmin: userDashboardAdminReducer,
    purchaseRequest: purchaseRequestReducer,
    pendingPurchase: pendingPurchaseReducer,
    calendar: calendarReducer,
    location: locationReducer,
    auth,
    notifications: notificationReducer

    // account: loginReducer
})

export default allReducers