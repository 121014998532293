import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchUsers } from './usersApi';

import axios from 'axios';
import fetch from "node-fetch"
import Cookies from 'universal-cookie';

const FETCH_USER_POSITIONS_REQUESTED = 'FETCH_USER_POSITIONS_REQUESTED'
const FETCH_USER_POSITIONS_SUCCEDED = 'FETCH_USER_POSITIONS_SUCCEDED'
const FETCH_USER_POSITIONS_FAILED = 'FETCH_USER_POSITIONS_FAILED'
const SET_LOADED_USER_POSITION = 'SET_LOADED_USER_POSITION'
const SET_AVAILABILITY = 'SET_AVAILABILITY'
const SET_FULLNAME = "SET_FULLNAME"
const SET_ADDRESS = "SET_ADDRESS"
const SET_PHONENUMBER = "SET_PHONENUMBER"
const SET_USERNAME = "SET_USERNAME"
const SET_EMAIL = "SET_EMAIL"
const SET_PASSWORD = "SET_PASSWORD"
const CLEAR_USER = "CLEAR_USER"
const SET_USER = "SET_USER"
const SET_LOCATION = "SET_LOCATION"


export const setAvailability = createAsyncThunk(
    'Users/setAvailability',
    async (arr) => {
        // Just return the array directly, don't wrap it in type/payload
        return arr;
    }
);

export const SetUser = createAsyncThunk(
    'Users/setUser',
    async (users) => {

        console.log("Setting user :", users.user_id)

        try {
            console.log("SetUser data :", users)

            return {
                type: SET_LOADED_USER_POSITION,
                payload: users
            }

        }
        catch (err) {
            return err.message
        }

    }

);


export const setuserBeingCreatedFullName = createAsyncThunk(
    'Users/setuserBeingCreatedFullName',
    async (users) => {
        try {
            console.log("SetUser data :", users)

            return {
                type: SET_FULLNAME,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);

export const setuserBeingCreatedPhoneNumber = createAsyncThunk(
    'Users/setuserBeingCreatedPhoneNumber',
    async (users) => {
        try {
            console.log("SetUser data :", users)

            return {
                type: SET_PHONENUMBER,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);




export const setLocationsSelected = createAsyncThunk(
    'Users/setLocationsSelected',
    async (users) => {
        try {

            return {
                type: SET_LOCATION,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);


export const setuserBeingCreatedAddress = createAsyncThunk(
    'Users/setuserBeingCreatedAddress',
    async (users) => {
        try {
            console.log("SetUser data :", users)

            return {
                type: SET_ADDRESS,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);

export const setuserBeingCreatedUsername = createAsyncThunk(
    'Users/setuserBeingCreatedUsername',
    async (users) => {
        try {
            console.log("SetUser data :", users)

            return {
                type: SET_USERNAME,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);
export const setuserBeingCreatedEmail = createAsyncThunk(
    'Users/setuserBeingCreatedEmail',
    async (email) => {
        try {
            return {
                type: SET_EMAIL,
                payload: email
            }
        }
        catch (err) {
            return err.message
        }
    }
);

export const setuserBeingCreatedPassword = createAsyncThunk(
    'Users/setuserBeingCreatedPassword',
    async (users) => {
        try {
            console.log("SetUser data :", users)

            return {
                type: SET_PASSWORD,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);



export const setUserBeingCreated = createAsyncThunk(
    'Users/setUserBeingCreated',
    async (users) => {
        try {
            console.log("clearing data of form")

            return {
                type: SET_USER,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);

export const clearUserBeingCreated = createAsyncThunk(
    'Users/clearUserBeingCreated',
    async (users) => {
        try {
            console.log("clearing data of form")

            return {
                type: CLEAR_USER,
                payload: users
            }
        }
        catch (err) {
            return err.message
        }

    }
);



const initialState = {
    loadingUser: false,
    users: [],
    usersError: null,
    loadedUser: {},
    availability: [],
    userBeingCreated: {}
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchUsers = createAsyncThunk(
    'Users/fetchUsers',
    async (data) => {
        try {
            const cookies = new Cookies();
            console.log("Getting users, org id", data)

            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/users?org_id=${data.org_id}&location_id=${data.location_id}`, {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
                // body: JSON.stringify({'accessToken' : cookies.get('accessTokenAuth0')})
            })


            const realData = await test.json();

            console.log("pulled users :", realData)

            const user = JSON.parse(localStorage.getItem('user'))[0];

            console.log("Add this to pulled users : ", user)

            return realData
        }
        catch (err) {
            return err.message
        }

    }
);

export const addNewUser = createAsyncThunk('Users/addNewUser', async (data) => {

    try {

        console.log("Check create ; ", data)
        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/users?org_id=${data.org_id}`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        return err.message
    }
}
);

export const patchUser = createAsyncThunk('Users/patchUser', async (data) => {

    try {

        console.log("patch ; ", data, data.newUser._id, data.loadedJobPosition)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/users/${data.newUser._id}?role=${data.loadedJobPosition}?org_id=${data.org_id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data.newUser),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

});

export const updateUser = createAsyncThunk('Users/updateUser', async (entry, newData) => {

    // const washingtonRef = doc(db, "cities", "DC");


    // Set the "capital" field of the city 'DC'
    await updateDoc(entry, newData);
})

export const deleteUser = createAsyncThunk('Users/deleteUser', async (toDelete) => {

    try {

        console.log("deleting ; ", toDelete)

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/users/${toDelete._id}`, {
            method: 'delete',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(toDelete),

        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

}
);

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        FETCH_USER_POSITIONS_REQUESTED: (state, action) => {
            return state
        },

        FETCH_USER_POSITIONS_SUCCEDED: (state, action) => {
            return { loading: false, users: action.payload, error: '' }
        },

        FETCH_USER_POSITIONS_FAILED: (state, action) => {
            //  console.log("fetch users failed reached")
            return { loading: false, users: [], error: action.payload }
        },


    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            //Fetching users
            .addCase(fetchUsers.pending, (state) => {
                state.loadingUser = false;
                state.usersError = ''

            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loadingUser = 'Done';
                console.log("fetch users :", action.payload)
                state.users = action.payload.docs
                state.usersError = ''
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.loadingUser = false;
                state.users = []
                console.log("Error message ;", action.error)
                state.usersError = action.error.message
            })

            .addCase(setuserBeingCreatedFullName.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedFullName : ", action)
                state.userBeingCreated = {
                    ...state.userBeingCreated,
                    displayName: action.payload.payload
                }
            })

            .addCase(setuserBeingCreatedPhoneNumber.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedPhoneNumber : ", action)
                state.userBeingCreated.phoneNumber = action.payload.payload
            })

            .addCase(setuserBeingCreatedEmail.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedEmail : ", action)
                state.userBeingCreated.email = action.payload.payload

            })

            .addCase(setuserBeingCreatedPassword.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedPassword : ", action)
                state.userBeingCreated.password = action.payload.payload

            })

            .addCase(setuserBeingCreatedAddress.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedAddress : ", action)
                state.userBeingCreated.address = action.payload.payload

            })

            .addCase(setuserBeingCreatedUsername.fulfilled, (state, action) => {
                console.log("setuserBeingCreatedUsername : ", action)
                state.userBeingCreated.username = action.payload.payload

            })


            .addCase(setLocationsSelected.fulfilled, (state, action) => {
                console.log("setLocationsSelected : ", action)
                state.userBeingCreated.locations = action.payload.payload

            })


            .addCase(clearUserBeingCreated.fulfilled, (state, action) => {
                console.log("Clearing user being created")
                state.userBeingCreated.displayName = ''
                state.userBeingCreated.phoneNumber = ''
                state.userBeingCreated.password = ''
                state.userBeingCreated.email = ''
                state.userBeingCreated.username = ''
                state.userBeingCreated.address = ''
                state.userBeingCreated.locations = []
            })


            .addCase(setUserBeingCreated.fulfilled, (state, action) => {
                console.log("setting user being created", action)
                state.userBeingCreated.displayName = action.payload.payload.displayName
                state.userBeingCreated.phoneNumber = action.payload.payload.phoneNumber
                state.userBeingCreated.password = action.payload.payload.password
                state.userBeingCreated.email = action.payload.payload.email
                state.userBeingCreated.username = action.payload.payload.username
                state.userBeingCreated.address = action.payload.payload.address
                state.userBeingCreated.locations = action.payload.payload.locations

            })




            //Selecting users
            .addCase(SetUser.pending, (state, action) => {
                console.log("pending loaded user :", action)
                state.usersError = ''
            })


            .addCase(setAvailability.fulfilled, (state, action) => {
                console.log("setAvailability :", action)

                state.availability = action.payload

            })

            .addCase(setAvailability.rejected, (state, action) => {
                console.log("setAvailability reject :", action)
                // state.availability = action.payload.payload
            })


            //Selecting users
            .addCase(SetUser.fulfilled, (state, action) => {
                console.log("fulfilled loaded user :", action)
                state.loadedUser = action.payload.payload

                //   state.loadingUser = 'Done';
                state.usersError = ''

                let userAvailability = []
                action.payload.payload?.user_metadata?.availability?.forEach(element => {
                    userAvailability.push(element)
                });

                state.availability = userAvailability

            })



            .addCase(SetUser.rejected, (state, action) => {
                console.log("error loaded user :", action)
            })


            //Updating users
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loadingUser = false;
                //  state.users.push(action.payload) TODO ideally users are updated here, but couldnt get it working so going to call GET again
                state.usersError = ''

            })

            //Creating Users
            .addCase(addNewUser.fulfilled, (state, action) => {
                state.loadingUser = false;
                //  state.users.push(action.payload) TODO ideally users are updated here, but couldnt get it working so going to call GET again
                state.usersError = ''
            })


            //Deleting Users
            .addCase(deleteUser.rejected, (state, action) => {
                state.loadingUser = false;
                state.usersError = action.error.message
                console.log("Error message ;", action.error)

            })
            .addCase(deleteUser.pending, (state, action) => {
                state.loadingUser = false;
                state.usersError = ''
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loadingUser = false; //by setting false here, the component will call fetch to update data
                state.usersError = ''
            })
    },
});

export const loadUsers = () => {
    return dispatch(fetchUsers())
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state) => state.users.users;
export const getUserLoadStatus = (state) => state.users.loadingUser;
export const getusersError = (state) => state.users.usersError;
export const getLoadedUser = (state) => state.users.loadedUser;
export const getAvailability = (state) => state.users.availability;

export const getUserBeingMade = (state) => state.users.userBeingCreated;

export default usersSlice.reducer;
export const usersActions = usersSlice.actions;
