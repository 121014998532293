import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styles from "./ReportingGroups.module.css";
import {
    setName,
    clearCatagoryBeingMade,
    getCatagoryBeingMade,
    addNewRG,
    addSubcatagoryToRG,
    deleteRG,
    fetchReportingGroups,
    fetchCatagories,
    getRGLoadStatus,
    getreportingGroupsError,
    getLoadedRG,
    patchRG,
    selectReportingGroups,
    setRG,
    setCatagoryBeingMade,
    setTitle,
    getCurrentView,
    setPageView
} from './reportingGroupSlice';

const InputField = React.memo(({ label, id, value, onChange, type = "text" }) => {
    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <input
                type={type}
                id={id}
                value={value || ''}
                onChange={onChange}
                className={styles.defaultInp}
            />
        </label>
    );
});

function ReportingGroup(props) {
    const dispatch = useDispatch();
    const [toggleShowCreate, setToggle1] = useState(false);
    const [toggleShowEdit, setToggle2] = useState(false);
    const [toggle, setToggle] = useState(false);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }

    const reportingGroups = useSelector(selectReportingGroups);
    const loading = useSelector(getRGLoadStatus);
    const reportingGroupsError = useSelector(getreportingGroupsError);
    const loadedRG = useSelector(getLoadedRG);
    const catagoryBeingMade = useSelector(getCatagoryBeingMade);
    const rgCurrentView = useSelector(getCurrentView);

    const selectReportingGroupInputRef = useRef();

    useEffect(() => {
        if (loading != 'Done') {
            dispatch(fetchReportingGroups({ org_id: user.org_id, location: user?.viewingLocation }));
        }
    }, [loading, dispatch, user.org_id, user.viewingLocation]);

    useEffect(() => {
        dispatch(fetchCatagories({ org_id: user.org_id, location: user?.viewingLocation }));
    }, [dispatch, user.org_id, user.viewingLocation]);

    const getPageWrapperClassName = useCallback(() => {
        const baseClass = styles.pageWrapper;
        const scrollClass = (toggleShowCreate === 'create' || toggleShowEdit === 'edit') ? styles.pageWrapperScroll : '';
        return `${baseClass} ${scrollClass}`.trim();
    }, [toggleShowCreate, toggleShowEdit]);

    const hidePopup = useCallback(() => {
        document.getElementById("popupMessage").style.visibility = "hidden";
        document.getElementById("popupBackground").style.visibility = "hidden";
    }, []);

    const subcategorySelectChange = useCallback((subcategory, option) => {
        if (option == 'add') {
            dispatch(addSubcatagoryToRG(subcategory));
        }
    }, [dispatch]);

    const renderSubcategories = useCallback(() => {
        if (catagoryBeingMade?.subcatagories) {
            let names = catagoryBeingMade.subcatagories.map(a => a.value);
            return names.join(', ');
        } else {
            return "";
        }
    }, [catagoryBeingMade]);

    const selectChange = useCallback((evt) => {
        clearSelects();
        dispatch(setCatagoryBeingMade(evt));
        if (toggleShowEdit != 'edit') {
            settingToggle2('edit');
        }
        dispatch(setRG(evt));
    }, [dispatch, toggleShowEdit]);

    const onDeleteClicked = useCallback((reportingGroup) => {
        dispatch(deleteRG(reportingGroup));
        clearSelects();
    }, [dispatch]);

    const onCancelClicked = useCallback(() => {
        setToggle2(false);
        setToggle1(false);
        clearSelects();
    }, []);

    const clearSelects = useCallback(() => {
        let blankEntry = { label: '', value: '' };
        dispatch(setRG(blankEntry));
        dispatch(clearCatagoryBeingMade());
    }, [dispatch]);

    const settingToggle2 = useCallback((value) => {
        setToggle2(prev => prev === 'edit' ? '' : value);
        setToggle1('');
    }, []);

    const settingToggle1 = useCallback((value) => {
        setToggle1(prev => prev === 'edit' ? '' : value);
        setToggle2('');
    }, []);

    const changeview = useCallback((view) => {
        settingToggle1('create');

        if (view == 'bucket') {
            document.getElementById("titleText").textContent = "Create Reporting Group";
            clearSelects();
            dispatch(clearCatagoryBeingMade());
        } else if (view == 'edit') {
            document.getElementById("titleText").textContent = "Existing Reporting Groups";
        }

        dispatch(setPageView(view));
    }, [dispatch, clearSelects, settingToggle1]);


    const minusIsClicked = useCallback((subcategory, index) => {
        const editedRg = structuredClone(loadedRG);
        editedRg.subcatagories.splice(index, 1);
        dispatch(patchRG(editedRg));
        dispatch(setRG(editedRg));



        console.log("removing : ",  subcategory, index)




    }, [dispatch, loadedRG]);


    const plusIsClicked = useCallback((catagory) => {
        let thingToAdd = document.getElementById("newCatagoryInput").value;
        const editedRg = structuredClone(catagory);
        if (editedRg?.subcatagories && editedRg?.subcatagories.length > 0){

        }
        else{
            editedRg.subcatagories = []
        }
        editedRg.subcatagories.push({ value: thingToAdd, label: thingToAdd });
        dispatch(patchRG(editedRg));
        dispatch(setRG(editedRg));


        onCancelClicked()
    }, [dispatch]);

    const setValueOfSelectFunct = useCallback((evt, index) => {
        console.log("Setting value of select (list) ", evt, evt.value, index);
    }, []);

    const onSaveClicked = useCallback(() => {
        let newReportingGroup = structuredClone(catagoryBeingMade);
        newReportingGroup.viewingLocation = user.viewingLocation;
        newReportingGroup.org_id = user.org_id;


        console.log("Current page view :", toggleShowCreate, toggleShowEdit)

        if (toggleShowEdit == 'edit') {
            newReportingGroup._id = loadedRG._id;
            dispatch(patchRG(newReportingGroup));
        } else {
            if (reportingGroups?.some(e => e.displayName === newReportingGroup.displayName)) { //if name is different than selected group
                document.getElementById("popupBackground").style.visibility = "visible"; //dont add, but show popup instead
                document.getElementById("popupMessage").style.visibility = 'visible';
            } else {

                console.log("Adding new rg: " , newReportingGroup)
                dispatch(addNewRG(newReportingGroup));
            }
        }

        dispatch(fetchReportingGroups({ org_id: user.org_id, location: user?.viewingLocation }));
        settingToggle2('edit');
        dispatch(clearCatagoryBeingMade());
        clearSelects();
    }, [catagoryBeingMade, user, toggleShowEdit, loadedRG, reportingGroups, dispatch, settingToggle2, clearSelects]);

    return (
        <div className={getPageWrapperClassName()}>
            <div id="popupBackground" onClick={hidePopup} style={{ visibility: 'hidden' }} className={styles.popupBackground}></div>
            <div id="popupMessage" className={styles.popupMessage} style={{ visibility: 'hidden' }} onClick={hidePopup}>
                <span>A reporting group with this name already exists, please use a different one.</span>
            </div>

            <div className={styles.userForm}>
                <div className={styles.userFormContent}>
                    <h3 id="titleText" className={styles.existingUser}>Reporting Groups</h3>

                    <div className={styles.selectandbuttonwrapper}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="reportingGroupSelect"
                                ref={selectReportingGroupInputRef}
                                value={loadedRG}
                                className={styles.reportingGroupSelect}
                                onChange={selectChange}
                                options={reportingGroups}
                                getOptionLabel={(option) => option.displayName}
                                getOptionValue={(option) => option.displayName}
                            />
                        </div>

                        <div className={styles.createReportingGroupWrapper}>
                            <button onClick={() => changeview("bucket")} className={styles.formBtn2}>
                                Create Reporting Group
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {rgCurrentView === "bucket" && (toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <div className={styles.reportingGroupInfoSection}>
                    <h4 className={styles.contactInfo}>Reporting Group Info</h4>

                    <div className={styles.flexBox}>
                        <div className={styles.leftCol}>
                            <InputField
                                label="Name"
                                id="displayName"
                                value={catagoryBeingMade?.displayName}
                                onChange={(evt) => dispatch(setTitle(evt.target.value))}
                            />
                            <div className={styles.wrapper}>
                                <input
                                    id="newCatagoryInput"
                                    className={styles.addItemInp}
                                    placeholder="Add Subcatagory"
                                />
                                <button className={styles.icon} onClick={() => plusIsClicked(loadedRG)}>
                                    +
                                </button>
                            </div>




                            <div className={styles.wrapper}>
                                <Select
                                    id="catagorySelect"
                                    options={loadedRG?.subcatagories}
                                    placeholder="Subcatagories"
                                    className={styles.bucketSelect}
                                    onChange={(evt) => setValueOfSelectFunct(evt)}
                                />
                                <button className={styles.icon} onClick={() => minusIsClicked(loadedRG)}>
                                    -
                                </button>
                            </div>


                        </div>
                    </div>
                </div>
            )}

            {rgCurrentView === "catagory" && (
                <div className={styles.gridHolder}>
                    {reportingGroups.map((reportingGroup, index) => (
                        <div key={index} className={styles.cardDiv}>
                            <div className={styles.centeredArea}>
                                <h5>{reportingGroup.displayName}</h5>
                                <div className={styles.wrapper}>
                                    <input
                                        id={`catagoryName${index}`}
                                        className={styles.addItemInp}
                                        placeholder="Add Items"
                                    />
                                    <button
                                        className={styles.icon}
                                        onClick={() => plusIsClicked(reportingGroup)}
                                    >
                                        +
                                    </button>
                                </div>
                                <div className={styles.cataSelectWrapper}>
                                    <Select
                                        id={`catagorySelect${index}`}
                                        options={reportingGroup?.subcatagories}
                                        placeholder="Categories"
                                        className={styles.bucketSelect}
                                        onChange={(evt) => setValueOfSelectFunct(evt, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div className={styles.blackLineDiv}></div>

            <div className={styles.formBtnDiv}>
                <button onClick={() => onDeleteClicked(loadedRG)} className={styles.formBtn}>
                    Delete
                </button>
                <button onClick={onCancelClicked} style={{ backgroundColor: 'black', color: 'white' }} className={`${styles.formBtn} ${styles.cancelBtn}`}>
                    Cancel
                </button>
                <button onClick={onSaveClicked} className={styles.formBtn2}>
                    {toggleShowEdit == 'edit' ? 'Save' : 'Create'}
                </button>
            </div>
        </div>
    );
}

export default ReportingGroup;