import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
//import * as actions from '../store/actions/MenuNavigation';
import { React } from 'react';


//THIS IS OUR PROFILE PAGE NOW
//HANDLING LOGINS AND LOGOUTS
function loginForm(props) {

    const dispatch = useDispatch();
    const history = useHistory();
   // const { user, isAuthenticated, isLoading } = useAuth0();



    //console.log("User auth status :" , user , isAuthenticated)
    // const [accountState, setAccount] = useAtom(interactWithAccount)

    //  console.log("accountState :", accountState)
    // console.log("accountState2 :", accountState(5))


    return (
        <>

            <div>


            </div>

        </>
    );


    //onChange={(item) => settingTaskName(item)}
}

function saveLocal(state, evt) {
    console.log("saving local :", state, evt)
    //state(evt)
    // setAccount()

}

function clearAccount() {

    // console.log("Clearing account")
    //  window.location.reload(false);


}




export default loginForm;
