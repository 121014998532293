import React, { useEffect, useRef, useState } from 'react';
import { FaPlusCircle, FaRegTrashAlt, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import Calendar from '../../Components/calendar/Calendar';
import { selectCalendar } from '../../Components/calendar/CalendarSlice';
import { fetchItems, getItemLoadStatus, getitemsError, getLoadedItem, selectItems } from '../forms/items/itemSlice';
import { fetchJobPositions, getJobPositionLoadStatus, selectJobPositions } from '../forms/JobPositions/jobPositionSlice';
import { fetchUsers, getLoadedUser, getUserLoadStatus, getusersError, selectUsers } from '../forms/users/UsersSlice';
import { } from '../taskBuilder/taskSlice';
import { uploadingFile } from '../userDashboard/userDashboardSlice';
import styles from "./createForm.module.css";
import {
    setDisplayOfReport,
    addAnswerChoiceColumn, addColumnDuringCreation, addFieldToSection, addNewForm, addResponseColumn, AddSection,
    addUserResponse, changeSectionName, clearForm, deleteUserResponse, editResponseColumn, getFormLoadStatus, getOneFile,
    getRowsForDeletion, patchForm, removeAnswerChoice, removeColumnDuringCreation, removeFieldFromSection, removeResponseColumn,
    RemoveSection, SaveItemToField, selectForms, setAction, setAnswerChoices, setAssignedJobPositions, setAssignedUser,
    setChecklistDescription, setDayValueForYearRecurrence, setDayValueOfMonthRecurrence, setDestinationType, setDueDateOfReport,
    setEndDate, setFormForEdit, setFormName, setFrequency, setGoogleEmail, setIncludePar, setIntervalAmount, setIntervalValue,
    setItemsToAdd, setMonthDayToRepeat, setMonthOption, setMonthValueForYearRecurrence, setOrdinalValueMonth, setParValue,
    setRepeatIntervalType, setResponseDescription, setSechdule, setSelectedDeleteRows, setStartDate, setTriggerPurchase, setUserResponsePrompt,
    setVisualDescription, setYearOption, toggleAllPars, toggleAllPurchaseTrigger, toggleReportRowForDeletion
} from './formSlice';





const isTablet = window.matchMedia(`
    screen and 
    (min-width: 768px) and 
    (max-width: 1366px) and
    (min-height: 600px) and
    (max-height: 1024px)
`).matches;

const isLandscapeTablet = window.matchMedia(`
  screen and 
  (min-width: 1024px) and 
  (max-height: 768px) and 
  (orientation: landscape)
`).matches;

const ControlledInput = ({
    value,
    onChange,
    placeholder,
    className,
    style,
    type = 'text',
    checked,
    id
}) => {
    const inputRef = useRef(null);
    const cursorPositionRef = useRef(null);

    useEffect(() => {
        // Only restore cursor position for text inputs, not checkboxes
        if (type === 'text' && cursorPositionRef.current !== null && document.activeElement === inputRef.current) {
            inputRef.current.setSelectionRange(
                cursorPositionRef.current,
                cursorPositionRef.current
            );
        }
    }, [value]);

    const handleChange = (e) => {
        // Save cursor position only for text inputs
        if (type === 'text') {
            cursorPositionRef.current = e.target.selectionStart;
        }
        onChange(e);
    };

    return (
        <input
            ref={inputRef}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={className}
            style={style}
            type={type}
            checked={checked}
            id={id}
        />
    );
};


const deviceDetection = {
    // Check if device is mobile (including tablets)
    isMobile: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            // Include tablets in mobile detection
            /Tablet|iPad/i.test(navigator.userAgent);
    },

    // Check if device is specifically a tablet
    isTablet: () => {
        return /Tablet|iPad/i.test(navigator.userAgent) ||
            // Alternative method using screen size
            (deviceDetection.isMobile() && Math.min(window.screen.width, window.screen.height) >= 768);
    },

    // Check if device is specifically a smartphone
    isSmartphone: () => {
        return deviceDetection.isMobile() && !deviceDetection.isTablet();
    },

    // Check if device is desktop
    isDesktop: () => {
        return !deviceDetection.isMobile();
    },

    // Check orientation
    isLandscape: () => {
        // Primary method using window.matchMedia
        const mediaQuery = window.matchMedia('(orientation: landscape)');

        // Fallback to window dimensions if matchMedia is not supported
        if (!mediaQuery.matches && window.screen) {
            return window.screen.width > window.screen.height;
        }

        return mediaQuery.matches;
    },

    // Get device type and orientation state
    getDeviceState: () => {
        return {
            isMobile: deviceDetection.isMobile(),
            isTablet: deviceDetection.isTablet(),
            isSmartphone: deviceDetection.isSmartphone(),
            isDesktop: deviceDetection.isDesktop(),
            isLandscape: deviceDetection.isLandscape()
        };
    }
};





const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]

const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' },]

const destinationType = [{ label: 'Send email alert' }, { label: '-' }]

const destinationFormat = [{ label: 'Format' }, { label: '-' }]

const timePeriodSelect = [{ label: 'Day' }, { label: 'Week' }, { label: 'Month' }, { label: 'Year' }]

const ordinalSelect = [{ label: 'First', value: 1 }, { label: 'Second', value: 2 }, { label: 'Third', value: 3 }, { label: 'Fourth', value: 4 }, { label: 'Last', value: -1 }]

const MonthSelect = [{ label: 'January' }, { label: 'February' }, { label: 'March' }, { label: 'April' }, { label: 'May' },
{ label: 'June' }, { label: 'July' }, { label: 'August' }, { label: 'September' }, { label: 'October' }
    , { label: 'November' }, { label: 'December' }]



const callBackFromChild = (value) => {
    console.log("Child gave back value : ", value)
}

const frequencyOptions = [
    { label: 'Infinite', value: 'Infinite' },
    { label: 'Reoccuring', value: 'Reoccuring' },
    { label: 'One Time', value: 'One Time' }
];
const dueDateOptions = [{ label: 'Same Day', value: 0 }, { label: '3 Days', value: 3 }, { label: '5 Days', value: 5 }, { label: '7 Days', value: 7 }]


const CreateForm = (props, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const formRef = React.useRef();

    const deviceState = deviceDetection.getDeviceState();

    const [displayTypeCal1, setDisplayTypeCal1] = useState('Input');
    const [displayTypeCal2, setDisplayTypeCal2] = useState('Input');

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => formRef.current,
    });

    if (isLandscapeTablet) {

        var dataGoingToCalendar = {
            style: { input: { display: 'flex', width: '22vw', zIndex: '0', marginLeft: isLandscapeTablet ? '5vw' : '-3vw', textAlign: 'center', fontSize: '1.2rem', borderRadius: '5px', border: '1px solid lightgrey', height: '34px' }, calendar: { zIndex: '100000', left: '35%', padding: '1.5%', display: 'block', position: 'absolute ', top: '38%' } },
            dayToLimitTo: 'Monday',
            callBack: handleCallback,
            placeholder: 'Start Date',
            lastClickLocation: '',
            displayType: displayTypeCal1,
            setDisplayType: handleSetDisplay1
        }

        var dataGoingToCalendar2 = {
            style: { input: { display: 'flex', width: '22vw', zIndex: '0', marginLeft: '0vw', textAlign: 'center', fontSize: '1.2rem', borderRadius: '5px', border: '1px solid lightgrey', height: '34px' }, calendar: { zIndex: '100000', left: '35%', padding: '1.5%', display: 'block', position: 'absolute ', top: '38%' } },
            dayToLimitTo: 'Monday',
            callBack: handleCallback2,
            placeholder: 'End Date',
            lastClickLocation: '',
            displayType: displayTypeCal2,
            setDisplayType: handleSetDisplay2,
        }
    } else {

        var dataGoingToCalendar = {
            style: { input: { display: 'flex', width: '22vw', zIndex: '0', textAlign: 'center', fontSize: '1.2rem', borderRadius: '5px', border: '1px solid lightgrey', height: '34px' }, calendar: { zIndex: '200000', left: '35%', padding: '1.5%', display: 'block', position: 'absolute ', top: '38%' } },
            dayToLimitTo: 'Monday',
            callBack: handleCallback,
            placeholder: 'Start Date',
            lastClickLocation: '',
            displayType: displayTypeCal1,
            setDisplayType: handleSetDisplay1
        }

        var dataGoingToCalendar2 = {
            style: { input: { display: 'flex', width: '22vw', zIndex: '0', marginLeft: '0vw', textAlign: 'center', fontSize: '1.2rem', borderRadius: '5px', border: '1px solid lightgrey', height: '34px' }, calendar: { zIndex: '200000', left: '35%', padding: '1.5%', display: 'block', position: 'absolute ', top: '38%' } },
            dayToLimitTo: 'Monday',
            callBack: handleCallback2,
            placeholder: 'Optional end Date',
            lastClickLocation: '',
            displayType: displayTypeCal2,
            setDisplayType: handleSetDisplay2,
        }
    }



    //fake data for now
    const Users = [
        { name: 'Alex' },
    ]

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }

    const formOptions = [
        { name: 'Data Entry Form' },
        { name: 'Checklist Form' },
        { name: 'Visual Form' },
        { name: 'Response Form' },
    ]

    var exampleDataEntry = { //Create a new one whenever field is added.
        item: '',
        includePar: true,
        parValue: '',
        userResponse: '',
        action: '',
    }

    var userResponseOptions = [{ label: 'Current Quantity', value: 'Current Quantity' }]
    var boolOptions = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);
    //const loadingTasks = useSelector(getTaskLoadStatus);

    const calData = useSelector(selectCalendar);

    const forms = useSelector(selectForms);
    const loadingForms = useSelector(getFormLoadStatus);
    const selectedDeletionRows = useSelector(getRowsForDeletion)
    const selectItemInputRef = useRef();

    const [formBeingEdited, setFormBeingEditedStatus] = useState(false)
    const [previewOpen, setPreviewOpenStatus] = useState(false)

    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);

    const [selectedJobOptions, setSelectedJob] = useState([]);
    const [selectedUserOptions, setSelectedUser] = useState([]);

    const [sectionEditIndex, setSectionBeingEditedIndex] = useState(0);

    console.log("checking :", history)

    const [includeParOnAll, setIncludeAllPar] = useState(false);

    const [dataFromChild, setDataFromChild] = useState("");

    const params = useParams()

    console.log("Pulled params : ", params)

    const hiddenFileInput = useRef(null); // ADDED



    const MultipleSelect = ({
        id = "daySelect",
        className,
        options = [],
        value = [],
        onChange,
        styles = {}
    }) => {
        const handleChange = (e) => {
            // Get selected options and create properly formatted objects
            const selectedValues = Array.from(e.target.selectedOptions).map(option => ({
                label: option?.value,
                value: option?.value,
                id: option?.value.toLowerCase(),
                name: option?.value
            }));

            // Call the provided onChange handler with the formatted selection
            onChange(selectedValues);
        };

        // Convert value array to array of labels for the select element
        const selectedValues = Array.isArray(value)
            ? value.map(d => d.label)
            : [];

        return (
            <select
                id={id}
                className={className || styles.select2}
                multiple
                value={selectedValues}
                onChange={handleChange}
                onTouchStart={(e) => {
                    e.target.classList.add('touched');
                }}
                onTouchEnd={(e) => {
                    if (e.target.classList.contains('touched')) {
                        e.preventDefault();
                        e.target.classList.remove('touched');
                        e.target.click();
                    }
                }}
            >
                {options.map((option) => (
                    <option
                        key={option.value || option.label}
                        value={option.label}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        );
    };




    useEffect(() => {
        console.log("check 2 : ", history?.location)

        if (history?.location?.state?.formToDisplay) {
            dispatch(setFormForEdit(history.location.state.formToDisplay.form))

            setFormBeingEditedStatus(true)
            console.log("Checking early :", forms)
        }

    }, [])

    useEffect(() => {


    }, [loadingForms, dispatch])


    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            dispatch(fetchItems({ org_id: user.org_id, viewingLocation: user?.viewingLocation }))
        }
    }, [loading, dispatch])

    useEffect(() => {
        if (loadingUsers != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [loadingUsers, dispatch])

    /*
        useEffect(() => {
    
            if (loadingTasks != 'Done') {
                //  console.log("fetching vendors")
                dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation }))
            }
        }, [])*/

    useEffect(() => {
        if (jobPositionLoading != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }))
            //  jobPositions.push({'jobTitle':'-'})
        }
    }, [jobPositionLoading, dispatch])

    const startDate = useSelector(
        (state) => state.forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1]?.startDate
    );

    console.log('Start date in CreateForm:', startDate);







    return (
        <div onClick={(e) => { pageClickEvent(e) }}>

            <>


                <div id="popupBackground1" className={styles.popupBackground}>
                </div>

                <div id="popupMessage1" className={styles.popupMessage} >
                    <button className={styles.closeButton} onClick={() => { hidePopup1() }}>X</button>

                    <div className={styles.typeOfSection} >
                        <span style={{ marginleft: '4%' }}>Type of section</span>

                    </div>


                    <div style={{ marginBottom: '2%' }}>
                        <button onClick={() => { addSectionToForms("Data Entry Form"); showPopup2() }} className={`${styles.defaultInp} ${styles.buttonStyling}`} >Data Entry</button>
                    </div>

                    <div style={{ marginBottom: '2%' }}>
                        <button onClick={() => { addSectionToForms("Checklist Form"); showPopup2() }} className={`${styles.defaultInp} ${styles.buttonStyling}`}>Checklist</button>
                    </div>

                    <div style={{ marginBottom: '2%' }}>
                        <button onClick={() => { addSectionToForms("Visual Form"); showPopup2() }} className={`${styles.defaultInp} ${styles.buttonStyling}`}>Visual</button>
                    </div>
                    <div>
                        <button onClick={() => { addSectionToForms("Response Form"); showPopup2() }} className={`${styles.defaultInp} ${styles.buttonStyling}`}>Response</button>
                    </div>

                </div>



                <div id="popupBackground2" className={styles.popupBackground2}>
                </div>

                <div id="popupMessage2" className={styles.popupMessage2}>

                    <div className={styles.popup2Header}>
                        <button className={styles.closeButton} onClick={() => { cancelSectionAdd() }}>X</button>

                    </div>

                    <div className={styles.popup2AssignSection}>
                        <h4 className="assignmentHeader" style={{ textAlign: 'left' }}>Assign</h4>

                        <div className={styles.popup2InputWrapper}>

                            <div className={styles.pu2JobSelectWrapper}>

                                {isTablet && jobPositionLoading == 'Done' && (
                                    <CustomSelect
                                        id="jobSelect"
                                        className={styles.userSelect}
                                        options={jobPositions.map(job => ({
                                            label: job.jobTitle,
                                            value: job.jobTitle
                                        }))}
                                        placeholder="Select Job Positions"
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions || []}
                                        onChange={(selectedOptions) => {
                                            dispatch(setAssignedJobPositions({
                                                e: selectedOptions,
                                                sectionIndex: sectionEditIndex + 1
                                            }));
                                        }}
                                        disabled={forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1]?.assignedUser?.length > 0}
                                        isOptionDisabled={(option) =>
                                            forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions?.length >= 1 &&
                                            !forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions?.includes(option.value)
                                        }
                                        multiple
                                        isClearable
                                    />
                                )}

                                {!isTablet && jobPositionLoading == 'Done' && (
                                    <Select
                                        id="jobSelect"
                                        options={jobPositions}
                                        className={styles.userSelect}
                                        onChange={(e) => { dispatch(setAssignedJobPositions({ e, sectionIndex: sectionEditIndex + 1 })) }}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions}
                                        isDisabled={forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1]?.assignedUser?.length > 0}
                                        placeholder="Job Positions"
                                        isMulti
                                        isOptionDisabled={() => selectedJobOptions.length >= 1}
                                        getOptionLabel={(option) => option.jobTitle}
                                        getOptionValue={(option) => option.jobTitle}
                                    />
                                )}





                            </div>

                            <div className={styles.pu2UserSelectWrapper}>


                                {isTablet && loadingUsers == 'Done' && (
                                    <CustomSelect
                                        id="userSelect"
                                        className={styles.assignUserSelect}
                                        options={users.map(user => ({
                                            label: user.displayName,
                                            value: user.displayName
                                        }))}
                                        onChange={(selectedOptions) => {
                                            dispatch(setAssignedUser({
                                                e: selectedOptions,
                                                sectionIndex: sectionEditIndex + 1
                                            }));
                                        }}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedUser || []}
                                        disabled={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions?.length > 0}
                                        placeholder="Select Users"
                                        multiple
                                        isOptionDisabled={(option) =>
                                            forms?.formBeingMade?.sections[sectionEditIndex]?.assignedUser?.length >= 1 &&
                                            !forms?.formBeingMade?.sections[sectionEditIndex]?.assignedUser?.includes(option.value)
                                        }
                                        isClearable
                                    />
                                )}

                                {!isTablet && loadingUsers == 'Done' && (
                                    <Select
                                        id="userSelect"
                                        className={styles.assignUserSelect}
                                        options={users}
                                        onChange={(e) => { dispatch(setAssignedUser({ e, sectionIndex: sectionEditIndex + 1 })) }}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedUser}
                                        isDisabled={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions?.length > 0}
                                        placeholder="Users"
                                        isMulti
                                        isOptionDisabled={() => selectedUserOptions.length >= 1}
                                        getOptionLabel={(option) => option.displayName}
                                        getOptionValue={(option) => option.displayName}
                                    />
                                )}
                            </div>



                        </div>

                    </div>

                    <div className={styles.popup2FrequencySection}>
                        <h4 className="frequency-header" style={{ textAlign: 'left' }}>Frequency</h4>

                        <div className={styles.popup2InputWrapper} style={{ display: 'block' }}>

                            <div className={styles.topSect}>
                                <div className={styles.pu2FrequencyWrapper}>

                                    {deviceState.isMobile &&
                                        <CustomSelect
                                            placeholder=""
                                            id="frequencySelect"
                                            options={frequencyOptions}
                                            className={styles.select2}

                                            value={forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency || null}
                                            onChange={(selectedOption) => {
                                                dispatch(setFrequency({
                                                    value: selectedOption,
                                                    sectionIndex: forms.formBeingMade.sections.length,
                                                    reportIndex: 0
                                                }));
                                            }}
                                            isClearable={false}
                                        />

                                    }

                                    {!deviceState.isMobile &&
                                        <Select
                                            placeholder=""
                                            id="frequencySelect"
                                            options={frequencyOptions}
                                            className={styles.select2}
                                            style={{ width: '8vw' }}
                                            value={forms?.formBeingMade?.sections[0]?.frequency ? forms.formBeingMade.sections[0].frequency : {}}
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            onChange={(evt) => { dispatch(setFrequency({ value: evt, sectionIndex: forms.formBeingMade.sections.length, reportIndex: 0 })) }}
                                        />

                                    }

                                </div>


                                {isLandscapeTablet &&
                                    <div className="calendarWrapper" style={{ zIndex: '0', marginLeft: '15vw' }}>
                                        <Calendar
                                            dataFromParent={dataGoingToCalendar}
                                            onChange2={handleCallback}
                                            sectionIndex={sectionEditIndex}
                                            reportIndex={0}
                                            initialValue={forms.formBeingMade?.sections[sectionEditIndex]?.startDate}
                                        />
                                    </div>
                                }
                                {!isLandscapeTablet &&
                                    <div className="calendarWrapper" style={{ zIndex: '0', marginLeft: '3vw' }}>
                                        <Calendar
                                            dataFromParent={dataGoingToCalendar}
                                            onChange2={handleCallback}
                                            sectionIndex={sectionEditIndex}
                                            reportIndex={0}
                                            initialValue={forms.formBeingMade?.sections[sectionEditIndex]?.startDate}
                                        />
                                    </div>
                                }



                                {forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency.label == 'Infinite' && (
                                    <div>
                                        <div className="calendarWrapper-infinite" style={{ marginLeft: '5vw' }}>
                                            <Calendar dataFromParent={dataGoingToCalendar2} onChange2={handleCallback2} sectionIndex={sectionEditIndex}
                                                initialValue={forms?.formBeingMade?.sections[sectionEditIndex]?.endDate} reportIndex={0} />

                                        </div>
                                    </div>
                                )}

                                {forms.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'One Time' && (
                                    <div className="due-date-select" style={{ marginLeft: '10vw', minWidth: '7vw' }}>




                                        {/*deviceState.isMobile &&
                                            <CustomSelect
                                                placeholder="Due date"
                                                id="frequencySelect"
                                                options={dueDateOptions}
                                                className={styles.select2}
                                                value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.due || null}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                onChange={(selectedOption) => {
                                                    dispatch(setDueDateOfReport({
                                                        value: selectedOption,
                                                        sectionIndex: forms.formBeingMade.sections.length - 1,
                                                        reportIndex: 0
                                                    }))
                                                }}
                                                isClearable={false}
                                            />

                                            */}

                                        {/*!deviceState.isMobile &&
                                            <Select
                                                placeholder=""
                                                id="frequencySelect"
                                                options={dueDateOptions}
                                                className={styles.select2}
                                                value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.due ? forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.due : {}}
                                                placeholder="Due date"
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                onChange={(evt) => { dispatch(setDueDateOfReport({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                            />

                                        */}




                                    </div>
                                )}

                            </div>

                            <div className={styles.botSect}>
                                <div className={styles.reoccurenceWrapper}>
                                    {forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency.label == 'Reoccuring' && (
                                        <div style={{
                                            marginTop: '5vh',
                                            display: 'block',
                                            width: '99vw',
                                            marginLeft: '1vw',
                                            height: '4.2vh',
                                            textAlign: 'left'
                                        }}>
                                            <h4 style={{
                                                minWidth: '6.25vw',
                                                height: '3.6vh',
                                                alignContent: 'center',
                                                marginTop: '0'
                                            }}>Repeat every</h4>

                                            <div style={{ display: 'flex' }}>
                                                <input
                                                    type='number'
                                                    className={styles.roundedGreyRec}
                                                    style={{ marginRight: '1vw' }}

                                                    onChange={(evt) => { dispatch(setIntervalValue({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                />

                                                {deviceState.isMobile &&

                                                    <CustomSelect
                                                        placeholder=""
                                                        id="frequencySelect"
                                                        className={styles.intervalSelect}
                                                        value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType
                                                            ? timePeriodSelect.find(option =>
                                                                option.label === forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType
                                                            )
                                                            : null
                                                        }
                                                        options={timePeriodSelect}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => {
                                                            dispatch(setRepeatIntervalType({
                                                                value: evt,
                                                                sectionIndex: forms.formBeingMade.sections.length - 1,
                                                                reportIndex: 0
                                                            }))
                                                        }}
                                                        isClearable={false}
                                                    />

                                                }

                                                {!deviceState.isMobile &&

                                                    <Select
                                                        placeholder=""
                                                        id="frequencySelect"
                                                        className={styles.intervalSelect}
                                                        options={timePeriodSelect}


                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setRepeatIntervalType({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                    />

                                                }



                                                <h4 style={{
                                                    minWidth: '4.17vw',
                                                    height: '3.6vh',
                                                    alignContent: 'center',
                                                    marginTop: '0',
                                                    marginLeft: '2vw'
                                                }}>Repeat</h4>

                                                <div style={{ display: 'flex' }}>
                                                    <input
                                                        type='number'
                                                        className={styles.roundedGreyRec}
                                                        onChange={(evt) => { dispatch(setIntervalAmount({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                    />
                                                </div>

                                                <h4 style={{
                                                    minWidth: '2.08vw',
                                                    height: '3.6vh',
                                                    alignContent: 'center',
                                                    marginTop: '0',
                                                    marginLeft: '25px'
                                                }}>times</h4>


                                            </div>



                                        </div>
                                    )}
                                    {forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType == 'Week'
                                        && forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType != 'Day'
                                        && forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency.label == 'Reoccuring' && (
                                            <div className={styles.weekSection}>
                                                <div style={{ maxWidth: '20vw' }}>
                                                    <h5 style={{ color: 'grey', textAlign: 'left' }}> Display On</h5>


                                                    {deviceState.isMobile &&
                                                        <CustomSelect
                                                            placeholder="Days"
                                                            id="daySelect"
                                                            options={daysOfWeek}
                                                            className={styles.select2}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display?.length > 0
                                                                ? forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display
                                                                : []}
                                                            onChange={(selectedOptions) => {
                                                                dispatch(setDisplayOfReport({
                                                                    value: selectedOptions,
                                                                    sectionIndex: forms.formBeingMade.sections.length - 1,
                                                                    reportIndex: 0
                                                                }))
                                                            }}
                                                            multiple={true}
                                                            isClearable={true}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                        />
                                                    }


                                                    {!deviceState.isMobile &&
                                                        <Select isMulti placeholder="Days" id="daySelect" options={daysOfWeek} className={styles.select2}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display?.length > 0 ? forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display : []}

                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setDisplayOfReport({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}> </Select>
                                                    }












                                                </div>
                                            </div>
                                        )}


                                    {forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType == 'Month' &&
                                        forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType != 'Day' &&
                                        forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency.label == 'Reoccuring' && (
                                            <div className={styles.monthSection}>
                                                <div style={{ width: '20.83vw', marginBottom: '2vh' }}>
                                                    <input
                                                        type="radio"
                                                        id="onDay"
                                                        name="drone"
                                                        value="onDay"
                                                        onClick={(evt) => { dispatch(setMonthOption({ value: 'cardinal', sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                    />
                                                    <label style={{ marginRight: '0.4vw', marginLeft: '0.1vw' }} htmlFor="onDay">On day</label>
                                                    <input
                                                        type='number'
                                                        className={styles.roundedGreyRec}
                                                        style={{ textAlign: 'center' }}
                                                        onChange={(evt) => { dispatch(setDayValueOfMonthRecurrence({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1 })) }}
                                                    />
                                                </div>

                                                <div style={{ textAlign: 'left', display: 'flex' }}>
                                                    <input
                                                        type="radio"
                                                        id="onThe"
                                                        name="drone"
                                                        value="onThe"
                                                        onClick={(evt) => { dispatch(setMonthOption({ value: 'ordinal', sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                    />
                                                    <label style={{
                                                        minWidth: '2.6vw',
                                                        marginLeft: '0.1vw',
                                                        marginRight: '0.5vw',
                                                        marginTop: '0.2vh'
                                                    }} htmlFor="onThe">On the</label>

                                                    <div className={styles.select2} style={{ paddingRight: '0.1vw' }}>









                                                        {deviceState.isMobile &&
                                                            <CustomSelect
                                                                placeholder="First"
                                                                id="daySelect"
                                                                style={{ width: '6.15vw' }}
                                                                options={ordinalSelect}
                                                                className={styles.select2}
                                                                value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.ordinalMonthValue || null}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                                onChange={(selectedOption) => {
                                                                    dispatch(setOrdinalValueMonth({
                                                                        value: selectedOption,
                                                                        sectionIndex: forms.formBeingMade.sections.length - 1,
                                                                        reportIndex: 0
                                                                    }))
                                                                }}
                                                                isClearable={false}
                                                            />
                                                        }


                                                        {!deviceState.isMobile &&

                                                            <Select
                                                                placeholder="First"
                                                                id="daySelect"
                                                                style={{ width: '6.15vw' }}
                                                                options={ordinalSelect}
                                                                className={styles.select2}
                                                                value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.ordinalMonthValue || []}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.value}
                                                                onChange={(evt) => { dispatch(setOrdinalValueMonth({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                                            />

                                                        }























                                                    </div>








                                                    {deviceState.isMobile &&
                                                        <CustomSelect
                                                            placeholder="Day"
                                                            id="daySelect"
                                                            style={{ width: '6.15vw' }}
                                                            options={daysOfWeek}
                                                            className={styles.select2}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.monthDayToRepeat || null}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(selectedOption) => {
                                                                dispatch(setMonthDayToRepeat({
                                                                    value: selectedOption,
                                                                    sectionIndex: forms.formBeingMade.sections.length - 1
                                                                }))
                                                            }}
                                                            isClearable={false}
                                                        />
                                                    }






                                                    {!deviceState.isMobile &&
                                                        <Select
                                                            placeholder="January"
                                                            id="daySelect"
                                                            style={{ width: '6.15vw' }}
                                                            options={daysOfWeek}
                                                            className={styles.select2}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.monthDayToRepeat || []}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1 })) }}
                                                        />

                                                    }












                                                </div>
                                            </div>
                                        )}

                                    {forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType == 'Year' &&
                                        forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType != 'Day' &&
                                        forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.frequency.label == 'Reoccuring' && (
                                            <div className={styles.yearSection} >
                                                <div style={{ display: 'flex' }}>
                                                    <input
                                                        type="radio"
                                                        id="onDay"
                                                        name="drone"
                                                        value="onDay"
                                                        onClick={(evt) => { dispatch(setYearOption({ value: 'cardinal', sectionIndex: forms.formBeingMade.sections.length - 1 })) }}
                                                    />
                                                    <label className={styles.onText} htmlFor="onDay">On</label>






                                                    {deviceState.isMobile &&
                                                        <CustomSelect
                                                            placeholder="January"
                                                            id="daySelect"
                                                            options={MonthSelect}
                                                            style={{ marginRight: '0.3vw' }}
                                                            className={styles.yearSelect1}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.monthValueForYear || null}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(selectedOption) => {
                                                                dispatch(setMonthValueForYearRecurrence({
                                                                    value: selectedOption,
                                                                    sectionIndex: forms.formBeingMade.sections.length - 1
                                                                }))
                                                            }}
                                                            isClearable={false}
                                                        />
                                                    }

                                                    {!deviceState.isMobile &&
                                                        <Select
                                                            placeholder="January"
                                                            id="daySelect"
                                                            options={MonthSelect}
                                                            style={{ marginRight: '0.3vw' }}
                                                            className={styles.yearSelect1}
                                                            value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.monthValueForYear || []}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.label}
                                                            onChange={(evt) => { dispatch(setMonthValueForYearRecurrence({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1 })) }}
                                                        />

                                                    }


                                                    <input
                                                        type='number'
                                                        className={styles.roundedGreyRec}
                                                        style={{ marginLeft: '0.1vw', textAlign: 'center' }}
                                                        placeholder="Enter Date"
                                                        onChange={(evt) => { dispatch(setDayValueForYearRecurrence({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1 })) }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>




                        </div>





                    </div>

                    <div className={styles.popup2DestinationSection}>

                        <div className={styles.popup2InputWrapper} style={{ display: 'block' }}>
                            <h4 className="assignmentHeader" style={{ textAlign: 'left' }}>Destination</h4>

                            <div className="destination-select-wrapper">
                                <Select
                                    id="destinationType"
                                    options={destinationType}
                                    className={`${styles.userSelect} destination-type-select`}
                                    value={forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    onChange={(evt) => { dispatch(setDestinationType({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                />


                            </div>

                        </div>
                        {
                            console.log("check : ", forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType)
                        }


                        {forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType.label == 'Send email alert' &&

                            <div style={{ textAlign: 'left' }}>

                                <input type='email' placeholder="Enter recipient email" className={styles.defaultInp} style={{ width: '15vw', marginRight: '2vw' }} onChange={(evt) => { dispatch(setGoogleEmail({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }} />


                                <div>
                                    <span style={{ fontSize: '.7rem', }}>* This info will not be saved, it is used once and discarded to send the email*</span>
                                </div>

                            </div>
                        }




                    </div>


                    <div>
                        <div style={{ marginTop: '5%', display:'flex' }} >
                            <button onClick={() => { hidePopup2(); showPopup1(); dispatch(RemoveSection()); }} className={styles.popupBtn3}>Back</button>

                            <button onClick={() => { hidePopup2();  }} className={styles.popupBtn3}>Next</button>
                        </div>
                    </div>




                </div>





                <div id="popupBackground3" className={styles.popupBackground3}>
                </div>

                <div id="popupMessage3" className={styles.popupMessage3} >
                    <button className={styles.closeButton} onClick={() => { cancelSectionAdd() }}>X</button>
                    <div style={{ textAlign: 'left', marginLeft: '2%', fontSize: '18px', marginTop: '1%' }}>

                        {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Visual' && (
                            <ControlledInput
                                className={styles.nameSectionTitle}
                                className={styles.defaultInp}

                                style={{ padding: '.5vw', marginBottom: '1vh', minWidth: '8vw' }}
                                onChange={(evt) => dispatch(changeSectionName({
                                    index: sectionEditIndex,
                                    name: evt.target.value
                                }))}
                                value={forms?.formBeingMade?.sections[sectionEditIndex]?.sectionName || ''}
                                placeholder='Name section'
                            />
                        )}

                        {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Response' &&

                            <ControlledInput
                                className={styles.defaultInp}
                                className={styles.nameSectionTitle}
                                style={{ padding: '.5vw', marginBottom: '1vh', minWidth: '8vw' }}
                                onChange={(evt) => dispatch(changeSectionName({
                                    index: sectionEditIndex,
                                    name: evt.target.value
                                }))}
                                value={forms?.formBeingMade?.sections[sectionEditIndex]?.sectionName || ''}
                                placeholder='Name section'
                            />
                        }
                        {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Checklist' &&

                            <ControlledInput
                                className={`${styles.defaultInp} ${styles.nameSectionTitle}`}
                                style={{ padding: '.5vw', marginBottom: '1vh', minWidth: '8vw' }}
                                onChange={(evt) => dispatch(changeSectionName({
                                    index: sectionEditIndex,
                                    name: evt.target.value
                                }))}
                                value={forms?.formBeingMade?.sections[sectionEditIndex]?.sectionName || ''}
                                placeholder='Name section'
                            />
                        }
                        {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Data Entry' &&

                            <ControlledInput
                                className={`${styles.defaultInp} ${styles.nameSectionTitle}`}

                                style={{ padding: '.5vw', marginBottom: '1vh', minWidth: '8vw' }}
                                onChange={(evt) => dispatch(changeSectionName({
                                    index: sectionEditIndex,
                                    name: evt.target.value
                                }))}
                                value={forms?.formBeingMade?.sections[sectionEditIndex]?.sectionName || ''}
                                placeholder='Name section'
                            />}
                    </div>

                    <div style={{ overflowX: 'overlay' }}>



                    </div>

                    {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Data Entry' && /*textAlign: 'left', marginLeft: '2%', display: 'flex', height: '68%'  */
                        <div className={styles.pu3boxWrap} className={styles.pu3BoxDE} style={{ width: '98%' }} >
                            <div className={styles.topAreaPu3} >

                                <span>Item</span>

                                <ControlledInput
                                    className={styles.includeParToggle}
                                    id="checkbox1"
                                    type="checkbox"

                                    onChange={(evt) => toggleAllPar(evt, sectionEditIndex)}
                                />
                                        Include par

                                <ControlledInput
                                    className={styles.triggerPurchaseToggle}
                                    id="checkbox1"
                                    type="checkbox"
                                    onChange={(evt) => toggleAllPurchaseTriggers(evt, sectionEditIndex)}
                                />
                                        Trigger purchase

                                        {forms?.formBeingMade?.sections[sectionEditIndex].fields.length > 0 && forms?.formBeingMade?.sections[sectionEditIndex].fields[0].userResponse.length > 0 &&

                                    <label className={styles.userResponseText}  >User Response</label>
                                }



                            </div>

                            {forms?.formBeingMade?.sections[sectionEditIndex].fields.map((field, index3) => (
                                <div className={styles.editWrapperOutside}  >

                                    <div className={styles.editWrapper}>

                                        <input id="checkbox1" type="checkbox" className={styles.dataCheckbox} onChange={(evt) => toggleRowForDeletion(evt, sectionEditIndex, index3)} />

                                        <div className={styles.selectWrapperForm}>

                                            <div style={{ height: 'auto', width: '10vw' }}>
                                                {loading == 'Done' && <Select id="itemSelect" ref={selectItemInputRef} value={forms.formBeingMade.sections[sectionEditIndex].fields[index3].item} className={styles.itemSelect} onChange={(item) => itemSelectedInField(item, sectionEditIndex, index3)}
                                                    options={items}
                                                    className={styles.itemSelect}
                                                    style={{ width: '100%', alignContent: 'center', marginBottom: 'auto', marginTop: 'auto', marginTop: '-.3%', }}
                                                    getOptionLabel={(option) => option.itemName}
                                                    getOptionValue={(option) => option.itemName}
                                                > </Select>
                                                }
                                            </div>

                                        </div>


                                        <div className="checkboxes" style={{ alignItems: 'center', display: 'flex' }}>
                                            <label><input type="checkbox" checked={forms.formBeingMade.sections[sectionEditIndex].fields[index3].includePar} onChange={(evt) => settingIncludePar(evt, sectionEditIndex, index3)} />

                                            </label>
                                        </div>

                                        <ControlledInput
                                            className={styles.enterPar}
                                            /*  style={{ width: '85px', height: '15px', marginLeft: '1%', marginRight: '3.7%', marginTop: '-.2%' }} */
                                            value={forms.formBeingMade.sections[sectionEditIndex].fields[index3].parValue}
                                            onChange={(evt) => settingParValue(evt, sectionEditIndex, index3)}
                                            placeholder="Enter Par"
                                        />

                                        <label className={styles.triggerPurchaseCheckbox} >
                                            <input type="checkbox"
                                                checked={forms.formBeingMade.sections[sectionEditIndex].fields[index3].triggerPurchase}
                                                onChange={(evt) => settingTriggerPurchase(evt, sectionEditIndex, index3)} />

                                        </label>

                                        {field?.userResponse?.map((obj, index4) => (

                                            <div className={styles.selectWrapperForm} style={{ marginLeft: '3wv' }}>
                                                <input className={` ${styles.defaultInp} ${styles.userResponse}`} style={{ width: '100%', height: '100%' }} value={obj.question} onChange={(evt) => settingUserResponsePrompt(evt, sectionEditIndex, index3, index4)}></input>

                                            </div>
                                        ))}


                                        <div style={{ display: 'flex', alignItems: 'center', width: '82px', justifyContent: 'space-evenly' }}>
                                            <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={(evt) => removedCol({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: index3, columnIndex: field?.userResponse?.length - 1 })} />

                                            <FaPlusCircle style={{ marginLeft: '2%' }} onClick={(evt) => addDataEntryCol({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: index3, columnIndex: field?.userResponse?.length - 1 })} />

                                        </div>



                                    </div>



                                </div>

                            ))}


                            <div>
                                {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Data Entry' &&
                                    <div className={styles.popup3BtnWrapper} style={{ display: 'flex', marginLeft: '2%' }}>

                                        <button className={styles.popupBtn3} onClick={() => { onDeleteClicked() }} >Delete rows</button>


                                        <button className={styles.popupBtn3} onClick={() => { addFieldToFormSection('Data Entry', sectionEditIndex, forms.columnsToAdd) }} style={{ marginRight: '5%', width: '111px' }} >+ Add row</button>


                                        <button onClick={() => { hidePopup3(); showPopup2(); }} className={styles.popupBtn3} >Back</button>


                                        <button onClick={() => { hidePopup3(); finishClicked() }} className={styles.popupBtn3}>Finish</button>

                                    </div>
                                }
                            </div>



                        </div>
                    }

                    {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Checklist' &&
                        <div style={{ textAlign: 'left', marginLeft: '2%', display: 'block', height: '68%' }}>
                            <div className={styles.pu3Box} style={{ width: '98%', overflowX: 'none' }}>

                                <div style={{ marginBottom: '3%', marginTop: '2%', marginLeft: '4%' }}>
                                    {forms?.formBeingMade?.sections[sectionEditIndex].fields.map((field, index3) => (
                                        <div style={{ display: 'flex', width: '100%' }}>

                                            <input style={{ marginRight: '3%', marginTop: '-3%', }} id="checkbox1" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, sectionEditIndex, index3)} />
                                            <div style={{ display: 'flex' }}>

                                                <textarea className={styles.ChecklistDescription} style={{}}
                                                    onChange={(evt) => dispatch(setChecklistDescription({
                                                        text: evt.target.value,
                                                        sectionIndex: sectionEditIndex,
                                                        fieldIndex: index3
                                                    }))}
                                                    value={field.description}
                                                    className={styles.defaultInp}
                                                    placeholder='Enter a description'
                                                />


                                                <div style={{ width: '1000px' }}>

                                                    {field.answerChoices.map((item, columnIndex) => (
                                                        < input className={`${styles.defaultInp} ${styles.checklistInput}`}
                                                            style={{ marginLeft: columnIndex != 0 ? '3%' : '0%', }}
                                                            value={item.text} onChange={(evt) => editAnswerChoice(evt, columnIndex, index3 + 1, sectionEditIndex)} />
                                                    ))}
                                                    <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={(evt) => removedAnswerChoiceCol({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: index3, columnIndex: field.answerChoices.length })} />

                                                    <FaPlusCircle style={{ marginLeft: '2%' }} onClick={(evt) => dispatch(addAnswerChoiceColumn({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: index3, type: 'single' }))} />


                                                </div>

                                            </div>
                                        </div>

                                    ))}

                                </div>
                            </div>

                            <div >

                                <div >
                                    {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Checklist' &&
                                        <div style={{ display: 'flex', marginLeft: '2%' }}>

                                            <button className={styles.popupBtn3} onClick={() => { onDeleteClicked() }} >Delete rows</button>


                                            <button className={styles.popupBtn3} onClick={() => { addFieldToFormSection('Checklist', sectionEditIndex, [], forms.answerChoices) }} style={{ marginRight: '5%', width: '111px' }} >+ Add row</button>


                                            <button onClick={() => { hidePopup3(); showPopup2(); }} className={styles.popupBtn3}>Back</button>


                                            <button onClick={() => { hidePopup3(); finishClicked() }} className={styles.popupBtn3}>Finish</button>

                                        </div>
                                    }
                                </div>





                            </div>



                        </div>
                    }


                    {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Visual' &&
                        <div style={{ textAlign: 'left', marginLeft: '6%', display: 'block', height: '70%' }}>
                            <div className={styles.pu3Box} style={{ width: '98%' }}>

                                <div style={{ marginTop: '3%' }}>
                                    {forms?.formBeingMade?.sections[sectionEditIndex].fields.map((field, index3) => (
                                        <div style={{ marginTop: '2%', marginLeft: '4%' }}>

                                            {forms?.formBeingMade?.sections[sectionEditIndex].fields[index3].adminImageUpload != 'none' && <img style={{ width: '200px' }} src={`data:image/png;base64,${field?.adminImageUpload}`} />}

                                            <div style={{ display: 'flex' }}>

                                                <input className={styles.visualCheckbox} id="checkbox4" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, sectionEditIndex, index3)} />

                                                <div className={styles.visualfieldSection}  >

                                                    <label className={styles.fileInputLabel}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            className={styles.defaultInp}
                                                            type='file'
                                                            onChange={(evt) => settingVisualFileUpload(evt, sectionEditIndex, index3)}
                                                        />



                                                        <span className={styles.imageUpload1} style={{}}>Image Upload</span>
                                                        <FaUpload className={styles.uploadIcon} />

                                                    </label>




                                                </div>

                                                <div className={styles.visualfieldSection} >
                                                    <textarea className={styles.visualDescription} placeholder={'Enter description'} onChange={(evt) => { settingVisualDescription(evt, sectionEditIndex, index3) }} value={field.description}> </textarea>
                                                </div>

                                            </div>
                                        </div>

                                    ))}

                                </div>
                            </div>
                            <div >
                                {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Visual' &&
                                    <div style={{ display: 'flex', marginLeft: '2%' }}>

                                        <button className={styles.popupBtn3} onClick={() => { onDeleteClicked() }} >Delete rows</button>


                                        <button className={styles.popupBtn3} onClick={() => { addFieldToFormSection('Visual', sectionEditIndex, [], forms.answerChoices) }} style={{ marginRight: '5%', width: '111px' }} >+ Add row</button>


                                        <button onClick={() => { hidePopup3(); showPopup2(); }} className={styles.popupBtn3} >Back</button>


                                        <button onClick={() => { hidePopup3(); finishClicked() }} className={styles.popupBtn3}>Finish</button>

                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Response' &&

                        <div style={{ textAlign: 'left', marginLeft: '6%', display: 'block', height: '68%' }}>
                            <div className={styles.pu3Box} style={{ width: '98%' }}>

                                <div>
                                    {forms?.formBeingMade?.sections[sectionEditIndex].fields.map((field, index3) => (
                                        <div style={{ display: 'flex', marginLeft: '4%' }}>
                                            <input style={{textAlign:'center'}}  id="checkbox4" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, sectionEditIndex, index3)} />

                                            {field?.responseColumns?.map((obj, index4) => (

                                                <div className={styles.DEfields}>
                                                    <div className={styles.ResfieldSection}>
                                                        <textarea style={{ marginTop: '5%' }} className={styles.responseBox} placeholder={'Enter description'} value={obj.text} onChange={(evt) => { settingResponseDescription(evt, sectionEditIndex, index3, index4) }} ></textarea>

                                                    </div>


                                                </div>
                                            ))}

                                            <div style={{ marginTop: '2.2%', fontSize: '22px' }}>

                                                <FaRegTrashAlt onClick={(evt) => removeResponseCol({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: index3, columnIndex: field?.responseColumns.length - 1 })} />
                                            </div>


                                            <div style={{ marginTop: '2.2%', fontSize: '22px', marginLeft: '1%' }}>
                                                <FaPlusCircle onClick={(evt) => dispatch(addResponseColumn({ sectionIndex: forms.formBeingMade.sections.length, fieldIndex: index3, dontAddHeaderCol: true, type: 'single' }))} />


                                            </div>


                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div >
                                {forms?.formBeingMade?.sections[sectionEditIndex]?.type == 'Response' &&
                                    <div style={{ display: 'flex', marginLeft: '2%' }}>

                                        <button className={styles.popupBtn3} onClick={() => { onDeleteClicked() }} >Delete rows</button>


                                        <button className={styles.popupBtn3} onClick={() => { addFieldToFormSection('Response', sectionEditIndex, [], [], forms.responseColumns) }} style={{ marginRight: '5%', width: '111px' }} >+ Add row</button>


                                        <button onClick={() => { hidePopup3(); showPopup2(); }} className={styles.popupBtn3} >Back</button>


                                        <button onClick={() => { hidePopup3(); finishClicked() }} className={styles.popupBtn3}>Finish</button>

                                    </div>
                                }
                            </div>
                        </div>
                    }





                </div>



                <div id="popupBackground4" className={styles.popupBackground4}>
                </div>

                <div id="popupMessage4" className={styles.popupMessage4}>

                    <div className={styles.popup2Header}>
                        <button className={styles.closeButton} onClick={() => { closePopUp4() }}>X</button>
                    </div>

                    <div className={styles.popup2AssignSection}>
                        <h4 className="assignmentHeader" style={{ textAlign: 'left' }}>Assign</h4>

                        <div className={styles.popup2InputWrapper}>

                            <div className={styles.pu2JobSelectWrapper}>
                                {jobPositionLoading == 'Done' && (
                                    <Select
                                        id="jobSelect"
                                        options={jobPositions}
                                        className={styles.userSelect}
                                        onChange={(e) => { dispatch(setAssignedJobPositions({ e, sectionIndex: sectionEditIndex + 1 })) }}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions}
                                        isDisabled={forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1]?.assignedUser?.length > 0}
                                        placeholder="Job Positions"
                                        isMulti
                                        isOptionDisabled={() => selectedJobOptions.length >= 1}
                                        getOptionLabel={(option) => option.jobTitle}
                                        getOptionValue={(option) => option.jobTitle}
                                    />
                                )}

                            </div>

                            <div className={styles.pu2UserSelectWrapper}>
                                {loadingUsers == 'Done' && (
                                    <Select
                                        id="userSelect"
                                        className={styles.assignUserSelect}
                                        options={users}
                                        onChange={(e) => { dispatch(setAssignedUser({ e, sectionIndex: sectionEditIndex + 1 })) }}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedUser}
                                        isDisabled={forms?.formBeingMade?.sections[sectionEditIndex]?.assignedJobPositions?.length > 0}
                                        placeholder="Users"
                                        isMulti
                                        isOptionDisabled={() => selectedUserOptions.length >= 1}
                                        getOptionLabel={(option) => option.displayName}
                                        getOptionValue={(option) => option.displayName}
                                    />
                                )}
                            </div>



                        </div>

                    </div>


                    <div className={styles.popup2FrequencySection}>
                        <h4 className="frequency-header" style={{ textAlign: 'left' }}>Frequency</h4>

                        <div className={styles.popup2InputWrapper} style={{ display: 'block' }}>

                            <div className={styles.topSect}>

                                <div className={styles.pu2FrequencyWrapper}>
                                    <Select
                                        placeholder=""
                                        id="frequencySelect"
                                        options={frequencyOptions}
                                        className={styles.select2}
                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.frequency ? forms?.formBeingMade?.sections[sectionEditIndex]?.frequency : {}}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.label}
                                        onChange={(evt) => { dispatch(setFrequency({ value: evt, sectionIndex: sectionEditIndex + 1, reportIndex: 0 })) }}
                                    />
                                </div>


                                <div className="calendarWrapper" style={{ marginLeft: '3vw', zIndex: '100' }}>
                                    <Calendar
                                        dataFromParent={dataGoingToCalendar}
                                        onChange2={handleCallback}
                                        sectionIndex={forms.formBeingMade.sections.length - 1}
                                        reportIndex={0}

                                        initialValue={forms?.formBeingMade?.sections[sectionEditIndex]?.startDate}
                                    />
                                </div>

                                {forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'Infinite' && (
                                    <div>
                                        <div className="calendarWrapperInfinite" style={{ marginLeft: '10vw' }}>
                                            <Calendar dataFromParent={dataGoingToCalendar2}
                                                onChange2={handleCallback2}
                                                sectionIndex={sectionEditIndex}
                                                reportIndex={0}
                                                initialValue={forms?.formBeingMade?.sections[sectionEditIndex]?.endDate} />
                                        </div>
                                    </div>
                                )}

                                {/*forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'One Time' && (
                                    <div className="due-date-select" style={{ marginLeft: '5vw' }}>
                                        <Select
                                            placeholder=""
                                            id="frequencySelect"

                                            options={dueDateOptions}
                                            className={styles.select2}
                                            value={forms?.formBeingMade?.sections[sectionEditIndex]?.due ? forms?.formBeingMade?.sections[sectionEditIndex]?.due : {}}
                                            placeholder="Due date"
                                            getOptionLabel={(option) => option.label}
                                            getOptionValue={(option) => option.label}
                                            onChange={(evt) => { dispatch(setDueDateOfReport({ value: evt, sectionIndex: sectionEditIndex + 1, reportIndex: 0 })) }}
                                        />
                                    </div>
                                )*/}

                            </div>
                            <div className={styles.botSect}>
                                <div className={styles.reoccurenceWrapper}>
                                    {forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'Reoccuring' && (
                                        <div style={{
                                            marginTop: '5vh',
                                            display: 'flex',
                                            width: '99vw',
                                            marginLeft: '1vw',
                                            height: '4.2vh'
                                        }}>
                                            <h4 style={{
                                                minWidth: '6.25vw',
                                                height: '3.6vh',
                                                alignContent: 'center',
                                                marginTop: '0'
                                            }}>Repeat every</h4>

                                            <div style={{ display: 'flex' }}>
                                                <input
                                                    type='number'
                                                    className={styles.roundedGreyRec}
                                                    style={{
                                                        width: '10.05vw',
                                                        height: '3.7vh',
                                                        padding: '1vh',
                                                        marginLeft: '0.6vw',
                                                        marginRight: '0.3vw',
                                                        textAlign: 'center'
                                                    }}
                                                    value={forms?.formBeingMade?.sections[sectionEditIndex]?.intervalValue}
                                                    onChange={(evt) => { dispatch(setIntervalValue({ value: evt.target.value, sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                />
                                                <Select
                                                    placeholder=""
                                                    id="frequencySelect"
                                                    style={{
                                                        width: '10.05vw',
                                                        marginTop: '1vh',
                                                        height: '9.3vh',
                                                        marginLeft: '0.12vw'
                                                    }}
                                                    options={timePeriodSelect}
                                                    className={styles.select2}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setRepeatIntervalType({ value: evt, sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                />



                                            </div>

                                            <h4 style={{
                                                minWidth: '4.17vw',
                                                height: '3.6vh',
                                                alignContent: 'center',
                                                marginTop: '0'
                                            }}>Repeat</h4>

                                            <div style={{ display: 'flex' }}>
                                                <input
                                                    className={styles.roundedGreyRec}
                                                    style={{
                                                        width: '10.05vw',
                                                        height: '3.7vh',
                                                        padding: '1vh',
                                                        marginLeft: '0.6vw',
                                                        marginRight: '0.3vw',
                                                        textAlign: 'center'
                                                    }}

                                                    value={forms?.formBeingMade?.sections[sectionEditIndex]?.intervalAmount}

                                                    onChange={(evt) => { dispatch(setIntervalAmount({ value: evt.target.value, sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                />
                                            </div>

                                            <h4 style={{
                                                minWidth: '2.08vw',
                                                height: '3.6vh',
                                                alignContent: 'center',
                                                marginTop: '0'
                                            }}>times</h4>
                                        </div>
                                    )}


                                    {forms?.formBeingMade?.sections[sectionEditIndex]?.intervalType == 'Week' &&
                                        forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.intervalType != 'Day' &&
                                        forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'Reoccuring' && (

                                            <div style={{ maxWidth: '20vw' }}>

                                                <h5 style={{ color: 'grey', textAlign: 'left' }}> Display On</h5>
                                                <Select isMulti placeholder="Days" id="daySelect" options={daysOfWeek} className={styles.select2}
                                                    value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display?.length > 0 ? forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.display : []}

                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    onChange={(evt) => { dispatch(setDisplayOfReport({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}> </Select>

                                            </div>
                                        )}

                                    {forms?.formBeingMade?.sections[sectionEditIndex]?.intervalType == 'Month' &&
                                        forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'Reoccuring' && (
                                            <div className={styles.monthSection}>
                                                <div style={{ width: '20.83vw', marginBottom: '2vh' }}>
                                                    <input
                                                        type="radio"
                                                        id="onDay"
                                                        name="drone"
                                                        value="onDay"
                                                        onClick={(evt) => { dispatch(setMonthOption({ value: 'cardinal', sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                    />
                                                    <label style={{ marginRight: '0.4vw', marginLeft: '0.1vw' }} htmlFor="onDay">On day</label>
                                                    <input
                                                        type='number'
                                                        className={styles.roundedGreyRec}
                                                        style={{ textAlign: 'center' }}
                                                        onChange={(evt) => { dispatch(setDayValueOfMonthRecurrence({ value: evt.target.value, sectionIndex: sectionEditIndex })) }}
                                                    />
                                                </div>

                                                <div style={{ textAlign: 'left', display: 'flex' }}>
                                                    <input
                                                        type="radio"
                                                        id="onThe"
                                                        name="drone"
                                                        value="onThe"
                                                        onClick={(evt) => { dispatch(setMonthOption({ value: 'ordinal', sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                    />
                                                    <label className={styles.onTheText} htmlFor="onThe">On the</label>

                                                    <div className={styles.select2} style={{ paddingRight: '0.1vw' }}>
                                                        <Select
                                                            placeholder="First"
                                                            id="daySelect"
                                                            style={{ width: '6.15vw' }}
                                                            options={ordinalSelect}
                                                            className={styles.select2}
                                                            value={forms?.formBeingMade?.sections[sectionEditIndex]?.ordinalMonthValue || []}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            onChange={(evt) => { dispatch(setOrdinalValueMonth({ value: evt, sectionIndex: sectionEditIndex, reportIndex: 0 })) }}
                                                        />
                                                    </div>

                                                    <Select
                                                        placeholder="January"
                                                        id="daySelect"
                                                        style={{ width: '6.15vw' }}
                                                        options={daysOfWeek}
                                                        className={styles.select2}
                                                        value={forms.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.monthDayToRepeat || []}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthDayToRepeat({ value: evt, sectionIndex: sectionEditIndex })) }}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                    {forms?.formBeingMade?.sections[sectionEditIndex]?.intervalType == 'Year' &&
                                        forms?.formBeingMade?.sections[sectionEditIndex]?.frequency.label == 'Reoccuring' && (
                                            <div className={styles.yearSection} style={{
                                                width: '26.04vw',
                                                display: 'inline-block',
                                                marginLeft: '-42vw',
                                                marginBottom: '4vh',
                                                marginTop: '2vh'
                                            }}>
                                                <div style={{ display: 'flex' }}>
                                                    <input
                                                        type="radio"
                                                        id="onDay"
                                                        name="drone"
                                                        value="onDay"
                                                        onClick={(evt) => { dispatch(setYearOption({ value: 'cardinal', sectionIndex: sectionEditIndex })) }}
                                                    />
                                                    <label className={styles.onText} htmlFor="onDay">On</label>

                                                    <Select
                                                        placeholder="January"
                                                        id="daySelect"
                                                        options={MonthSelect}
                                                        style={{ marginRight: '0.3vw' }}
                                                        className={styles.select2}
                                                        value={forms?.formBeingMade?.sections[sectionEditIndex]?.monthValueForYear || []}
                                                        getOptionLabel={(option) => option.label}
                                                        getOptionValue={(option) => option.label}
                                                        onChange={(evt) => { dispatch(setMonthValueForYearRecurrence({ value: evt, sectionIndex: sectionEditIndex })) }}
                                                    />

                                                    <input
                                                        type='number'
                                                        className={styles.roundedGreyRec}
                                                        style={{ marginLeft: '0.1vw', textAlign: 'center' }}
                                                        placeholder="Enter Date"
                                                        onChange={(evt) => { dispatch(setDayValueForYearRecurrence({ value: evt.target.value, sectionIndex: sectionEditIndex })) }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>




                        </div>





                    </div>

                    <div className={styles.popup2DestinationSection}>

                        <div className={styles.popup2InputWrapper} style={{ display: 'block' }}>
                            <h4 className="assignmentHeader" style={{ textAlign: 'left' }}>Destination</h4>

                            <div className="destination-select-wrapper">
                                <Select
                                    id="destinationType"
                                    options={destinationType}
                                    className={`${styles.userSelect} destination-type-select`}
                                    value={forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.label}
                                    onChange={(evt) => { dispatch(setDestinationType({ value: evt, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }}
                                />


                            </div>

                        </div>
                        {
                            console.log("check : ", forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType)
                        }


                        {forms?.formBeingMade?.sections[forms.formBeingMade.sections.length - 1]?.destinationType.label == 'Send email alert' &&

                            <div style={{ textAlign: 'left' }}>

                                <input type='email' placeholder="Enter recipient email" className={styles.defaultInp} style={{ width: '15vw', marginRight: '2vw' }} onChange={(evt) => { dispatch(setGoogleEmail({ value: evt.target.value, sectionIndex: forms.formBeingMade.sections.length - 1, reportIndex: 0 })) }} />


                                <div>
                                    <span style={{ fontSize: '.7rem', }}>* This info will not be saved, it is used once and discarded to send the email*</span>
                                </div>

                            </div>
                        }




                    </div>


                    <div>
                        <div style={{ marginTop: '5%' }} >
                            <button onClick={() => { hideReschedulePopup(); }} className={styles.popupBtn3}>Done</button>

                        </div>
                    </div>




                </div>

                <div className={styles.pageWrapper}>

                    <h3 style={{ color: 'grey', marginBottom: '3%', marginLeft: '1%' }}>  {forms.formBeingMade.name ? forms.formBeingMade.name : 'Form Builder'} </h3>

                    <div style={{ display: 'flex', width: '100%' }}>

                        <input id="taskName" onChange={(evt) => dispatch(setFormName(evt.target.value))} placeholder={forms.formBeingMade.name ? forms.formBeingMade.name : 'Add Name'} value={forms.formBeingMade.name} style={{ marginBottom: '5%', marginLeft: '1%', height: '1px', }} className={`${styles.defaultInp}`} />


                        <button style={{ marginLeft: '2%', height: '5px', lineHeight: '5px' }} className={`${styles.defaultInp} ${styles.defaultInp2} ${styles.addASection}`} onClick={() => { showPopup1() }} disabled={forms.formBeingMade?.name ? false : true}>Add a section</button>

                    </div>

                    <div className={styles.blackLineDiv2}>
                        &nbsp;
                    </div>

                    {!previewOpen &&
                        <div className={styles.editArea}>
                            {forms.formBeingMade.sections.map((section, index2) => (
                                <div>
                                    <div className={styles.sectionDiv} key={index2}>

                                        <input className={`${styles.defaultInp} ${styles.defaultInp2} ${styles.nameSectionTitle}`} onChange={(evt) => dispatch(changeSectionName({ index: index2, name: evt.target.value }))} placeholder='Name section' value={section.sectionName} />

                                        {/* Section content */}
                                        {section.type == 'Data Entry' &&
                                            <div className={styles.pu3boxWrap} >
                                                <div className={styles.pu3Box} style={{ width: '98%' }}>
                                                    <div>
                                                        <div style={{ marginLeft: '6%' }}>
                                                            <span>Item</span>


                                                            <ControlledInput
                                                                className={styles.includeParToggle2}
                                                                id="checkbox1"
                                                                type="checkbox"
                                                                onChange={(evt) => toggleAllPar(evt, index2)}
                                                            />
                                                            Include par


                                                            <ControlledInput
                                                                className={styles.triggerPurchaseToggle2}
                                                                id="checkbox1"
                                                                type="checkbox"
                                                                onChange={(evt) => toggleAllPurchaseTriggers(evt, index2)}
                                                            />


                                                            Trigger purchase
                                                            {section.fields.length > 0 && section.fields[0].userResponse.length > 0 &&
                                                                <label style={{ marginLeft: (8.4) + '%' }}>User Response</label>
                                                            }
                                                        </div>
                                                    </div>

                                                    {section.fields.map((field, index3) => (
                                                        <div style={{ marginLeft: '4%' }} key={index3}>
                                                            <div className={styles.editWrapper}>
                                                                <input
                                                                    id="checkbox1"
                                                                    type="checkbox"
                                                                    className={styles.dataCheckbox}
                                                                    onChange={(evt) => toggleRowForDeletion(evt, index2, index3)}
                                                                />

                                                                <div className={styles.selectWrapperForm}>
                                                                    {loading == 'Done' &&
                                                                        <CustomSelect
                                                                            id="itemSelect"
                                                                            value={field.item}
                                                                            className={styles.select2}
                                                                            onChange={(item) => itemSelectedInField(item, index2, index3)}
                                                                            options={items}
                                                                            getOptionLabel={(option) => option.itemName}
                                                                            getOptionValue={(option) => option.itemName}
                                                                        />
                                                                    }
                                                                </div>

                                                                <div className={styles.triggerPurchaseCheckbox}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={field.includePar}
                                                                        onChange={(evt) => settingIncludePar(evt, index2, index3)}
                                                                    />
                                                                </div>

                                                                <ControlledInput
                                                                    className={styles.enterPar}
                                                                    value={field.parValue}
                                                                    onChange={(evt) => settingParValue(evt, index2, index3)}
                                                                    placeholder="Enter Par"
                                                                />

                                                                <div className={styles.triggerPurchaseCheckbox}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={field.triggerPurchase}
                                                                        onChange={(evt) => settingTriggerPurchase(evt, index2, index3)}
                                                                    />
                                                                </div>


                                                                {field?.userResponse?.map((obj, index4) => (

                                                                    <div className={styles.selectWrapperForm} style={{ marginLeft: '3wv' }}>
                                                                        <input className={` ${styles.defaultInp} ${styles.userResponse}`} style={{ width: '100%', height: '100%' }} value={obj.question} onChange={(evt) => settingUserResponsePrompt(evt, forms?.formBeingMade?.sections.length - 1, index3, index4)}></input>

                                                                    </div>
                                                                ))}


                                                                <div style={{ display: 'flex', alignItems: 'center', width: '82px', justifyContent: 'space-evenly' }}>
                                                                    <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={(evt) => removedCol({ sectionIndex: index2, fieldIndex: index3, columnIndex: field?.userResponse?.length - 1 })} />

                                                                    <FaPlusCircle style={{ marginLeft: '2%' }} onClick={(evt) => addDataEntryCol({ sectionIndex: index2, fieldIndex: index3, columnIndex: field?.userResponse?.length - 1 })} />

                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                        {section.type == 'Checklist' &&
                                            <div className={styles.pu3boxWrap} >
                                                <div className={styles.pu3Box} style={{ width: '98%', overflowX: 'none' }}>
                                                    <div style={{ marginBottom: '3%', marginTop: '2%', marginLeft: '4%' }}>
                                                        {section.fields.map((field, index3) => (
                                                            <div style={{ display: 'flex', width: '100%' }} key={index3}>
                                                                <input style={{ marginRight: '1.2%', marginTop: '-3%', }} id="checkbox1" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, index2, index3)} />
                                                                <div style={{ display: 'flex' }}>
                                                                    <textarea style={{ textAlign: 'center', width: '200px', height: '45px', marginTop: '0%', marginRight: '2.2%', marginBottom: '3%' }}
                                                                        onChange={(evt) => dispatch(setChecklistDescription({
                                                                            text: evt.target.value,
                                                                            sectionIndex: index2,
                                                                            fieldIndex: index3
                                                                        }))}
                                                                        value={field.description}
                                                                        className={styles.defaultInp}
                                                                        placeholder='Enter a description'
                                                                    />

                                                                    <div style={{ width: '1000px' }}>
                                                                        {field.answerChoices.map((item, columnIndex) => (


                                                                            <ControlledInput
                                                                                key={columnIndex}
                                                                                className={`${styles.defaultInp} ${styles.checklistInput}`}
                                                                                style={{ marginTop: '0%', textAlign: 'center', width: '80px', marginLeft: columnIndex != 0 ? '3%' : '0%', marginBottom: '3%' }}
                                                                                value={item.text}
                                                                                onChange={(evt) => editAnswerChoice(evt, columnIndex, index3 + 1, index2)}
                                                                            />

                                                                        ))}
                                                                        <FaRegTrashAlt style={{ marginLeft: '2%' }} onClick={(evt) => removedAnswerChoiceCol({ sectionIndex: index2, fieldIndex: index3, columnIndex: field.answerChoices.length })} />
                                                                        <FaPlusCircle style={{ marginLeft: '2%' }} onClick={(evt) => dispatch(addAnswerChoiceColumn({ sectionIndex: index2, fieldIndex: index3, type: 'single' }))} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {section.type == 'Visual' &&
                                            <div className={styles.pu3boxWrap} >
                                                <div className={styles.pu3Box} style={{ width: '98%' }}>
                                                    <div style={{ marginTop: '3%', marginLeft: '3vw' }}>
                                                        {section.fields.map((field, index3) => (
                                                            <div style={{ marginTop: '2%' }} key={index3}>
                                                                {field.adminImageUpload != 'none' && <img style={{ width: '200px' }} src={`data:image/png;base64,${field?.adminImageUpload}`} />}

                                                                <div style={{ display: 'flex' }}>
                                                                    <input className={styles.visualCheckbox} id="checkbox4" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, index2, index3)} />

                                                                    <div className={styles.visualfieldSection}  >
                                                                        <label className={styles.fileInputLabel}>
                                                                            <input
                                                                                style={{ display: 'none' }}
                                                                                className={styles.defaultInp}
                                                                                type='file'
                                                                                onChange={(evt) => settingVisualFileUpload(evt, index2, index3)}
                                                                            />
                                                                            <span className={styles.imageUpload1} style={{ minWidth: '12vw' }} >Image Upload</span>
                                                                            {deviceState.isDesktop && <FaUpload className={styles.uploadIcon2} />}
                                                                        </label>
                                                                    </div>

                                                                    <div className={styles.visualfieldSection} style={{ marginLeft: '4vw' }} >
                                                                        <textarea className={styles.visualDescription} placeholder={'Enter description'} onChange={(evt) => { settingVisualDescription(evt, index2, index3) }} value={field.description}> </textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {section.type == 'Response' &&
                                            <div className={styles.pu3boxWrap}>
                                                <div className={styles.pu3Box} style={{ width: '98%' }}>
                                                    <div style={{ marginLeft: '3vw' }}>
                                                        {section.fields.map((field, index3) => (
                                                            <div style={{ display: 'flex' }} key={index3}>
                                                                <input id="checkbox4" type="checkbox" onChange={(evt) => toggleRowForDeletion(evt, index2, index3)} />

                                                                {field?.responseColumns?.map((obj, index4) => (
                                                                    <div className={styles.DEfields} key={index4} style={{ paddingLeft: '0%' }}>
                                                                        <div className={styles.ResfieldSection}>
                                                                            <textarea style={{ marginTop: '5%' }} className={styles.responseBox} placeholder={'Enter description'} value={obj.text} onChange={(evt) => { settingResponseDescription(evt, index2, index3, index4) }} ></textarea>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                <div style={{ marginTop: '2.2%', fontSize: '22px' }}>
                                                                    <FaRegTrashAlt onClick={(evt) => removeResponseCol({ sectionIndex: index2, fieldIndex: index3, columnIndex: field?.responseColumns.length - 1 })} />
                                                                </div>

                                                                <div style={{ marginTop: '2.2%', fontSize: '22px', marginLeft: '1%' }}>
                                                                    <FaPlusCircle onClick={(evt) => dispatch(addResponseColumn({ sectionIndex: index2, fieldIndex: index3, dontAddHeaderCol: true, type: 'single' }))} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }


                                        <div className={styles.scheduledEditArea}>
                                            {(() => {
                                                if (section) {
                                                    const weekDayLetterList = getDaysOfWeekFromData(section?.display ?? []);
                                                    let recurrenceText = "";
                                                    let intervalText = "";
                                                    let dateRangeText = "";

                                                    const scheduleInfo = section;

                                                    if (scheduleInfo) {
                                                        const startDate = new Date(scheduleInfo.startDate);
                                                        const endDate = new Date(scheduleInfo.endDate);
                                                        const formatDate = (date) => date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

                                                        switch (scheduleInfo.intervalType) {
                                                            case "Day":
                                                                recurrenceText = "Daily";
                                                                dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                break;
                                                            case "Week":
                                                                recurrenceText = weekDayLetterList ? `Weekly on ${weekDayLetterList.join(",")}` : "Weekly";
                                                                dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                break;
                                                            case "Month":
                                                                if (scheduleInfo.monthOption === "cardinal") {
                                                                    recurrenceText = `Monthly on day ${scheduleInfo.dayValueForMonth}`;
                                                                } else if (scheduleInfo.monthOption === "ordinal") {
                                                                    recurrenceText = `Monthly on the ${scheduleInfo?.ordinalMonthValue?.label} ${scheduleInfo?.monthDayToRepeat?.label}`;
                                                                }
                                                                dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                break;
                                                            case "Year":
                                                                if (scheduleInfo.yearOption === "cardinal") {
                                                                    recurrenceText = `Yearly on ${scheduleInfo?.monthValueForYear?.label} ${scheduleInfo?.dayValueForYear}`;
                                                                } else if (scheduleInfo.yearOption === "ordinal") {
                                                                    recurrenceText = `Yearly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label} of ${scheduleInfo.monthValueForYear.label}`;
                                                                }
                                                                dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                break;
                                                            default:
                                                                if (scheduleInfo.frequency.label === "Infinite") {
                                                                    recurrenceText = "Infinite";
                                                                    dateRangeText = `${formatDate(startDate)}${scheduleInfo.endDate ? ` - ${formatDate(new Date(scheduleInfo.endDate))}` : ' - Ongoing'}`;
                                                                } else {
                                                                    recurrenceText = scheduleInfo.frequency.label;
                                                                    dateRangeText = `${formatDate(startDate)}`;
                                                                }
                                                        }

                                                        if (scheduleInfo.intervalValue && scheduleInfo.intervalValue !== "1") {
                                                            intervalText = `Every ${scheduleInfo.intervalValue} ${scheduleInfo?.intervalType?.toLowerCase()}${scheduleInfo.intervalValue !== "1" ? "s" : ""}`;
                                                        }
                                                    }

                                                    return (
                                                        <>
                                                            <button onClick={() => openReschedulePopup(index2)}> Edit Schedule:

                                                        <span className={styles.spanWidthtable1} >{section.form?.name}</span>
                                                                <span className={styles.spanWidthtable2}>{recurrenceText} </span>
                                                                <span className={styles.spanWidthtable2}>{intervalText} </span>
                                                                <span className={styles.spanWidthtable2} style={{ minWidth: '186px' }}>{dateRangeText}</span>
                                                            </button>



                                                            <button style={{ marginLeft: '2vw' }} onClick={() => addFieldToFormSection(section.type, index2, [], [], forms.responseColumns)}> Add Row </button>





                                                        </>
                                                    );
                                                }
                                            })()}





                                        </div>


                                    </div>
                                </div>

                            ))}
                        </div>
                    }





                    {previewOpen && forms.formBeingMade.sections.length > 0 &&
                        <div className={styles.previewArea}>
                            <div className={styles.titleSection} style={{ paddingLeft: '4%' }}>
                                <span> {forms.formBeingMade.name}</span>
                            </div>

                            {forms.formBeingMade.sections.map((section, index2) => (
                                section.fields.map((field, index3) => {
                                    if (section.type == 'Data Entry') {
                                        return (
                                            <div style={{ textAlign: 'left', marginLeft: '2%', display: 'flex', height: '68%' }} key={`${index2}-${index3}`}>
                                                <div className={styles.pu3Box} style={{ width: '98%', paddingTop: '2%' }}>
                                                    <div>
                                                        {index3 == 0 &&
                                                            <div style={{ marginLeft: '6%', display: 'flex' }}>
                                                                <span>Item</span>
                                                                <span style={{ marginLeft: '14%' }}>Par Amount</span>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div style={{ marginLeft: '4%' }}>
                                                        <div style={{ display: 'flex', height: 'auto', width: 'auto', marginTop: '.4%', marginBottom: '1.5%' }}>
                                                            <div className={styles.selectWrapperForm}>
                                                                <div style={{ height: '30px' }}>
                                                                    <input className={styles.defaultInp} disabled='true' value={field.item.itemName} placeholder="Enter Par" />
                                                                </div>
                                                            </div>

                                                            {field.includePar &&
                                                                <ControlledInput
                                                                    className={styles.defaultInp}
                                                                    /*style={{ width: '85px', height: '15px', marginLeft: '1%', marginRight: '3.7%', marginTop: '-.2%' }}*/
                                                                    value={forms.formBeingMade.sections[index2].fields[index3].parValue}
                                                                    onChange={(evt) => settingParValue(evt, index2, index3)}
                                                                    placeholder="Enter Par"
                                                                />}
                                                            {!field.includePar &&
                                                                <div style={{ width: '202px' }}> </div>
                                                            }

                                                            {field?.userResponse?.map((obj, index4) => (
                                                                <div className={styles.DEfieldSection} style={{ marginRight: '2.3%', width: '290px', marginTop: '-1.7%' }} key={index4}>

                                                                    <ControlledInput
                                                                        className={styles.defaultInp}
                                                                        style={{ width: '280px', height: '34px', display: 'flex' }}
                                                                        value={obj.question}
                                                                        onChange={(evt) => settingUserResponsePrompt(evt, index2, index3, index4)}
                                                                    />


                                                                    <input className={styles.defaultInp} style={{ marginTop: '-3%' }} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    if (section.type == 'Response') {
                                        return (
                                            <div style={{ textAlign: 'left', marginLeft: '6%', display: 'flex', height: '68%' }} key={`${index2}-${index3}`}>
                                                <div className={styles.pu3Box} style={{ width: '98%' }}>
                                                    <div className={styles.sectionFieldWrapper} style={{ marginLeft: '0%' }}>
                                                        <div style={{ display: 'flex', marginLeft: '0%' }}>
                                                            {field?.responseColumns?.map((obj, index4) => (
                                                                <div className={styles.DEfields} key={index4} style={{ paddingLeft: '0%' }}>
                                                                    <div className={styles.ResfieldSection}>
                                                                        <textarea style={{ marginTop: '5%', resize: "none", width: '300px', height: 'auto', border: 'none', background: 'transparent' }} disabled='true' value={obj.text} onChange={(evt) => { settingResponseDescription(evt, index2, index3, index4) }}></textarea>

                                                                        <div style={{ marginTop: '2%' }}>
                                                                            <input style={{ marginTop: '1%' }} className={styles.defaultInp} value={obj.userResponse}></input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    if (section.type == 'Checklist') {
                                        return (
                                            <div style={{ textAlign: 'left', marginLeft: '2%', display: 'flex', height: '68%' }} key={`${index2}-${index3}`}>
                                                <div className={styles.pu3Box} style={{ width: '98%', overflowX: 'none' }}>
                                                    <div className={styles.sectionFieldWrapper}>
                                                        <div style={{ marginLeft: '4%' }}>
                                                            <div style={{ display: 'flex', height: 'auto', width: 'auto', marginTop: '.4%', marginBottom: '2%' }}>
                                                                <div style={{ display: 'block', marginLeft: '1%' }}>
                                                                    <div style={{ marginBottom: '1.5%', marginLeft: '.5%' }}>
                                                                        <span disabeld='true' placeholder='Enter a description'>{field.description}</span>
                                                                    </div>

                                                                    <div style={{ width: '1000px' }}>
                                                                        {field.answerChoices.map((item, columnIndex) => (
                                                                            < input className={styles.defaultInp}
                                                                                style={{ marginTop: '0%', textAlign: 'center', width: '80px', marginLeft: columnIndex != 0 ? '3%' : '0%', marginBottom: '3%' }}
                                                                                value={item.text} disabled='true' key={columnIndex} />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    if (section.type == 'Visual') {
                                        return (
                                            <div style={{ textAlign: 'left', marginLeft: '6%', display: 'flex', height: '70%' }} key={`${index2}-${index3}`}>
                                                <div className={styles.pu3Box} style={{ width: '98%' }}>
                                                    <div style={{ marginTop: '3%' }}>
                                                        <div style={{ marginTop: '2%' }}>
                                                            {field.adminImageUpload != 'none' && <img style={{ width: '200px' }} src={`data:image/png;base64,${field?.adminImageUpload}`} />}

                                                            <div style={{ display: 'flex' }}>
                                                                <div className={styles.visualfieldSection} style={{ width: '370px', alignContent: 'center', height: '46px' }} >
                                                                    <label className={styles.fileInputLabel}>
                                                                        <input
                                                                            style={{ display: 'none' }}
                                                                            className={styles.defaultInp}
                                                                            type='file'
                                                                            onChange={(evt) => settingVisualFileUpload(evt, index2, index3)}
                                                                            disabled
                                                                        />
                                                                        <span className={styles.defaultInp} style={{ width: '66%', textAlign: 'center', alignContent: 'center', display: 'block' }}>Image Upload</span>
                                                                        <FaUpload style={{ display: 'block', marginTop: '-8%', marginBottom: '6%', marginLeft: '57%' }} />
                                                                    </label>
                                                                </div>

                                                                <div className={styles.visualfieldSection} style={{ width: '400px', marginLeft: '6%', alignContent: 'center', height: '46px' }}>
                                                                    <textarea className={styles.defaultInp} placeholder={'Enter description'} value={field.description} disabled> </textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            ))}
                        </div>
                    }


                    {forms.formBeingMade.sections.length > 0 &&
                        <div className={styles.bottomButtons}>
                            {previewOpen &&
                                <button className={styles.cancelbtn} onClick={() => { previewForm() }}>Edit form</button>
                            }

                            {!previewOpen &&
                                <div style={{ display: 'flex', marginRight: '1%' }}>
                                    <button className={styles.cancelbtn} onClick={() => { previewForm() }}>Preview form</button>

                                    <button onClick={() => { handlePrint() }} className={styles.cancelbtn}>Print</button>

                                    <button onClick={() => { onCancelClicked() }} className={styles.cancelbtn}>Cancel</button>


                                </div>

                            }

                            {!previewOpen && <button onClick={() => { onDeleteClicked() }} className={styles.cancelbtn} style={{ marginLeft: '4%', marginRight: '3%' }}>Delete rows</button>
                            }

                            {!previewOpen &&

                                <button onClick={() => { onSaveClicked() }} className={styles.saveBtn}>Save</button>
                            }

                        </div>

                    }
                </div>



            </>
        </div >
    )

    function addDataEntryCol(sectionIndex, fieldIndex, colIndex,) {

        dispatch(addColumnDuringCreation(sectionIndex, fieldIndex, colIndex))
    }


    function closePopUp4() {
        hideReschedulePopup();
    }

    function openReschedulePopup(index2) {
        document.getElementById("popupBackground4").style.display = "block"
        document.getElementById("popupBackground4").style.visibility = "visible"
        document.getElementById("popupMessage4").style.display = 'block'
        document.getElementById("popupMessage4").style.visibility = 'visible'

        console.log("Section check : ", forms.formBeingMade.sections[index2])


        setSectionBeingEditedIndex(index2)



        console.log("Index of section to change :", index2)


        // dispatch(setRequestedReport(forms.formBeingMade.sections[index2]))
    }

    function hideReschedulePopup() {
        document.getElementById("popupMessage4").style.display = "none"
        document.getElementById("popupBackground4").style.visibility = "hidden"



        if (forms.formBeingMade.sections[sectionEditIndex].frequency.label == "Reoccuring") {
            console.log("Calculate end date and save it here to display :", forms.formBeingMade.sections[sectionEditIndex])

            const correctEndDate = calculateEndDate(forms.formBeingMade.sections[sectionEditIndex]);

            dispatch(setEndDate({ data: correctEndDate, sectionIndex: sectionEditIndex }))
            console.log("End date corrected :", correctEndDate)
        }

    }

    function getDaysOfWeekFromData(display) {
        console.log("receiving: ", display);

        // Ensure display is an array
        const displayArray = Array.isArray(display) ? display : [];

        const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const letterMap = {
            "Sunday": "Su",
            "Monday": "M",
            "Tuesday": "T",
            "Wednesday": "W",
            "Thursday": "Th",
            "Friday": "F",
            "Saturday": "Sa"
        };

        // Create a map of days present in the display data
        const presentDays = new Map(displayArray.map(item => [item.label, item]));

        // Sort and map the days to their abbreviated forms
        const letterList = dayOrder
            .filter(day => presentDays.has(day))
            .map(day => letterMap[day]);

        return letterList;
    }
    function calculateEndDate(section) {
        if (!section.startDate || !section.frequency || !section.intervalType ||
            !section.intervalValue || !section.intervalAmount) {
            return null;
        }

        let endDate = new Date(section.startDate);

        // Only calculate if it's recurring
        if (section.frequency.label === "Reoccuring") {
            const intervalValue = parseInt(section.intervalValue);
            const intervalAmount = parseInt(section.intervalAmount);

            switch (section.intervalType) {
                case "Day":
                    // Add (intervalValue * intervalAmount) days
                    endDate.setDate(endDate.getDate() + (intervalValue * intervalAmount));
                    break;

                case "Week":
                    // Add (intervalValue * intervalAmount) weeks
                    endDate.setDate(endDate.getDate() + (intervalValue * intervalAmount * 7));
                    break;

                case "Month":
                    // Add (intervalValue * intervalAmount) months
                    endDate.setMonth(endDate.getMonth() + (intervalValue * intervalAmount));
                    break;

                case "Year":
                    // Add (intervalValue * intervalAmount) years
                    endDate.setFullYear(endDate.getFullYear() + (intervalValue * intervalAmount));
                    break;

                default:
                    return null;
            }

            // If the task has display days (specific days of week), adjust end date accordingly
            if (section.display && section.display.length > 0) {
                const sortedDays = sort_days(section.display);
                if (sortedDays.length > 0) {
                    // Find the last occurrence of the last day in the sorted days
                    const lastDay = sortedDays[sortedDays.length - 1];
                    endDate = getFirstDayOfWeekAfter(endDate, lastDay);
                }
            }
        }

        return endDate;
    }

    function handleSetDisplay2(k) {
        console.log("Handle display 2 : ", k)

        setDisplayTypeCal2(k)
    }
    function handleSetDisplay1(k) {
        console.log("Handle display 1: ", k)

        setDisplayTypeCal1(k)
    }


    function handleFileClick() {

        hiddenFileInput.current.click();    // ADDED
    }


    function pageClickEvent(e) {
        //somewhere on the page was clicked
        let parent = findParent(e.target)

        //if its inside a calendar
        if (parent.id == "mainCal") {
            //do nothing
        }
        else if (parent.tagName == 'BODY') {
            //close any opened calendars 

            setDisplayTypeCal1('Input')
            setDisplayTypeCal2('Input')
        }

    }

    function findParent(element) {
        //used to iterate through html parent nodes of the clicked part of the screen
        //check if the click passes through any area we specified based on id below...
        //and logic to close the necessary components based on the click
        if (element.parentNode) {
            if (element.id == "mainCal" || element.id == "mainCal2") {
                return element
            }
            else {
                if (element.tagName == 'BODY') {
                    return element
                }
                else {
                    return (findParent(element.parentNode))
                }
            }
        }
        else {
            return element
        }
    }

    function handleCallback(data, sectionIndex, reportIndex) {
        dispatch(setStartDate({ data, sectionIndex, reportIndex }));
    }

    function handleCallback2(data, sectionIndex, reportIndex) {
        console.log("Setting end date :", "yep")
        dispatch(setEndDate({ data, sectionIndex, reportIndex }))

    }

    function toggleReportRow(evt, sectionIndex, rowIndex) {
        console.log("toggleReportRow: ", evt.target.checked, sectionIndex, rowIndex)
        dispatch(toggleReportRowForDeletion({ evt: evt.target.checked, sectionIndex: sectionIndex, rowIndex: rowIndex, newReports: forms.reportsPendingRemoval }))
    }

    function previewForm() {
        if (!previewOpen) {
            setPreviewOpenStatus(true)
        }
        else {
            setPreviewOpenStatus(false)
        }
    }

    function CustomSelect({
        placeholder,
        id,
        options,
        className,
        value,
        onChange,
        multiple,
        isClearable,
        getOptionLabel = (option) => option?.label,
        getOptionValue = (option) => option?.label,
        ...props
    }) {
        const [isOpen, setIsOpen] = useState(false);

        const handleChange = (e) => {
            if (e.target.value === 'reset') {
                onChange(multiple ? [] : null);
            } else if (multiple) {
                const selectedValues = Array.from(e.target.selectedOptions).map(option => option.value);
                const selectedOptions = options.filter(option =>
                    selectedValues.includes(String(getOptionValue(option)))
                );
                onChange(selectedOptions);
            } else {
                // Convert the selected value to number if it's numeric
                const targetValue = !isNaN(e.target.value) ? Number(e.target.value) : e.target.value;
                const selectedOption = options.find(option =>
                    getOptionValue(option) === targetValue
                );
                onChange(selectedOption);
            }
        };

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        // Helper function to get the current value for the select
        const getCurrentValue = () => {
            if (multiple) {
                return Array.isArray(value) ? value.map(v => getOptionValue(v)) : [];
            }
            // Handle both numeric and string values
            return value ? String(getOptionValue(value)) : '';
        };

        return (
            <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                <select
                    id={id}
                    className={className}
                    value={getCurrentValue()}
                    onChange={handleChange}
                    multiple={multiple}
                    onBlur={toggleDropdown}
                    onFocus={toggleDropdown}
                    {...props}
                >
                    {placeholder && <option value="" disabled>{placeholder}</option>}
                    {options?.map((option) => (
                        <option
                            key={String(getOptionValue(option))}
                            value={String(getOptionValue(option))}
                        >
                            {getOptionLabel(option)}
                        </option>
                    ))}
                    {isClearable && <option value="reset">Reset</option>}
                </select>
            </div>
        );
    }

    function toggleAllPar(evt, index) {
        console.log("Toggling par :", evt, index)
        dispatch(toggleAllPars({ checked: evt.target.checked, sectionIndex: index }))
    }

    function toggleAllPurchaseTriggers(evt, index) {//little unnecesary here but it is what it is
        dispatch(toggleAllPurchaseTrigger({ checked: evt.target.checked, sectionIndex: index }))
    }

    function finishClicked() {
        hidePopup1()
        hidePopup2()
        hidePopup3()

        let sectionIndex = forms.formBeingMade.sections.length - 1
        if (forms?.columnsToAdd?.length > 0) {
            dispatch(removeColumnDuringCreation([]))
        }
        let tempList = []

        let reports = [...forms.requestedReports]

        // console.log("Before sort :" , reports)
        reports = reports.sort(function (a, b) {
            //   console.log("Check : a b :" , a , b , new Date(a.startDate) < new Date(b.startDate))

            return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1//TODO refactor to avoid expensive stuff like this
        });



        if (forms.formBeingMade.sections[sectionIndex].frequency.label == "Reoccuring") {
            console.log("Calculate end date and save it here to display :", forms.formBeingMade.sections[sectionIndex])

            const correctEndDate = calculateEndDate(forms.formBeingMade.sections[sectionIndex]);

            dispatch(setEndDate({ data: correctEndDate, sectionIndex: sectionIndex }))
            console.log("End date corrected :", correctEndDate)
        }


        dispatch(setSechdule({ sectionIndex: sectionIndex, requestedReports: reports }))

        dispatch(setItemsToAdd({ tempList })) //clearing items selected

        //Clearing assignment page select states
    }

    function cancelSectionAdd() {
        dispatch(RemoveSection())

        hidePopup1()
        hidePopup2()
        hidePopup3()
    }

    function editResponseColumns(evt, colIndex) {
        console.log("Evt chekc : ", evt?.target?.value)

        dispatch(editResponseColumn({ data: evt?.target?.value, columnIndex: colIndex }))
    }

    function editAnswerChoice(evt, colIndex, fieldIndex, sectionIndex) {
        console.log("Evt chekc : ", evt.target.value, colIndex, fieldIndex, sectionIndex)


        dispatch(setAnswerChoices({ data: evt.target.value, fieldIndex: fieldIndex, sectionIndex: sectionIndex, columnIndex: colIndex }))
    }

    function deleteUserResponseField(sectionIndex, fieldIndex, columnIndex) {
        dispatch(deleteUserResponse({ sectionIndex: sectionIndex, fieldIndex: fieldIndex, columnIndex: columnIndex }))
    }


    function addUserResponseField(sectionIndex, fieldIndex) {
        console.log("whys this failing :", sectionIndex, fieldIndex)

        dispatch(addUserResponse({ sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    function settingIncludePar(evt, sectionIndex, fieldIndex) {
        dispatch(setIncludePar({ status: evt.target.checked, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))

    }




    function settingTriggerPurchase(evt, sectionIndex, fieldIndex) {
        dispatch(setTriggerPurchase({ status: evt.target.checked, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }



    function settingResponseDescription(evt, sectionIndex, fieldIndex, columnIndex) {
        console.log("settingResponseDescription  : ", evt.target.value)
        dispatch(setResponseDescription({ text: evt.target.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex, columnIndex: columnIndex }))

    }

    async function settingVisualFileUpload(evt, sectionIndex, fieldIndex) {
        console.log("File upload : ", evt, sectionIndex, fieldIndex)

        //uploadingFile
        await dispatch(uploadingFile({ name: evt.target.value, files: evt.target.files[0] }))

        await dispatch(getOneFile({ value: evt.target.files[0].name, sectionIndex, fieldIndex })) //Loads file id into state

        //  dispatch(setVisualFileUpload({files: evt.target.files, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    function toggleRowForDeletion(evt, index1, index2) {
        //rows can change after being toggled for deletion, so we have to save a list of indexes to delete
        console.log("EVT :", evt.target.checked)
        let listOfCheckedRowsToDelete = structuredClone(selectedDeletionRows)
        let toRemove = { section: index1, field: index2 }

        let result;
        if (evt.target.checked) {
            console.log('Checked to delete :  ', listOfCheckedRowsToDelete, toRemove)

            listOfCheckedRowsToDelete.push(toRemove)
            dispatch(setSelectedDeleteRows(listOfCheckedRowsToDelete))

        }
        else {

            console.log("Looking for : ", toRemove, " in , ", listOfCheckedRowsToDelete)

            result = listOfCheckedRowsToDelete.filter(function (element) {
                console.log("cheeeeeeeeeck : ", element.section != toRemove.section, element.field != toRemove.field, toRemove, element, element.field, toRemove.field)

                if (element.field != toRemove.field) {
                    return element
                }
                else {
                    if (element.section != toRemove.section) {
                        return element
                    }
                    else {
                        //match found, dont return it (that way it gets filtered out)
                    }
                }



            });
            console.log("Final result ROWS :", result)

            dispatch(setSelectedDeleteRows(result))

        }
    }

    function removeResponseCol(i) {

        let temp1 = [...forms.responseColumns]

        temp1.splice(i, 1)

        let j = 0
        /*   temp1.forEach(element => {
                    document.getElementById('responseTA' + j).value = element.text
               j++
           });*/

        console.log(" setting new/removing response column", temp1)


        dispatch(removeResponseColumn({ data: temp1, columnIndex: i }))
    }

    function removedAnswerChoiceCol(i) {

        console.log("Check :  ", i)
        /*
                let temp1 = [...forms.answerChoices]

                temp1.splice(i, 1)

                /*   let j = 0
                   temp1.forEach(element => {
                    document.getElementById('answerChoiceInp' + j).value = element.text
                       j++
                   });*/

        dispatch(removeAnswerChoice({ sectionIndex: i.sectionIndex, fieldIndex: i.fieldIndex, columnIndex: i.columnIndex }))
    }


    function removedCol(indexes) {
        dispatch(removeColumnDuringCreation({ sectionIndex: indexes.sectionIndex, fieldIndex: indexes.fieldIndex, columnIndex: indexes.columnIndex }))
    }


    function settingVisualDescription(evt, sectionIndex, fieldIndex) {
        console.log("Setting value : ", evt.target.value)
        dispatch(setVisualDescription({ text: evt.target.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    /*
        function PrintButton() {
            const componentRef = useRef();

            return (
                <div>
                    <ComponentToPrint />
                    <ReactToPrint
                        trigger={() => <button>Print this out!</button>}
                        content={() => componentRef.current}
                    />
                </div>
            );
        }*/



    function settingChecklistDescription(evt, sectionIndex, fieldIndex) {
        dispatch(setChecklistDescription({ text: evt.target.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
    }

    function onDeleteClicked() {
        //loop over indexes to delete
        let i = 0 //tracking iteration to adjust for shifting index (item gets deleted, so we need to update index to delete)
        selectedDeletionRows.forEach(element => {
            dispatch(removeFieldFromSection({ type: "DoesntMatter", index: element, iAdjust: i }))
            i++
        });


        dispatch(setSelectedDeleteRows([]))

    }

    function settingParValue(evt, sectionIndex, fieldIndex) {
        console.log("Setting par value : ", evt)
        dispatch(setParValue({ par: evt.target.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))

    }
    function settingAction(item, sectionIndex, fieldIndex) {
        console.log("settingAction : ", item)

        dispatch(setAction({ action: item.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))

    }


    function settingUserResponsePrompt(item, sectionIndex, fieldIndex, columnIndex) {
        console.log("Setting user prompt : ", item.target.value)

        dispatch(setUserResponsePrompt({ prompt: item.target.value, sectionIndex: sectionIndex, fieldIndex: fieldIndex, columnIndex: columnIndex }))

    }


    function itemSelectedInField(item, sectionIndex, fieldIndex) {

        dispatch(SaveItemToField({ item: item, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
        if (item?.par) {
            dispatch(setParValue({ par: item.par, sectionIndex: sectionIndex, fieldIndex: fieldIndex }))
        }
    }

    function addFieldToFormSection(evt, index2, columnsToAdd = [], answerChoices = [], responseColumns = []) {
        console.log('Columns to add : ', columnsToAdd)

        if (evt == 'Data Entry') {
            dispatch(addFieldToSection({ type: evt, index: index2, columnsToAdd: columnsToAdd }))
            //set user response prompt/question for newly added row
            let i = 0
            columnsToAdd.forEach(element => {
                dispatch(addUserResponse({ sectionIndex: index2, fieldIndex: forms.formBeingMade.sections[index2].fields.length }))

                dispatch(setUserResponsePrompt({
                    prompt: element.question, sectionIndex: index2,
                    columnIndex: i,
                    fieldIndex: forms.formBeingMade.sections[index2].fields.length - 1
                }))
                i++
            });

        }
        else if (evt == 'Checklist') {

            dispatch(addFieldToSection({ type: evt, index: index2 }))

            let i = 0
            //  answerChoices.forEach(element => {
            //    dispatch(addAnswerChoiceColumn({ sectionIndex: forms.formBeingMade.sections.length - 1, fieldIndex: forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1].fields.length, dontAddHeaderCol: true }))

            //    dispatch(setAnswerChoices({ data: element.text, sectionIndex: -1, columnIndex: i, fieldIndex: forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1].fields.length + 1 }))
            //      i++
            //    });
        }
        else if (evt == 'Visual') {
            dispatch(addFieldToSection({ type: evt, index: index2 }))
        }
        else if (evt == 'Response') {
            dispatch(addFieldToSection({ type: evt, index: index2 }))

            console.log("Adding new row, current columns : ", responseColumns)
            let j = 0

            for (let j = 0; j < responseColumns.length - 1; j++) {
                const element = responseColumns[j];
                console.log('Adding column to new row: ', element, j)

                dispatch(addResponseColumn({
                    sectionIndex: forms.formBeingMade.sections.length - 1,
                    fieldIndex: forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1].fields.length,
                    dontAddHeaderCol: true
                }))

                dispatch(setResponseDescription({
                    data: element.text,
                    sectionIndex: -1,
                    columnIndex: j,
                    fieldIndex: forms.formBeingMade.sections[forms.formBeingMade.sections.length - 1].fields.length
                }))
                j++

            }

        }
    }

    function addSectionToForms(evt) {
        dispatch(AddSection(evt))
    }


    function hidePopup1() {
        document.getElementById("popupMessage1").style.display = "none"
        document.getElementById("popupBackground1").style.visibility = "hidden"
    }

    function showPopup1() {
        document.getElementById("popupBackground1").style.display = "block"
        document.getElementById("popupBackground1").style.visibility = "visible"
        document.getElementById("popupMessage1").style.display = 'block'
        document.getElementById("popupMessage1").style.visibility = 'visible'

        setSectionBeingEditedIndex(forms.formBeingMade.sections.length)

    }

    function hidePopup2() {
        document.getElementById("popupMessage2").style.display = "none"
        document.getElementById("popupBackground2").style.display = "none"


    }

    function showPopup2() {
        document.getElementById("popupBackground2").style.visibility = "visible"
        document.getElementById("popupMessage2").style.visibility = 'visible'
        document.getElementById("popupBackground2").style.display = "block"
        document.getElementById("popupMessage2").style.display = 'block'

        document.getElementById("popupBackground1").style.display = "none"
        document.getElementById("popupBackground1").style.visibility = "hidden"
        document.getElementById("popupMessage1").style.display = 'none'
        document.getElementById("popupMessage1").style.visibility = 'hidden'

        setSectionBeingEditedIndex(forms.formBeingMade.sections.length)

        //setSectionBeingEditedIndex(0)


    }

    function hidePopup3() {
        document.getElementById("popupMessage3").style.display = "none"
        document.getElementById("popupBackground3").style.display = "none"
    }

    function showPopup3() {
        document.getElementById("popupBackground3").style.visibility = "visible"
        document.getElementById("popupBackground3").style.display = "block"

        document.getElementById("popupMessage3").style.visibility = "visible"
        document.getElementById("popupMessage3").style.display = "block"
    }

    function onCancelClicked() {
        dispatch(clearForm())
    }

    function testCode2() {
        let name2 = document.getElementById("taskName").value

        let tempFormClone = structuredClone(JSON.parse(JSON.stringify(forms.formBeingMade)))

        tempFormClone.name = name2
        tempFormClone.createdBy = user._id

    }
    function ordinalToDate(year, ordinal, month, dayOfWeek) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        const monthIndex = monthNames.indexOf(month.label);
        const dayIndex = daysOfWeek.indexOf(dayOfWeek.label);

        if (monthIndex === -1 || dayIndex === -1) {
            throw new Error("Invalid month or day of week");
        }

        let date = new Date(year, monthIndex, 1);

        // Find the first occurrence of the day in the month
        while (date.getDay() !== dayIndex) {
            date.setDate(date.getDate() + 1);
        }

        if (ordinal.value === -1) {
            // Last occurrence
            let lastOccurrence = new Date(date);
            while (true) {
                date.setDate(date.getDate() + 7);
                if (date.getMonth() !== monthIndex) {
                    return lastOccurrence;
                }
                lastOccurrence = new Date(date);
            }
        } else {
            // Nth occurrence
            date.setDate(date.getDate() + (ordinal.value - 1) * 7);

            // Check if we've moved to the next month
            if (date.getMonth() !== monthIndex) {
                throw new Error("The specified ordinal date does not exist in this month");
            }
        }

        return date;
    }

    // Helper function to check if a year is a leap year
    function isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

    }
    function getNextAppearanceYear(startDate, targetMonth, targetDate) {
        // Ensure we're working with a Date object
        const start = new Date(startDate);

        console.log("Function recieves :", startDate, targetMonth, targetDate)
        // Convert month name to month index (0-11)
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const targetMonthIndex = monthNames.indexOf(targetMonth);

        if (targetMonthIndex === -1) {
            throw new Error("Invalid month name");
        }

        // Create a new Date object for the result
        let result = new Date(start.getFullYear(), targetMonthIndex, targetDate);

        // If the result is before or equal to the start date, move to the next year
        if (result <= start) {
            result.setFullYear(result.getFullYear() + 1);
        }

        return result;
    }
    function getNthDayOfWeekAfterDate(startDate, nthOccurrence, targetDayOfWeek, monthInterval) {


        console.log("Inpts getNthDayOfWeekAfterDate : ", startDate, nthOccurrence, targetDayOfWeek, monthInterval)
        // Ensure proper date object
        const date = new Date(startDate);

        // Convert targetDayOfWeek from {label: "Monday"} format to 0-6 index
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const targetDayIndex = daysOfWeek.indexOf(targetDayOfWeek);

        if (targetDayIndex === -1) {
            throw new Error("Invalid day of week");
        }



        for (let i = 0; i < 7; i++) {

            if (date.getDay() !== targetDayIndex) {
                date.setDate(date.getDate() + 1)
            }
        }

        console.log("Date of first/next weekday :", date)

        let currentInterval = Math.floor(date.getDate() / 7)
        if (nthOccurrence == -1) {
            // Last occurrence of the month
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            while (date <= lastDayOfMonth) {
                if (date.getDay() === targetDayIndex &&
                    new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000).getMonth() !== date.getMonth()) {
                    return date;
                }
                date.setDate(date.getDate() + 7);
            }

        } else {
            let intervalDifference = nthOccurrence - currentInterval

            if (intervalDifference <= 0) {//event has already passed (was in a previous week)

            }
            else {
                return date.setDate(date.getDate() + (intervalDifference * 7))
            }

            console.log("current interval : ", currentInterval)
        }

        console.log("Final date :", date)
        return date;
    }

    function getWeekdayName(weekday) {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return weekdays[weekday];
    }

    function getMonthName(month) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return months[month - 1];
    }
    function getNthWeekdayOfMonth(weekday, date, occurrence) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1

        const day = new Date(year, month - 1, 1).getDay(); // Get the weekday of the 1st day of the month
        const diff = weekday - day < 0 ? weekday - day + 7 : weekday - day; // Calculate the difference to the target weekday

        let firstOccurrence = 1 + diff;

        if (occurrence === 'Last') {
            const daysInMonth = new Date(year, month, 0).getDate(); // Get the total number of days in the month
            firstOccurrence += 7 * Math.floor((daysInMonth - firstOccurrence) / 7);
        } else {
            firstOccurrence += 7 * (occurrence - 1);
        }

        return new Date(year, month - 1, firstOccurrence);
    }

    function sort_days(scrambledDays) {
        const fullWeek = [
            { label: "Sunday" },
            { label: "Monday" },
            { label: "Tuesday" },
            { label: "Wednesday" },
            { label: "Thursday" },
            { label: "Friday" },
            { label: "Saturday" }
        ];

        // Get today's day index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const todayIndex = new Date().getDay();

        // Filter out any invalid day names and get the indices of the valid ones
        const indices = scrambledDays
            .filter(day => fullWeek.some(fullDay => fullDay.label === day.label))
            .map(day => fullWeek.findIndex(fullDay => fullDay.label === day.label));

        // Sort the indices
        indices.sort((a, b) => a - b);

        // Rotate the indices so that today's day is at index 0
        const rotatedIndices = indices.map(index => (index - todayIndex + 7) % 7);

        // Sort the rotated indices
        rotatedIndices.sort((a, b) => a - b);

        // Map the sorted rotated indices back to day objects
        return rotatedIndices.map(index => fullWeek[(index + todayIndex) % 7]);
    }

    function getFirstDayOfMonthAfter(date, targetDayOfMonth) {

        // Create a new Date object to avoid modifying the original
        let result = new Date(date);
        result.setDate(1); // Set the date to the first day of the month

        // Get the current month
        let currentMonth = result.getMonth();

        // Calculate the difference in target day of the month
        let diff = targetDayOfMonth - result.getDate();

        if (diff < 0) {
            // If the target day is before the current day, move to the next month
            result.setMonth(currentMonth + 1);
            result.setDate(targetDayOfMonth); // Set to the target day of the next month
        } else {
            result.setDate(targetDayOfMonth); // Set to the target day of the current month
        }

        return result;
    }

    function getFirstDayOfWeekAfter(date, targetDayOfWeek) {
        console.log("inp :", date, targetDayOfWeek);

        // Create a new Date object to avoid modifying the original
        let result = new Date(date);

        const fullWeek = [
            { label: "Sunday" },
            { label: "Monday" },
            { label: "Tuesday" },
            { label: "Wednesday" },
            { label: "Thursday" },
            { label: "Friday" },
            { label: "Saturday" }
        ];

        // Find the index of the target day in fullWeek
        let targetDayIndex = fullWeek.findIndex(day => day?.label === targetDayOfWeek?.label);

        // If the day is not found, return null or throw an error
        if (targetDayIndex === -1) {
            console.error("Invalid target day:", targetDayOfWeek);
            return null;
        }

        // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        let currentDayOfWeek = result.getDay();

        // Calculate days to add:
        // If current day is before target day, add the difference
        // If current day is after target day, add 7 minus the difference
        let daysToAdd = (targetDayIndex + 7 - currentDayOfWeek) % 7;

        // If daysToAdd is 0, it means we're on the target day, so we add 7 to get the next occurrence
        daysToAdd = daysToAdd === 0 ? 7 : daysToAdd;

        // Add the calculated days
        result.setDate(result.getDate() + daysToAdd);

        return result;
    }


    function onSaveClicked() {

        console.log("Goal here is to get edit to save :", history.location.state)


        let name2 = document.getElementById("taskName").value

        let tempFormClone = structuredClone(JSON.parse(JSON.stringify(forms.formBeingMade)))

        tempFormClone.name = name2
        tempFormClone.createdBy = user._id
        tempFormClone.createdByEmail = user.email



        console.log("tempFormClone ", tempFormClone)
        tempFormClone.sections.forEach(element => {

            console.log("start date chekc :", element)

            if (element.frequency.label == "Reoccuring") {
                let endDate = new Date();

                switch (element.intervalType) {
                    case "Day":
                        console.log("the numbers :", endDate, element.intervalValue, element.intervalAmount)
                        endDate.setDate(endDate.getDate() + (element.intervalValue * element.intervalAmount));
                        break;
                    case "Week":
                        endDate.setDate(endDate.getDate() + (element.intervalValue * element.intervalAmount * 7));
                        break;
                    case "Month":
                        endDate.setMonth(endDate.getMonth() + (element.intervalValue * element.intervalAmount));
                        break;
                    case "Year":
                        endDate.setFullYear(endDate.getFullYear() + (element.intervalValue * element.intervalAmount));
                        break;
                }
                element.endDate = endDate
                tempFormClone.endDate = endDate
            }


            if (element.frequency.label == 'One Time') {
                element.nextDueDate = new Date(new Date(element.endDate).getTime() + (1 * 24 * 60 * 60 * 1000));
            }
            else {
                //recurring
                if (element.intervalType == "Day") {
                    element.nextDueDate = new Date(new Date(element.endDate).getTime() + (1 * 24 * 60 * 60 * 1000));


                    const start = new Date(element.startDate).getTime();
                    const end = new Date(element.endDate).getTime();
                    const current = new Date().getTime();
                    const interval = element.intervalValue * 24 * 60 * 60 * 1000; // Convert days to milliseconds


                    //  console.log("day interval check : ", start, current, end, current < start, current > end)
                    // If current date is before start date, return start date
                    if (current < start) {
                        element.nextDueDate = new Date(start)
                    }

                    // If current date is after end date, return null
                    else if (current > end) {
                        element.nextDueDate = new Date(end).setDate(end.getDate())
                    }

                    else {
                        //  console.log("where we should be ")
                        // Calculate how many intervals have passed since the start date
                        const intervalsPassed = Math.floor((current - start) / interval);

                        //   console.log("intervalsPassed", start, intervalsPassed, interval)

                        // Calculate the next appearance date
                        let nextAppearance = new Date(start + ((intervalsPassed + 1) * interval));

                        // If next appearance is after end date, return null
                        if (nextAppearance < end) {
                            element.nextDueDate = new Date(new Date(start).setDate(nextAppearance.getDate()))
                        }
                        else {
                            element.nextDueDate = new Date(end)

                        }
                    }



                    console.log("Calculating next due date :", element.nextDueDate)

                }
                if (element.intervalType == "Week") {
                    // Convert all values to milliseconds
                    //  const start = new Date(element.startDate);
                    const end = new Date(element.endDate);
                    const current = new Date();
                    const intervalMs = element.intervalValue * 7 * 24 * 60 * 60 * 1000; // Convert weeks to milliseconds

                    let sortedDays = sort_days(element.display)
                    let firstStartDayAfterStartDate = getFirstDayOfWeekAfter(new Date(element.startDate), sortedDays[0])

                    const start = new Date(firstStartDayAfterStartDate);


                    console.log("week firstStartDayAfterStartDate check : ", firstStartDayAfterStartDate);

                    // If current date is before start date, return start date
                    if (current < start) {
                        element.nextDueDate = new Date(start.getTime());
                    }
                    // If current date is after end date, add due days to end date
                    else if (current > end) {
                        element.nextDueDate = new Date(end.getTime());
                    }
                    else {
                        console.log("where we should be");
                        // Calculate how many intervals have passed since the start date
                        const intervalsPassed = Math.floor((current.getTime() - start.getTime()) / intervalMs);

                        console.log("intervalsPassed", start, intervalsPassed, intervalMs);

                        // Calculate the next appearance date
                        let nextAppearance = new Date(start.getTime() + ((intervalsPassed) * intervalMs));

                        // If next appearance is before or equal to end date, use it
                        if (nextAppearance <= end) {
                            element.nextDueDate = new Date(nextAppearance.getTime());
                        }
                        else {
                            element.nextDueDate = new Date(end.getTime());
                        }
                    }
                }

                if (element.intervalType == "Month") {
                    // Convert all values to milliseconds
                    const end = new Date(element.endDate);
                    // new Date(element.endDate)
                    const current = new Date();

                    const start = new Date(element.startDate); //get the next date that this date appears

                    const intervalMs = element.intervalValue * 30 * 24 * 60 * 60 * 1000; // Convert months to milliseconds
                    const intervalsPassed = Math.floor((current.getTime() - start.getTime()) / intervalMs);
                    let nextAppearance = new Date(start.getTime() + ((intervalsPassed) * intervalMs));


                    if (element.monthOption == 'cardinal') {
                        element.nextDueDate = new Date(nextAppearance.getTime());


                        // Set the date to the 23rd day of the month
                        current.setDate(element.dayValueForMonth);

                        // If the current date is after the 23rd day of the month, move to the next month
                        //   console.log("here :", new Date().getDate(), element.dayValueForMonth)
                        if (start.getDate() > element.dayValueForMonth) {
                            current.setMonth(current.getMonth() + 1);
                        }
                        //       console.log("check :", current, new Date(current.getTime() + dueMs))
                        element.nextDueDate = new Date(current.getTime());



                    }
                    else {
                        //ordinal

                        //console.log("Where are we :", element.ordinalMonthValue.label, element.monthDayToRepeat.label, 'after', new Date(element.startDate), 'every', element.intervalValue, 'months',)
                        element.nextDueDate = new Date(nextAppearance.getTime());

                        let weekday = element.monthDayToRepeat.label
                        let date = new Date(element.startDate);
                        let occurrence = element.ordinalMonthValue.label

                        let resultDate = getNthDayOfWeekAfterDate(start, element.ordinalMonthValue.value, element.monthDayToRepeat.label, element.intervalValue)

                        console.log("resultDate :", new Date(resultDate))
                    }

                }
                if (element.intervalType == "Year") {
                    // Convert all values to milliseconds
                    const end = new Date(element.endDate);
                    const current = new Date();

                    const intervalMs = element.intervalValue * 365.25 * 24 * 60 * 60 * 1000; // Convert years to milliseconds



                    console.log("error check prevent :  ", element?.monthValueForYear, element?.monthValue2ForYear, element)
                    let firstStartDayAfterStartDate = getNextAppearanceYear(new Date(element.startDate), element?.monthValueForYear ? element?.monthValueForYear?.label : element?.monthValue2ForYear?.label, element.dayValueForYear)

                    const start = new Date(firstStartDayAfterStartDate);

                    const intervalsPassed = Math.floor((current.getTime() - start.getTime()) / intervalMs);

                    if (element.yearOption == 'cardinal') {
                        // Set the date to the specified day of the month

                        // If the next appearance is before the current date, move to the next year
                        if (firstStartDayAfterStartDate < current) {
                            firstStartDayAfterStartDate.setFullYear(firstStartDayAfterStartDate.getFullYear() + element.intervalValue);
                        }

                        element.nextDueDate = new Date(firstStartDayAfterStartDate.getTime());
                    }
                    else {
                        //ordinal

                        // calculate date from ordinal selects
                        //thats our target date, then just do above
                        console.log("error check prevent :  ", element?.monthValueForYear, element?.monthValue2ForYear.label, element)

                        let dateIncoming = ordinalToDate(new Date().getFullYear(), element.ordinalValueForYear, element.monthValue2ForYear, element.monthDayToRepeat)
                        let nextApperance = getNextAppearanceYear(dateIncoming, element?.monthValue2ForYear?.label, dateIncoming.getDay())

                        // If the next appearance is before the current date, move to the next year
                        if (nextApperance < current) {
                            nextApperance.setFullYear(nextApperance.getFullYear() + element.intervalValue);
                        }

                        console.log("Setting :", new Date(nextApperance.getTime()))
                        element.nextDueDate = new Date(nextApperance.getTime());
                    }
                }



            }
        });

        if (!formBeingEdited) {
            dispatch(addNewForm({ name: name2, form: tempFormClone, org_id: user.org_id, createLocation: user.viewingLocation }))

        }
        else {

            let outGoingData = structuredClone(history.location.state.formToDisplay)
            outGoingData.name = name2;
            outGoingData.form = tempFormClone

            console.log("UPDATED FORM CLONE : ", tempFormClone)
            dispatch(patchForm(outGoingData))
            //  window.location.href = '${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}/' + "View_Form";

        }


        console.log("end date result : ", tempFormClone)

        dispatch(clearForm())

        // clearForm()

    }

    function testCode() {


        let sectionIndex = forms.formBeingMade.sections.length - 1

        let reports = [...forms.requestedReports]

        // console.log("Before sort :" , reports)
        reports = reports.sort(function (a, b) {
            //   console.log("Check : a b :" , a , b , new Date(a.startDate) < new Date(b.startDate))

            return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1//TODO refactor to avoid expensive stuff like this
        });


        dispatch(setSechdule({ sectionIndex: sectionIndex, requestedReports: reports }))

    }
}


export default CreateForm;
