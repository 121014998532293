import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"
const FormData = require('form-data');

const FETCH_USER_DASHBOARD = 'FETCH_USER_DASHBOARD'
const FETCH_USER_DASHBOARD_SUCCEDED = 'FETCH_USER_DASHBOARD_SUCCEDED'
const FETCH_USER_DASHBOARD_FAILED = 'FETCH_USER_DASHBOARD_FAILED'
const SET_LOADED_ITEM = 'SET_LOADED_ITEM'
const SET_TASK = 'SET_TASK'
const SET_PAGE_VIEW = "SET_PAGE_VIEW"
const SET_QTY_FIELD = "SET_QTY_FIELD"
const SET_CHECKBOX_FIELD = "SET_CHECKBOX_FIELD"
const SET_RESPONSE_FIELD = "SET_RESPONSE_FIELD"
const SET_USER_RESPONSE_ANSWER = "SET_USER_RESPONSE_ANSWER"
const SET_ORDER_VALUE = "SET_ORDER_VALUE"
const SET_CHECKBOX = "SET_CHECKBOX"
const SET_REQUEST_TYPE = "SET_REQUEST_TYPE"
const SET_JOB = "SET_JOB"
const SET_SUMMARY = "SET_SUMMARY"


export const fetchDashboardState = createAsyncThunk(
  'userDashboard/fetchDashboardState',
  async () => {

    return {
      type: FETCH_USER_DASHBOARD,
      payload: {}
    }

  }
);

// Add new action creator
export const removeFieldFromForm = createAsyncThunk(
  'Forms/removeFieldFromForm',
  async (data) => {
    return {
      type: 'REMOVE_FIELD',
      payload: data
    };
  }
);


// Add new action creator
export const changeItemInField = createAsyncThunk(
  'Forms/changeItemInField',
  async (data) => {
    return {
      type: 'CHANGE_ITEM',
      payload: data
    };
  }
);
export const setAdminReviewCheckbox = createAsyncThunk(
  'userDashboard/setAdminReviewCheckbox',
  async (status) => {
    console.log("setting setCheckbox :", status)
    return {
      SET_CHECKBOX,
      payload: status
    }

  }
);

export const setUserResponseAnswerForDataEntry = createAsyncThunk(
  'Forms/setUserResponseAnswerForDataEntry',
  async (item) => {
    return {
      type: SET_USER_RESPONSE_ANSWER,
      payload: item
    }
  }
);

export const setOrderValue = createAsyncThunk(
  'userDashboard/setOrderValue',
  async (status) => {
    console.log("setting setOrderValue :", status)
    return {
      SET_ORDER_VALUE,
      payload: status
    }

  }
);

export const updateCheckboxField = createAsyncThunk(
  'userDashboard/updateCheckboxField',
  async (page) => {
    return {
      type: SET_CHECKBOX_FIELD,
      payload: page
    }

  }
);

export const updateResponseField = createAsyncThunk(
  'userDashboard/updateResponseField',
  async (page) => {
    return {
      type: SET_RESPONSE_FIELD,
      payload: page
    }

  }
);
export const updateQuantityField = createAsyncThunk(
  'userDashboard/updateQuantityField',
  async (page) => {
    return {
      type: SET_QTY_FIELD,
      payload: page
    }

  }
);

export const setPageView = createAsyncThunk(
  'userDashboard/pageView',
  async (page) => {
    return {
      type: SET_PAGE_VIEW,
      payload: page
    }

  }
);

export const setTask = createAsyncThunk(
  'userDashboard/setTask',
  async (task) => {
    console.log("Task :", task)
    return {
      type: SET_TASK,
      payload: task
    }

  }
);


export const setRequestTypeToGenerate = createAsyncThunk(
  'userDashboard/setRequestTypeToGenerate',
  async (task) => {

    return {
      type: SET_REQUEST_TYPE,
      payload: task
    }

  }
);

// Add new action creator at the top with your other exports
export const addRowToForm = createAsyncThunk(
  'Forms/addRowToForm',
  async (type) => {
    return {
      type: 'ADD_ROW',
      payload: type
    };
  }
);

//its probably bad practice somehow to update the state like this
export const setLoadStatus = createAsyncThunk(
  'userDashboard/setLoadStatus',
  async (status) => {
    console.log("setting LoadStatus :", status)
    return {
      type: SET_TASK,
      payload: status
    }

  }
);
export const setSelectedJob = createAsyncThunk(
  'userDashboard/setSelectedJob',
  async (item) => {
    return {
      type: SET_JOB,
      payload: item
    }
  }
);
export const setDashboardSummary = createAsyncThunk(
  'userDashboard/setDashboardSummary',
  async (item) => {
    return {
      type: SET_SUMMARY,
      payload: item
    }
  }
);

export const settingPendingQuantity = createAsyncThunk(
  'userDashboard/settingPendingQuantity',
  async ({ itemId, newQuantity }) => {
    return {
      type: 'SET_PENDING_QUANTITY',
      payload: {
        itemId,
        newQuantity
      }
    };
  }
);


// Helper function to safely copy objects
const safeClone = (obj) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    // Fallback to manual copy for complex objects
    if (Array.isArray(obj)) {
      return obj.map(item => safeClone(item));
    }
    if (obj && typeof obj === 'object') {
      const copy = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = safeClone(obj[key]);
        }
      }
      return copy;
    }
    return obj;
  }
};

export const testScheduler2 = createAsyncThunk('userDashboard/testScheduler2', async () => {

  console.log("testScheduler2; ")

  try {

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/scheduleUpdater`)

    const realData = await test.json();
    console.log("Returned data :", realData)

    return realData

    //const realData = await test.json();

    //return realData
  }
  catch (err) {
    console.log("Error posting file :", err)
    return err.message
  }

}
);


export const getOneFile = createAsyncThunk('userDashboard/getOneFile', async (data) => {

  console.log("getOneFile File ; ", data)

  try {

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/files?` + new URLSearchParams({ name: data.value }))



    console.log("Trying to retrieve one file : ", test)
    const realData = await test.json();

    console.log("Returned data :", realData)
    data.result = realData
    return data

    //const realData = await test.json();

    //return realData
  }
  catch (err) {
    console.log("Error posting file :", err)
    return err.message
  }

}
);

//huh?, first param of async thunk, shouldnt it be userDashboard?  - scared to change it 9/12/2024
export const uploadingFile = createAsyncThunk('Tasks/uploadingFile', async (data) => {

  console.log("Uploading File ; ", data)

  console.log("Check one : ", data.name)

  console.log("Check 2 : ", data.files)

  try {

    const outData = new FormData();
    outData.append('file', data.files);
    outData.append('name', data.name);
    // outData.append('_id', test);

    console.log("Sending outData: ", outData)

    await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/files`, {
      method: 'POST',
      body: outData
    }).then((response) => {
      console.log("response : ", response)
      return response.text();
    })

    //const realData = await test.json();

    //return realData
  }
  catch (err) {
    console.log("Error posting file :", err)
    return err.message
  }

}
);


export const addUserSubmittedTask = createAsyncThunk('Tasks/addUserSubmittedTask', async (initialData) => {

  try {

    console.log("Check create ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/userSubmittedTasks`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    console.log("returned from submitting task : ", realData)
    return realData.docs[0]
  }
  catch (err) {
    return err.message
  }

}
);

const initialState = {
  loadingPageInfo: false,
  currentView: '',
  dashboardError: null,
  loadedTask: {},
  userDashboard: {},
  justAdded: {},
  summary: {}
};





export const itemSlice = createSlice({
  name: 'userDashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_USER_DASHBOARD: (state) => {
      console.log("Returning dashboard :", state.userDashboard)
      return state.dashboard
    },

    FETCH_USER_DASHBOARD_SUCCEDED: (state, action) => {
      return { loading: false, items: action.payload, error: '' }
    },

    FETCH_USER_DASHBOARD_FAILED: (state, action) => {
      // console.log("fetch items failed reached")
      return { loading: false, items: [], error: action.payload }
    },
    /*
        SET_LOADED_ITEM: (state, action) => {
          state.loadedItem = action.payload
          console.log("Set loadedItem :" , state.loadedItem)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder



      .addCase(setRequestTypeToGenerate.fulfilled, (state, action) => {

        console.log("setRequestTypeToGenerate", action)
        state.loadedTask.task.requestTypeToGenerate = action.payload.payload

      })


      .addCase(fetchDashboardState.fulfilled, (state, action) => {
        console.log("'fetching' board state, and changing load status:", action)
        state.userDashboard.userDashboard = { test: "test" }
        state.loadingPageInfo = false

      })

      .addCase(setLoadStatus.fulfilled, (state, action) => {
        console.log("Setting(forcing) load status :", action)
        state.loadingPageInfo = action.payload.payload;

      })
      .addCase(setPageView.pending, (state) => {
        state.loadingPageInfo = true;
        console.log("Setting view pending:", state)

      })
      .addCase(setPageView.fulfilled, (state, action) => {

        console.log("Setting view :", action)
        state.loadingPageInfo = false;
        state.currentView = action.payload.payload

        //    console.log("data :", state.reportingGroups, action)
      })
      .addCase(setPageView.rejected, (state, action) => {
        console.log("Setting view rejected:", action)

        state.loadingPageInfo = false;
        state.currentView = action.payload
        state.reportingGroupsError = action.error.message
      })










      .addCase(removeFieldFromForm.fulfilled, (state, action) => {
        const { fieldIndex } = action.payload.payload;

        console.log('Removing field:', {
          fieldIndex,
          currentFieldCount: state.loadedTask?.section?.fields?.length
        });

        // Validate that we have fields to remove from in loadedTask.section
        if (!state.loadedTask?.section?.fields) {
          console.warn('No fields array found in loadedTask.section');
          return;
        }

        // Validate that we have fields to remove from in loadedTask.task.form.sections
        if (!state.loadedTask?.task?.form?.sections || !state.loadedTask.task.form.sections.length) {
          console.warn('No sections or fields array found in loadedTask.task.form');
          return;
        }

        // Validate field index
        if (fieldIndex < 0 || fieldIndex >= state.loadedTask.section.fields.length) {
          console.warn('Invalid field index:', fieldIndex);
          return;
        }

        // Remove from loadedTask.section.fields
        state.loadedTask.section.fields = state.loadedTask.section.fields.filter(
          (_, index) => index !== fieldIndex
        );

        // Find the corresponding section index in loadedTask.task.form.sections
        const sectionIndex = state.loadedTask.task.form.sections.findIndex(
          (section) => section.fields === state.loadedTask.section.fields
        );

        if (sectionIndex !== -1) {
          // Remove from loadedTask.task.form.sections[sectionIndex].fields
          state.loadedTask.task.form.sections[sectionIndex].fields = state.loadedTask.task.form.sections[sectionIndex].fields.filter(
            (_, index) => index !== fieldIndex
          );
        }

        // Update loadedTaskCopy if it exists
        if (state.loadedTaskCopy?.section?.fields) {
          state.loadedTaskCopy.section.fields = state.loadedTaskCopy.section.fields.filter(
            (_, index) => index !== fieldIndex
          );
        }

        if (
          state.loadedTaskCopy?.task?.form?.sections &&
          state.loadedTaskCopy.task.form.sections.length
        ) {
          const copyTaskSectionIndex = state.loadedTaskCopy.task.form.sections.findIndex(
            (section) => section.fields === state.loadedTaskCopy.section.fields
          );

          if (copyTaskSectionIndex !== -1) {
            state.loadedTaskCopy.task.form.sections[copyTaskSectionIndex].fields = state.loadedTaskCopy.task.form.sections[copyTaskSectionIndex].fields.filter(
              (_, index) => index !== fieldIndex
            );
          }
        }

        console.log('Field removed successfully:', {
          newFieldCount: state.loadedTask.section.fields.length
        });
      })



      .addCase(setAdminReviewCheckbox.rejected, (state, action) => {
        console.log("Setting setCheckbox rejected :", action,)


      })

      .addCase(changeItemInField.fulfilled, (state, action) => {
        const { item, fieldIndex } = action.payload.payload;

        console.log('Changing item:', {
          receivedItem: !!item,
          fieldIndex,
          itemDetails: item
        });

        // Validate input
        if (!item) {
          console.warn('No item provided to changeItemInField');
          return;
        }

        try {
          // Validate field exists before updating
          if (state.loadedTask?.section?.fields?.[fieldIndex]) {
            // Update main task
            state.loadedTask.section.fields[fieldIndex] = {
              ...state.loadedTask.section.fields[fieldIndex],
              item: item,
              parValue: item.par || "",
              triggerPurchase: true,
              userResponse: [{
                question: "Quantity:",
                answer: ""
              }]
            };

            // Find the corresponding section index in loadedTask.task.form.sections
            const sectionIndex = state.loadedTask.task.form.sections.findIndex(
              (section) => section.fields === state.loadedTask.section.fields
            );

            if (sectionIndex !== -1) {
              // Update loadedTask.task.form.sections[sectionIndex].fields
              state.loadedTask.task.form.sections[sectionIndex].fields[fieldIndex] = {
                ...state.loadedTask.task.form.sections[sectionIndex].fields[fieldIndex],
                item: JSON.parse(JSON.stringify(item)),
                parValue: item.par || "",
                triggerPurchase: true,
                userResponse: [{
                  question: "Quantity:",
                  answer: ""
                }]
              };
            }

            // Update copy if it exists
            if (state.loadedTaskCopy?.section?.fields?.[fieldIndex]) {
              state.loadedTaskCopy.section.fields[fieldIndex] = {
                ...state.loadedTaskCopy.section.fields[fieldIndex],
                item: JSON.parse(JSON.stringify(item)),
                parValue: item.par || "",
                triggerPurchase: true,
                userResponse: [{
                  question: "Quantity:",
                  answer: ""
                }]
              };
            }

            // Find the corresponding section index in loadedTaskCopy.task.form.sections
            const copyTaskSectionIndex = state.loadedTaskCopy?.task?.form?.sections?.findIndex(
              (section) => section.fields === state.loadedTaskCopy.section.fields
            );

            if (copyTaskSectionIndex !== -1) {
              // Update loadedTaskCopy.task.form.sections[copyTaskSectionIndex].fields
              state.loadedTaskCopy.task.form.sections[copyTaskSectionIndex].fields[fieldIndex] = {
                ...state.loadedTaskCopy.task.form.sections[copyTaskSectionIndex].fields[fieldIndex],
                item: JSON.parse(JSON.stringify(item)),
                parValue: item.par || "",
                triggerPurchase: true,
                userResponse: [{
                  question: "Quantity:",
                  answer: ""
                }]
              };
            }

            console.log('Item changed successfully:', {
              fieldIndex,
              newItemName: item.itemName
            });
          } else {
            console.warn('Field index not found:', fieldIndex);
          }
        } catch (error) {
          console.error('Error updating item:', error);
        }
      })   // Updated reducer case
      .addCase(addRowToForm.fulfilled, (state, action) => {
        console.log("Adding new row to section", {
          currentFields: state.loadedTask.section.fields.length
        });

        // Get the last field as template
        const lastField = state.loadedTask.section.fields[
          state.loadedTask.section.fields.length - 1
        ];

        // Create new field based on the last field
        const newField = {
          item: lastField.item ? {
            _id: lastField.item._id,
            itemName: lastField.item.itemName,
            vendor: safeClone(lastField.item.vendor) || {},
            orderTriggerIneq: lastField.item.orderTriggerIneq || "-",
            reportingGroup: safeClone(lastField.item.reportingGroup) || [],
            viewingLocation: lastField.item.viewingLocation,
            org_id: lastField.item.org_id,
            cost: lastField.item.cost || null,
            createdAt: lastField.item.createdAt || null,
            dateOfCost: lastField.item.dateOfCost || null,
            description: lastField.item.description || null,
            displayName: lastField.item.displayName || null,
            itemAmount: lastField.item.itemAmount || null,
            itemNumber: lastField.item.itemNumber || null,
            lastUpdateDate: lastField.item.lastUpdateDate || null,
            location: lastField.item.location || null,
            orderTriggerValue: lastField.item.orderTriggerValue || null,
            par: lastField.item.par || null,
            pendingOrderQuantity: lastField.item.pendingOrderQuantity || null,
            size: lastField.item.size || null
          } : '',
          includePar: false,
          triggerPurchase: lastField.triggerPurchase || false,
          userResponse: [
            {
              question: "Quantity:",
              answer: ""
            }
          ],
          parValue: lastField.parValue || "",
          responseOption: "",
          adminIncludeInOrder: "",
          adminReviewCheckboxValue: "",
          adminReviewCheckboxToggle: "off",
          addedLate: true
        };

        // Add the new field to the section
        state.loadedTask.section.fields.push(newField);

        // Update the loadedTaskCopy as well to maintain sync
        if (state.loadedTaskCopy && state.loadedTaskCopy.section) {
          if (!state.loadedTaskCopy.section.fields) {
            state.loadedTaskCopy.section.fields = [];
          }
          state.loadedTaskCopy.section.fields.push(safeClone(newField));
        }

        console.log("Row added successfully", {
          newFieldCount: state.loadedTask.section.fields.length
        });
      })

      .addCase(setAdminReviewCheckbox.fulfilled, (state, action) => {
        console.log("Setting setCheckbox :", action,)
        state.loadedTask.section.fields[action.payload.payload.index1].adminReviewCheckboxToggle = action.payload.payload.value
        //   state.loadingPageInfo = true
        // state.dashboardError = ''
        //  state.currentView = "task"

      })

      .addCase(setOrderValue.fulfilled, (state, action) => {
        console.log("Setting setCheckbox :", action, state.loadedTask.task.section.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3])
        state.loadedTask.section.fields[action.payload.payload.index1].adminReviewCheckboxValue = action.payload.payload.value
        //   state.loadingPageInfo = true
        //  state.dashboardError = ''
        //   state.currentView = "task"

      })
      .addCase(setDashboardSummary.fulfilled, (state, action) => {

        state.summary = action.payload.payload
      })

      .addCase(settingPendingQuantity.fulfilled, (state, action) => {
        const { itemId, newQuantity, vendor } = action.payload;

        // If we have vendor information, update in vendor structure
        if (vendor && state.summary[vendor]) {
          const items = state.summary[vendor]?.items || state.summary[vendor];
          const itemIndex = items.findIndex(item => item._id === itemId);
          if (itemIndex !== -1) {
            if (state.summary[vendor].items) {
              state.summary[vendor].items[itemIndex].pendingOrderQuantity = newQuantity;
            } else {
              state.summary[vendor][itemIndex].pendingOrderQuantity = newQuantity;
            }
          }
        }
        // If no vendor or not found in vendor structure, try array
        else if (Array.isArray(state.summary)) {
          const itemIndex = state.summary.findIndex(item => item._id === itemId);
          if (itemIndex !== -1) {
            state.summary[itemIndex].pendingOrderQuantity = newQuantity;
          }
        }

        // Also update in the fields if present
        if (state.loadedTask?.section?.fields) {
          const fieldIndex = state.loadedTask.section.fields.findIndex(
            f => f.item?._id === itemId
          );
          if (fieldIndex !== -1) {
            state.loadedTask.section.fields[fieldIndex].item.pendingOrderQuantity = newQuantity;
          }
        }
      })


      .addCase(setSelectedJob.fulfilled, (state, action) => {

        console.log("check? :",)


        state.selectedJobPosition = action.payload.payload


      })
      .addCase(setTask.fulfilled, (state, action) => {
        console.log("Setting task :", action.payload.payload, action.payload.payload.tasK)
        state.loadedTask = action.payload.payload
        state.loadedTaskCopy = action.payload.payload


        state.loadingPageInfo = true
        state.dashboardError = ''
        if (action.payload.payload.type == 'userSubmission') {
          state.currentView = "userSubmission"

        }
        else {
          state.currentView = action.payload.payload.type
        }

      })


      .addCase(testScheduler2.fulfilled, (state, action) => {
        console.log("testScheduler2 fulfilled")
      })

      //Creating Tasks
      .addCase(getOneFile.fulfilled, (state, action) => {
        console.log("getOneFile Success :", state, action, action.payload.index1)

        state.loadedTask.section.fields[action.payload.index1].userImageUpload = action.payload.result.chunks[0].data

        //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
        state.tasksError = ''
      })

      //Creating Tasks
      .addCase(addUserSubmittedTask.fulfilled, (state, action) => {
        console.log("addUserSubmittedTask Success :", state, action)

        state.loading = false;
        state.loadingPageInfo = false
        state.currentView = ''
        state.justAdded = action.payload
        //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
        state.tasksError = ''
      })


      .addCase(updateCheckboxField.fulfilled, (state, action) => {
        console.log("Setting updateCheckboxField :", action)

        state.loadedTask.section.fields[action.payload.payload.index1].answerChoicesResponse = action.payload.payload.value
        state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      .addCase(updateQuantityField.fulfilled, (state, action) => {
        console.log("Setting updateQuantityField :", action)
        state.loadedTask.section.fields[action.payload.payload.index1].pendingCurrentQuantity = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      .addCase(updateResponseField.fulfilled, (state, action) => {
        console.log("Setting updateResponseField :", action)


        state.loadedTask.section.fields[action.payload.payload.index1].responseColumns[action.payload.payload.index2].userResponse = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })







      //Assigning input when changed
      .addCase(setUserResponseAnswerForDataEntry.fulfilled, (state, action) => {

        console.log("setUserResponseAnswerForDataEntry question coming :", action.payload.payload)
        //state.loadedTask.task.section.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].userResponse

        //0 every time here? makes sense but feels wrong

        state.loadedTask.section.fields[action.payload.payload.fieldIndex].userResponse[action.payload.payload.columnIndex].answer = action.payload.payload.prompt

        state.FormsError = ''
      })


  },
});

//export const { fetch, create } = itemSlice.actions;

export const loaduserDashboard = () => {
  return dispatch(fetchuserDashboard())
}

/*
export const setLoadeduserDashboard = (item) => {
  return dispatch(SET_SELECTED_ITEM(item))
}
*/


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectuserDashboard = (state) => {
  return state.userDashboard
};

export const getLoadStatus = (state) => state.userDashboard.loadingPageInfo;
export const getdashboardError = (state) => state.userDashboard.dashboardError;
export const getTask = (state) => state.userDashboard.loadedItem;
export const getCurrentView = (state) => state.userDashboard.currentView;

export default itemSlice.reducer;
export const itemActions = itemSlice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.items =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/