// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import App from './App.js';
import './Components/SideBar.js';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/store.js';

const history = createBrowserHistory();

export const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();



/*
    <Auth0Provider
      domain="dev-7-jkl6z6.us.auth0.com"
      clientId="168TSsyS4Hs2UJ3jou26PCdewe6fL3hZ"
      authorizationParams={{
        redirect_uri: "http://127.0.0.1:3000"
      }}
    >
    </Auth0Provider>
*/