//these should be synchronous function
//and we should NOT mutate the original state

export const selectedPage = (state = 0, action) => {

    switch (action.type) { //
        case 'SET_SELECTED_PAGE':
            //   console.log("reducer reached :", action.type, action.payload)
            return state = action.payload;

        default: return state
    }
}
/*

const initialValue= [{rgCurrentView:'bucket'}]

//handles changing views on reporting group form page.
export const rgCurrentView = (state = initialValue[0], action) => {

    switch (action.type) { //
        case 'SET_RG_View':

        console.log("setting view :" ,  action.payload)
            return state = action.payload;

        default: return state
    }
}

*/