import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"


const FETCH_ITEMS_REQUESTED = 'FETCH_ITEMS_REQUESTED'
const FETCH_ITEMS_SUCCEDED = 'FETCH_ITEMS_SUCCEDED'
const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED'
const SET_LOADED_ITEM = 'SET_LOADED_ITEM'
const ADD_CATAGORY = 'ADD_CATAGORY'
const REMOVE_CATAGORY = 'REMOVE_CATAGORY'
const SET_CATAGORY = "SET_CATAGORY"
const SET_INEQUALITY = "SET_INEQUALITY"
const SET_ITEM = "SET_ITEM"
const SET_NAME = "SET_NAME"
const SET_COST = "SET_COST"
const SET_ITEM_AMOUNT = 'SET_ITEM_AMOUNT'
const SET_PAR = "SET_PAR"
const SET_SIZE = "SET_SIZE"
const SET_ITEM_NUMBER = "SET_ITEM_NUMBER"
const SET_TRIGGER_VALUE = "SET_TRIGGER_VALUE"
const SET_DESCRIPTION = "SET_DESCRIPTION"
const SET_PENDING_ORDER_QTY = "SET_PENDING_ORDER_QTY"
const SET_DATE_OF_COST = "SET_DATE_OF_COST"
const CLEAR_ITEM = "CLEAR_ITEM"

export const SetItem = createAsyncThunk(
  'Items/setItem',
  async (item) => {

    return {
      type: SET_LOADED_ITEM,
      payload: item
    }

  }
);

export const SetCatagory = createAsyncThunk(
  'Items/setCatagory',
  async (item) => {

    return {
      type: SET_CATAGORY,
      payload: item
    }

  }
);

export const RemoveCatagory = createAsyncThunk(
  'Items/removeCatagory',
  async (item) => {

    return {
      type: REMOVE_CATAGORY,
      payload: item
    }

  }
);

export const setIneqValue = createAsyncThunk(
  'Items/setIneqValue',
  async (item) => {

    return {
      type: "SET_INEQUALITY",
      payload: item
    }

  }
);

export const AddCatagory = createAsyncThunk(
  'Items/AddCatagory',
  async (item) => {

    return {
      type: ADD_CATAGORY,
      payload: item
    }

  }
);

export const setItemBeingCreated = createAsyncThunk(
  'Items/setItemBeingCreated',
  async (item) => {

    return {
      type: SET_ITEM,
      payload: item
    }

  }
);

export const setName = createAsyncThunk(
  'Items/setName',
  async (item) => {

    return {
      type: SET_NAME,
      payload: item
    }

  }
);

export const setCost = createAsyncThunk(
  'Items/setCost',
  async (item) => {

    return {
      type: SET_COST,
      payload: item
    }

  }
);
export const setItemAmount = createAsyncThunk(
  'Items/setItemAmount',
  async (item) => {

    return {
      type: SET_ITEM_AMOUNT,
      payload: item
    }

  }
);
export const setPar = createAsyncThunk(
  'Items/setPar',
  async (item) => {

    return {
      type: SET_PAR,
      payload: item
    }

  }
);
export const setSize = createAsyncThunk(
  'Items/setSize',
  async (item) => {

    return {
      type: SET_SIZE,
      payload: item
    }

  }
);
export const setItemNumber = createAsyncThunk(
  'Items/setItemNumber',
  async (item) => {

    return {
      type: SET_ITEM_NUMBER,
      payload: item
    }

  }
);
export const setOrderValue = createAsyncThunk(
  'Items/setOrderValue',
  async (item) => {

    return {
      type: SET_TRIGGER_VALUE,
      payload: item
    }

  }
);
export const setDescription = createAsyncThunk(
  'Items/setDescription',
  async (item) => {

    return {
      type: SET_DESCRIPTION,
      payload: item
    }

  }
);
export const setPendingOrderQTY = createAsyncThunk(
  'Items/setPendingOrderQTY',
  async (item) => {

    return {
      type: SET_PENDING_ORDER_QTY,
      payload: item
    }

  }
);

export const setDateOfCost = createAsyncThunk(
  'Items/setDateOfCost',
  async (item) => {

    return {
      type: SET_DATE_OF_COST,
      payload: item
    }

  }
);


export const clearItemBeingMade = createAsyncThunk(
  'Items/clearItemBeingMade',
  async (item) => {

    return {
      type: CLEAR_ITEM,
      payload: item
    }

  }
);

const initialState = {
  loading: false,
  items: [],
  itemsError: null,
  loadedItem: '',
  rgCatagories: [],
  searchDone: false,
  searchResult: [],
  inequalValue: '',
  itemBeingCreated: {}
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchItems = createAsyncThunk(
  'Items/fetchItems',
  async (data) => {
    try {

      console.log("fetching items orgId:", data.viewingLocation, data.locationId)
      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/items?org_id=${data.org_id}&location=${data.viewingLocation}`)

      const realData = await test.json();

      return realData
    }
    catch (err) {
      console.log("Error message :", err.message)
      return err.message
    }

  }
);



export const itemSearch = createAsyncThunk(
  'Items/itemSearch',
  async (param) => {
    try {
      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/items?org_id=${param.org_id}&search=${param.data}&location=${param.location_id}`)

      const realData = await test.json();

      return realData

    }
    catch (err) {
      console.log("Error message :", err.message)
      return err.message
    }

  }
);



export const addNewItem = createAsyncThunk('Items/addNewItem', async (initialData) => {

  try {

    console.log("Check create ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/items`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    return err.message
  }

}
);

export const patchItem = createAsyncThunk('Items/patchItem', async (data) => {

  try {

    console.log("patch ; ", data, data._id)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/items/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

});


export const deleteItem = createAsyncThunk('Items/deleteItem', async (toDelete) => {

  try {

    console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/items/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

}
);



export const itemSlice = createSlice({
  name: 'items',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_ITEMS_REQUESTED: (state, action) => {
      return state
    },

    FETCH_ITEMS_SUCCEDED: (state, action) => {
      return { loading: false, items: action.payload, error: '' }
    },

    FETCH_ITEMS_FAILED: (state, action) => {
      // console.log("fetch items failed reached")
      return { loading: false, items: [], error: action.payload }
    },
    /*
        SET_LOADED_ITEM: (state, action) => {
          state.loadedItem = action.payload
          console.log("Set loadedItem :" , state.loadedItem)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder


      .addCase(fetchItems.pending, (state) => {
        state.loading = true;
        state.itemsError = ''
        console.log("fetch items in reducer, pending")

      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        console.log("fetched  :", action, state, state.items)
        state.loading = 'Done';
        state.items = action.payload.docs
        state.itemsError = ''
        // console.log("data :", state.items, action)
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.loading = false;
        state.items = []
        console.log("action;", action.error)
        state.itemsError = action.error.message

      })

      .addCase(setDateOfCost.fulfilled, (state, action) => {
        //also sets when entry was created 
        state.itemBeingCreated.dateOfCost = action.payload.payload
        state.itemBeingCreated.createdAt = action.payload.payload
      })

      .addCase(clearItemBeingMade.fulfilled, (state, action) => {
        state.itemBeingCreated.itemName = ""
        state.itemBeingCreated.cost = ""
        state.itemBeingCreated.itemAmount = ""
        state.itemBeingCreated.par = ""
        state.itemBeingCreated.size = ""
        state.itemBeingCreated.itemNumber = ""
        state.itemBeingCreated.orderTriggerValue = ""
        state.itemBeingCreated.description = ""
        state.itemBeingCreated.pendingOrderQuantity = ""
        state.itemBeingCreated.dateOfCost = ""


      })

      .addCase(setName.fulfilled, (state, action) => {
        state.itemBeingCreated.itemName = action.payload.payload
      })

      .addCase(setCost.fulfilled, (state, action) => {
        state.itemBeingCreated.cost = action.payload.payload
      })

      .addCase(setItemAmount.fulfilled, (state, action) => {
        state.itemBeingCreated.itemAmount = action.payload.payload
      })

      .addCase(setPar.fulfilled, (state, action) => {
        state.itemBeingCreated.par = action.payload.payload
      })

      .addCase(setSize.fulfilled, (state, action) => {
        state.itemBeingCreated.size = action.payload.payload
      })

      .addCase(setItemNumber.fulfilled, (state, action) => {
        state.itemBeingCreated.itemNumber = action.payload.payload
      })

      .addCase(setOrderValue.fulfilled, (state, action) => {
        state.itemBeingCreated.orderTriggerValue = action.payload.payload
      })

      .addCase(setDescription.fulfilled, (state, action) => {
        state.itemBeingCreated.description = action.payload.payload
      })

      .addCase(setPendingOrderQTY.fulfilled, (state, action) => {
        state.itemBeingCreated.pendingOrderQuantity = action.payload.payload
      })


      .addCase(setItemBeingCreated.fulfilled, (state, action) => {
        state.itemBeingCreated = action.payload.payload
      })

      .addCase(setIneqValue.fulfilled, (state, action) => {
        console.log("SETTING INEQUAL VALUE Action :", action)
        state.inequalValue = action.payload.payload.option
      })
      .addCase(setIneqValue.rejected, (state, action) => {
        console.log(" setIneqValue error:", action)
      })
      .addCase(itemSearch.pending, (state, action) => {
        state.searchDone = false;
        state.searchResult = []
      })


      .addCase(itemSearch.fulfilled, (state, action) => {
        state.searchDone = true;
        console.log("item search result :", action, (typeof action.payload.docs), action.payload.docs)
        //here group by vendor for displaying
        //groups is a dictionary type thing
        const groups = action.payload.docs.reduce((groups, item) => {
          if (groups[item.vendor.company]) {
            let group = groups[item.vendor.company]
            group.push(item);
          }
          else {
            let group = [item]
            groups[item.vendor.company] = group;
          }

          return groups;
        }, {});

        console.log("groups :", groups)
        // state.itemsError = action.error.message
        state.searchResult = groups
      })


      //Selecting items
      .addCase(SetItem.fulfilled, (state, action) => {
        state.loadedItem = action.payload.payload
        state.loading = 'Done';
        state.itemsError = ''

      })


      .addCase(RemoveCatagory.fulfilled, (state, action) => {
        console.log("add catagories: ", action)


        let indToRemove = state.rgCatagories.findIndex(i => i.value === action.payload.payload.value);
        state.rgCatagories.splice(indToRemove, 1);


        //   let index3 = pendingCatagories.subcatagories.findIndex(i => i.value === catagory.value);
        //   pendingCatagories.subcatagories.splice(index3, 1);


        //  state.items.push(action.payload) TODO ideally items are updated here, but couldnt get it working so going to call GET again
        state.itemsError = ''
      })


      .addCase(SetCatagory.fulfilled, (state, action) => {
        console.log("setting catagory list: ", action)
        state.rgCatagories = action.payload.payload
        //  state.items.push(action.payload) TODO ideally items are updated here, but couldnt get it working so going to call GET again
        state.itemsError = ''
      })


      .addCase(AddCatagory.fulfilled, (state, action) => {
        console.log("add catagories: ", action)
        state.rgCatagories.push(action.payload.payload)
        //  state.items.push(action.payload) TODO ideally items are updated here, but couldnt get it working so going to call GET again
        state.itemsError = ''
      })

      //Creating Items
      .addCase(addNewItem.fulfilled, (state, action) => {
        state.loading = false;
        //  state.items.push(action.payload) TODO ideally items are updated here, but couldnt get it working so going to call GET again
        state.itemsError = ''
      })

      //update items


      .addCase(patchItem.fulfilled, (state, action) => {
        state.loading = false; //by setting false here, the component will call fetch to update data
        state.itemsError = ''
      })

      //Deleting Items
      .addCase(deleteItem.rejected, (state, action) => {
        state.loading = false;
        state.itemsError = action.error.message
      })
      .addCase(deleteItem.pending, (state, action) => {
        state.loading = true;
        state.itemsError = ''
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.loading = false; //by setting false here, the component will call fetch to update data
        state.itemsError = ''
      })
  },
});

//export const { fetch, create } = itemSlice.actions;

export const loadItems = () => {
  return dispatch(fetchItems())
}

/*
export const setLoadedItems = (item) => {
  return dispatch(SET_SELECTED_ITEM(item))
}
*/


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectItems = (state) => state.item.items;
export const getItemLoadStatus = (state) => state.item.loading;
export const getitemsError = (state) => state.item.itemsError;
export const getLoadedItem = (state) => state.item.loadedItem;
export const getCatagories = (state) => state.item.rgCatagories;
export const getSearchResult = (state) => state.item.searchResult;
export const getIneqValue = (state) => state.item.inequalValue;
export const getItemBeingCreated = (state) => state.item.itemBeingCreated;


export const getSearchDone = (state) => state.item.searchDone;

export default itemSlice.reducer;
export const itemActions = itemSlice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.items =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/