export const set_selected_menu_tab = (value) =>{
    return {
        type: 'SET_SELECTED_PAGE',
        payload:value
    }
}

export const set_selected_subpage = (value) =>{
    return {
        type: 'SET_SELECTED_SUBPAGE',
        destination: '',
        payload:value
        
    }
}


export const set_rg_page_view = (value) =>{
    return {
        type: 'SET_RG_View',
        payload:value
        
    }
}



//http://stackoverflow.com/questions/53588272/export-multiple-actions-in-action-js-file-redux