import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styles from "./JobPosition.module.css";
import {
    setJobTitle,
    setDescription,
    getJobBeingMade,
    SetJobPosition,
    RemovePermission,
    AddPermission,
    getPermsToAdd,
    getPermsToRemove,
    fetchPermissions,
    updatePermissionsOnJob,
    patchJob,
    addNewJobPosition,
    deleteJobPosition,
    fetchJobPositions,
    getJobPositionLoadStatus,
    getLoadedJobPosition,
    getjobPositionsError,
    selectJobPositions,
    setPermissionList,
    getPermissionList,
    clearJobBeingMade,
    setJobBeingMade
} from './jobPositionSlice';

const InputField = React.memo(({ label, id, value, onChange }) => {
    const inputRef = useRef(null);

    const handleChange = (event) => {
        const { selectionStart, selectionEnd } = event.target;
        onChange(event);
        setTimeout(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    };

    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <input
                type="text"
                id={id}
                ref={inputRef}
                value={value || ''}
                onChange={handleChange}
                className={styles.defaultInp}
            />
        </label>
    );
});

function JobSetup(props) {
    const dispatch = useDispatch();
    const [toggleShowCreate, setToggle1] = useState(false);
    const [toggleShowEdit, setToggle2] = useState(false);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }
    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);
    const permissionList = useSelector(getPermissionList);
    const jobBeingCreated = useSelector(getJobBeingMade);

    const selectJobPositionRef = useRef();

    useEffect(() => {
        if (jobPositionLoading != 'Done') {
            dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }));
        }
    }, [jobPositionLoading, dispatch, user.org_id, user.viewingLocation]);

    const getPageWrapperClassName = useCallback(() => {
        const baseClass = styles.pageWrapper;
        const scrollClass = (toggleShowCreate === 'create' || toggleShowEdit === 'edit') ? styles.pageWrapperScroll : '';
        return `${baseClass} ${scrollClass}`.trim();
    }, [toggleShowCreate, toggleShowEdit]);

    const hidePopup = useCallback(() => {
        document.getElementById("popupMessage").style.visibility = "hidden";
        document.getElementById("popupBackground").style.visibility = "hidden";
    }, []);

    const togglePermissionFromPosition = useCallback((evt, permissionName) => {
        let tempPermList = [...(permissionList || [])];
        if (evt.target.checked) {
            tempPermList.push(permissionName);
            dispatch(AddPermission({ loadedJobPosition, permissionName }));
        } else {
            const index = tempPermList.indexOf(permissionName);
            if (index > -1) {
                tempPermList.splice(index, 1);
                dispatch(RemovePermission({ loadedJobPosition, data: permissionName }));
            }
        }
        dispatch(setPermissionList(tempPermList));
    }, [dispatch, permissionList, loadedJobPosition]);

    const settingToggle1 = useCallback((value) => {
        if (toggleShowCreate == 'create') {
            setToggle1('');
            document.getElementById("titleText").textContent = "Creating Job Position";
        } else {
            if (toggleShowEdit == 'edit') {
                clearDataFromFields();
                clearSelects();
                dispatch(SetJobPosition({}));
            }
            dispatch(setPermissionList([]));
            document.getElementById("titleText").textContent = "Existing Job Position";
            setToggle1(value);
        }

        if (value == 'create') {
            document.getElementById("titleText").textContent = "Creating Job Position";
        }

        setToggle2('');
    }, [toggleShowCreate, toggleShowEdit, dispatch]);

    const settingToggle2 = useCallback((value) => {
        if (toggleShowEdit == 'edit') {
            setToggle2('');
        } else {
            setToggle2(value);
        }
        setToggle1('');
    }, [toggleShowEdit]);

    const onDeleteClicked = useCallback((jobPosition) => {
        dispatch(deleteJobPosition(jobPosition)).then(() => {
            clearDataFromFields();
        });
        clearSelects();
    }, [dispatch]);

    const clearDataFromFields = useCallback(() => {
        dispatch(setPermissionList([]));
        dispatch(clearJobBeingMade());
        clearSelects();
    }, [dispatch]);

    const onCancelClicked = useCallback(() => {
        clearDataFromFields();
        clearSelects();
        dispatch(clearJobBeingMade());
        dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }));
        setToggle2(false);
        setToggle1(false);
    }, [dispatch, user.org_id, user.viewingLocation]);

    const clearSelects = useCallback(() => {
        let blankEntry = { label: '', value: '' };
        dispatch(SetJobPosition(blankEntry));
    }, [dispatch]);

    const onSaveClicked = useCallback(() => {
        let newJob = structuredClone(jobBeingCreated);
        newJob.permissions = permissionList;
        newJob.userId = user._id;
        newJob.viewingLocation = user.viewingLocation;
        newJob.org_id = user.org_id;

        if (toggleShowEdit != 'edit') {
            if (jobPositions?.some(e => e.jobTitle === document.getElementById('jobTitle').value.toLowerCase())) {
                document.getElementById("popupBackground").style.visibility = "visible";
                document.getElementById("popupMessage").style.visibility = 'visible';
            } else {
                dispatch(addNewJobPosition(newJob));
                clearSelects();
                dispatch(clearJobBeingMade());
            }
        } else {
            newJob._id = loadedJobPosition._id;
            dispatch(patchJob(newJob));
            dispatch(clearJobBeingMade());
        }

        dispatch(fetchJobPositions({ org_id: user.org_id, viewingLocation: user.viewingLocation }));

        if (!jobPositions?.some(e => e.jobTitle === document.getElementById('jobTitle').value.toLowerCase())) {
            settingToggle2('edit');
        }

        clearSelects();
    }, [jobBeingCreated, permissionList, user, toggleShowEdit, jobPositions, loadedJobPosition, dispatch, settingToggle2, clearSelects]);

    const selectChange = useCallback((evt) => {
        clearSelects();
        dispatch(SetJobPosition(evt));
        dispatch(setJobBeingMade(evt));
        if (toggleShowEdit != 'edit') {
            settingToggle2('edit');
        }
        document.getElementById("titleText").textContent = "Existing Job Position";
    }, [dispatch, toggleShowEdit, settingToggle2, clearSelects]);

    return (
        <div className={getPageWrapperClassName()}>
            <div id="popupBackground" onClick={hidePopup} style={{visibility:'hidden'}} className={styles.popupBackground}></div>
            <div id="popupMessage" className={styles.popupMessage} style={{visibility:'hidden'}} onClick={hidePopup}>
                <span>A job position with this title already exists, please use a different one.</span>
            </div>

            <div className={styles.userForm}>
                <div style={{ width: '100%' }}>
                    <h3 id="titleText" style={{ textAlign: 'left' }} className={styles.existingUser}>Job Positions</h3>

                    <div style={{ marginTop: '1%', display: 'flex', width: '100%', }}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="jobSelect"
                                ref={selectJobPositionRef}
                                value={loadedJobPosition}
                                className={styles.jobSelect}
                                onChange={selectChange}
                                options={jobPositions}
                                getOptionLabel={(option) => option.jobTitle}
                                getOptionValue={(option) => option.jobTitle}
                            />
                        </div>

                        <div className={styles.createUserWrapper} >
                            <button onClick={() => settingToggle1("create")} className={styles.formBtn3}>
                                Create Job
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {(toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <div className={styles.userInfoSection}>
                    <h4 className={styles.contactInfo} style={{ textAlign: 'left' }}>Job Info</h4>

                    <div className={styles.flexBox}>
                        <div className={styles.inputColumn}>
                            <InputField
                                label="Job Title"
                                id="jobTitle"
                                value={jobBeingCreated?.jobTitle}
                                onChange={(evt) => dispatch(setJobTitle(evt.target.value))}
                            />
                            <InputField
                                label="Description"
                                id="description"
                                value={jobBeingCreated?.description}
                                onChange={(evt) => dispatch(setDescription(evt.target.value))}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={styles.blackLineDiv}></div>

            {(toggleShowCreate == 'create' || toggleShowEdit == 'edit') && (
                <div className={styles.bottomSection}>
                    <div className={styles.permissionSection}>
                        <h4 className={styles.permissionText} style={{ textAlign: 'left' }}>Job Related Permissions</h4>
                        <div className={styles.permissionWrapper}>
                            {['Purchase Requests', 'Maintenance Requests', 'Tasks', 'Edit Users', 'View Form Submissions'].map((permission) => (
                                <div key={permission} className={styles.permissionBlock}>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${permission}`}
                                        checked={permissionList && permissionList.includes(permission)}
                                        onChange={(evt) => togglePermissionFromPosition(evt, permission)}
                                    />
                                    <span className={styles.fixedTextWidth}>{permission}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.formBtnDiv}>
                        <button onClick={() => onDeleteClicked(loadedJobPosition)} className={styles.formBtn} style={{ border: '1px solid black' }}>Delete</button>
                        <button onClick={onCancelClicked} className={styles.formBtn} style={{ backgroundColor: 'black', color: 'white' }}>Cancel</button>
                        <button
                            onClick={onSaveClicked}
                            className={styles.formBtn2}
                        >
                            {toggleShowEdit == 'edit' ? 'Save' : 'Create'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default JobSetup;