
import { React, useEffect, useRef } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from "react-router-dom";
import Select from 'react-select';
import Header from '../../Components/Header';
import SideBar from '../../Components/SideBar';
import styles from "./task.module.css";
import { fetchTasks, setTaskForEdit, getTaskLoadStatus, selectTasks, SetSection, RemoveSection, setKeepUpdatedTask, setRequestStatusReportOnComplete, setDestinationFormat, addNewTask, SaveItemToField, updateQuantity, setFrequency, setDestinationType, updateNote, setJobPosition, setAssignedUser, taskSlice, deleteTask } from './taskSlice';
import { fetchItems, getItemLoadStatus, getLoadedItem, getitemsError, selectItems } from '../forms/items/itemSlice';
import { SetJobPosition, addNewJobPosition, deleteJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../forms/JobPositions/jobPositionSlice';
import { SetUser, addNewUser, selectUsers, getUserLoadStatus, getusersError, getLoadedUser, fetchUsers, deleteUser, updateUser } from '../forms/users/UsersSlice';
import { useAuth0 } from "@auth0/auth0-react";
import { LuPencil } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";

const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]
const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' }]

const destinationType = [{ label: 'User Email' },]

const destinationFormat = [{ label: 'Format' },]
let frequency = []


function viewTask(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const tasks = useSelector(selectTasks);
    const loadingTasks = useSelector(getTaskLoadStatus);

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);

    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }


    //const loadedTask = useSelector(getLoadedItem);

    /*

    const vendors = useSelector(selectVendors);
    const vendorLoading = useSelector(getVendorLoadStatus);
    const vendorsError = useSelector(getVendorsError);
    const loadedVendor = useSelector(getLoadedVendor);
*/




    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation }))
        }
    }, [loadingTasks, dispatch])


    console.log("Tasks : ", tasks)


    return (

        <>

            <div className={styles.pageWrapper}>

                <div className={styles.inputSection} >

                    <div>
                        <h4 style={{ marginTop: '1%' }}>Forms</h4>

                        <div style={{ textAlign: 'right', marginBottom: '3%' }}>
                            <h4 style={{ color: 'grey', textAlign: 'right', marginTop: '-1.1%' }}>Filters</h4>
                        </div>
                    </div>

                    {tasks && tasks.tasks.map((task) => (
                        <div style={{ marginBottom: '1%', width: '100%' }}>
                            <span> {task.name} </span>

                            <div style={{ float: 'Right', justifyContent: 'space-between', display: 'flex', fontSize: '20px', width: '52px', }}>


                                <LuPencil onClick={() => { openPage('Create_Task', task) }} />

                                <FaRegTrashAlt onClick={() => { dispatchDeleteTask(task) }} />
                            </div>
                            <div className={styles.blackLineDiv2}>
                                &nbsp;
                        </div>
                        </div>

                    ))}

                </div>






            </div>

        </>
    )



    function dispatchDeleteTask(task) {
        dispatch(deleteTask(task)).then(() => {
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation }))

        })
    }

    function openPage(page, task) {
        const location = {
            pathname: `/${page}/${task.name}`,
            state: { taskToDisplay: task }
        }
        console.log("Need to open this task :", task)
        //  dispatch(setTaskForEdit(task))




        history.push(location);

        /*
        props.navigation.navigate(page, {
            data: { task: task },
        });*/

        /*
                navigation.navigate('http://localhost:3000/' + page, {
                    params: { task: task },
                });
        */

        // navigation.navigate({ task })


        /*
      setTimeout(() => {
          window.location.href = 'http://localhost:3000/' + page; //TODO

      }, 1000);*/


        //navigate(page)
    }

    function settingRequestStatusReportOnComplete(evt) {
        dispatch(setRequestStatusReportOnComplete(evt.target.checked))
    }


    function settingKeepUpdatedTask(evt) {
        console.log("evt :", evt)
        dispatch(setKeepUpdatedTask(evt.target.checked))
    }

    function settingDestinationFormat(evt) {
        dispatch(setDestinationFormat(evt))
    }

    function settingDestinationType(evt) {
        dispatch(setDestinationType(evt))
    }


    function settingJobPosition(evt) {
        dispatch(setJobPosition(evt))
    }

    function settingAssignedUser(evt) {
        dispatch(setAssignedUser(evt))
    }

    function settingFrequency(evt) {
        dispatch(setFrequency(evt))
    }

    function removeSectionFromTasks(evt, index) {
        dispatch(RemoveSection({ evt: evt, index: index }))
    }



    function toggleDay(day) {
        if (!frequency.includes(day)) {          //checking weather array contain the id
            frequency.push(day);               //adding to array because value doesnt exists
        } else {
            frequency.splice(frequency.indexOf(day), 1);  //deleting
        }
        console.log(frequency, frequency.includes('M'));


        ///  loadedUser.availability = frequency
        console.log("check : ", loadedUser, frequency)
        dispatch(updateUser(loadedUser, { availability: frequency }))
        dispatch(fetchUsers(user.org_id))
    }



    function reportingGroupSelectChange(evt) {
        //  console.log("Checking evt : ", evt)
        //dispatch(setRG(evt))
    }

    function vendorSelectChange(evt) {
        //  console.log("vender set :", evt)
        //dispatch(setVendor(evt))
    }

    function itemSelectedInField(item, index) {
        console.log("Item and index :", item, index)


        dispatch(SaveItemToField({ item: item, index: index }))
    }

    function addSectionToTasks(evt) {

        //set section type
        console.log(" evt ; ", evt)
        dispatch(SetSection(evt))
    }



    function onDeleteClicked(item) {
        /*
        dispatch(deleteItem(item)).then(res => {
            clearDataFromFields()
        })*/
    }


    function onCancelClicked() {/*
        //should clear selected states too todo
        document.getElementById('displayName').value = ''
        document.getElementById('Cost').value = ''
        document.getElementById('Date').value = ''
        document.getElementById('Par').value = ''
        document.getElementById('Size').value = ''
        document.getElementById("itemNumber").value = ''

        clearSelects()*/
    }

    function clearSelects() {
        /*
        let blankEntry = { label: '', value: '' }

        dispatch(setVendor(blankEntry))
        dispatch(setRG(blankEntry))
        dispatch(SetItem(blankEntry))*/
    }


    function updateNoteField(index) {

        console.log("Element :", document.getElementById("Note" + index), "Note" + index)

        var value = document.getElementById("Note" + index).value;
        console.log("Update note :", { index: index, note: value })

        dispatch(updateNote({ index: index, note: value }))

        console.log("Value check : ", value)
    }


    function updateQuantityField(index) {
        console.log("Update qty :", index)

        var value = document.getElementById("Qty" + index).value;

        dispatch(updateQuantity({ index: index, value: value }))

        console.log("Value check : ", value)

    }


    function onSaveClicked() {

        console.log("Data that needs saving :", tasks, tasks.taskBeingMade)

        let name = document.getElementById("taskName").value
        dispatch(addNewTask({ name: name, task: tasks.taskBeingMade, "org_id": user.org_id }))



    }




    function clearDataFromFields() {
        /*
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''*/
    }




}

export default viewTask;