import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"


const FETCH_LOCATIONS_REQUESTED = 'FETCH_LOCATIONS_REQUESTED'
const FETCH_LOCATIONS_SUCCEDED = 'FETCH_LOCATIONS_SUCCEDED'
const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED'
const SET_LOADED_LOCATION = 'SET_LOADED_LOCATION'
const ADD_CATAGORY = 'ADD_CATAGORY'
const REMOVE_CATAGORY = 'REMOVE_CATAGORY'
const SET_ADDRESS = "SET_ADDRESS"
const SET_PHONE = "SET_PHONE"
const SET_NAME = "SET_NAME"
const SET_WEBSITE = "SET_WEBSITE"
const SET_EMAIL = "SET_EMAIL"
const SET_LOCATION = "SET_LOCATION"

const fetchLocationsRequested = () => {
  return {
    type: FETCH_LOCATIONS_REQUESTED
  }
}

const fetchLocationsSuccess = (LOCATIONS) => {
  return {
    type: FETCH_LOCATIONS_SUCCEDED
  }
}

const fetchLocationsFailed = (error) => {
  return {
    type: FETCH_LOCATIONS_FAILED,
    payload: error
  }
}

/*
export const SetLocation = (params) => async (dispatch) => {
  await useDispatch(SET_LOADED_LOCATION(params))
  return await useDispatch(fetchLocations())
}
*/

export const SetLocation = createAsyncThunk(
  'Locations/setLocation',
  async (location) => {

    return {
      type: SET_LOADED_LOCATION,
      payload: location
    }

  }
);



export const setLocationBeingMade = createAsyncThunk(
  'Locations/setLocationBeingMade',
  async (location) => {

    return {
      type: SET_LOCATION,
      payload: location
    }

  }
);

export const setLocationNameBeingMade = createAsyncThunk(
  'Locations/setLocationNameBeingMade',
  async (location) => {

    return {
      type: SET_NAME,
      payload: location
    }

  }
);
export const setLocationEmailBeingMade = createAsyncThunk(
  'Locations/setLocationEmailBeingMade',
  async (location) => {

    return {
      type: SET_EMAIL,
      payload: location
    }

  }
);
export const setLocationPhoneBeingMade = createAsyncThunk(
  'Locations/setLocationPhoneBeingMade',
  async (location) => {

    return {
      type: SET_PHONE,
      payload: location
    }

  }
);
export const setLocationWebsiteBeingMade = createAsyncThunk(
  'Locations/setLocationWebsiteBeingMade',
  async (location) => {

    return {
      type: SET_WEBSITE,
      payload: location
    }

  }
);
export const setLocationAddressBeingMade = createAsyncThunk(
  'Locations/setLocationAddressBeingMade',
  async (location) => {

    return {
      type: SET_ADDRESS,
      payload: location
    }

  }
);






const initialState = {
  loading: false,
  locations: [],
  locationsError: null,
  loadedLocation: '',
  locationBeingMade: {}
};


// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchLocations = createAsyncThunk(
  'Locations/fetchLocations',
  async (orgId) => {
    try {

    //  console.log("fetching locations orgId:" , orgId)
      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/locations?org_id=${orgId}`)

      const realData = await test.json();

      return realData
    }
    catch (err) {
   //   console.log("Error message :", err.message)
      return err.message
    }

  }
);


export const addNewLocation = createAsyncThunk('Locations/addNewLocation', async (initialData) => {

  try {

  //  console.log("Check create ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/locations`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    return err.message
  }

}
);

export const patchLocation = createAsyncThunk('Locations/patchLocation', async (data) => {

  try {

   // console.log("patch ; ", data, data._id)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/locations/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
  //  console.log("whats my error : ", err.message)
    return err.message
  }

});


export const deleteLocation = createAsyncThunk('Locations/deleteLocation', async (toDelete) => {

  try {

   // console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/locations/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
  //  console.log("whats my error : ", err.message)
    return err.message
  }

}
);



export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_LOCATIONS_REQUESTED: (state, action) => {
      return state
    },

    FETCH_LOCATIONS_SUCCEDED: (state, action) => {
      return { loading: false, locations: action.payload, error: '' }
    },

    FETCH_LOCATIONS_FAILED: (state, action) => {
      // console.log("fetch locations failed reached")
      return { loading: false, locations: [], error: action.payload }
    },
    /*
        SET_LOADED_LOCATION: (state, action) => {
          state.loadedLocation = action.payload
          console.log("Set loadedLocation :" , state.loadedLocation)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder


      .addCase(fetchLocations.pending, (state) => {
        state.loading = true;
        state.locationsError = ''
     //   console.log("fetch locations in reducer, pending")

      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        console.log("fetched  :", action)
        state.loading = 'Done';
        state.locations = action.payload.docs
        state.locationsError = ''
        // console.log("data :", state.locations, action)
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.loading = false;
        state.locations = []
     //   console.log("action;", action.error)
        state.locationsError = action.error.message

      })


  
      //Selecting locations
      .addCase(SetLocation.fulfilled, (state, action) => {
        state.loadedLocation = action.payload.payload
        state.loading = 'Done';
        state.locationsError = ''

      })

      //Creating Locations
      .addCase(addNewLocation.fulfilled, (state, action) => {
        state.loading = false;
        //  state.locations.push(action.payload) TODO ideally locations are updated here, but couldnt get it working so going to call GET again
        state.locationsError = ''
      })
      .addCase(setLocationBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          address: action.payload.payload.address,
          name: action.payload.payload.name,
          website: action.payload.payload.website,
          phone: action.payload.payload.phone,
          email: action.payload.payload.email,
        };
      })
      
      .addCase(setLocationAddressBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          address: action.payload.payload,
        };
      })
      
      .addCase(setLocationNameBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          name: action.payload.payload,
        };
      })
      
      .addCase(setLocationPhoneBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          phone: action.payload.payload,
        };
      })
      
      .addCase(setLocationEmailBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          email: action.payload.payload,
        };
      })
      
      .addCase(setLocationWebsiteBeingMade.fulfilled, (state, action) => {
        state.locationBeingMade = {
          ...state.locationBeingMade,
          website: action.payload.payload,
        };
      })


      //update locations


      .addCase(patchLocation.fulfilled, (state, action) => {
        state.loading = false; //by setting false here, the component will call fetch to update data
        state.locationsError = ''
      })

      //Deleting Locations
      .addCase(deleteLocation.rejected, (state, action) => {
        state.loading = false;
        state.locationsError = action.error.message
      })
      .addCase(deleteLocation.pending, (state, action) => {
        state.loading = true;
        state.locationsError = ''
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.loading = false; //by setting false here, the component will call fetch to update data
        state.locationsError = ''
      })
  },
});

//export const { fetch, create } = locationSlice.actions;

export const loadLocations = () => {
  return dispatch(fetchLocations())
}

/*
export const setLoadedLocations = (location) => {
  return dispatch(SET_SELECTED_LOCATION(location))
}
*/


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectLocations = (state) => state.location.locations;
export const getLocationLoadStatus = (state) => state.location.loading;
export const getlocationsError = (state) => state.location.locationsError;
export const getLoadedLocation = (state) => state.location.loadedLocation;
export const getLocationBeingMade = (state) => state.location.locationBeingMade;

export default locationSlice.reducer;
export const locationActions = locationSlice.actions;

