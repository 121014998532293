
import { React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useHistory, } from "react-router-dom";
import Select from 'react-select';
import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import styles from "./notification.module.css";
import { selectNotifications, setSelectedNotification, fetchNotifications } from "./notification.slice"
import { fetchTasks, setTaskForEdit, getTaskLoadStatus, selectTasks, SetSection, RemoveSection, setKeepUpdatedTask, setRequestStatusReportOnComplete, setDestinationFormat, addNewTask, SaveItemToField, updateQuantity, setFrequency, setDestinationType, updateNote, setJobPosition, setAssignedUser, taskSlice, deleteTask } from '../../taskBuilder/taskSlice';
import { fetchItems, getItemLoadStatus, getLoadedItem, getitemsError, selectItems } from '../../forms/items/itemSlice';
import { SetJobPosition, addNewJobPosition, deleteJobPosition, fetchJobPositions, getJobPositionLoadStatus, getLoadedJobPosition, getjobPositionsError, selectJobPositions } from '../../forms/JobPositions/jobPositionSlice';
import { SetUser, addNewUser, selectUsers, getUserLoadStatus, getusersError, getLoadedUser, fetchUsers, deleteUser, updateUser } from '../../forms/users/UsersSlice';
import { useAuth0 } from "@auth0/auth0-react";
import { LuPencil } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";


const taskTypes = [{ label: 'Maintenance Request' }, { label: 'Purchase Request' }, { label: 'Notification' }, { label: 'Select Form' }]
const daysOfWeek = [{ label: 'Sunday' }, { label: 'Monday' }, { label: 'Tuesday' }, { label: 'Wednesday' }, { label: 'Thursday' }, { label: 'Friday' },
{ label: 'Saturday' }]

const destinationType = [{ label: 'User Email' },]

const destinationFormat = [{ label: 'Format' },]
let frequency = []














const PDFBlobViewer = ({ pdfData }) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [error, setError] = useState(null);
  
    useEffect(() => {
      if (pdfData) {
        try {
          // Check if the pdfData is already a valid URL
          if (pdfData.startsWith('data:application/pdf')) {
            setPdfUrl(pdfData);
            return;
          }
  
          // Remove any potential prefix (like "data:application/pdf;base64,")
          const base64Data = pdfData.replace(/^data:application\/pdf;base64,/, "");
  
          // Decode the base64 string
          const binaryString = window.atob(base64Data);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
  
          // Create a Blob from the decoded data
          const blob = new Blob([bytes], { type: 'application/pdf' });
          
          // Create a URL for the Blob
          const url = URL.createObjectURL(blob);
          
          setPdfUrl(url);
  
          // Clean up the URL when the component unmounts
          return () => URL.revokeObjectURL(url);
        } catch (err) {
          console.error("Error processing PDF data:", err);
          setError("Failed to process PDF data. See console for details.");
        }
      }
    }, [pdfData]);
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    if (!pdfUrl) {
      return <div>Loading PDF...</div>;
    }
  
    return (
      <div style={{ width: '100%', height: '500px' }}>
        <iframe
          src={pdfUrl}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          title="PDF Viewer"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    );
  };










function notifications(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const [expandedTasks, setExpandedTasks] = useState({});
    // ... (rest of your existing code)

    const toggleExpand = (taskId) => {
        setExpandedTasks(prev => ({
            ...prev,
            [taskId]: !prev[taskId]
        }));


        console.log("check? :", taskId, notifSliceState?.selectedNotification?._id)
        if (taskId == notifSliceState?.selectedNotification?._id) {
            setExpandedTaskId()
            console.log("reached 1: ")
        }
        else {
            setExpandedTaskId(taskId)
            console.log("reached 2: ")

        }

        console.log("Expanded tasks :", expandedTasks, "setExpandedTaskId", expandedTaskId)
    };



    const [expandedTaskId, setExpandedTaskId] = useState(null);




    const notifications = useSelector(selectTasks);
    const notifSliceState = useSelector(selectNotifications);

    console.log("notifSliceState", notifSliceState)
    const loadingTasks = useSelector(getTaskLoadStatus);

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);

    const users = useSelector(selectUsers);
    const loadingUsers = useSelector(getUserLoadStatus);
    const usersError = useSelector(getusersError);
    const loadedUser = useSelector(getLoadedUser);

    const jobPositions = useSelector(selectJobPositions);
    const jobPositionLoading = useSelector(getJobPositionLoadStatus);
    const jobPositionErrors = useSelector(getjobPositionsError);
    const loadedJobPosition = useSelector(getLoadedJobPosition);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`

    }


    //const loadedTask = useSelector(getLoadedItem);

    /*

    const vendors = useSelector(selectVendors);
    const vendorLoading = useSelector(getVendorLoadStatus);
    const vendorsError = useSelector(getVendorsError);
    const loadedVendor = useSelector(getLoadedVendor);
*/




    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation, type: 'Notification' }))
        }
    }, [loadingTasks, dispatch])


    console.log("notifications : ", notifications)


    return (

        <>

            <div id="pagewrapper" className={styles.pageWrapper}>


                <div>
                    <h4 style={{ marginTop: '1%', textAlign: 'left9' }}>Notifications</h4>

                    <div style={{ textAlign: 'right', marginBottom: '3%' }}>
                        <h4 style={{ color: 'grey', textAlign: 'right', marginTop: '-1.1%' }}>Filters</h4>
                    </div>
                </div>

                <div className={styles.pagebody}>
                    {notifications && notifications.tasks.map((task) => (
                        <div
                            key={task.id}
                            className={styles.notificationBody}
                            style={{
                                transition: 'all 0.3s ease-in-out',
                                height: notifSliceState?.selectedNotification?._id === task?._id ? '760px' : '80px',
                                overflow: 'hidden',
                                cursor: 'pointer'
                            }}

                            onClick={() => setNotif(task)}
                          >
                            <div className={styles.iconBuffer}>
                                <div className={styles.iconArea}>
                                    <MdOutlineNotificationsActive />
                                </div>
                            </div>

                            <div className={styles.midbuffer}>
                                <div className={styles.notifMiddle}>
                                    <span>{task.name}</span>

                                    {task?.task?.fields?.length > 0 && <div style={{ fontSize: '12px', marginTop: '-2%' }}>
                                        <span>{task?.task?.fields[0]?.note}</span>
                                    </div>}


                                    <span style={{
                                        display: notifSliceState?.selectedNotification?._id === task?._id ? 'block' : 'none',
                                        marginTop: '10px'
                                    }}>
                                        {task?.task?.fields?.map((field) => {
                                            return (
                                                <div style={{ display: 'flex' }}>

                                                    <div style={{ marginTop: '3%' }}>
                                                        {field.picture && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.picture.data}`} />}
                                                    </div>


                                                    {field.file && <PDFBlobViewer pdfData={field.file.data} />}

                                                </div>
                                            )
                                        })}



                                    </span>
                                </div>
                            </div>
                            <div className={styles.iconBuffer}>
                                <div className={styles.iconArea}>
                                    {notifSliceState?.selectedNotification?._id === task?._id ? <FiMinusCircle /> : <FiPlusCircle />}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>








            </div>

        </>
    )
    /*
                    {notifications && notifications.tasks.map((task) => (
                        <div style={{ marginBottom: '1%', width: '100%' }}>
                            <span> {task.name} </span>
    
                            <div style={{ float: 'Right', justifyContent: 'space-between', display: 'flex', fontSize: '20px', width: '52px', }}>
    
    
                                <LuPencil onClick={() => { openPage('view_notification', task) }} />
    
                                <FaRegTrashAlt onClick={() => { dispatchDeleteTask(task) }} />
                            </div>
                            <div className={styles.blackLineDiv2}>
                                &nbsp;
                            </div>
                        </div>
    
                    ))}
    */
    function dispatchDeleteTask(task) {
        dispatch(deleteTask(task)).then(() => {
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation }))

        })
    }


    function setNotif(notif) {

        console.log("check? :", notif._id, notifSliceState?.selectedNotification?._id, notif._id == notifSliceState?.selectedNotification?._id)

        if (!notifSliceState?.selectedNotification?._id) {
            dispatch(setSelectedNotification(notif))

        }
        else {
            if (notif._id == notifSliceState?.selectedNotification?._id) {
                dispatch(setSelectedNotification({}))
            }
            else {
                dispatch(setSelectedNotification(notif))
            }
        }







    }
    function openPage(page, task) {
        const encodedTask = encodeURIComponent(JSON.stringify(task));

        const location = {
            pathname: `/${page}/${task.name}`,
            search: `?taskData=${encodedTask}`
        };

        console.log("Navigating to:", location);
        history.push(location);
        navigation.navigate({ location })

    }

    function settingRequestStatusReportOnComplete(evt) {
        dispatch(setRequestStatusReportOnComplete(evt.target.checked))
    }

    function expandTask() {

        //change css states (?)
        //save redux state of whats opened



        /*
        .profileButton {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            width: 225px;
            height: 50px;
            border-radius: 35px;
            display: flex;
            top: 24%;
            position: relative;
            left: 13%;
            background-color: white;
            transition: all .3s ease-in-out;
        
        
        }
        
        
        
        .profileButton:hover {
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            width: 225px;
            border-radius: 35px;
            display: flex;
            top: 24%;
            position: relative;
            left: 13%;
            background-color: white;
        
        
        
            height: 250px;
        
        
        }*/




    }

    function settingKeepUpdatedTask(evt) {
        console.log("evt :", evt)
        dispatch(setKeepUpdatedTask(evt.target.checked))
    }

    function settingDestinationFormat(evt) {
        dispatch(setDestinationFormat(evt))
    }

    function settingDestinationType(evt) {
        dispatch(setDestinationType(evt))
    }


    function settingJobPosition(evt) {
        dispatch(setJobPosition(evt))
    }

    function settingAssignedUser(evt) {
        dispatch(setAssignedUser(evt))
    }

    function settingFrequency(evt) {
        dispatch(setFrequency(evt))
    }

    function removeSectionFromTasks(evt, index) {
        dispatch(RemoveSection({ evt: evt, index: index }))
    }



    function toggleDay(day) {
        if (!frequency.includes(day)) {          //checking weather array contain the id
            frequency.push(day);               //adding to array because value doesnt exists
        } else {
            frequency.splice(frequency.indexOf(day), 1);  //deleting
        }
        console.log(frequency, frequency.includes('M'));


        ///  loadedUser.availability = frequency
        console.log("check : ", loadedUser, frequency)
        dispatch(updateUser(loadedUser, { availability: frequency }))
        dispatch(fetchUsers(user.org_id))
    }



    function reportingGroupSelectChange(evt) {
        //  console.log("Checking evt : ", evt)
        //dispatch(setRG(evt))
    }

    function vendorSelectChange(evt) {
        //  console.log("vender set :", evt)
        //dispatch(setVendor(evt))
    }

    function itemSelectedInField(item, index) {
        console.log("Item and index :", item, index)


        dispatch(SaveItemToField({ item: item, index: index }))
    }

    function addSectionToTasks(evt) {

        //set section type
        console.log(" evt ; ", evt)
        dispatch(SetSection(evt))
    }



    function onDeleteClicked(item) {
        /*
        dispatch(deleteItem(item)).then(res => {
            clearDataFromFields()
        })*/
    }


    function onCancelClicked() {/*
        //should clear selected states too todo
        document.getElementById('displayName').value = ''
        document.getElementById('Cost').value = ''
        document.getElementById('Date').value = ''
        document.getElementById('Par').value = ''
        document.getElementById('Size').value = ''
        document.getElementById("itemNumber").value = ''

        clearSelects()*/
    }

    function clearSelects() {
        /*
        let blankEntry = { label: '', value: '' }

        dispatch(setVendor(blankEntry))
        dispatch(setRG(blankEntry))
        dispatch(SetItem(blankEntry))*/
    }


    function updateNoteField(index) {

        console.log("Element :", document.getElementById("Note" + index), "Note" + index)

        var value = document.getElementById("Note" + index).value;
        console.log("Update note :", { index: index, note: value })

        dispatch(updateNote({ index: index, note: value }))

        console.log("Value check : ", value)
    }


    function updateQuantityField(index) {
        console.log("Update qty :", index)

        var value = document.getElementById("Qty" + index).value;

        dispatch(updateQuantity({ index: index, value: value }))

        console.log("Value check : ", value)

    }


    function onSaveClicked() {

        console.log("Data that needs saving :", tasks, tasks.taskBeingMade)

        let name = document.getElementById("taskName").value
        dispatch(addNewTask({ name: name, task: tasks.taskBeingMade, "org_id": user.org_id }))



    }




    function clearDataFromFields() {
        /*
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''*/
    }




}

export default notifications;