import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetch from "node-fetch";
const SET_LOADED_TASK = 'SET_LOADED_TASK'
const SET_SECTION_TYPE = 'SET_SECTION_TYPE'
const REMOVE_SECTION = "REMOVE_SECTION"
const SAVE_ITEM_TO_FIELD = "SAVE_ITEM_TO_FIELD"
const UPDATE_QUANTITY = "UPDATE_QUANTITY"
const UPDATE_NOTE = "UPDATE_NOTE"

const SET_JOB_POSITION = 'SET_JOB_POSITION'
const SET_ASSIGNED_USER = 'SET_ASSIGNED_USER'
const SET_DESTINATION_FORMAT = 'SET_DESTINATION_FORMAT'
const SET_FREQUENCY = 'SET_FREQUENCY'
const SET_TASK_NAME = 'SET_TASK_NAME'
const SET_FORM_FIELD = 'SET_FORM_FIELD'
const SET_JOB_REPORT = "SET_JOB_REPORT"
const SET_USER_REPORT = "SET_USER_REPORT"
const REMOVE_USER_RESPONSE = "REMOVE_USER_RESPONSE"
const ADD_USER_RESPONSE = "ADD_USER_RESPONSE"
const UPDATE_ASSIGNED_JOB_POSITION = "UPDATE_ASSIGNED_JOB_POSITION"
const SET_START_DATE = "SET_START_DATE"
const SET_END_DATE = "SET_END_DATE"
const DELETE_ROWS = "DELETE_ROWS"
const CLEAR_SELECTED_ROWS = "CLEAR_SELECTED_ROWS"
const SET_DELETE_ROWS = "SET_DELETE_ROWS"
const SET_DUE_DATE = "SET_DUE_DATE"

const SET_INTERVAL_TYPE = "SET_INTERVAL_TYPE"
const SET_INTERVAL_VALUE = "SET_INTERVAL_VALUE"
const SET_INTERVAL_AMOUNT = "SET_INTERVAL_AMOUNT"
const SET_DISPLAY_DAYS = "SET_DISPLAY_DAYS"

const SET_MONTH_OPTION = "SET_MONTH_OPTION"
const SET_ORDINAL_MONTH_VALUE = "SET_ORDINAL_MONTH_VALUE"
const SET_MONTHLY_REPEAT_DAY = "SET_MONTHLY_REPEAT_DAY"
const SET_YEAR_OPTION = "SET_YEAR_OPTION"
const SET_YEAR_RECURRENCE = "SET_YEAR_RECURRENCE"
const SET_DAY_OF_MONTH_RECURRENCE = "SET_DAY_OF_MONTH_RECURRENCE"
const SET_ORDINAL_VALUE_YEAR = "SET_ORDINAL_VALUE_YEAR"
const SET_DAY_VALUE_FOR_YEAR_RECURRENCE = "SET_DAY_VALUE_FOR_YEAR_RECURRENCE"

const SET_NOTIF = "SET_NOTIF"

export const SetSection = createAsyncThunk(
    'Tasks/setSection',
    async (item) => {
        return {
            type: SET_SECTION_TYPE,
            payload: item
        }
    }
);

export const RemoveSection = createAsyncThunk(
    'Tasks/removeSection',
    async (item) => {
        return {
            type: REMOVE_SECTION,
            payload: item
        }
    }
);

export const updateQuantity = createAsyncThunk(
    'Tasks/updateQuantity',
    async (item) => {
        return {
            type: UPDATE_QUANTITY,
            payload: item
        }
    }
);

export const updateNoteNotification = createAsyncThunk(
    'Tasks/updateNoteNotification',
    async (item) => {

        return {
            type: UPDATE_NOTE,
            payload: item
        }

    }
);

export const SaveItemToField = createAsyncThunk(
    'Tasks/SaveItemToField',
    async (data) => {
        console.log("Recieved data :", data)
        return {
            type: SAVE_ITEM_TO_FIELD,
            payload: data
        }

    }
);

export const SetTask = createAsyncThunk(
    'Tasks/setTask',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const setEndDate = createAsyncThunk(
    'Tasks/setEndDate',
    async (item) => {
        return {
            type: SET_END_DATE,
            payload: item
        }
    }
);

export const setAssignedJobPositions = createAsyncThunk(
    'Tasks/setAssignedJobPositions',
    async (item) => {
        return {
            type: UPDATE_ASSIGNED_JOB_POSITION,
            payload: item
        }
    }
);

export const setJobPosition = createAsyncThunk(
    'Tasks/setJobPosition',
    async (item) => {
        return {
            type: SET_JOB_POSITION,
            payload: item
        }
    }
);

export const setAssignedUser = createAsyncThunk(
    'Tasks/setAssignedUser',
    async (item) => {
        return {
            type: SET_ASSIGNED_USER,
            payload: item
        }
    }
);

export const setFrequency = createAsyncThunk(
    'Tasks/setFrequency',
    async (item) => {
        return {
            type: SET_FREQUENCY,
            payload: item
        }
    }
);

export const setDestinationType = createAsyncThunk(
    'Tasks/setDestinationType',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const setDestinationFormat = createAsyncThunk(
    'Tasks/setDestinationFormat',
    async (item) => {
        return {
            type: SET_DESTINATION_FORMAT,
            payload: item
        }
    }
);

export const setKeepUpdatedTask = createAsyncThunk(
    'Tasks/setKeepUpdatedTask',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const addUserResponse = createAsyncThunk(
    'Tasks/addUserResponse',
    async (item) => {
        return {
            type: ADD_USER_RESPONSE,
            payload: item
        }
    }
);

export const removeUserResponse = createAsyncThunk(
    'Tasks/removeUserResponse',
    async (item) => {
        return {
            type: REMOVE_USER_RESPONSE,
            payload: item
        }
    }
);




export const setRequestStatusReportOnComplete = createAsyncThunk(
    'Tasks/setRequestStatusReportOnComplete',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const setTaskName = createAsyncThunk(
    'Tasks/setTaskName',
    async (item) => {
        return {
            type: SET_TASK_NAME,
            payload: item
        }
    }
);



export const saveTask = createAsyncThunk(
    'Tasks/saveTask',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const setTaskForEdit = createAsyncThunk(
    'Tasks/setTaskForEdit',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);

export const setFormField = createAsyncThunk(
    'Tasks/setFormField',
    async (item) => {
        return {
            type: SET_FORM_FIELD,
            payload: item
        }
    }
);

export const clearTask = createAsyncThunk(
    'Tasks/clearTask',
    async (item) => {
        return {
            type: SET_LOADED_TASK,
            payload: item
        }
    }
);


export const settingJobToReportTo = createAsyncThunk(
    'Tasks/settingJobToReportTo',
    async (item) => {
        return {
            type: SET_JOB_REPORT,
            payload: item
        }
    }
);

export const settingUserToReportTo = createAsyncThunk(
    'Tasks/settingUserToReportTo',
    async (item) => {
        return {
            type: SET_USER_REPORT,
            payload: item
        }
    }
);

export const setStartDate = createAsyncThunk(
    'Tasks/setStartDate',
    async (item) => {
        return {
            type: SET_START_DATE,
            payload: item
        }
    }
);

export const deleteSelectedRows = createAsyncThunk(
    'Tasks/deleteSelectedRows',
    async (item) => {
        return {
            type: DELETE_ROWS,
            payload: item
        }
    }
);

export const clearSelectedDeleteRows = createAsyncThunk(
    'Tasks/clearSelectedDeleteRows',
    async (item) => {
        return {
            type: CLEAR_SELECTED_ROWS,
            payload: item
        }
    }
);
export const setSelectedDeleteRows = createAsyncThunk(
    'Tasks/setSelectedDeleteRows',
    async (item) => {
        return {
            type: SET_DELETE_ROWS,
            payload: item
        }
    }
);
export const setDueDateOfReport = createAsyncThunk(
    'Tasks/setDueDateOfReport',
    async (item) => {
        return {
            type: SET_DUE_DATE,
            payload: item
        }
    }
);


export const setRepeatIntervalType = createAsyncThunk(
    'Tasks/setRepeatIntervalType',
    async (item) => {
        return {
            type: SET_INTERVAL_TYPE,
            payload: item
        }
    }
);

export const setIntervalValue = createAsyncThunk(
    'Tasks/setIntervalValue',
    async (item) => {
        return {
            type: SET_INTERVAL_VALUE,
            payload: item
        }
    }
);


export const setIntervalAmount = createAsyncThunk(
    'Tasks/setIntervalAmount',
    async (item) => {
        return {
            type: SET_INTERVAL_AMOUNT,
            payload: item
        }
    }
);
export const setDisplayOfReport = createAsyncThunk(
    'Tasks/setDisplayOfReport',
    async (item) => {
        return {
            type: SET_DISPLAY_DAYS,
            payload: item
        }
    }
);
export const setMonthOption = createAsyncThunk(
    'Tasks/setMonthOption',
    async (item) => {
        return {
            type: SET_MONTH_OPTION,
            payload: item
        }
    }
);
export const setOrdinalValueMonth = createAsyncThunk(
    'Tasks/setOrdinalValueMonth',
    async (item) => {
        return {
            type: SET_ORDINAL_MONTH_VALUE,
            payload: item
        }
    }
);

export const setMonthDayToRepeat = createAsyncThunk(
    'Tasks/setMonthDayToRepeat',
    async (item) => {
        return {
            type: SET_MONTHLY_REPEAT_DAY,
            payload: item
        }
    }
); export const setYearOption = createAsyncThunk(
    'Tasks/setYearOption',
    async (item) => {
        return {
            type: SET_YEAR_OPTION,
            payload: item
        }
    }
);

export const setMonthValueForYearRecurrence = createAsyncThunk(
    'Tasks/setMonthValueForYearRecurrence',
    async (item) => {
        return {
            type: SET_YEAR_RECURRENCE,
            payload: item
        }
    }
);

export const setDayValueOfMonthRecurrence = createAsyncThunk(
    'Tasks/setDayValueOfMonthRecurrence',
    async (item) => {
        return {
            type: SET_DAY_OF_MONTH_RECURRENCE,
            payload: item
        }
    }
);
export const setOrdinalValueYear = createAsyncThunk(
    'Tasks/setOrdinalValueYear',
    async (item) => {
        return {
            type: SET_ORDINAL_VALUE_YEAR,
            payload: item
        }
    }
);
export const setDayValueForYearRecurrence = createAsyncThunk(
    'Tasks/setDayValueForYearRecurrence',
    async (item) => {
        return {
            type: SET_DAY_VALUE_FOR_YEAR_RECURRENCE,
            payload: item
        }
    }
);
export const setMonthValueForYearRecurrenceBottom = createAsyncThunk(
    'Tasks/setMonthValueForYearRecurrenceBottom',
    async (item) => {
        return {
            type: SET_DAY_VALUE_FOR_YEAR_RECURRENCE,
            payload: item
        }
    }
);
export const setSelectedNotification = createAsyncThunk(
    'Tasks/setSelectedNotification',
    async (item) => {
        return {
            type: SET_NOTIF,
            payload: item
        }
    }
);



const initialState = {
    rowsSelectedForDeletion: [],
    selectedTask: {},
    loadingTasks: false,
    notifications: [],
    taskError: null,
    loadedTask: '',
    taskBeingMade: { type: '', fields: [], keepUpdatedCopy: false, requestReportOnCompletion: false, assignedJobPosition: [], assignedUser: [], destinationFormat: '', destinationType: '', frequency: [] },
};

//Four task types


var maintenanceRequestLine = {
    item: '',
    quantity: '',
    note: '',

    includePar: false,
    userResponse: [],
    parValue: '',
    responseOption: '',
    adminIncludeInOrder: '',
    adminReviewCheckboxValue: '',
    adminReviewCheckboxToggle: 'off'

}
const purchaseRequestLine = {
    item: '',
    quantity: '',
    note: '',

    includePar: false,
    userResponse: [],
    parValue: '',
    responseOption: '',
    adminIncludeInOrder: '',
    adminReviewCheckboxValue: '',
    adminReviewCheckboxToggle: 'off'
}
const notificationLine = {
    notification: '',
    picture: '',
    item: ''
}
const formLine = {
    formRef: '',
    note: '',
}

// The function(s) below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(action_name(input))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests. --- the slice's extra reducers catch the results
// of these async thunks

export const fetchNotifications = createAsyncThunk(
    'Tasks/fetchNotifications',
    async (param) => {

        console.log("incoming task params :", param)
        try {
            let test
            if (param != null) {
                //add param to query

                if (param.user_id) {
                    //4/5/24 
                    //This is becoming the really specific query used for most users (assigned tasks to user, also assigned to the current day)
                    //its assumed here this function to query is sent all data all below data all the time, even though im only checking for user id

                    test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks?org_id=` + param.org_id + '&location=' + param.location_id + '&user_id=' + param.user_id + '&day=' + param.today + '&jobPosition=' + param.jobPosition)

                    const realData = await test.json();

                    return realData
                }
                else {
                    if (param.type) {
                        test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks?org_id=` + param.org_id + '&location=' + param.location_id + '&type=' + param.type)
                    }
                    else {
                        test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks?org_id=` + param.org_id + '&location=' + param.location_id)
                    }

                    const realData = await test.json();



                    console.log('Returned purchase requests ; ', realData)
                    return realData
                }
            }
            else {
                //normal query
                test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks`)

                const realData = await test.json();

                return realData
            }
        }
        catch (err) {
            return err.message
        }

    }
);

export const addNewTask = createAsyncThunk('Tasks/addNewTask', async (initialData) => {


    console.log("POSTING NEW ")

    try {
        if (initialData.type == 'Purchase Request' || 'Maintenance Request') {
            initialData.orderComplete = false
        }

        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(initialData),
        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        return err.message
    }

}
);

export const deleteTask = createAsyncThunk('Tasks/deleteTask', async (data) => {
    try {
        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks/${data._id}`, {
            method: 'delete',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        return err.message
    }
}

);

export const patchTask = createAsyncThunk('Tasks/patchTask', async (data) => {

    console.log("updating task :", data)
    try {
        let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks/${data._id}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })

        const realData = await test.json();

        return realData
    }
    catch (err) {
        console.log("whats my error : ", err.message)
        return err.message
    }

});

export const notificationSlice = createSlice({
    name: 'tasks',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        FETCH_TASKS_REQUESTED: (state, action) => {
            return state
        },

        FETCH_TASKS_SUCCEDED: (state, action) => {
            return { loading: false, tasks: action.payload, error: '' }
        },

        FETCH_TASKS_FAILED: (state, action) => {
            // console.log("fetch tasks failed reached")
            return { loading: false, tasks: [], error: action.payload }
        },

    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            //Fetching tasks
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = false;
                state.tasksError = ''
            })

            .addCase(fetchNotifications.fulfilled, (state, action) => {
                console.log("Tasks action : ", action)

                state.loading = 'Done';
                state.tasks = action.payload.docs
                state.tasksError = ''
            })

            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.tasks = []
                console.log("Error message ;", action.error)
                state.tasksError = action.error.message
            })

            .addCase(updateQuantity.rejected, (state, action) => {
                state.tasksError = ''
            })

            .addCase(updateQuantity.fulfilled, (state, action) => {
                state.taskBeingMade.fields[action.payload.payload.index].quantity = action.payload.payload.value
                state.tasksError = ''
            })

            .addCase(clearTask.fulfilled, (state, action) => {
                state.taskBeingMade = initialState.taskBeingMade
                state.tasksError = ''
            })

            .addCase(clearSelectedDeleteRows.fulfilled, (state, action) => {
                state.rowsSelectedForDeletion = []
            })

            .addCase(setSelectedDeleteRows.fulfilled, (state, action) => {
                console.log("Setting rows for deletion :", action)
                state.rowsSelectedForDeletion = action.payload.payload
            })

            .addCase(settingJobToReportTo.fulfilled, (state, action) => {
                console.log("reportToJobPosition", action)
                state.taskBeingMade.reportToJobPosition = action.payload.payload
            })

            .addCase(setAssignedJobPositions.fulfilled, (state, action) => {
                state.taskBeingMade.assignedJobPositions = action.payload.payload.e
                state.tasksError = ''
            })

            .addCase(settingUserToReportTo.fulfilled, (state, action) => {
                console.log("reportToUser", action)
                state.taskBeingMade.reportToUser = action.payload.payload
            })

            .addCase(setTaskForEdit.fulfilled, (state, action) => {
                state.taskBeingMade = action.payload.payload.task
                state.taskBeingMade.fields = action.payload.payload.task.fields
                state.taskBeingMade.assignedUser = action.payload.payload.task.assignedUser
                state.taskBeingMade.frequency = action.payload.payload.task.frequency
                state.taskBeingMade.keepUpdatedCopy = action.payload.payload.task.keepUpdatedCopy
                state.taskBeingMade.requestReportOnCompletion = action.payload.payload.task.requestReportOnCompletion
                state.taskBeingMade.type = action.payload.payload.task.type
                state.taskBeingMade.destinationFormat = action.payload.payload.task.destinationFormat
                state.taskBeingMade.destinationType = action.payload.payload.task.destinationType
                state.taskBeingMade.assignedJobPosition = action.payload.payload.task.assignedJobPosition
                state.taskBeingMade.name = action.payload.payload.name

                state.tasksError = ''
            })


            .addCase(setFormField.fulfilled, (state, action) => {
                state.taskBeingMade.fields[action.payload.payload.index].formRef = action.payload.payload.form
            })

            .addCase(setTaskName.fulfilled, (state, action) => {
                state.taskBeingMade.name = action.payload.payload
            })


            //Creating Tasks
            .addCase(SetSection.fulfilled, (state, action) => {
                let oldType = state.taskBeingMade.type
                state.taskBeingMade.type = action.payload.payload;

                if (state.taskBeingMade.fields.length > 1 && oldType != action.payload.payload) {
                    state.taskBeingMade.fields = [];
                }

                //based on type add different field object thing here (need one initial element)
                if (action.payload.payload == 'Maintenance Request') {
                    state.taskBeingMade.fields.push(maintenanceRequestLine)
                }
                if (action.payload.payload == 'Purchase Request') {
                    state.taskBeingMade.fields.push(purchaseRequestLine)
                }
                if (action.payload.payload == 'Notification') {
                    state.taskBeingMade.fields.push(notificationLine)
                }


                if (action.payload.payload == 'Select Form') {
                    state.taskBeingMade.fields.push(formLine)
                }

                if (state.taskBeingMade.fields.length > 1 && oldType != action.payload.payload) {
                    state.taskBeingMade.fields.pop();
                }
                state.tasksError = ''
            })

            .addCase(patchTask.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = []
            })

            .addCase(setSelectedNotification.fulfilled, (state, action) => {

                console.log("check? :",)


                    state.selectedNotification = action.payload.payload
       

            })




            .addCase(setFrequency.fulfilled, (state, action) => {
                state.taskBeingMade.frequency = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setStartDate.fulfilled, (state, action) => {
                console.log('setStartDate: ', action, action.value)


                state.startDate = action.payload.payload.data

                state.FormsError = ''
            })

            .addCase(setJobPosition.fulfilled, (state, action) => {
                state.taskBeingMade.assignedJobPosition = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setJobPosition.rejected, (state, action) => {
                state.tasksError = ''
            })
            .addCase(setAssignedUser.fulfilled, (state, action) => {
                state.taskBeingMade.assignedUser = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setDestinationType.fulfilled, (state, action) => {
                state.taskBeingMade.destinationType = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setDestinationFormat.fulfilled, (state, action) => {
                state.taskBeingMade.destinationFormat = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setKeepUpdatedTask.fulfilled, (state, action) => {
                state.taskBeingMade.keepUpdatedCopy = action.payload.payload
                state.tasksError = ''
            })
            .addCase(setRequestStatusReportOnComplete.fulfilled, (state, action) => {
                state.taskBeingMade.requestReportOnCompletion = action.payload.payload
                state.tasksError = ''
            })


            .addCase(setDueDateOfReport.fulfilled, (state, action) => {
                state.taskBeingMade.due = action.payload.payload.value
                state.tasksError = ''
            })

            //Removing Sections
            .addCase(deleteSelectedRows.fulfilled, (state, action) => {
                state.taskBeingMade.fields.splice(0, 1); // 2nd parameter means remove one item only

                //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
                state.tasksError = ''



                console.log('deleteSelectedRows', action,)

                let temp = structuredClone(action.payload.payload.task.fields)
                let temp2 = structuredClone(action.payload.payload.toRemove)
                temp2.sort().reverse().forEach(element => {

                    temp.splice(element, 1)
                });

                state.taskBeingMade.fields = temp
                state.rowsSelectedForDeletion = []

                state.FormsError = ''



            })

            .addCase(setRepeatIntervalType.fulfilled, (state, action) => {
                console.log('setRepeatIntervalType: ', action, action.value)


                state.taskBeingMade.intervalType = action.payload.payload.value.label

                state.FormsError = ''
            })

            //Removing Sections
            .addCase(RemoveSection.fulfilled, (state, action) => {
                state.taskBeingMade.fields
                state.taskBeingMade.fields.splice(0, 1); // 2nd parameter means remove one item only

                //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
                state.tasksError = ''
            })

            //Assigning input when changed
            .addCase(SaveItemToField.fulfilled, (state, action) => {
                state.taskBeingMade.fields[action.payload.payload.index].item = action.payload.payload.item
                state.tasksError = ''
            })


            //Assigning input when changed
            .addCase(updateNoteNotification.fulfilled, (state, action) => {
                state.taskBeingMade.fields[action.payload.payload.index].note = action.payload.payload.note
                state.tasksError = ''
            })

            .addCase(setEndDate.fulfilled, (state, action) => {
                console.log('setStartDate: ', action, action.value)


                state.taskBeingMade.endDate = action.payload.payload.data

                state.FormsError = ''
            })


            //Assigning input when changed
            .addCase(addUserResponse.fulfilled, (state, action) => {
                //  state.formBeingMade.sections[action.payload.payload.sectionIndex].fields[action.payload.payload.fieldIndex].userResponse.push({ question: '', answer: '' })

                state.taskBeingMade.fields[action.payload.payload.fieldIndex].userResponse.push({ question: '', answer: '' })
                state.tasksError = ''
            })



            //Assigning input when changed
            .addCase(removeUserResponse.fulfilled, (state, action) => {
                //  state.formBeingMade.sections[action.payload.payload.columnIndex.sectionIndex ].fields[action.payload.payload.columnIndex.fieldIndex].responseColumns.splice(action.payload.payload.columnIndex.columnIndex, 1)

                state.taskBeingMade.fields[action.payload.payload.fieldIndex].userResponse.splice(action.payload.payload.columnIndex.columnIndex, 1)
                state.tasksError = ''
            })

            //Selecting tasks
            .addCase(SetTask.fulfilled, (state, action) => {
                state.loadedTask = action.payload.payload
                state.loading = 'Done';
                state.tasksError = ''
            })

            //Creating Tasks
            .addCase(addNewTask.fulfilled, (state, action) => {
                state.loading = false;
                state.tasksError = ''
            })

            //Deleting Tasks
            .addCase(deleteTask.rejected, (state, action) => {
                console.log("Error :", action.error.message)
                state.loading = false;
                state.tasksError = action.error.message
            })
            .addCase(deleteTask.pending, (state, action) => {
                state.loading = true;
                state.tasksError = ''
            })
            .addCase(deleteTask.fulfilled, (state, action) => {
                state.loading = false; //by setting false here, the component will call fetch to update data
                state.tasksError = ''
            })

            .addCase(setIntervalValue.fulfilled, (state, action) => {
                state.taskBeingMade.intervalValue = action.payload.payload.label
                state.tasksError = ''
            })
            .addCase(setIntervalAmount.fulfilled, (state, action) => {
                state.taskBeingMade.setIntervalAmount = action.payload.payload.label
                state.tasksError = ''
            })


            .addCase(setDisplayOfReport.fulfilled, (state, action) => {
                console.log('setDisplayOfReport: ', action, action.value)


                state.taskBeingMade.display = action.payload.payload.value

                state.FormsError = ''
            })







            .addCase(setMonthValueForYearRecurrence.fulfilled, (state, action) => {
                console.log('setMonthValueForYearRecurrence: ', action, action.value)

                state.taskBeingMade.monthValueForYear = action.payload.payload.value

                state.FormsError = ''
            })

            .addCase(setMonthDayToRepeat.fulfilled, (state, action) => {
                console.log('setMonthDayToRepeat: ', action, action.value)

                state.taskBeingMade.monthDayToRepeat = action.payload.payload.value

                state.FormsError = ''
            })
            .addCase(setOrdinalValueMonth.fulfilled, (state, action) => {
                console.log('setOrdinalValueMonth: ', action, action.value)

                state.taskBeingMade.ordinalMonthValue = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setMonthOption.fulfilled, (state, action) => {
                console.log('setMonthOption: ', action, action.value)

                state.taskBeingMade.monthOption = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setYearOption.fulfilled, (state, action) => {
                console.log('setYearOption: ', action, action.value)

                state.taskBeingMade.yearOption = action.payload.payload.value

                state.FormsError = ''
            })



            .addCase(setOrdinalValueYear.fulfilled, (state, action) => {
                console.log('setOrdinalValueYear: ', action, action.value)

                state.taskBeingMade.ordinalValueForYear = action.payload.payload.value

                state.FormsError = ''
            })

            .addCase(setDayValueOfMonthRecurrence.fulfilled, (state, action) => {
                console.log('setMonthValueForYearRecurrence: ', action, action.value)

                state.taskBeingMade.dayValueForMonth = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setDayValueForYearRecurrence.fulfilled, (state, action) => {
                console.log('setDayValueForYearRecurrence: ', action, action.value)

                state.taskBeingMade.dayValueForYearRecurrence = action.payload.payload.value

                state.FormsError = ''
            })


            .addCase(setMonthValueForYearRecurrenceBottom.fulfilled, (state, action) => {
                console.log('setMonthValueForYearRecurrenceBottom: ', action, action.value)

                state.taskBeingMade.monthValue2ForYear = action.payload.payload.value

                state.FormsError = ''
            })


    },
});


export const loadTasks = (options) => {
    return fetchNotifications(options)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotifications = (state) => state.notifications;
export const getNotificationsLoadStatus = (state) => state.notifications.loadingTasks;
export const gettasksError = (state) => state.notifications.tasksError;
export const getLoadedNotifications = (state) => state.notifications.loadedTask;
export const getRowsForDeletion = (state) => state.notifications.rowsSelectedForDeletion;


export default notificationSlice.reducer;
export const itemActions = notificationSlice.actions;
