import allReducers from './reducer/index';
import { configureStore, createStore, compose } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';

const redux = require('redux')
const thunkMIiddleware = require('redux-thunk')


const applyMiddleware = redux.applyMiddleware
//const StateLoader = require("../state.loader")
//import {StateLoader} from "../state.loader"
//export const stateLoader = new StateLoader()

//export const store = configureStore({ reducer: allReducers }, composeWithDevTools())

/*
export default configureStore({
    reducer: allReducers, // ES6, ie.:  reducer:reducer
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMIiddleware),
    composeWithDevTools()
});

*/

const enhancers = [ applyMiddleware(thunkMIiddleware)] //stateLoader.loadState(),

const store = configureStore({ reducer: allReducers }, compose(...enhancers), composeWithDevTools())

store.subscribe(() => {
 // console.log('subscribed for actions', store.getState());

  /*
  if(store.getState().action.indexOf('messanger') !== -1) {
  }*/

});

export default store






  //.prepend(thunkMIiddleware.middleware)