import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


import fetch from "node-fetch"

const FETCH_VENDORS_REQUESTED = 'FETCH_VENDORS_REQUESTED'
const FETCH_VENDORS_SUCCEDED = 'FETCH_VENDORS_SUCCEDED'
const FETCH_VENDORS_FAILED = 'FETCH_VENDORS_FAILED'
const SAVE_ITEM_SELECTION = 'SAVE_ITEM_SELECTION'
const SAVE_QUANTITY_SELECTION = 'SAVE_QUANTITY_SELECTION'
const SET_PR = 'SET_PR'
const ADD_CART_TO_PURCHASE_REQUEST = "ADD_CART_TO_PURCHASE_REQUEST"
const SET_Page_View = "SET_Page_View"


const initialState = {
  pendingOrder: {},
  selectedPR: {},
  currentView: 'request',
  summary: {},
  selectedOrder: {}

};

export const fetchpurchaseRequest = createAsyncThunk(
  'purchaseRequest/fetchpurchaseRequest',
  async () => {
    try {

      let test = await fetch("http://localhost:4000/vendor")

      const realData = await test.json();

      return realData
    }
    catch (err) {
      return err.message
    }

  }
);

export const setSelectedOrder = createAsyncThunk(
  'purchaseRequest/setSelectedOrder',
  async (item) => {
      return {
          type: SET_NOTIF,
          payload: item
      }
  }
);

export const setSummary = createAsyncThunk(
  'purchaseRequest/setSummary',
  async (reportingGroup) => {

    console.log("Check setSummary ; ", reportingGroup)

    return {
      type: SET_Page_View,
      payload: reportingGroup
    }

  }
);

export const setPageView = createAsyncThunk(
  'purchaseRequest/pageView',
  async (reportingGroup) => {

    console.log("Check setPageView ; ", reportingGroup)

    return {
      type: SET_Page_View,
      payload: reportingGroup
    }

  }
);


export const addCartToPendingPurchaseRequest = createAsyncThunk(
  'purchaseRequest/addCartToPendingPurchaseRequest',
  async (item) => {

    try {

      console.log("patch : ", JSON.parse(item));


      let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/tasks/${item._id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),

      })

      const realData = await test.json();

      return realData
    }
    catch (err) {
      console.log("whats my error : ", err.message)
      return err.message
    }
  }
);

export const saveQuantityToItem = createAsyncThunk(
  'purchaseRequest/saveQuantityToItem',
  async (item) => {

    return {
      type: SAVE_QUANTITY_SELECTION,
      payload: item
    }

  }
);

export const setPurchaseRequest = createAsyncThunk(
  'purchaseRequest/setPurchaseRequest',
  async (item) => {

    return {
      type: SET_PR,
      payload: item
    }

  }
);

export const saveItemSelected = createAsyncThunk(
  'purchaseRequest/saveItemSelected',
  async (item) => {

    return {
      type: SAVE_ITEM_SELECTION,
      payload: item
    }

  }
);




export const patchVendor = createAsyncThunk('purchaseRequest/patchVendor', async (data) => {

  try {

    console.log("patch ; ", data, data._id)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor/${data._id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

});


export const deleteVendor = createAsyncThunk('purchaseRequest/deleteVendor', async (toDelete) => {


  try {

    console.log("deleting ; ", toDelete)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/vendor/${toDelete._id}`, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toDelete),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("whats my error : ", err.message)
    return err.message
  }

}
);



export const purchaseRequestSlice = createSlice({
  name: 'purchaseRequest',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      .addCase(saveItemSelected.fulfilled, (state, action) => {
        console.log("action in save item selected : ", action)


        //If item is selected, save its information
        if (action.payload.payload.check) {
          console.log("Setting pending order thing")
          if (!state.pendingOrder[action.payload.payload.companyName]) {
            state.pendingOrder[action.payload.payload.companyName] = {}
          }
          state.pendingOrder[action.payload.payload.companyName][action.payload.payload.item.displayName] = action.payload.payload.item
        }
        else { //otherwise make sure its removed from state
          delete state.pendingOrder[action.payload.payload.companyName][action.payload.payload.item.displayName]
        }



      })

      .addCase(saveItemSelected.rejected, (state, action) => {
        console.log("error? : ", action)
      })

      .addCase(saveQuantityToItem.fulfilled, (state, action) => {
        console.log("action in save qty selected : ", action, state.pendingOrder[action.payload.payload.companyName])

        //if the item that had a new quantity added to it exists in our state, update its quantity
        if (state.pendingOrder[action.payload.payload.companyName] && state.pendingOrder[action.payload.payload.companyName][action.payload.payload.item.displayName]) {
          state.pendingOrder[action.payload.payload.companyName][action.payload.payload.item.displayName].pendingOrderQuantity = action.payload.payload.evt
        }
        else {

        }
      })

      .addCase(setSummary.fulfilled, (state, action) => {
        console.log("Action : ",  action)
        state.summary = action.payload.payload
      })


      .addCase(addCartToPendingPurchaseRequest.fulfilled, (state, action) => {
        console.log("action in addCartToPendingPurchaseRequest : ", action)

      })

      //Fetching reportingGroups
      .addCase(setPageView.pending, (state) => {
        console.log("Setting view pending:", state)

      })
      .addCase(setPageView.fulfilled, (state, action) => {
        console.log("Setting view :", action)
        state.currentView = action.payload.payload

        //    console.log("data :", state.reportingGroups, action)
      })
      .addCase(setPageView.rejected, (state, action) => {
        console.log("Setting view rejected:", action)

        state.currentView = action.payload
        state.reportingGroupsError = action.error.message
      })

      .addCase(setPurchaseRequest.fulfilled, (state, action) => {
        if (action.payload.payload.evt) {
          console.log("Setting PR :", action)
          state.selectedPR = action.payload.payload.task
        }
        else {
          console.log("Setting PR TO BE EMPTY :", action)

          state.selectedPR = {}
        }
      })

      

      .addCase(setSelectedOrder.fulfilled, (state, action) => {
            state.setSelectedOrder = action.payload.payload
    })


  },
});


export const loadpurchaseRequest = () => {
  return dispatch(fetchpurchaseRequest())
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectpurchaseRequest = (state) => state.purchaseRequest;
export const selectView = (state) => state.currentView;
export const getSummary = (state) => state.summary




export default purchaseRequestSlice.reducer;
export const purchaseRequestActions = purchaseRequestSlice.actions;
