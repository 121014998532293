import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"



const UPDATE_PRICE = "UPDATE_PRICE"
const UPDATE_GOOD_STATUS = "UPDATE_GOOD_STATUS"
const UPDATE_RECIEVED_STATUS = "UPDATE_RECIEVED_STATUS"
const UPDATE_BAD_STATUS = "UPDATE_BAD_STATUS"
const UPDATE_NOTE = "UPDATE_NOTE"
export const toggleNotRecievedStatus = createAsyncThunk(
    'pendingPurchase/toggleNotRecievedStatus',
    async (item) => {

        return {
            type: UPDATE_RECIEVED_STATUS,
            payload: item
        }

    }
);
export const updatePrice = createAsyncThunk(
    'pendingPurchase/updatePrice',
    async (item) => {

        return {
            type: UPDATE_PRICE,
            payload: item
        }

    }
);
export const toggleBadStatus = createAsyncThunk(
    'pendingPurchase/toggleBadStatus',
    async (item) => {

        return {
            type: UPDATE_BAD_STATUS,
            payload: item
        }

    }
);
export const toggleGoodStatus = createAsyncThunk(
    'pendingPurchase/toggleGoodStatus',
    async (item) => {

        return {
            type: UPDATE_GOOD_STATUS,
            payload: item
        }

    }
);

export const updateNoteOnPendingPurchase = createAsyncThunk(
    'pendingPurchase/updateNoteOnPendingPurchase',
    async (item) => {

        return {
            type: UPDATE_NOTE,
            payload: item
        }

    }
);




export const fetchPendingPurchases = createAsyncThunk(
    'pendingPurchase/fetchPendingPurchase',
    async (data) => {
        try {

            console.log("org id pending purchase: ", data)
            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase?org_id=${data.org_id}&location_id=${data.location_id}`)

            const realData = await test.json();

            return realData
        }
        catch (err) {
            return err.message
        }

    }
);


export const createPendingPurchase = createAsyncThunk(
    'pendingPurchase/createPendingPurchase',
    async (data) => {

        try {


            console.log("data incoming :  ", data)
            const editedItem = structuredClone(data.create);

            for (let i = 0; i < editedItem.length; i++) {
                delete editedItem[i]._id
            }

            console.log("Posting!!!!! !:", editedItem)


            /*
                        const groups = action.payload.docs.reduce((groups, item) => {
                            if (groups[item.vendor.company]) {
                                let group = groups[item.vendor.company]
                                group.push(item);
                            }
                            else {
                                let group = [item]h
                                groups[item.vendor.company] = group;
                            }
            
                            return groups;
                        }, {});*/




            let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/pendingPurchase`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ purchaseOrder: editedItem, org_id: data.org_id, location: data.location }),

            })

            const realData = await test.json();

            return realData
        }
        catch (err) {
            console.log("whats my error : ", err.message)
            return err.message
        }
    }
);


const initialState = {
    ppLoadStatus: '',
    orders: []
};


export const pendingPurchaseSlice = createSlice({
    name: 'pendingPurchase',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder

            .addCase(fetchPendingPurchases.pending, (state, action) => {
                state.ppLoadStatus = '';
            })

            .addCase(fetchPendingPurchases.fulfilled, (state, action) => {
                state.ppLoadStatus = 'Done';

                state.orders = action.payload.docs
            })

            .addCase(createPendingPurchase.pending, (state, action) => {
                state.ppLoadStatus = '';
            })






            .addCase(toggleGoodStatus.fulfilled, (state, action) => {
                if (action.payload.payload.itemIndex) {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder[action.payload.payload.itemIndex].goodStatus = action.payload.payload.value;

                }
                else {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder.goodStatus = action.payload.payload.value;

                }



            })


            .addCase(toggleBadStatus.fulfilled, (state, action) => {
                if (action.payload.payload.itemIndex) {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder[action.payload.payload.itemIndex].badStatus = action.payload.payload.value;

                }
                else {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder.badStatus = action.payload.payload.value;

                }
            })


            .addCase(toggleNotRecievedStatus.fulfilled, (state, action) => {
                if (action.payload.payload.itemIndex) {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder[action.payload.payload.itemIndex].recieved = action.payload.payload.value;

                }
                else {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder.recieved = action.payload.payload.value;

                }

            })


            .addCase(updateNoteOnPendingPurchase.fulfilled, (state, action) => {
                if (action.payload.payload.itemIndex) {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder[action.payload.payload.itemIndex].note = action.payload.payload.value;

                }
                else {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder.note = action.payload.payload.value;

                }

            })



            .addCase(updatePrice.fulfilled, (state, action) => {

                console.log("sent to price :" , action)

                if (action.payload.payload.itemIndex) {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder[action.payload.payload.itemIndex].cost = action.payload.payload.value;

                }
                else {
                    state.orders[action.payload.payload.orderIndex].purchaseOrder.cost = action.payload.payload.value;

9
                }

            })



    },
});





/*
export const loadPendingPurchases = () => {
    return dispatch(fetchpurchaseRequest())
}
*/

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPendingPurchase = (state) => state.pendingPurchase;
export const getPendingPurchaseLoadStatus = (state) => state.pendingPurchase.ppLoadStatus;


export default pendingPurchaseSlice.reducer;

export const pendingPurchaseActions = pendingPurchaseSlice.actions;


