
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Header from '../../Components/Header';
import SideBar from '../../Components/SideBar';
import styles from "./userDashboardAdmin.module.css";
import { addNewItem, deleteItem, fetchItems, getItemLoadStatus, getitemsError, patchItem, selectItems, SetItem } from '../forms/items/itemSlice';
import { getCurrentView, fetchUserSubmittedTask, getTask, setOrderValue, setCheckbox, addAdminSubmittedTask, setTask, setPageView, getLoadStatus, selectuserDashboardAdmin, setLoadStatus, fetchDashboardState } from './userDashboardAdminSlice'
import { useParams } from 'react-router';
import { fetchTasks, setTaskForEdit, getTaskLoadStatus, selectTasks, SetSection, RemoveSection, setKeepUpdatedTask, setRequestStatusReportOnComplete, setDestinationFormat, addNewTask, SaveItemToField, updateQuantity, setFrequency, setDestinationType, updateNote, setJobPosition, setAssignedUser, taskSlice, deleteTask } from '../taskBuilder/taskSlice';
import Select from 'react-select';

function UserDashboard(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();

    const tasks = useSelector(selectTasks);
    const loadingTasks = useSelector(getTaskLoadStatus);

    const userDashboardAdmin = useSelector(selectuserDashboardAdmin);
   // const loadingDashboard = useSelector(getLoadStatus);

    const currentView = useSelector(getCurrentView)

    const params = useParams()

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getTask);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }

    //Error/issue in the pattern here sedtting up userDashboard
    //The select call to  get the dashboard state does not change the loading status
    ///typically here we fetch data and its  passed back through a extra reducer that updates the load state
    //but i dont really have data to return, unless here is where we query for tasks assigned to the user

    var userResponseOptions = [{ label: 'Current Quantity', value: 'Current Quantity' }]
    var actionOptions = [{ label: 'Order', value: 'Order' }]
    var boolOptions = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]

    console.log("Pulled params : ", params, user)


    console.log("Check 123 : ", loadingDashboard)

    useEffect(() => {

        if (loadingDashboard) {
            console.log("fetching user submitted tasks")
            dispatch(fetchUserSubmittedTask())
        }
    }, [loadingDashboard, dispatch])


    useEffect(() => {

        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation, user_id: user._id}))
            //    dispatch(setLoadStatus(true))
        }
    }, [loadingTasks, dispatch])

    console.log("Pulled tasks : ", tasks)
    console.log("Pulled submitted stuff :", userDashboardAdmin, loadedItem)
    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            dispatch(fetchItems({org_id: user.org_id, location: user?.user_metadata?.viewingLocation}))
        }
    }, [loading, dispatch])


    console.log("pulled dashboard :", userDashboardAdmin, "  Load status : ", loadingDashboard, userDashboardAdmin?.loadedTask?.task?.fields)


    /*

                                 userDashboard.loadedTask.task.fields.map((field) => {

                                        field.formRef.form.sections.map((section) => {
                                            
                                                 section.fields.map((sectionField, index3) => (

*/


    return (

        <>
            <div className={styles.pageWrapper}>
                {currentView != "task" && <div>


                </div>}

                <div className={styles.dynamicFormSection}>

                    {currentView != "task" && <div style={{ display: 'flex' }}>

                        <div style={{ display: 'block' }}>



                            {loadingDashboard == false && userDashboardAdmin.userSubmittedTasks.map((task) => (
                                <div style={{ display: 'flex' }}>
                                    <span> {task?.name} </span>

                                    <button style={{ marginLeft: '4%' }} onClick={() => { openTask(task) }}>View form</button>

                                </div>
                            ))}
                        </div>

                    </div>}

                    {currentView == "task" && <div style={{ display: 'flex' }}>


                        <div>

                            <div className={styles.titleSection}>
                                <span> {userDashboardAdmin.loadedTask.name}</span>

                            </div>

                            {loadingDashboard == false && userDashboardAdmin.loadedTask.task.fields.map((field, index1) => {

                                return (field.formRef.form.sections.map((section, index2) => {

                                    return (section.fields.map((sectionField, index3) => {
                                        if (section.type == 'Data Entry') {
                                            return (<div className={styles.DEfields} key={index3} >

                                                <div style={{ minWidth: '100px' }}>
                                                    {index3 == 0 && <span>Data Entry</span>}

                                                </div>

                                                <div className={styles.otherFields}>
                                                    <div className={styles.DEfieldSection}>
                                                        {index3 == 0 && <span>Item</span>}

                                                        <div style={{ marginTop: '5%' }}>
                                                            <span>{sectionField.item.displayName}</span>

                                                        </div>
                                                    </div>
                                                    <div className={styles.DEfieldSection}>
                                                        <div className="checkboxes">
                                                            <label> {index3 == 0 && <span>Current Quantity</span>} </label>  </div>


                                                        <div style={{ marginTop: '5%' }}>

                                                            <span>{sectionField.item.itemAmount}</span>
                                                        </div>
                                                    </div>

                                                    <div className={styles.DEfieldSection}>
                                                        <div className="checkboxes">
                                                            <label> {index3 == 0 && <span>Par</span>} </label>  </div>



                                                        <div style={{ marginTop: '5%' }}>


                                                            <span>{sectionField.parValue}</span>

                                                        </div>

                                                    </div>

                                                    <div className={styles.DEfieldSection}>
                                                        <div className="checkboxes">
                                                            <label> {index3 == 0 && <span>Recomendation</span>} </label>  </div>



                                                        <div style={{ marginTop: '5%' }}>


                                                            <span>{sectionField.pendingCurrentQuantity}</span>

                                                        </div>
                                                    </div>


                                                    <div className={styles.DEfieldSection}>
                                                        <div className="checkboxes">
                                                            <label> {index3 == 0 && <span>Add to Order</span>} </label>  </div>


                                                        <div className="checkboxes" style={{ marginTop: '5%' }}>


                                                            <label><input type="checkbox" onChange={(evt) => setAddToOrderCheckbox(evt, index1, index2, index3)} /> <input className={styles.orangeButton} style={{ width: '100px', height: '28px' }} onChange={(evt) => settingOrderValue(evt, index1, index2, index3)} /></label>  </div>

                                                    </div>

                                                </div>

                                            </div>
                                            )
                                        }
                                        if (section.type == 'Response') {
                                            return (<div className={styles.DEfields} key={index3} >

                                                <div style={{ minWidth: '100px' }}>
                                                    {index3 == 0 && <span>Response Form</span>}

                                                </div>

                                                <div className={styles.otherFields}>
                                                    <div className={styles.DEfieldSection} style={{ width: '500px' }}>
                                                        <span >Description</span>

                                                        <div style={{ marginTop: '3%' }}>
                                                            <span>{sectionField.description}</span>

                                                            <div style={{ marginTop: '2%' }}>
                                                                <span value={sectionField.userResponse} >{sectionField.userResponse}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            )
                                        }

                                        if (section.type == 'Checklist') {
                                            return (<div className={styles.DEfields} key={index3} >

                                                <div style={{ minWidth: '100px' }}>
                                                    {index3 == 0 && <span>Checklist Form</span>}

                                                </div>

                                                <div className={styles.otherFields}>
                                                    <div className={styles.DEfieldSection} style={{ width: '500px' }}>
                                                        <span>Description</span>

                                                        <div style={{ marginTop: '3%' }}>
                                                            <span>{sectionField.description}</span>

                                                            <div style={{ marginTop: '3%' }}>

                                                                <span>{sectionField.checkboxResponse}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            )
                                        }

                                        if (section.type == 'Visual') {
                                            return (<div className={styles.DEfields} key={index3} >

                                                <div style={{ minWidth: '100px' }}>
                                                    {index3 == 0 && <span>Visual Form</span>}

                                                </div>

                                                <div className={styles.otherFields}>
                                                    <div className={styles.DEfieldSection} style={{ width: '500px' }}>


                                                        <div style={{ marginTop: '3%' }}>
                                                            {section.fields[index3].adminImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${section.fields[index3].adminImageUpload}`} />}
                                                        </div>


                                                        <div style={{ marginTop: '3%' }}>
                                                            <span>{sectionField.description}</span>
                                                        </div>



                                                    </div>

                                                </div>


                                                <div style={{ marginLeft: '10%' }}>
                                                    <div className={styles.DEfieldSection} style={{ width: '500px' }}>

                                                        <div style={{ marginTop: '3%' }}>
                                                            {section.fields[index3].userImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${section.fields[index3].userImageUpload}`} />}
                                                        </div>


                                                        <div style={{ marginTop: '3%' }}>
                                                            <span>{sectionField.description}</span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            )
                                        }

                                    })
                                    )
                                })
                                )


                            })}

                        </div>


                    </div>}



                </div>


                {currentView == "task" && <div style={{ display: 'flex' }}>

                    <div className={styles.bottomSection}>
                        <div className={styles.formBtnDiv}>
                            <button onClick={() => { onCancelClicked() }} className={styles.formBtn}>Cancel</button>
                            <button onClick={() => { onSubmitClicked(loadedItem) }} className={styles.formBtn}>Submit</button>
                        </div>


                    </div>


                </div>}

            </div>
        </>
    )




    function settingOrderValue(evt, index1, index2, index3) {
        console.log("setOrderValue evt, index1, index2, index3:", evt, index1, index2, index3)

        dispatch(setOrderValue({ value: evt.target.value, index1, index2, index3 }))

    }


    function setAddToOrderCheckbox(evt, index1, index2, index3) {
        console.log("setAddToOrderCheckbox evt, index1, index2, index3:", evt.target.value, index1, index2, index3)

        dispatch(setCheckbox({ value: evt.target.value, index1, index2, index3 }))
    }

    function openTask(task) {
        console.log("Opening task :", task)


        dispatch(setTask(task))

    }

    function onSubmitClicked(item) {
        console.log("Submit clicked", item)
        dispatch(addAdminSubmittedTask(item))
    }

    function onDeleteClicked(item) {
        console.log("deleting:", item)
        dispatch(deleteItem(item))

        clearDataFromFields()
    }


    function onCancelClicked() {
        //should clear selected states too todo

        dispatch(setPageView(""))
        //  dispatch(setTask({}))

    }

    function onSaveClicked() {


        if (loadedItem._id) {
            dispatch(patchItem({
                displayName: document.getElementById('displayName').value,
                vendor: loadedVendor, //must match collection name in firebase 'Vendors'
                cost: document.getElementById('Cost').value,
                createdAt: document.getElementById('Date').value,
                dateOfCost: document.getElementById('Date').value,
                itemAmount: document.getElementById('displayName').value,
                itemName: document.getElementById('displayName').value,
                par: document.getElementById('Par').value,
                pendingOrderQuantity: document.getElementById('displayName').value,
                size: document.getElementById('Size').value,
                itemNumber: document.getElementById("itemNumber").value,
                reportingGroup: loadedRG,
                _id: loadedItem._id
            }))
        }
        else {
            dispatch(addNewItem({
                displayName: document.getElementById('displayName').value,
                vendor: loadedVendor, //must match collection name in firebase 'Vendors'
                cost: document.getElementById('Cost').value,
                createdAt: document.getElementById('Date').value,
                dateOfCost: document.getElementById('Date').value,
                itemAmount: document.getElementById('displayName').value,
                itemName: document.getElementById('displayName').value,
                par: document.getElementById('Par').value,
                pendingOrderQuantity: document.getElementById('displayName').value,
                size: document.getElementById('Size').value,
                itemNumber: document.getElementById("itemNumber").value,
                reportingGroup: loadedRG,

            }))
        }

        dispatch(fetchItems({org_id: user.org_id, location: user?.user_metadata?.viewingLocation}))

    }

    function clearDataFromFields() {
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''
    }

}

export default UserDashboard;