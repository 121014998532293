import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import fetch from "node-fetch"


const FETCH_USER_DASHBOARD = 'FETCH_USER_DASHBOARD'
const FETCH_USER_DASHBOARD_SUCCEDED = 'FETCH_USER_DASHBOARD_SUCCEDED'
const FETCH_USER_DASHBOARD_FAILED = 'FETCH_USER_DASHBOARD_FAILED'
const SET_LOADED_ITEM = 'SET_LOADED_ITEM'
const SET_TASK = 'SET_TASK'
const SET_PAGE_VIEW = "SET_PAGE_VIEW"
const SET_QTY_FIELD = "SET_QTY_FIELD"
const SET_CHECKBOX_FIELD = "SET_CHECKBOX_FIELD"
const SET_RESPONSE_FIELD = "SET_RESPONSE_FIELD"
const SET_CHECKBOX = "SET_CHECKBOX"
const SET_ORDER_VALUE = "SET_ORDER_VALUE"

export const fetchDashboardState = createAsyncThunk(
  'userDashboardAdmin/fetchDashboardState',
  async () => {

    return {
      type: FETCH_USER_DASHBOARD,
      payload: {}
    }

  }
);


export const updateCheckboxField = createAsyncThunk(
  'userDashboardAdmin/updateCheckboxField',
  async (page) => {
    return {
      type: SET_CHECKBOX_FIELD,
      payload: page
    }

  }
);

export const updateResponseField = createAsyncThunk(
  'userDashboardAdmin/updateResponseField',
  async (page) => {
    return {
      type: SET_RESPONSE_FIELD,
      payload: page
    }

  }
);
export const updateQuantityField = createAsyncThunk(
  'userDashboardAdmin/updateQuantityField',
  async (page) => {
    return {
      type: SET_QTY_FIELD,
      payload: page
    }

  }
);

export const setPageView = createAsyncThunk(
  'userDashboardAdmin/pageView',
  async (page) => {
    return {
      type: SET_PAGE_VIEW,
      payload: page
    }

  }
);


export const setTask = createAsyncThunk(
  'userDashboardAdmin/setTask',
  async (task) => {
    console.log("Task :", task)
    return {
      type: SET_TASK,
      payload: task
    }

  }
);


//its probably bad practice somehow to update the state like this
export const setLoadStatus = createAsyncThunk(
  'userDashboardAdmin/setLoadStatus',
  async (status) => {
    console.log("setting LoadStatus :", status)
    return {
      type: SET_TASK,
      payload: status
    }

  }
);



export const setOrderValue = createAsyncThunk(
  'userDashboardAdmin/setOrderValue',
  async (status) => {
    console.log("setting setOrderValue :", status)
    return {
      SET_ORDER_VALUE,
      payload: status
    }

  }
);

export const setCheckbox = createAsyncThunk(
  'userDashboardAdmin/setCheckbox',
  async (status) => {
    console.log("setting setCheckbox :", status)
    return {
      SET_CHECKBOX,
      payload: status
    }

  }
);



export const fetchUserSubmittedTask = createAsyncThunk('Tasks/fetchUserSubmittedTask', async (param) => {

  try {

    console.log("fetchUserSubmittedTask incoming submitted task params :", param)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/userSubmittedTasks?org_id=` + param.org_id + '&location=' + param.viewingLocation + '&user_id=' + param.user_id + '&job_id='+param.job_id)
    const realData = await test.json();

    return realData
  }
  catch (err) {
    console.log("Fetching submitted tasks error :", err)

    return err.message
  }

}
);


export const addAdminSubmittedTask = createAsyncThunk('Tasks/addAdminSubmittedTask', async (initialData) => {

  try {

    console.log("Check create ; ", initialData)

    let test = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/adminTaskSubmission`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(initialData),

    })

    const realData = await test.json();

    return realData
  }
  catch (err) {
    return err.message
  }

}
);

const initialState = {
  loadingPageInfo: true,
  currentView: '',
  dashboardError: null,
  userSubmittedTasks: {},
  userDashboardAdmin: {},
  loadedTask: {}
};





export const itemSlice = createSlice({
  name: 'userDashboardAdmin',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    FETCH_USER_DASHBOARD: (state) => {
      console.log("Returning dashboard :", state.userDashboardAdmin)
      return state.dashboard
    },

    FETCH_USER_DASHBOARD_SUCCEDED: (state, action) => {
      return { loading: false, items: action.payload, error: '' }
    },

    FETCH_USER_DASHBOARD_FAILED: (state, action) => {
      // console.log("fetch items failed reached")
      return { loading: false, items: [], error: action.payload }
    },
    /*
        SET_LOADED_ITEM: (state, action) => {
          state.loadedItem = action.payload
          console.log("Set loadedItem :" , state.loadedItem)
          return state
        }*/
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder





      .addCase(fetchDashboardState.fulfilled, (state, action) => {
        console.log("'fetching' board state, and changing load status:", action)
        state.userDashboardAdmin.userDashboardAdmin = { test: "test" }
        state.loadingPageInfo = false

      })

      .addCase(setLoadStatus.fulfilled, (state, action) => {
        console.log("Setting(forcing) load status :", action)
        state.loadingPageInfo = action.payload.payload;
      })
      .addCase(setPageView.pending, (state) => {
        state.loadingPageInfo = true;
        console.log("Setting view pending:", state)
        s
      })
      .addCase(setPageView.fulfilled, (state, action) => {

        console.log("Setting view :", action)
        state.loadingPageInfo = false;
        state.currentView = action.payload.payload

        //    console.log("data :", state.reportingGroups, action)
      })
      .addCase(setPageView.rejected, (state, action) => {
        console.log("Setting view rejected:", action)

        state.loadingPageInfo = false;
        state.currentView = action.payload
        state.reportingGroupsError = action.error.message
      })

      .addCase(setTask.fulfilled, (state, action) => {
        console.log("Setting task :", action)
        state.loadedTask = action.payload.payload
        state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })


      //Creating Tasks
      .addCase(addAdminSubmittedTask.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingPageInfo = false

        console.log("addAdminSubmittedTask Success :", state, action)
        //  state.tasks.push(action.payload) TODO ideally tasks are updated here, but couldnt get it working so going to call GET again
        state.tasksError = ''
      })




      .addCase(fetchUserSubmittedTask.fulfilled, (state, action) => {
        console.log("fetched submitted tasks :", action)
        state.loading = false;
        state.loadingPageInfo = false
        state.userSubmittedTasks = action.payload.docs
        state.tasksError = ''
        // console.log("data :", state.items, action)
      })



      .addCase(updateCheckboxField.fulfilled, (state, action) => {
        console.log("Setting updateCheckboxField :", action)
        state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].checkboxResponse = action.payload.payload.value
        state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      .addCase(updateQuantityField.fulfilled, (state, action) => {
        console.log("Setting updateQuantityField :", action)
        state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].pendingCurrentQuantity = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      .addCase(updateResponseField.fulfilled, (state, action) => {
        console.log("Setting updateResponseField :", action)
        state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].userResponse = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })

      .addCase(setCheckbox.fulfilled, (state, action) => {
        console.log("Setting setCheckbox :", action, state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3])
        state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].adminIncludeInOrder = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      

      .addCase(setOrderValue.fulfilled, (state, action) => {
        console.log("Setting setCheckbox :", action, state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3])
        state.loadedTask.task.fields[action.payload.payload.index1].formRef.form.sections[action.payload.payload.index2].fields[action.payload.payload.index3].addToOrderValue = action.payload.payload.value
        //   state.loadingPageInfo = true
        state.dashboardError = ''
        state.currentView = "task"

      })
      






  },
});

//export const { fetch, create } = itemSlice.actions;

export const loaduserDashboardAdmin = () => {
  return dispatch(fetchuserDashboardAdmin())
}

/*
export const setLoadeduserDashboardAdmin = (item) => {
  return dispatch(SET_SELECTED_ITEM(item))
}
*/


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectuserDashboardAdmin = (state) => {
  console.log("StaTe: ", state)
  return state.userDashboardAdmin
};
export const getAdminLoadStatus = (state) => state.userDashboardAdmin.loadingPageInfo;
export const getdashboardError = (state) => state.userDashboardAdmin.dashboardError;
export const getTask = (state) => state.userDashboardAdmin.loadedTask;
export const getCurrentView = (state) => state.userDashboardAdmin.currentView;

export const getUserSubmittedTasks = (state) => state.userDashboardAdmin.userSubmittedTasks;


export default itemSlice.reducer;
export const itemActions = itemSlice.actions;



/*
  reducers: {
    fetch: (state) => {
      fetchData()
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.items =  fetchData();
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    create: (state, action) => {
      state.value += action.payload;
    },
  },

*/